import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MzModalComponent } from 'ngx-materialize';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-self-assessment',
  templateUrl: './self-assessment.component.html',
  styleUrls: ['./self-assessment.component.less']
})
export class SelfAssessmentComponent implements OnInit {
  @ViewChild('bottomSheetModal') bottomSheetModal: MzModalComponent
  @ViewChild('warningModel') warningModel: MzModalComponent
  @ViewChild('history') history: MzModalComponent
  @ViewChild('targethistory') targetHistory: MzModalComponent
  @ViewChild('parameterwiseData') parameterwiseData: MzModalComponent
  @ViewChild('h1computation') h1computation: MzModalComponent
  // displayedColumns: string[] = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfAssessment', 'SelfComments'];
  displayedColumns: string[] = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments'];
  selectedPeriod: number;
  roleSelected: string;
  userInfo: any;
  masterData: any;
  errorMessage: string;
  tblMetrics: any;
  H1tblMetrics: any;
  showMetricsTable: boolean = false;
  DisagreementReasons: any;
  DisagreementRemarks: string;
  DisagreementReasonSelected: any;
  isSubmissionAllowed: boolean = false;
  dueDate: string = "";
  warningdata: string;
  action: string;
  DisagreementData: any;
  MetricsDisagreementData: any
  DisagreementMessage: string;
  IsDBApplicable: number;
  IsAppraisalApplicable: number;
  IsSPIApplicable: number;
  Proratedvalue: string = '';
  TotalVCP: string = '';
  FinalPayout: string = '';
  TotalPayable: string = '';
  AccountList: any;
  CompanyPerFormance: string = '';
  WeightedPercentage: string = '';
  H1WeightedPercentage: string = '';
  TotalWeightedAverage: string = '';
  RevenueAchievement: string = '';
  NewJoineeMsg: string = '';
  NewJoinee: number = 0;
  NewJoineeCheckForAppraisal: number = 0;
  SPIAmount: string = '';
  ApprovedComissionAmount: string = '';
  targetHistoryArr: any = [];
  OtherParamArr: any = [];
  ManagerStartDoing: string = '';
  ManagerContinueDoing: string = '';
  ManagerStopDoing: string = '';
  showOtherMetricsTable: boolean = false;
  OtherParameterRatingMaster: any = [];
  PeriodType: string = 'Half-Yearly';
  StrataTable: any = [];
  QuantitativePercentage: string = '';
  QualitativePercentage: string = '';
  showStratatable: boolean = false;
  AppraisalStatus: number = 0;
  NetAmount: string = '';
  PaidAmount: string = '';
  SalesIncentiveText: string = '';
  SalesIncentiveURL: string = '';
  AppType: string = '';
  EmpPlans: string = '';
  show: boolean = false;
 //OtherdisplayedColumns: string[] = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveComments', 'ExecutiveWeightage'];
  OtherdisplayedColumns: string[] = ['ParameterName', 'DBWeightage', 'Target', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ReviewerComments', 'ExecutiveComments', 'ExecutiveWeightage'];
  FeedbackDetails: any = [];
  FeedbackApplicable: boolean = false;
  IsFeedbackDetails: boolean = false;
  FeedbackdisplayedColumns: string[];
  PeriodTypeNew: string = 'Half-Yearly';
  GrowthKickerPercentage: string = '';
  AchievementincludingMBO: string = '';
  FinalGrowthKickerPercentage: string = '';
  GrowthKickerValue: string = '';
  MosaicValue: string = '';
  MosaicStatus: string = '';
  MSAValue: string = '';
  MSAStatus: string = '';
  LDValue: string = '';
  LDStatus: string = '';
  TotalAmountPayable: string = '';
  DisPeriodArray:any=[];
  PortfolioGrowth: string = '';
  H1PortfolioGrowth: string = '';
  TotalAmountPaid: string = '';
  RemainingPayable: string = '';
  SPIName: string = '';
  tblIncentive: any;
  H1tblIncentive: any;
  GrowthKickerStatus: string = '';
  MosaicKickerValue: string = '';
  MSAKickerValue: string = '';
  LDKickerValue: string = '';
  UpdatedAchievement: string = '';
  H1UpdatedAchievement: string = '';
  SalesIncentiveURLByCountry: string = '';
  SalesIncentiveTextByCountry: string = '';
  tblDiscretionary: any;
  showDiscretionarytable: boolean = false;
  DiscretionaryTotalvalue: string = '';
  DiscretionaryProrated: string = '';
  DiscretionaryPayout: string = '';
  TotalExecutiveWeightage: string = '';
  DbPaid: string = '';
  RemainingDbPayable: string = '';
  TotalExecutiveDBRating: number = 0;
  PlanType: string = '';
  Plan: string = '';
  PlanTypeFlag: boolean = false;
  TotalWeightage: number = 0;
  TotalSelfRating: number = 0;
  TotalManagerRating: number = 0;
  TotalReviewerRating: number = 0;
  AppraisalWeightageApplicable: number = 0;
  DbNAFlag: boolean = false;
  DBStatus: number = 0;
  FinalPayoutDB: string = '';
  H1overallachievement: any;
  tblIsHistoryRead; any;
  IsReadbyEmployee: number;
  IsReadbyManager: number;
  parameterwiseDatarr: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblMetrics = [];
    this.H1tblMetrics = [];
    this.DisagreementReasons = [];
    this.DisagreementRemarks = '';
    this.DisagreementReasonSelected = [];
    this.DisagreementData = {};
    this.MetricsDisagreementData = [];
    this.DisagreementMessage = '';
    this.IsDBApplicable = 0;
    this.IsAppraisalApplicable = 0;
    this.IsSPIApplicable = 1;
    this.AccountList = [];
    this.selectedPeriod = 0;
    this.targetHistoryArr = [];
    this.OtherParamArr = [];
    this.FeedbackDetails = [];
    this.tblIncentive = [];
    this.H1tblIncentive = [];
    this.tblDiscretionary = [];
    this.PlanType = "0";
    this.AppraisalWeightageApplicable = 0;
    this.tblIsHistoryRead = [];
    this.IsReadbyEmployee = 0;
    this.IsReadbyManager = 0;
    this.parameterwiseDatarr = []
  }

  ngOnInit() {
    const getFullDate = new Date();
    const currentDate = new Date().toISOString().slice(0, 10);
    const getYear = getFullDate.getFullYear();
    const fullYrAprilm = getYear + '-04-01';
    const fullYrSeptm = getYear + '-09-30';
    var hfyOctm = getYear + '-10-01';
    var hfyMarchm = getYear + '-03-31';   

    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.masterData = this.appService.GetMasterDetails();
        if (this.userInfo.AccessType[0].Showperiodyear == 'isFullyearly') {
          for (var o in this.masterData.dtPeriodDet) {
            if ((this.masterData.dtPeriodDet[o].PeriodID) % 2 == 0) {
              this.DisPeriodArray.push(this.masterData.dtPeriodDet[o]);
            }
          }
          this.masterData.dtPeriodDet = this.DisPeriodArray;
        }
        this.roleSelected = "2";
        var ActualDataObj = {};

        ActualDataObj = this.appService.GetActualData();
        if (ActualDataObj == undefined) {
          //this.selectedPeriod = 12;

          var octdt = null;
          this.appService.SetLoadingShow(true);
          if (currentDate > hfyMarchm) {
            octdt = getYear + '-10-01';
            if (currentDate < hfyOctm) {
              hfyMarchm = getYear + '-03-31';
            }
            else {
              hfyMarchm = getYear + 1 + '-03-31';
            }
          }
          else if (currentDate <= hfyMarchm) {
            octdt = getYear - 1 + '-10-01';
            if (currentDate >= octdt) {
              hfyMarchm = getYear + '-03-31';
            }
            else {
              hfyMarchm = getYear + 1 + '-03-31';
            }
          }

          if (currentDate >= octdt && currentDate <= hfyMarchm) {
            this.selectedPeriod = this.masterData.dtPeriodDet[1].PeriodID;
          }
          else if (currentDate >= fullYrAprilm && currentDate <= fullYrSeptm) {
            this.selectedPeriod = this.masterData.dtPeriodDet[2].PeriodID;
          }

        }
        else {
          this.selectedPeriod = ActualDataObj['PeriodID'];
          this.appService.ResetSetActualData();
        }       
        this.onPeriodChange(this.selectedPeriod, this.masterData.dtUserDet[0].DeputedBU);       
        this.DisagreeWithActualsReasons(false);
      }
      else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }

  onPeriodChange(id: number, Bu: string): void {
    this.PlanTypeFlag = false;
    this.TotalExecutiveDBRating = 0;
    this.TotalWeightage = 0;
    this.TotalSelfRating = 0;
    this.TotalManagerRating = 0;
    this.TotalReviewerRating = 0;
    this.tblMetrics = [];
    this.H1tblMetrics = [];
    this.IsFeedbackDetails = false;
    this.showDiscretionarytable = false;
    this.SalesIncentiveURLByCountry = '';
    if (id.toString() != '') {
        var dueArray = this.masterData.dtSBUPeriodDet.filter((due: any) => due.PeriodID == id && due.SBU == Bu && due.PSNo == this.userInfo.PS_No);
      if (dueArray.length > 0 && (dueArray[0].EmpDeadline != undefined || dueArray[0].EmpDeadline != null)) {
        this.dueDate = dueArray[0].EmpDeadline.replace("T00:00:00", "");
      }
      else if (dueArray.length == 0 || dueArray[0].EmpDeadline == undefined || dueArray[0].EmpDeadline == null) {
        this.dueDate = '';
      }
      var UserData = {
        "PS_No": this.userInfo.PS_No,
        "PeriodId": id.toString(),
        'Role': 'Employee',
          'UserType': '',
          "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.MetricsDetailsURL();
      this.appService.GetDataFromAPI(url, UserData)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.selectedPeriod = id;
            this.getOtherParametersData();
            var YearTextArr = this.masterData.dtPeriodDet.filter((period: any) => period.PeriodID == id);
            var YearText = YearTextArr[0].TargetUploadYearText;
            if (this.userInfo.AppType == 1) {
              // this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfAssessment', 'SelfComments'];
              this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments'];
            }
            else if (this.userInfo.AppType == 2) {
              this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments'];

            }
              this.tblMetrics = response.ResponseData.dtMertricsDet;
            
            this.tblIncentive = response.ResponseData.dtIncentiveDet;
            this.tblDiscretionary = response.ResponseData.dtDiscretionaryDet;
            this.isSubmissionAllowed = response.ResponseData.isSubmissionAllowed;
            this.DisagreementMessage = response.ResponseData.DisagreementStatus;
            this.AccountList = response.ResponseData.dtAccounts;
            //this.EmpPlans = response.ResponseData.EmpPlans;
            this.masterData.EmpPlans = response.ResponseData.EmpPlans;
            this.IsDBApplicable = response.ResponseData.IsDBApplicable;
            this.IsAppraisalApplicable = response.ResponseData.IsAppraisalApplicable;
            this.IsSPIApplicable = response.ResponseData.IsSPIApplicable;
            
           
            if (this.tblMetrics.length > 0) {
              this.PlanType = this.tblMetrics[0].PlanType;
              this.Plan = this.tblMetrics[0].ROLE;
              if (this.PlanType == "1") {
                this.PlanTypeFlag = true;
              }
              else {
                this.PlanTypeFlag = false;
                this.showMetricsTable = true;
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].StatusID == 6)) {
                // this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfAssessment', 'SelfComments', 'ManagerAssessment', 'ManagerComments'];
                // this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfAssessment', 'SelfComments'];
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments'];
              }
              else if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].StatusID == 7 && this.AppraisalStatus === 14)) {
                // this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfAssessment', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
              }
              else if (this.tblMetrics[0] != undefined && this.tblMetrics[0].StatusID == 14 && this.AppraisalStatus === 7) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'AchievementWeighted'];
              }
              else if (this.tblMetrics[0] != undefined && this.tblMetrics[0].StatusID == 14 && (this.AppraisalStatus === 14 || this.userInfo.AppType == 2)) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'AchievementWeighted', 'ManagerComments', 'ReviewerComments'];
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanText != '') && (this.tblMetrics[0].IncentivePlanURL != '') && (this.tblMetrics[0].IncentivePlanURL != null)) {
                this.SalesIncentiveText = this.tblMetrics[0].IncentivePlanText + YearText;
                this.SalesIncentiveURL = this.tblMetrics[0].IncentivePlanURL + YearText + '.pdf';
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanTextByCountry != '') && (this.tblMetrics[0].IncentivePlanURLByCountry != '' && this.tblMetrics[0].IncentivePlanURLByCountry != null)) {
                this.SalesIncentiveTextByCountry = this.tblMetrics[0].IncentivePlanTextByCountry + YearText;
                this.SalesIncentiveURLByCountry = this.tblMetrics[0].IncentivePlanURLByCountry + YearText + '.pdf';
              }
              this.PeriodTypeNew = this.tblMetrics[0].PeriodType;
              if (this.PeriodTypeNew == 'Half-Yearly') {
                this.GetFeedbackDetails();
              }
              //this.dataSource = this.tblMetrics;
              this.SPIAmount = this.tblIncentive[0].SPIAmount;
              this.TotalPayable = this.tblIncentive[0].PayoutAmount;
              this.Proratedvalue = this.tblIncentive[0].ProRatedValue;
              this.NetAmount = this.tblIncentive[0].NetAmount;
              this.PaidAmount = this.tblIncentive[0].PaidAmount;
              this.TotalVCP = this.appService.AddPercentageSign(this.tblIncentive[0].TotalVCP);
              this.FinalPayout = this.appService.AddPercentageSign(this.tblIncentive[0].FinalPayout);
              this.CompanyPerFormance = this.appService.AddPercentageSign(this.tblIncentive[0].CompanyPerformance);
              this.NewJoinee = this.tblMetrics[0].NewJoinee;
              this.AppType = this.tblMetrics[0].AppType;
              this.NewJoineeCheckForAppraisal = this.tblMetrics[0].NewJoineeCheckForAppraisal;
              this.GrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].GrowthKickerPercentage);
              this.FinalGrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].FinalGrowthKickerPercentage);
              this.GrowthKickerValue = this.tblIncentive[0].GrowthKickerValue;
              this.AchievementincludingMBO = this.appService.AddPercentageSign(this.tblIncentive[0].AchievementincludingMBO);
              this.MosaicValue = this.tblIncentive[0].MosaicValue;
              this.MosaicStatus = this.tblIncentive[0].MosaicStatus;
              this.MSAValue = this.tblIncentive[0].MSAValue;
              this.MSAStatus = this.tblIncentive[0].MSAStatus;
              this.LDValue = this.tblIncentive[0].LDValue;
              this.LDStatus = this.tblIncentive[0].LDStatus;
              this.TotalAmountPayable = this.tblIncentive[0].TotalAmountPayable;
              this.PortfolioGrowth = this.tblIncentive[0].PortfolioGrowth;
              this.TotalAmountPaid = this.tblIncentive[0].TotalAmountPaid;
              this.RemainingPayable = this.tblIncentive[0].RemainingPayable;
              this.SPIName = this.tblIncentive[0].SPIName;
              this.GrowthKickerStatus = this.tblIncentive[0].GrowthKickerStatus;
              this.MosaicKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MosaicKickerValue);
              this.MSAKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MSAKickerValue);
              this.LDKickerValue = this.tblIncentive[0].LDKickerValue;
              this.UpdatedAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].UpdatedAchievement);
              this.ApprovedComissionAmount = this.tblIncentive[0].ApprovedCommisionAmount;
              if (this.NewJoinee == 1) {
                this.NewJoineeMsg = "New Joinee (Joined post " + this.tblMetrics[0].DOJCheckDate + ")";
                //this.RevenueAchievement = this.appService.GetRevenueAchievement(this.tblMetrics, parseFloat(this.CompanyPerFormance));
                this.RevenueAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].RevenueAcievement);
                this.TotalWeightedAverage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentage);
                //this.WeightedPercentage = this.appService.GetWeightedAverage(parseFloat(this.RevenueAchievement.replace('%', '')), parseFloat(this.TotalWeightedAverage.replace('%', '')));
              }
              else {
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
              }

              this.tblIsHistoryRead = response.ResponseData.dtIsHistoryRead;
              if (this.tblIsHistoryRead.length > 0) {
                this.IsReadbyEmployee = this.tblIsHistoryRead[0].IsReadbyEmployee;
                this.IsReadbyManager = this.tblIsHistoryRead[0].IsReadbyManager;
              }

              //if (this.tblDiscretionary.length > 0 && this.tblMetrics[0].StatusID == 14 && this.PeriodTypeNew == 'Yearly' && this.selectedPeriod > 7) {
              //  this.showDiscretionarytable = true;
              //}
              if (this.tblDiscretionary.length > 0) {
                this.DiscretionaryTotalvalue = this.tblDiscretionary[0].TotalvalueDisplay;
                this.DiscretionaryProrated = this.tblDiscretionary[0].ProratedValueDisplay;
                this.DiscretionaryPayout = this.tblDiscretionary[0].PayoutAmountDisplay;
                this.TotalExecutiveWeightage = this.tblDiscretionary[0].TotalExecutiveWeightage;
                this.DbPaid = this.tblDiscretionary[0].DbPaid;
                this.RemainingDbPayable = this.tblDiscretionary[0].RemainingDbPayable;
                this.FinalPayoutDB = this.tblDiscretionary[0].FinalPayoutDB;
              }

/*              this.openH1computationModal(0);*/
              //}
            }
              else {
                this.showMetricsTable = false;
                this.dialog.open(DialogDataExampleDialog, {
                  data: "No record found."
                });
                return;
              }
            }
        }
          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
    }
  }
 
  SubmitChanges(action: string, ShowMessage: string): void {
    this.action = action;
    var otherparameterfiltered = [];
    var SelfAssessment;
    for (var i in this.OtherParamArr) {
      if (this.OtherParamArr[i].isAppraisal == 1) {
        SelfAssessment = this.OtherParamArr[i].SelfAssessment;
      }
      else {
        SelfAssessment = 0;
      }
      if (this.OtherParamArr[i].isTotal == 0) {
        otherparameterfiltered.push({
          'ParameterId': this.OtherParamArr[i].ParameterId,
          'AssessmentValue': SelfAssessment,
          'Comments': this.OtherParamArr[i].SelfComments,
          'Weightage': 0
        });
      }
    };
    var UserData = {
      "PS_No": this.userInfo.PS_No,//this.userInfo.PS_No,
      "action": action,
      "role": "Employee",
      "Comments": this.tblMetrics[0].EmpRemarks,
      "dtMertricsDet": this.tblMetrics,
      "PeriodId": this.selectedPeriod,
      "tbl": otherparameterfiltered,
      "ThingsManagerContinueDoing": this.ManagerContinueDoing,
      "ThingsManagerStopDoing": this.ManagerStopDoing,
       "ThingsManagerStartDoing": this.ManagerStartDoing,
      "AppType": this.userInfo.AppType,
      "FinalReviewerAssessment": 0,
      "IsDBApplicable": this.IsDBApplicable,
      "IsAppraisalApplicable": this.IsAppraisalApplicable,
      "IsSPIApplicable": this.IsSPIApplicable

    }
    if (action != "SAV") {
      if (!this.appService.CheckIfAssessmentNoAreFilled(this.tblMetrics) && this.PlanTypeFlag == false) {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Please enter valid assessment values"
        });
        return;
      }
      if (this.showOtherMetricsTable && this.userInfo.AppType == 1 ) {
        if (!this.appService.CheckOtherParameters(this.OtherParamArr)) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please select self rating"
          });
          return;
        }
        if (!this.appService.CheckOtherParametersForSelfComments(this.OtherParamArr)) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please enter self assessment comments."
          });
          return;
        }
        if (this.userInfo.AppType == 1) {
          if (this.ManagerContinueDoing.trim().length == 0 || this.ManagerContinueDoing === null || this.ManagerContinueDoing === undefined) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please enter 2 things which the manager should continue doing."
            });
            return;
          }
          if (this.ManagerStopDoing.trim().length == 0 || this.ManagerStopDoing === null || this.ManagerStopDoing === undefined) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please enter 2 things which the manager should stop doing."
            });
            return;
          }
          if (this.ManagerStartDoing.trim().length == 0 || this.ManagerStartDoing === null || this.ManagerStartDoing === undefined) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please enter 2 things which the manager should start doing."
            });
            return;
          }
        }
      }

        // else {
      var showWarning = false;
      //for (var o in this.tblMetrics) {
      //if (this.tblMetrics[o].SelfComments == undefined || this.tblMetrics[o].SelfComments == null || this.tblMetrics[o].SelfComments.trim() == '') {
      //  showWarning = true;
      //  break;
      //}
      //}
      //if (showWarning) {
      //  this.warningdata = "You are about to submit the assessment. You will not be able to make any changes once it is submitted. Remarks are not entered. Are you sure you want to leave it empty?";
      //  this.warningModel.openModal();
      //  return;
      //}
      //
      this.warningdata = "You are about to submit the assessment. You will not be able to make any changes once it is submitted.";
      this.warningModel.openModal();
      return;
      //}
    }
    if (ShowMessage == 'Y') {
      this.appService.SetLoadingShow(true);
    }
    var url = this.configs.UpdateMetricsDetailsURL();
    this.appService.GetDataFromAPI(url, UserData)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (ShowMessage == 'Y') {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {

            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.tblMetrics = [];
            // this.tblMetrics = this.tblMetrics;
            this.onPeriodChange(this.selectedPeriod, this.masterData.dtUserDet[0].DeputedBU);
            //this.tblMetrics =
            //this.showMetricsTable = true;
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
    //}
  }

  ChangeRole() {
    switch (this.roleSelected) {

      case "2": this._router.navigate(['self-assessment']);
        break;
      case "3": this._router.navigate(['manager-view']);
        break;
      case "4": this._router.navigate(['reviewer-view']);
        break;
      case "1": this._router.navigate(['bulk-upload']);
        break;
      case "0": this._router.navigate(['bulk-upload']);
        break;
      case "6": this._router.navigate(['reject-assessment/6']);
        break;
      case "7": this._router.navigate(['reject-assessment/7']);
        break;
      default: this._router.navigate(['self-assessment']);
    }
  }

  DisagreeWithActuals() {
    if (this.DisagreementReasonSelected.length == 0) {
      //this.bottomSheetModal.closeModal();
      //this.dialog.open(DialogDataExampleDialog, {
      //  data: "Please select review reason(s)"
      //});
      return;
    }
    if (this.DisagreementRemarks.trim() == '') {
      //this.bottomSheetModal.closeModal();
      //this.dialog.open(DialogDataExampleDialog, {
      //  data: "Please enter review comments"
      //});
      return;
    }
    this.bottomSheetModal.closeModal();
    var ReasonsSelected = [];

    var PendingWith = 0;
    for (var x in this.DisagreementReasonSelected) {
      for (var o in this.DisagreementReasons) {
        if (this.DisagreementReasons[o].strReason.trim() == this.DisagreementReasonSelected[x].trim()) {
          var data = {
            'ReasonSelected': this.DisagreementReasons[o].strReason,
            'PendingWithRole': this.DisagreementReasons[o].RoleID

          }
          ReasonsSelected.push(data);
          break;
        }
      }
    }

    var Data = {
      "PS_No": this.userInfo.PS_No,
      "ApprovalId": this.tblMetrics[0].ApprovalID,
      "Role": 'Employee',
      "Remarks": this.DisagreementRemarks,
      "ReasonsSelected": ReasonsSelected,
      "PendingWith": PendingWith,
      "MetricsDisagreementData": this.MetricsDisagreementData,
      "AppType": this.userInfo.AppType
    }
    var url = this.configs.SubmitDisagreementDataURL();
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(url, Data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.SubmitChanges('SAV', 'N');
          this.onPeriodChange(this.selectedPeriod, this.masterData.dtUserDet[0].DeputedBU);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.DisagreementRemarks = '';
          this.DisagreementReasonSelected = [];
        }
      },
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  DisagreeWithActualsPopup() {
    this.DisagreementRemarks = '';
    this.MetricsDisagreementData = [];
    if (this.DisagreementReasons.length == 0) {
      this.DisagreeWithActualsReasons(true);
    }
    else {

      for (var o in this.tblMetrics) {
        var data = {
          'ApprovalID': this.tblMetrics[o].ApprovalId,
          'Comments': this.tblMetrics[o].SelfComments,
          'SalesMetricId': this.tblMetrics[o].SalesMetricID,
          'SalesMetricName': this.tblMetrics[o].SalesMetricName
        }
        this.MetricsDisagreementData.push(data);
      }
      this.MetricsDisagreementData = this.MetricsDisagreementData.filter((data) => data.SalesMetricId != 9998 && data.SalesMetricId != 9999);

      this.bottomSheetModal.openModal();
    }
  }

  DisagreeWithActualsReasons(showpopup) {
    var Data = {
      "AppType": this.userInfo.AppType
    }
    var url = this.configs.GetRejectionReasonsURL();
    this.appService.GetDataFromAPI(url, Data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementReasons = JSON.parse(response.ResponseData.Reasons);
          if (showpopup) {
            this.bottomSheetModal.openModal();
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  onCancelClick(): void {
    this.warningModel.closeModal();
  }

  onSubmitClick(): void {
    var otherparameterfiltered = [];
    var SelfAssessment;
    for (var i in this.OtherParamArr) {
      if (this.OtherParamArr[i].isAppraisal == 1) {
        SelfAssessment = this.OtherParamArr[i].SelfAssessment;
      }
      else {
        SelfAssessment = 0;
      }
      if (this.OtherParamArr[i].isTotal == 0) {
        otherparameterfiltered.push({
          'ParameterId': this.OtherParamArr[i].ParameterId,
          'AssessmentValue': SelfAssessment,
          'Comments': this.OtherParamArr[i].SelfComments,
          'Weightage': 0
        });
      }
    };
    var UserData = {
      "PS_No": this.userInfo.PS_No,//this.userInfo.PS_No,
      "action": this.action,
      "role": "Employee",
      "Comments": this.tblMetrics[0].EmpRemarks,
      "dtMertricsDet": this.tblMetrics,
      "PeriodId": this.selectedPeriod,
      "tbl": otherparameterfiltered,
      "ThingsManagerContinueDoing": this.ManagerContinueDoing,
      "ThingsManagerStopDoing": this.ManagerStopDoing,
      "ThingsManagerStartDoing": this.ManagerStartDoing,
      "AppType": this.userInfo.AppType,
      "FinalReviewerAssessment": 0,
      "IsDBApplicable": this.IsDBApplicable,
      "IsAppraisalApplicable": this.IsAppraisalApplicable,
      "IsSPIApplicable": this.IsSPIApplicable
    }
    this.warningModel.closeModal();
    this.appService.SetLoadingShow(true);
    var url = this.configs.UpdateMetricsDetailsURL();
    this.appService.GetDataFromAPI(url, UserData)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.tblMetrics = [];
          this.onPeriodChange(this.selectedPeriod, this.masterData.dtUserDet[0].DeputedBU);
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  openhistoryModal() {
    var data = {
      "ApprovalId": this.tblMetrics[0].ApprovalID,
      "ActionType":1 // From eployee login to check read count 
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.DisAgreedBy != undefined) {
            this.history.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closehistoryModal() {
    this.history.closeModal();
    this.onPeriodChange(this.selectedPeriod, this.masterData.dtUserDet[0].DeputedBU);
  
  }
  onViewAction(SalesMetricID, PSNo, PeriodID) {
    var ActualDataList = [];
    for (var o in this.tblMetrics) {
      //if (this.tblMetrics[o].SalesMetricID != 2) {
      var metric = {
        'MetricName': this.tblMetrics[o].SalesMetricName,
        'MetricId': this.tblMetrics[o].SalesMetricID,
        'Accounts': []
      }
      ActualDataList.push(metric);
      //}
    }

    var data = {
      'ActualDataList': ActualDataList,
      'SalesMetricID': SalesMetricID,
      'PSNo': PSNo,
      'PeriodID': PeriodID,
      'Path': 'self-assessment',
      'Plan': this.Plan,
    };
    this.appService.SetActualData(data);
    this.SubmitChanges('SAV', 'N');
    this._router.navigate(['actual-details']);
  }
  GettargetHistory() {
    var data = {
      psNo: this.userInfo.PS_No,
        PeriodId: this.selectedPeriod,
        AppType: this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetHistoryURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.targetHistoryArr = response.ResponseData;
          if (this.targetHistoryArr.length > 0) {
            this.targetHistory.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  GetParameterwiseData(salesMetricId) {
    var data = {
      psNo: this.userInfo.PS_No,
      PeriodId: this.selectedPeriod,
      AppType: this.userInfo.AppType,
      SalesMetricsID: salesMetricId
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetParameterwiseURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.parameterwiseDatarr = response.ResponseData;
          if (this.parameterwiseDatarr.length > 0) {
            this.parameterwiseData.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closeTargethistoryModal() {
    this.targetHistory.closeModal();
  }

  closeparameterwiseDataModal() {
    this.parameterwiseData.closeModal();
  }
    DownLoadTargets(): void {
      this.DownLoadTargetsApi(this.userInfo.PS_No, this.selectedPeriod, 'Employee', 'Released', 'Final', this.AppType);
  }
  DownLoadTargetsApi(Psno, FYPeriodId, Role, TargetStatus, TargetLetterType, AppType): void {

    var data = {
      "PSNO": Psno,
      "PeriodId": FYPeriodId,
      "Role": Role,
      "TargetStatus": TargetStatus,
      "TargetLetterType": TargetLetterType,
      "AppType": AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetLetterURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.appService.DownloadTargetletter(response.ResponseData);
        }
      });


  }
  getOtherParametersData(): void {
    this.OtherParamArr = [];
    this.DbNAFlag = false;
    this.AppraisalStatus = 0;
    this.PeriodType = 'Half-Yearly';
    this.showOtherMetricsTable = false;
    this.showStratatable = false;
    this.DBStatus = 0;
    var data = {
      'PSNO': this.userInfo.PS_No,
      'RoleId': 1,
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetOtherParametersDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.AppraisalStatus = 0;
          
          var OtherParameterSMA = response.ResponseData.dtOtherParameterSalesMetricApproval;
          if (OtherParameterSMA.length > 0) {
            this.AppraisalStatus = OtherParameterSMA[0].StatusID;


            this.ManagerContinueDoing = OtherParameterSMA[0].ThingsManagerContinueDoing;
            this.ManagerStartDoing = OtherParameterSMA[0].ThingsManagerStartDoing;
            this.ManagerStopDoing = OtherParameterSMA[0].ThingsManagerStopDoing;
          }

          this.DBStatus = 0;
          var DBApproval = response.ResponseData.dtDBApproval;
          if (DBApproval.length > 0) {
            this.DBStatus = DBApproval[0].StatusID;
            if (this.DBStatus === 14) {
              this.showDiscretionarytable = true;
            }
          }

          var OtherParam = [];
          OtherParam = response.ResponseData.dtAssessmentData;

          if (OtherParam.length > 0) {
            for (var o in OtherParam) {
              if (OtherParam[o].isAppraisal == 1 || OtherParam[o].isTotal == 1 || this.DBStatus == 14 || this.userInfo.AppType == 2) {
                this.OtherParamArr.push(OtherParam[o]);
              }
            }
            this.AppraisalWeightageApplicable = this.OtherParamArr[0].AppraisalWeightageApplicable;
          }

          //else {
          //  this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment'];
          //}
          if (this.selectedPeriod >= 7) {
            if (this.userInfo.AppType == 1) {
              if (this.AppraisalWeightageApplicable == 0) {

                this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment'];
              }
              else {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment'];
              }
            }
            else if (this.userInfo.AppType == 2){
              this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage','SelfCommentsDelivery'];
            }
          }
          if (this.OtherParamArr.length > 0) {
            this.PeriodType = this.OtherParamArr[0].PeriodType;
            this.showOtherMetricsTable = true;
          
          }
          if (this.OtherParamArr.length > 0 && this.tblMetrics.length > 0) {
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].Value == 0 && this.OtherParamArr[o].isTotal == 0 && (this.AppraisalStatus == 14 || this.tblMetrics[0].StatusID == 14) && this.selectedPeriod >= 7) {
                this.DbNAFlag = true;
              }
            }
          }
          this.OtherParameterRatingMaster = response.ResponseData.dtOtherParameterRatingMaster;
         
          this.StrataTable = response.ResponseData.StrataRangeMaster;
          var percentagemaster = response.ResponseData.dtOtherParameterWeightageMaster;
          for (var o in percentagemaster) {
            {
              this.QuantitativePercentage = percentagemaster[o].QuantitativeWeightage + '%';
              this.QualitativePercentage = percentagemaster[o].QualitativeWeightage + '%';
            }
          }
          if (this.OtherParamArr.length > 0) {
            this.TotalWeightage = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalWeightage = this.TotalWeightage + this.OtherParamArr[o].Value;
              }
            }
            this.TotalExecutiveDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalExecutiveDBRating = this.TotalExecutiveDBRating + this.OtherParamArr[o].ExecutiveWeightage;
              }
            }
            this.TotalSelfRating = 0;
            this.TotalManagerRating = 0;
            this.TotalReviewerRating = 0;
            if (this.AppraisalWeightageApplicable == 1) {
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalSelfRating = this.TotalSelfRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalManagerRating = this.TotalManagerRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalReviewerRating = this.TotalReviewerRating / 100;
            }
            else {
              var count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment);
                  count = count + 1;
                }
              }
              this.TotalSelfRating = parseFloat((this.TotalSelfRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment);
                  count = count + 1;
                }
              }
              this.TotalManagerRating = parseFloat((this.TotalSelfRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment);
                  count = count + 1;
                }
              }
              this.TotalReviewerRating = parseFloat((this.TotalReviewerRating / count).toFixed(2));
            }
          }
          if (this.tblMetrics != undefined && this.tblMetrics.length == 0) {
            this.showOtherMetricsTable = false;
            
          }
          
          //if (parseInt(StatusId) ==6) {
          //  this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment'];
          //}
          //if (this.AppraisalStatus === 14) {
          //  //this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveComments', 'ExecutiveWeightage'];
          //  if (this.selectedPeriod > 7) {
          //    if (this.IsDBApplicable == 1) {
          //      this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment', 'ExecutiveWeightage', 'ExecutiveComments'];
          //    }
          //    else {
          //      this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
          //    }
          //  }
          //  else {
          //    this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
          //  }
          //  this.showStratatable = true;
          //}


          ///////start
          if (this.selectedPeriod >= 7) {
            if (this.IsDBApplicable == 1) {
              if (this.userInfo.AppType == 1) {
                if (this.AppraisalStatus === 14 && this.DBStatus === 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'ManagerComments', 'ReviewerComments', 'ExecutiveWeightage', 'ExecutiveComments'];
                  this.showStratatable = true;
                }
                if (this.AppraisalStatus === 14 && this.DBStatus != 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
                  this.showStratatable = true;
                }
                if (this.AppraisalStatus === 7 && this.DBStatus === 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage','SelfComments', 'SelfAssessment', 'ExecutiveWeightage', 'ExecutiveComments'];
                }
              }
              else {
                if (this.AppraisalStatus === 14 && this.DBStatus != 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
                  this.showStratatable = true;
                }
                if (this.DBStatus === 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'FinalDBRating'];
                }
              }
            }
            else {
              if (this.AppraisalStatus === 14) {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'ManagerComments', 'ReviewerComments'];
                this.showStratatable = true;
              }
            }
          }
          else {
            if (this.AppraisalStatus === 14) {
              this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ReviewerComments'];
              this.showStratatable = true;
            }
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
    }
    GetFeedbackDetails(): void {
        this.showStratatable = false;
        this.showOtherMetricsTable = false;
        var data = {
            "PSNo": this.userInfo.PS_No,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "LoggedInPSNo": this.userInfo.PS_No
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetFeedbackDetailsURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.FeedbackdisplayedColumns = ['FeedbackName', 'CNBFeedback', 'SalesHeadFeedback'];
                    this.FeedbackDetails = response.ResponseData.dtFeedbackDetails;
                    this.IsFeedbackDetails = true;
                    if (this.FeedbackDetails.length == 0) {
                        this.IsFeedbackDetails = false;
                    }
                }
            });
  }
  getTeamName() {
    return this.appService.TeamName(this.userInfo.AppType);
  }
  AddRatingValues(): void {
    this.TotalSelfRating = 0;
    if (this.AppraisalWeightageApplicable == 1) {
      for (var o in this.OtherParamArr) {
        if (this.OtherParamArr[o].isTotal == 0) {
          this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment * this.OtherParamArr[o].Value);
        }
      }
      this.TotalSelfRating = this.TotalSelfRating / 100;
    }
    else {
      var count = 0;
      for (var o in this.OtherParamArr) {
        if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
          this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment);
          count = count + 1;
        }
      }
      this.TotalSelfRating = parseFloat((this.TotalSelfRating / count).toFixed(2));
    }
  }

  //openH1computationModal(type:any) {
  //  var data = {
  //    "PS_No": this.userInfo.PS_No,
  //    "PeriodId": '11',
  //    'Role': 'Employee',
  //    'UserType': '',
  //    "AppType": this.userInfo.AppType
  //  };
  //  this.appService.SetLoadingShow(true);
  //  var url = this.configs.GetH1MetricsDetailsURL();
  //  this.appService.GetDataFromAPI(url, data)
  //    .then(response => {
  //      this.appService.SetLoadingShow(false);
  //      if (response.ResponseCode == this.configs.RetCodeFailure()) {
  //        this.dialog.open(DialogDataExampleDialog, {
  //          data: response.ResponseData
  //        });
  //      }
  //      else {
  //        this.H1tblMetrics = response.ResponseData.dtMertricsDet;
  //        this.H1tblIncentive = response.ResponseData.dtIncentiveDet;
  //        this.H1WeightedPercentage = this.appService.AddPercentageSign(this.H1tblIncentive[0].WeightedPercentage);
  //        this.H1UpdatedAchievement = this.appService.AddPercentageSign(this.H1tblIncentive[0].UpdatedAchievement);
  //        this.H1overallachievement = this.appService.AddPercentageSign(((this.tblIncentive[0].WeightedPercentage) + (this.H1tblIncentive[0].UpdatedAchievement)) / 2);

  //        if (type == 1) {
  //          if (this.H1tblMetrics.length > 0) {
  //            this.h1computation.openModal();
  //          }
  //          else {
  //            this.dialog.open(DialogDataExampleDialog, {
  //              data: "No data available"
  //            });
  //          }
  //        }

  //      }
  //    }
  //      ,
  //      error => {
  //        this.errorMessage = <string>error;
  //      });

  //}
}
