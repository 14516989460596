import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from './app.service';


@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: './popup.component.html',
})
export class DialogDataExampleDialog {
  MessageType: string;
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private appService: AppService) {
    this.MessageType = '';
    if (typeof data === 'string') {
      this.MessageType = 'string';
    }
    else {
      this.MessageType = 'array';
    }
  }
  
  onOkClick(): void {
    this.dialogRef.close();
  }
}
