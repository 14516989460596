import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.less']
})
export class BulkUploadComponent implements OnInit {
  @ViewChild('confirmModel') confirmModel: MzModalComponent
  uploadedFile: File;
  periodid: any;
  UserPsno: any;
  periodValus: any;
  ShowSection: number;
  userInfo: any;
  Message: any;
  DownloadFilePath: any;
  DownloadFilePathBase: any;
  roleSelected: any;
  masterData: any;
  roleSelectedName: any;
  dataSource: any;
  mappingData: any;
  selectAll: boolean = true;
  displayedColumns: string[] = ['name', 'manager', 'sbu'];
  SalesMetricColumns: string[] = [];
  AllowedPeriodUpload: any;
  LoggedInUserRole: number = 0;
  warningdata: any = [];
  TargetForUploadAfterConfirm: any = [];
  AllowedRolesForPeriod: any = [];
  RoleByPeriodMaster: any = [];
  //dataSource = ELEMENT_DATA;
    isH1Applicable: boolean = false;
    AppType: string = 'SPM';
  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.periodid = this.appService.AutoPeriodId();
    this.UserPsno = '';
    this.uploadedFile = null;
    this.periodValus = [];
    this.ShowSection = 1;
    this.Message = '';
    this.DownloadFilePathBase = '../../../UploadTemplates/UploadTemplate_';
    this.roleSelected = '0';
    this.roleSelectedName = '';
    this.DownloadFilePath = '';
    this.dataSource = [];
    this.mappingData = [];
    this.AllowedPeriodUpload = [];
    this.LoggedInUserRole = 0;
  }
  ngOnInit() {
    this.appService.SetLoadingShow(true);
    this.userInfo = this.appService.GetLoginUserInfo();
    this.UserPsno = this.userInfo.PS_No;
    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        var TargetScreenRoles = this.appService.GetUserRoleForTargetUpload(this.userInfo.SPMRoles);
        if (TargetScreenRoles.CSRoleId > 0)
          this.LoggedInUserRole = TargetScreenRoles.CSRoleId;
        else if (TargetScreenRoles.CBORoleId > 0)
          this.LoggedInUserRole = TargetScreenRoles.CBORoleId;
        else if (TargetScreenRoles.HRRoleId > 0)
          this.LoggedInUserRole = TargetScreenRoles.HRRoleId;
        else if (TargetScreenRoles.SELARoleId > 0)
          this.LoggedInUserRole = TargetScreenRoles.SELARoleId;
        else if (TargetScreenRoles.FunctionHeadId > 0)
          this.LoggedInUserRole = TargetScreenRoles.FunctionHeadId;
        var UserData = {
          "PS_No": this.userInfo.PS_No,
          "AppType": this.userInfo.AppType
        }
        this.masterData = this.appService.GetMasterDetails();
        this.appService.GetDataFromAPI(this.configs.GetYearsForTargetUpload(), UserData)
          .then(response => {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.periodValus = response.ResponseData.dtYearsForTrgts;
              this.RoleByPeriodMaster = response.ResponseData.dtApplicablePlanForYear;
              this.periodid = response.ResponseData.dtYearsForTrgts[0].TargetUploadYearText;
              this.onChangeOFPeriod(this.periodid);
              this.ShowSection = 1;
              //this.ChangeView(2);
            }
          });
      } else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
  ChangeView(value: number) {
    this.ShowSection = value;
  }
  fileChange(event) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadedFile = fileList[0];
    }
    else {
      this.uploadedFile = null;
    }
  }

  UploadFile() {
    if (this.uploadedFile != null) {
      this.appService.SetLoadingShow(true);
      this.appService.FileUpLoad(this.configs.UploadFile(), this.uploadedFile, this.UserPsno, this.LoggedInUserRole.toString(), this.roleSelectedName, this.AllowedPeriodUpload, this.periodid, this.userInfo.AppType)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.Message = response.ResponseData;
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            if (response.ResponseData.approveTargets.length == 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData.retmsgs
              });
              this.Reload();
            }
            else {
              this.appService.SetLoadingShow(false);
              this.warningdata = response.ResponseData.retmsgs;
              this.TargetForUploadAfterConfirm = response.ResponseData.approveTargets;
              this.confirmModel.openModal();
            }
            //this.Navigate('/bulk-upload-grid');
          }

        });
    }
  }

  Navigate(url) {
    this.router.navigate([url]);
  }
  ChangeRole(id) {
    if (id != '') {
      this.roleSelected = id;
      if (this.roleSelected != '0') {
        this.appService.SetLoadingShow(true);
        this.Reload();
        for (var o in this.AllowedRolesForPeriod) {
          if (this.AllowedRolesForPeriod[o].RoleId == this.roleSelected) {
            this.appService.SetRoleID(this.roleSelected);
            this.roleSelectedName = this.AllowedRolesForPeriod[o].RoleName;
            this.DownloadFilePath = this.DownloadFilePathBase + this.periodid + '_' + this.roleSelectedName.replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '') + ".xlsx";
            this.ChangeView(2);
            break;
          }
        } 
      }
      else {
        this.roleSelectedName = '';
        this.DownloadFilePath = '';
      }
    }
  }
  Reload() {
    this.SalesMetricColumns = [];
    this.dataSource = [];
    this
    this.uploadedFile = null;
    var data = {
      'psNo': this.userInfo.PS_No,
      'periodYear': this.periodid,
      'Role': "CET",
      'SelectedRole': this.roleSelected,
      'AppType': this.userInfo.AppType
    };
    this.appService.GetDataFromAPI(this.configs.GetSubmissionAllowedPeriodURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
           //{
           // this.SalesMetricColumns = response.ResponseData.metricsMasters;
           // this.appService.SetBulkUploadList(response.ResponseData.empSalesMatricsInfos);
           // this.dataSource = this.appService.GetBulkUploadList();
           // if (this.dataSource.length == 0) {
           //   this.dialog.open(DialogDataExampleDialog, {
           //     data: "No targets has been uploaded to view it."
           //   });
           //   //return;
           // }
           // for (var o in this.dataSource) {
           //   this.dataSource[o].select = true;
           //   }
           // this.selectAll = true;

           // this.displayedColumns = ['name', 'manager', 'sbu'];

           // }
            if (this.dataSource.length > 0) {
                if (this.dataSource[0].IsH1PeriodApplicable == '1') {
                    this.isH1Applicable = true;
                }
                else {
                    this.isH1Applicable = false;
                }
                this.AppType = this.dataSource[0].AppType;
          }
          if (response.ResponseData.allowedPeriodUploads.length > 0) {
            this.AllowedPeriodUpload = response.ResponseData.allowedPeriodUploads;
          }
        }
      });
  }
  InitiateAction() {
    var FinalList = [];

    for (var o in this.dataSource) {
      if (this.dataSource[o].select == true) {
        FinalList.push(this.dataSource[o]);
      }
    }
    this.appService.SetLoadingShow(true);
    var data = {
      'PS_No': this.userInfo.PS_No,
      'userrole': "CET",
      'List': FinalList,
      'AppType': this.userInfo.AppType
    };
    this.appService.GetDataFromAPI(this.configs.SaveSalesMetricsActualValuesURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Uploaded targets are published to the sales executives. It will be visible to sales executives in the assessment page."
          });
          this.dataSource = null;
          this.Reload();
        }
      });
  }

  SelectRecords(val, period) {
    if (val.toString() == 'ALL') {
      this.selectAll = !this.selectAll;
      for (var o in this.dataSource) {
        this.dataSource[o].select = this.selectAll;
      }
    }
    else {
      for (var o in this.dataSource) {
        if (val.toString() == this.dataSource[o].psNo.toString() && this.dataSource[o].period == period.toString()) {
          this.dataSource[o].select = !this.dataSource[o].select;
          break;
        }
      }
    }
    this.ResetAllCheckBox();
  }

  ResetAllCheckBox() {
    this.selectAll = true;
    for (var o in this.dataSource) {
      if (this.dataSource[o].select == false) {
        this.selectAll = false;
        break;
      }
    }
  }

  onChangeOFPeriod(id) {
    if (id != '') {
      this.periodid = id;
      this.FilterPlans(id);
    }
    else {
      this.periodid = '0';
    }
  }
  onCancelClick(): void {
    this.confirmModel.closeModal();
  }

  UpdateTargetsAfterConfirmation(): void {
    this.onCancelClick();
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.UpdateTargetsAfterConfirmationURL(), this.TargetForUploadAfterConfirm)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.onCancelClick();
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.TargetForUploadAfterConfirm = [];
          this.dataSource = null;
          this.Reload();
        }
      });

  }

  FilterPlans(periodid) {
    this.AllowedRolesForPeriod = [];
    for (var o in this.RoleByPeriodMaster) {
      if (this.RoleByPeriodMaster[o].TargetUploadYearText == periodid) {
        this.AllowedRolesForPeriod.push(this.RoleByPeriodMaster[o]);
      }
    }

  }
}

