import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MzModalComponent } from 'ngx-materialize';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-my-targets',
  templateUrl: './my-targets.component.html',
  styleUrls: ['./my-targets.component.less']
})
export class MyTargetsComponent implements OnInit {
  @ViewChild('history') history: MzModalComponent
  @ViewChild('targethistory') targetHistory: MzModalComponent
  @ViewChild('qualitativedata') qualitativedata: MzModalComponent
  @ViewChild('parameterwiseData') parameterwiseData: MzModalComponent
  displayedColumns: string[] = ['Parameter', 'Measurement', 'Weightage', 'Period', 'Target', 'Threshold'];
  selectedPeriod: any;
  roleSelected: string;
  userInfo: any;
  masterData: any;
  plandata; any;
  errorMessage: string;
  tblMetrics: any;
  planid: any;
  Quan: any;
  Qual: any;
  id: any;
  sum: number = 0;
  count: number = 0;
  type: number = 0;
  StatusId: any;
  ManagerName: string;
  FYPeriodid: any;
  showMetricsTable: boolean = false;
  isSubmissionAllowed: boolean = false;
  dueDate: string = "";
  warningdata: string;
  action: string;
  DisagreementData: any;
  MetricsDisagreementData: any
  DisagreementMessage: string;
  PSNO: string;
  Proratedvalue: string = '';
  TotalVCP: string = '';
  TotalPayable: string = '';
  AccountList: any;
  UserData: any;
  loadUser: boolean = false;
  IsManager: boolean = false;
  PeriodName: string = '';
  CompanyPerFormance: string = '';
  WeightedPercentage: string = '';
  TotalWeightedAverage: string = '';
  RevenueAchievement: string = '';
  NewJoineeMsg: string = '';
  NewJoinee: number = 0;
  SPIAmount: string = '';
  targetHistoryArr: any = [];
  OtherParamArr: any = [];
  qualitativeArr: any = [];
  NavbackPath: string = 'dashboard';
  periodValus: any;
  mydata: any;
  DownloadFilePathForH1TargetLetter: any;
  DownloadFilePathBaseForH1TargetLetter: any;
  DownloadFilePathForH2TargetLetter: any;
  DownloadFilePathBaseForH2TargetLetter: any;
  DownloadFilePath: any;
  DownloadFilePath2: any;


  SalesIncentiveText: string = '';
  SalesIncentiveURL: string = '';
  IsH1PeriodApplicable: number = 1;
  SalesIncentiveURLByCountry: string = '';
  SalesIncentiveTextByCountry: string = '';
  MBOSubmitValidation: string = '';
  TargetData: any = [];
  CheckIsnullPU: string = '';
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblMetrics = [];
    this.DisagreementData = {};
    this.MetricsDisagreementData = [];
    this.DisagreementMessage = '';
    this.PSNO = '';
    this.AccountList = '';
    this.plandata = [];
    this.loadUser = false;
    this.IsManager = false;
    this.UserData = {};
    this.targetHistoryArr = [];
    this.selectedPeriod = '0';
    this.periodValus = [];
    this.OtherParamArr = [];
    this.mydata = {};
    this.DownloadFilePathForH1TargetLetter = '';
    this.DownloadFilePathForH2TargetLetter = '';
    this.TargetData = [];
    this.CheckIsnullPU = '';
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    this.masterData = this.appService.GetMasterDetails();
    this.selectedPeriod = '3';
    //for (var o in this.masterData.dtPeriodDet) {
    //  if (this.selectedPeriod == this.masterData.dtPeriodDet[o].PeriodID) {
    //    this.PeriodName = this.masterData.dtPeriodDet[o].Name;
    //    break;
    //  }
    //}
    var IsNavBwtweenTargetDashBoardAndMyTargets = this.appService.GetIsNavBwtweenTargetDashBoardAndMyTargets();
    if (IsNavBwtweenTargetDashBoardAndMyTargets) {
      this.PSNO = this.appService.GetPsno();
    }
    else {
      this.PSNO = this.userInfo.PS_No;
    }
    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess || IsNavBwtweenTargetDashBoardAndMyTargets) {
        var UserData = {
          "PS_No": this.PSNO,
          "AppType": this.userInfo.AppType
        }
        this.appService.GetDataFromAPI(this.configs.GetYearsForTargetUpload(), UserData)
          .then(response => {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.periodValus = response.ResponseData.dtYearsForTrgts;
           
              if (IsNavBwtweenTargetDashBoardAndMyTargets) {
                this.selectedPeriod = this.appService.GetperiodId();
                this.onPeriodChange(this.selectedPeriod);
              }
              else {
                this.selectedPeriod = this.masterData.dtPeriodDet[0].TargetUploadYearText;
                this.GetEmployeeData();
              }


            }
          });
      }
      else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }

  onPeriodChange(id: any): void {
    this.appService.SetIsNavBwtweenTargetDashBoardAndMyTargets(false);
    this.SalesIncentiveURLByCountry = '';
    if (id.toString() != '') {
      var UserData = {
        "PSNo": this.PSNO,
        "PeriodId": id.toString(),
        "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.GetMyTargetsForYearURL();
      this.appService.GetDataFromAPI(url, UserData)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            //this.SalesIncentiveText = 'Sales Incentive Plan ' + id.toString();
            //this.SalesIncentiveURL = 'assets/images/SPMProcessDoc' + id.toString()+'.pdf';
            this.loadUser = true;
            this.tblMetrics = response.ResponseData.targetParamters;
            this.Quan = response.ResponseData.Quan;
            this.Qual = response.ResponseData.qual;
            this.mydata = response.ResponseData.UserDetails;
            this.IsH1PeriodApplicable = this.mydata.IsH1PeriodApplicable;
            this.FYPeriodid = this.mydata.FYPeriodId;
            this.StatusId = this.mydata.StatusId;
            this.ManagerName = this.mydata.ManagerName;
            if (this.ManagerName == this.userInfo.Name) {
              this.IsManager = true;
            }
            if (this.mydata.IncentivePlanURL != null) {
              this.SalesIncentiveText = this.mydata.IncentivePlanText + id.toString();
              this.SalesIncentiveURL = this.mydata.IncentivePlanURL + id.toString() + '.pdf';
            }
            if (this.mydata.IncentivePlanTextByCountry != '' && (this.mydata.IncentivePlanURLByCountry != '' && this.mydata.IncentivePlanURLByCountry != null)) {
              this.SalesIncentiveTextByCountry = this.mydata.IncentivePlanTextByCountry + id.toString();
              this.SalesIncentiveURLByCountry = this.mydata.IncentivePlanURLByCountry + id.toString() + '.pdf';
            }
            this.GetPlanData(id);

            this.AccountList = response.ResponseData.UserDetails.Accounts;
            if (this.tblMetrics.length > 0) {
              this.showMetricsTable = true;
              this.displayedColumns = ['Parameter', 'Measurement', 'Weightage', 'Period', 'Target', 'Threshold'];

              //this.SPIAmount = this.tblMetrics[0].SPIAmount;
              //this.TotalPayable = this.tblMetrics[0].PayoutAmount;
              //this.Proratedvalue = this.tblMetrics[0].ProRatedValue;
              //this.TotalVCP = this.appService.AddPercentageSign(this.tblMetrics[0].TotalVCP);
              //this.CompanyPerFormance = this.appService.AddPercentageSign(this.tblMetrics[0].CompanyPerformance);
              //this.NewJoinee = this.tblMetrics[0].NewJoinee;
              //if (this.NewJoinee == 1) {
              //  this.NewJoineeMsg = "New Joinee (Joined post " + this.tblMetrics[0].DOJCheckDate + ")";
              //  this.RevenueAchievement = this.appService.GetRevenueAchievement(this.tblMetrics, parseFloat(this.CompanyPerFormance));
              //  this.TotalWeightedAverage = this.appService.AddPercentageSign(this.tblMetrics[0].WeightedPercentage);
              //  this.WeightedPercentage = this.appService.GetWeightedAverage(parseFloat(this.RevenueAchievement.replace('%', '')), parseFloat(this.TotalWeightedAverage.replace('%', '')));
              //}
              //else {
              //  this.WeightedPercentage = this.appService.AddPercentageSign(this.tblMetrics[0].WeightedPercentage);
              //}
            }
            else {
              this.showMetricsTable = false;
              this.dialog.open(DialogDataExampleDialog, {
                data: "No record found."
              });
              return;
            }
          }
        }
          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
    }
  }

  onBackClick() {
    this.NavbackPath = this.appService.GetNavbackPathForAssessmentDetails();
    if (this.NavbackPath.length == 0) {
      this.NavbackPath = 'dashboard';
    }
    if (this.NavbackPath == 'view-targets') {
      this.appService.SetIsNavBwtweenTargetDashBoardAndMyTargets(true);
    }
    this._router.navigate([this.NavbackPath]);
  }


  GetEmployeeData() {
    var data = {
      "psNo": this.PSNO
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetEmployeeDetailsForDashBoardURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.UserData = response.ResponseData;
          this.loadUser = true;
          this.onPeriodChange(this.selectedPeriod);
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  GetPlanData(id: any) {
    var data = {
      "PSNo": this.PSNO,
      "PeriodId": id.toString(),
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetPlanDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.plandata = response.ResponseData;
          this.planid = this.plandata[0].PlanId;
          this.id = id;

          if ((this.planid == '700'|| this.planid == '698'|| this.planid == '699') && this.id == 'FY23') {
            this.DownloadFilePath = '../../../H1targetLetters/' + this.PSNO + '_' + 'H1FY23' + '.pdf';
            this.DownloadFilePath2 = '../../../H2targetLetters/' + this.PSNO + '_' + 'H2FY23' + '.pdf';
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
  }


  openhistoryModal() {
    var data = {
      "ApprovalId": this.tblMetrics[0].ApprovalID,
      "ActionType": 0
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.DisAgreedBy != undefined) {
            this.history.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closehistoryModal() {
    this.history.closeModal();
  }
  onViewAction(SalesMetricID, PSNo, PeriodID) {

    var ActualDataList = [];
    for (var o in this.tblMetrics) {
      //if (this.tblMetrics[o].SalesMetricID != 2) {
      var metric = {
        'MetricName': this.tblMetrics[o].Parameter,
        'MetricId': this.tblMetrics[o].SalesMetricID,
        'Accounts': []
      }
      ActualDataList.push(metric);
      //}
    }

    var data = {
      'ActualDataList': ActualDataList,
      'SalesMetricID': SalesMetricID,
      'PSNo': PSNo,
      'PeriodID': PeriodID,
      'Path': 'assessment-details',

    };
    this.appService.SetActualData(data);
    this._router.navigate(['actual-details']);
  }
  GettargetHistory() {
    var data = {
      psNo: this.PSNO,
      PeriodId: this.selectedPeriod,
      AppType: this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetHistoryForYearURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.targetHistoryArr = response.ResponseData;
          if (this.targetHistoryArr.length > 0) {
            this.targetHistory.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closeTargethistoryModal() {
    this.targetHistory.closeModal();
  }
  DownLoadTargets(): void {
    this.DownLoadTargetsApi(this.PSNO, this.mydata.FYPeriodId, 'Employee', 'Released', 'Final', this.mydata.AppType);
  }
  DownLoadTargetsApi(Psno, FYPeriodId, Role, TargetStatus, TargetLetterType, AppType): void {

    var data = {
      "PSNO": Psno,
      "PeriodId": FYPeriodId,
      "Role": Role,
      "TargetStatus": TargetStatus,
      "TargetLetterType": TargetLetterType,
      "AppType": AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetLetterURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.appService.DownloadTargetletter(response.ResponseData);
        }
      });


  }
  AcceptRejectTargets(value) {

    var data = {
      "PSNo": this.PSNO,
      "Period": this.selectedPeriod,
      "AppType": this.userInfo.AppType,
      "EmployeeAccepted": value
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.UpdateEmployeeTargetAcceptanceURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.onPeriodChange(this.selectedPeriod);
        }
      }

        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  OpenQualitativeModal() {
    var data = {
      'PSNO': this.userInfo.PS_No,
      'RoleId': 1,
      'PeriodId': this.mydata.FYPeriodId,
      'AppType': this.userInfo.AppType
    }

    this.appService.SetLoadingShow(true);
    var url = this.configs.GetQualitativeParametersDataURL();

    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {

          var OtherParam = [];
          this.OtherParamArr = [];
          OtherParam = response.ResponseData.dtAssessmentData;

          if (OtherParam.length > 0) {
            for (var o in OtherParam) {
              if (OtherParam[o].ParameterName != 'Total') {
                this.OtherParamArr.push(OtherParam[o]);
              }
            }
            this.qualitativedata.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });

          }


        }
      }
        ,
        error => {

        });
  }

  Savequalitativepercent() {
    this.sum = 0;
    this.count = 0;
  
    this.MBOSubmitValidation = '';
    if (this.OtherParamArr.length > 0) {
      for (var e in this.OtherParamArr) {
        this.sum += (this.OtherParamArr[e].Value);
        if (this.OtherParamArr[e].Value > 40) {
       //  this.dialog.open(DialogDataExampleDialog, {
          //  data: "The weightage should not be greater than 40"
        //  });
          this.MBOSubmitValidation = 'Please ensure weightage of individual parameters(s) is not greater than 40';
        

        }
        
        if (this.OtherParamArr[e].Value != 0) {
          this.count = this.count + 1;
        }
        if (this.count > 5) {
          //this.dialog.open(DialogDataExampleDialog, {
          //  data: "Only maximum of 5 parameters can have weightage "
          //});
          //this.type = 1;
          this.MBOSubmitValidation = "Only maximum of 5 parameters can have weightage";

        }
      }
      if (this.sum == 100) {

      }
      else {
        //this.dialog.open(DialogDataExampleDialog, {
        //  data: "The sum of weightage should be 100"
        //});
        //this.type = 1;
        this.MBOSubmitValidation = "The sum of weightage should be 100";
      }
      if (this.MBOSubmitValidation.length == 0) {
        //this.dialog.closeAll();
        this.qualitativedata.closeModal();

        this.qualitativeArr = [];
        for (var o in this.OtherParamArr) {
          this.qualitativeArr.push({ "SalesMetricId": this.OtherParamArr[o].ParameterId, "Weightage": this.OtherParamArr[o].Value });
        }

        var data = {
          'PSNO': this.userInfo.PS_No,
          'dtPercentagedata': this.qualitativeArr,
          'PeriodId': this.selectedPeriod,
          'AppType': this.userInfo.AppType
        }
        this.appService.SetLoadingShow(true);
        var url = this.configs.SaveQualitativePercentageURL();
        this.appService.GetDataFromAPI(url, data)
          .then(response => {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });

            }
          },
            error => {

            });
      }
      }
    }
 
  GetMyTargetData(salesMetricId,Periodid) {
    var data = {
      psNo: this.PSNO,      
      AppType: this.userInfo.AppType,
      SalesMetricsID: salesMetricId,
      PeriodId: Periodid
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetMyTargetDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.TargetData = response.ResponseData;
          for (var o in this.TargetData) {
            if (this.TargetData[o].PracticeUnit != '') {
              this.CheckIsnullPU = this.TargetData[o].PracticeUnit;
              break;
            }
          }
          if (this.TargetData.length > 0) {
            this.parameterwiseData.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  closeparameterwiseDataModal() {
    this.parameterwiseData.closeModal();
  }
}




