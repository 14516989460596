export const OAuthSettings = {

  instance: "https://login.microsoftonline.com/",
  tenant: 'ff355289-721e-4dd7-a663-afec62ab9d54',
  clientId: GetEnvironmentValue() == "PROD" ? 'c0da6762-a18c-49ca-a537-1eb56d560c8d' : 'e407444c-0168-4007-a300-885de56de960',
  redirectUri:GetEnvironmentValue() == "PROD" ? "https://spm.ltimindtree.com/": (window.location.origin + (window.location.origin.indexOf("localhost:") == -1 ? "/" : "")),
    //redirectUri: window.location.origin + "/app",
  //endpoints: environment.adalConfig.endpoints,
  navigateToLoginRequestUrl: false,
  cacheLocation: 'localStorage',
  endpoints: {
    graphApiUri: "https://graph.microsoft.com"
  },
};
export function GetEnvironmentValue(): string {
  var Env = 'QA';
  if (window.location.origin.indexOf("spm.lntinfotech.com") != -1
    || window.location.origin.indexOf("spm.ltimindtree.com") != -1
    || window.location.origin.indexOf("inspirelti.azurewebsites.net") != -1) {
    Env = 'PROD';
  }
  return Env;
}