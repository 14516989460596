import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
    selector: 'app-actual-upload',
    templateUrl: './actual-upload.component.html',
    styleUrls: ['./actual-upload.component.less']
})
export class ActualUploadComponent implements OnInit {
  @ViewChild('confirmModel') confirmModel: MzModalComponent
    uploadedFile: File;
    periodid: any;
    UserPsno: any;
    periodValus: any;
  ShowSection: number;
  DownloadFilePathForPID: any;
  DownloadFilePathBaseForPID: any;
  DownloadFilePathForAccount: any;
  DownloadFilePathBaseForAccount: any;
    ShowIOptionTypeSection: number;
    userInfo: any;
    Message: any;
    typeSelected: any;
    masterData: any;
    periodValues: any;
    DownloadFilePath: any;
  UserTempFiltered: any = [];
  EligibleSPIFiltered: any = [];
  PidmappingforEmployeeFiltered: any = [];
  AccountmappingforEmployeeFiltered: any = [];
  selectedPSNO: any;
  UploadFileTypes: any = [];
  UploadTypes: any = [];
    UploadOptionTypes: any = [];
  ShowUploadedUserMaster: boolean = false;
  ShowEmployeeMapping: boolean = false;
  ShowUploadedSPIAmounts: boolean = false;
  UserUploadMessages: any = [];
  MappingType: string = '';
  typeOptionSelected: any;
  YearType: string = '';
  AllowedRolesForPeriod: any = [];
  roleSelected: any;
  roleSelectedName: any;
  DownloadFilePathBase: any;
  RoleByPeriodMaster: any = [];
  TargetForUploadAfterConfirm: any = [];
  warningdata: string = '';
  UpadateTargetMessage = '';
  Usermaster_Active: string = '../../../UploadTemplates/UserMaster_Active';
  EligibleSPImaster_Active: string = '../../../UploadTemplates/EligibleSPIMaster_Active.xlsx';
  SuccessFlag: number;
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  typeSelectednew: string = '';
    constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
        this.appService.SetLoadingShow(false);
        this.periodid = '0';
        this.UserPsno = '';
        this.uploadedFile = null;
        this.periodValus = [];
      this.ShowSection = 1;
      this.selectedPSNO = '';
        this.ShowIOptionTypeSection = 1;
        this.Message = '';
        this.periodValues = [];
      this.typeSelected = 0;
      this.DownloadFilePathForPID = '';
      this.DownloadFilePathForAccount = '';
      this.typeOptionSelected = 0;
      this.roleSelected = '0';
      this.roleSelectedName = '';
      this.DownloadFilePathBaseForPID = '../../../UploadTemplates/PIDMappingForEmployee';
      this.DownloadFilePathBaseForAccount = '../../../UploadTemplates/AccountMappingForEmployee';
      this.DownloadFilePathBase = '../../../UploadTemplates/WeightageTemplate_';
      this.UploadFileTypes = [{ 'TypeId': 1, 'Type': 'PID Mapping' }, { 'TypeId': 2, 'Type': 'Account Mapping' }]
      this.UploadTypes = [{ 'TypeId': 1, 'Type': 'PID Mapping' }]
      //this.UploadOptionTypes = [{ 'OptionTypeId': 1, 'OptionType': 'Revenue' }, { 'OptionTypeId': 2, 'OptionType': 'TCV' }, { 'OptionTypeId': 3, 'OptionType': 'CSAT' }]
      this.typeSelectednew = '';
    }
  ngOnInit() {
    this.appService.SetLoadingShow(false);
    this.userInfo = this.appService.GetLoginUserInfo();
    this.UserPsno = this.userInfo.PS_No;

    if (this.userInfo != null) {
    
      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.masterData = this.appService.GetMasterDetails();
        this.periodid = '0';
        this.ShowSection = 0;
        this.ShowIOptionTypeSection = 0;
        this.LoadUploadsForUser();
      }
      else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
    this.Usermaster_Active = this.Usermaster_Active + '_' + this.userInfo.AppType + '.xlsx';
  }
    ChangeView(value: number) {
      this.ShowSection = value;
      this.ShowOptiontype(this.ShowIOptionTypeSection);
    }
  ShowOptiontype(value: number) {
    this.ShowIOptionTypeSection = value;
    this.periodValues = [];
    if ((value == 1 || value == 13 || value == 15 || value == 16 || value == 2 || value == 21 || value == 27 || value == 29 || value == 31 || value == 32) && this.periodValues.length == 0) {
      this.ShowUserMaster(false);
      this.ShowEligibleSPIMaster(false);
      this.UserUploadMessages = []
      if (value == 13) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTarget.xlsx';
      }
      else if (value == 15) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTargetAccounts.xlsx';
      }
      else if (value == 16) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTargetACD.xlsx';
      }
      else if (value == 21) {
        this.DownloadFilePath = '../../../UploadTemplates/CSAT_Pretarget.xlsx';
      }
      else if (value == 27) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTargetACM.xlsx';
      }
      else if (value == 32) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTargetACMWithPU.xlsx';
      }
      else if (value == 29) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTargetAccountswithDU.xlsx';
      }
      else if (value == 31) {
        this.DownloadFilePath = '../../../UploadTemplates/PreTargetBudgetUploadwithPU.xlsx';
      }
      else {
        this.DownloadFilePath = '../../../UploadTemplates/UserMaster_Upload_' + this.userInfo.AppType + '.xlsx';
      }
      this.appService.SetLoadingShow(true);
      this.GetTargetYearTextForPeriod();
    }
    if (value == 8) {
      this.DownloadFilePath = '../../../UploadTemplates/TCV.xlsx';
    }
    else if ((value == 14 || value == 6)  && this.typeSelected == 1) {
      this.DownloadFilePath = '../../../UploadTemplates/PIDMapping.xlsx';
    }
    else if (value == 6 && this.typeSelected == 2) {
      this.DownloadFilePath = '../../../UploadTemplates/CGC.xlsx';
    }
      
    else if (value == 2) {
      this.DownloadFilePath = '../../../UploadTemplates/Incentives.xlsx';
    }
    else if (value == 3) {
      this.DownloadFilePath = '../../../UploadTemplates/FinalIncentive.xlsx';
    }
    else if (value == 4) {
      this.DownloadFilePath = '../../../UploadTemplates/FinalRating.xlsx';
    }
    else if (value == 10 || value == 22) {
      this.DownloadFilePath = '../../../UploadTemplates/CSAT.xlsx';
    }
    else if (value == 11) {
      this.DownloadFilePath = '../../../UploadTemplates/CMMI.xlsx';
    }
    else if (value == 17) {
      this.DownloadFilePath = '../../../UploadTemplates/AccountMapping_upload.xlsx';
    }
    else if (value == 18) {
      // this.DownloadFilePath = '../../../UploadTemplates/EmployeeWeightages.xlsx';
      this.GetTargetYearTextForPeriod();
    }
    else if (value == 19) {
      this.DownloadFilePath = '../../../UploadTemplates/TargetMapping_' + this.userInfo.AppType + '.xlsx';
      this.periodid = 0;
      this.GetTargetYearTextForPeriod();
    }
    else if (value == 26) {
      this.DownloadFilePath = '../../../UploadTemplates/QualitativeTargetMapping_' + this.userInfo.AppType + '.xlsx';
      this.periodid = 0;
      this.typeSelectednew = '0';
      this.GetTargetYearTextForPeriod();
    }

    else if (value == 20) {
      this.DownloadFilePath = '../../../UploadTemplates/TargetMapping_' + this.userInfo.AppType + '.xlsx';
      this.periodid = 0;
      this.GetTargetYearTextForPeriod();
    }
    else if (value == 23) {
      if (this.userInfo.AppType == 1) {
        this.DownloadFilePath = '../../../UploadTemplates/ActualsMappingUpload.xlsx';
      }
      else if (this.userInfo.AppType == 2) {
        this.DownloadFilePath = '../../../UploadTemplates/ActualsMappingUpload_2.xlsx';
      }
    }
    else if (value == 24) {
      this.DownloadFilePath = '../../../UploadTemplates/AchievementPercentageUpload.xlsx';
    }
      else if (value == 28) {
        this.DownloadFilePath = '../../../UploadTemplates/HunterUSAMapping.xlsx';
    }
  }
    fileChange(event) {

        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.uploadedFile = fileList[0];
        }
        else {
            this.uploadedFile = null;
        }
    }

    UploadFile() {

        var data = {
            'PeriodId': this.periodid,
            'AppType': this.userInfo.AppType
        };

        var url = '';
        if (this.ShowIOptionTypeSection == 8){// TCV
            url = this.configs.UploadEmployeeWiseTCVDetailsURL();
            if (this.uploadedFile != null) {
                this.appService.SetLoadingShow(true);
              this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No,'','')
                    .then(response => {
                        if (response.ResponseCode == this.configs.RetCodeFailure()) {
                            this.appService.SetLoadingShow(false);
                            this.Message = response.ResponseData;
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }
                        else {
                            this.uploadedFile = null;
                            this.appService.SetLoadingShow(false);
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }

                    });
            }
        }
        else if (this.ShowIOptionTypeSection == 10 || this.ShowIOptionTypeSection == 22 ) {//10- CSAT 22- past year csat
          url = this.configs.UploadCSATDetailsURL();
          if (this.ShowIOptionTypeSection == 10) {
            this.MappingType = 'CSAT';
          }
          else {
            this.MappingType = 'PastYearCSAT';
          }
            if (this.uploadedFile != null) {
              this.appService.SetLoadingShow(true);
              this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, this.MappingType, this.userInfo.PS_No, '', '')
                    .then(response => {
                        if (response.ResponseCode == this.configs.RetCodeFailure()) {
                            this.appService.SetLoadingShow(false);
                            this.Message = response.ResponseData;
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }
                        else {
                            this.uploadedFile = null;
                            this.appService.SetLoadingShow(false);
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }

                    });
            }
        }
        else if (this.ShowIOptionTypeSection == 11) {//CMMI
            url = this.configs.UploadCMMIDetailsURL();
            if (this.uploadedFile != null) {
                this.appService.SetLoadingShow(true);
              this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No, '','')
                    .then(response => {
                        if (response.ResponseCode == this.configs.RetCodeFailure()) {
                            this.appService.SetLoadingShow(false);
                            this.Message = response.ResponseData;
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }
                        else {
                            this.uploadedFile = null;
                            this.appService.SetLoadingShow(false);
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }

                    });
            }
        }

        else if (this.ShowIOptionTypeSection == 18) {  //EmployeeWeightages
          url = this.configs.UploadEmployeeWeightagesDetailsURL();
          if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.FileUpLoad(url, this.uploadedFile, this.UserPsno, this.userInfo.PS_No, this.roleSelectedName, "", this.periodid, this.userInfo.AppType)

           // this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No, '')
              .then(response => {
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                  this.appService.SetLoadingShow(false);
                  this.Message = response.ResponseData;
                  this.dialog.open(DialogDataExampleDialog, {
                    data: response.ResponseData
                  });
                }
                else {
                  this.uploadedFile = null;
                  this.appService.SetLoadingShow(false);
                  this.dialog.open(DialogDataExampleDialog, {
                    data: response.ResponseData.retmsgs
                  });
                }

              });
          }
        }
      else if (this.ShowIOptionTypeSection == 6 || this.ShowIOptionTypeSection == 9 || this.ShowIOptionTypeSection == 12 || this.ShowIOptionTypeSection ==14)  {//PID &CGC
            if (this.typeSelected == 1)
                url = this.configs.PIDMappingURL();
            else if (this.typeSelected == 2)
                url = this.configs.AccountMappingURL();
          if (this.userInfo.AppType == 2) {
            url = this.configs.PIDMappingURL();
            if (this.ShowIOptionTypeSection == 9) {
              this.MappingType = 'CGC';
            }
            else if (this.ShowIOptionTypeSection == 12) {
              this.MappingType = 'PID';
            }
          }
          //Past Revenue Upload
          if (this.ShowIOptionTypeSection == 14) {
            if (this.typeSelected == 1) {
              url = this.configs.PastYear_PIDMappingURL();
              this.YearType = 'PastYearPID'
            }
            else if (this.typeSelected == 2) {
              url = this.configs.Past_AccountMappingURL();
              this.YearType = 'PastYearCGC'
            }
          }

            if (this.uploadedFile != null) {
                this.appService.SetLoadingShow(true);
                this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, this.MappingType, this.userInfo.PS_No, this.YearType,'')
                    .then(response => {
                        if (response.ResponseCode == this.configs.RetCodeFailure()) {
                            this.appService.SetLoadingShow(false);
                            this.Message = response.ResponseData;
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }
                        else {
                            this.uploadedFile = null;
                            this.appService.SetLoadingShow(false);
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }

                    });
            }
        }
        else if (this.ShowIOptionTypeSection == 7) {//Sales File
            if (this.typeSelected == 1)
                url = this.configs.UpdateSalesMappingURL();
            if (this.uploadedFile != null) {
                this.appService.SetLoadingShow(true);
              this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No,'','')
                    .then(response => {
                        if (response.ResponseCode == this.configs.RetCodeFailure()) {
                            this.appService.SetLoadingShow(false);
                            this.Message = response.ResponseData;
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }
                        else {
                            this.uploadedFile = null;
                            this.appService.SetLoadingShow(false);
                            this.dialog.open(DialogDataExampleDialog, {
                                data: response.ResponseData
                            });
                        }

                    });
            }
      }
      if (this.ShowIOptionTypeSection == 17) {// AccountMappings
        url = this.configs.UploadAccountMappingDetailsURL();
        if (this.uploadedFile != null) {
          this.appService.SetLoadingShow(true);
          this.appService.FileUpLoadAccountMapping(url, this.uploadedFile,  this.userInfo.AppType, '', this.userInfo.PS_No)
            .then(response => {
              if (response.ResponseCode == this.configs.RetCodeFailure()) {
                this.appService.SetLoadingShow(false);
                this.Message = response.ResponseData;
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }
              else {
                this.uploadedFile = null;
                this.appService.SetLoadingShow(false);
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }

            });
        }
      }
      if (this.ShowIOptionTypeSection == 13 || this.ShowIOptionTypeSection == 15 || this.ShowIOptionTypeSection == 16 || this.ShowIOptionTypeSection == 27 || this.ShowIOptionTypeSection == 29 || this.ShowIOptionTypeSection == 31 || this.ShowIOptionTypeSection == 32) {// pre target
        if (this.ShowIOptionTypeSection == 13) {
          url = this.configs.UploadPreTargetDetailsURL();
        }
        else if (this.ShowIOptionTypeSection == 15) {
          url = this.configs.UploadPreTargetAccountsDetailsURL();
        }
        else if (this.ShowIOptionTypeSection == 16) {
          url = this.configs.UploadPreTargetACDDetailsURL();
        }
        else if (this.ShowIOptionTypeSection == 27) {
          url = this.configs.UploadPreTargetDetailsForACMURL();
        }
        else if (this.ShowIOptionTypeSection == 32) {
          url = this.configs.UploadPreTargetDetailsForACMWithPUURL();
        }
        else if (this.ShowIOptionTypeSection == 29) {
          url = this.configs.UploadPreTargetAccountsDetailswithDUURL();
        }
        else if (this.ShowIOptionTypeSection == 31) {
          url = this.configs.UploadPreTargetBudgetUploadwithPUDetailsURL();
        }
        if (this.uploadedFile != null) {
          this.appService.SetLoadingShow(true);
          this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, ''  , this.userInfo.PS_No,'','')
            .then(response => {
              if (response.ResponseCode == this.configs.RetCodeFailure()) {
                this.appService.SetLoadingShow(false);
                this.Message = response.ResponseData;
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }
              else {
                this.uploadedFile = null;
                this.appService.SetLoadingShow(false);
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }

            });
        }
      }

      if (this.ShowIOptionTypeSection == 23) {//New alternate PID-CGC
        url = this.configs.DUPortfoliomappingForActualURL();
        if (this.uploadedFile != null) {
          this.appService.SetLoadingShow(true);
          this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No, '', '')
            .then(response => {
              if (response.ResponseCode == this.configs.RetCodeFailure()) {
                this.appService.SetLoadingShow(false);
                this.Message = response.ResponseData;
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }
              else {
                this.uploadedFile = null;
                this.appService.SetLoadingShow(false);
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }

            });
        }
      }

      if (this.ShowIOptionTypeSection == 28) {//NHunter USA Mapping
        url = this.configs.HunterUSAMappingURL();
        if (this.uploadedFile != null) {
          this.appService.SetLoadingShow(true);
          this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No, '', '')
            .then(response => {
              if (response.ResponseCode == this.configs.RetCodeFailure()) {
                this.appService.SetLoadingShow(false);
                this.Message = response.ResponseData;
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }
              else {
                this.uploadedFile = null;
                this.appService.SetLoadingShow(false);
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }

            });
        }
      }

      if (this.ShowIOptionTypeSection == 24) {//New alternate PID-CGC
        url = this.configs.UpdateAchievementPercentageURL();
        if (this.uploadedFile != null) {
          this.appService.SetLoadingShow(true);
          this.appService.FileUpLoadActuals(url, this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No, '', '')
            .then(response => {
              if (response.ResponseCode == this.configs.RetCodeFailure()) {
                this.appService.SetLoadingShow(false);
                this.Message = response.ResponseData;
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }
              else {
                this.uploadedFile = null;
                this.appService.SetLoadingShow(false);
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData
                });
              }

            });
        }
      }

    }




    onChangeOFPeriod(id) {
        if (id != '') {
          this.periodid = id;
          this.FilterPlans(id);
          if (this.ShowIOptionTypeSection == 1) {
            this.GetUserData();
          }
            else if (this.ShowIOptionTypeSection == 2) {
            this.EligibleSPIMaster();
          }
          }
        else {
            this.periodid = '0';
        }
    }
    LoadUploadsForUser() {
        var data = {
            "PSNo": this.userInfo.PS_No,
            'AppType': this.userInfo.AppType
        };

        this.appService.GetDataFromAPI(this.configs.GetUploadsByUserURL(), data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.UploadOptionTypes = response.ResponseData;
                  var ReportId = null;
                  this.route.params.subscribe(params =>
                    ReportId = params.ReportId);
                  if (ReportId != '' && ReportId != null && ReportId != undefined) {
                    this.typeOptionSelected = parseInt(ReportId);
                    this.ShowOptiontype(this.typeOptionSelected);

                  }
                    //this.ChangeView(2);
                }
            });
    }
    GetTargetYearTextForPeriod() {
        var UserData = {
            "PS_No": this.userInfo.PS_No,
            "AppType": this.userInfo.AppType
        }
        this.appService.GetDataFromAPI(this.configs.GetYearsForTargetUpload(), UserData)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                  this.periodValues = response.ResponseData.dtYearsForTrgts;
                  this.RoleByPeriodMaster = response.ResponseData.dtApplicablePlanForYear;
                  this.periodid = '0';
                  var ReportId = null;
                  this.route.params.subscribe(params =>
                    ReportId = params.ReportId);
                  if (ReportId != '' && ReportId != null && ReportId != undefined) {
                    this.periodid = this.appService.GetDataForPreTargets().PeriodYear;

                  }
                }
            });
    }


    UploadUserMasterFile() {

        if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.UserMasterUpload(this.configs.UploadUserMasterDetailsURL(), this.uploadedFile, this.periodid, this.userInfo.PS_No, this.userInfo.AppType)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.appService.SetLoadingShow(false);
                        this.uploadedFile = null;
                        this.UserUploadMessages = response.ResponseData;
                    }

                });
        }
    }


    UploadFileForEligibleSPI() {

        var data = {
            'PeriodID': this.periodid,
            'AppType': this.userInfo.AppType
        };


        if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.IncentiveEligibleAmountUpload(this.configs.UploadIncentiveEligibleAmountDetailedURL(), this.uploadedFile, this.periodid, this.userInfo.AppType)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.uploadedFile = null;
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }

                });
        }
    }

    UploadFileForFinalAmountPaid() {
        if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.IncentiveEligibleAmountUpload(this.configs.UploadFinalIncentivesURL(), this.uploadedFile, this.periodid, this.userInfo.AppType)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.uploadedFile = null;
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }

                });
        }
    }

    UploadFileForFinalRating() {
        if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.IncentiveEligibleAmountUpload(this.configs.UploadFinalRatingURL(), this.uploadedFile, this.periodid, this.userInfo.AppType)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.uploadedFile = null;
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }

                });
        }
    }

    UploadFileForACD() {
        if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.IncentiveEligibleAmountUpload(this.configs.UploadACDExcelURL(), this.uploadedFile, this.periodid, this.userInfo.AppType)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.appService.SetLoadingShow(false);
                        this.uploadedFile = null;
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }

                });
        }
    }

    ShowUserMaster(value) {
        this.ShowUploadedUserMaster = value;
      if (value == true) {
        this.GetUserData();
      }
    }
    GetUserData() {

        var data = {
            'AppType': this.userInfo.AppType,
            'PeriodYear': this.periodid
        };
        this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPI(this.configs.GetUserDataURL(), data)
            .then(response => {
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.appService.SetLoadingShow(false);
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                   

                  this.UserTempFiltered = response.ResponseData.dtUserMaster;
                  this.EligibleSPIFiltered = response.ResponseData.dtEligibleSPIMaster;
                    this.appService.SetLoadingShow(false);
                    if (this.UserTempFiltered.length == 0) {
                        this.dialog.open(DialogDataExampleDialog, {
                            data: "No record found."
                        });
                        // return;
                  }
                  if (this.EligibleSPIFiltered.length == 0) {
                    this.dialog.open(DialogDataExampleDialog, {
                      data: "No record found."
                    });
                    // return;
                  }
                }
            }
                , error => {
                   
                });
  }
  DownloadPreTargetData() {
    window.open("TargetAccountMapping.ashx?PSNO=" + this.userInfo.PS_No + "&PeriodId=" + this.periodid + "&AppType=" + this.userInfo.AppType );
  }

  DownloadPreTargetDatawithDU() {
    window.open("TargetAccountMappingwithDU.ashx?PSNO=" + this.userInfo.PS_No + "&PeriodId=" + this.periodid + "&AppType=" + this.userInfo.AppType );
  }

  ShowEligibleSPIMaster(value) {
    this.ShowUploadedSPIAmounts = value;
    if (value == true) {
      this.EligibleSPIMaster();
    }
  }
  EligibleSPIMaster() {

    var data = {
      'AppType': this.userInfo.AppType,
      'PeriodYear': this.periodid
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetEligibleSPIDataURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {


          
          this.EligibleSPIFiltered = response.ResponseData.dtEligibleSPIMaster;
          this.appService.SetLoadingShow(false);
          if (this.EligibleSPIFiltered.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            // return;
          }
        }
      }
        , error => {

        });
  }

  ChangeRole(id) {
    if (id != '') {
      this.roleSelected = id;
      if (this.roleSelected != '0') {
        //this.appService.SetLoadingShow(true);
        for (var o in this.AllowedRolesForPeriod) {
          if (this.AllowedRolesForPeriod[o].RoleId == this.roleSelected) {
            this.appService.SetRoleID(this.roleSelected);
            this.roleSelectedName = this.AllowedRolesForPeriod[o].RoleName;
            this.DownloadFilePath = this.DownloadFilePathBase + this.periodid + '_' + this.roleSelectedName + ".xlsx";
            //this.ChangeView(2);
            break;
          }
        }
      }
      else {
        this.roleSelectedName = '';
        this.DownloadFilePath = '';
      }
    }
  }

  FilterPlans(periodid) {
    this.AllowedRolesForPeriod = [];
    for (var o in this.RoleByPeriodMaster) {
      if (this.RoleByPeriodMaster[o].TargetUploadYearText == periodid) {
        this.AllowedRolesForPeriod.push(this.RoleByPeriodMaster[o]);
      }
    }

  }
  UploadFileForTargetMapping() {
    var TargetScreenRoles = this.appService.GetUserRoleForTargetUpload(this.userInfo.SPMRoles);
    var LoggedInUserRole = '0';
    if (TargetScreenRoles.CSRoleId > 0)
      LoggedInUserRole = TargetScreenRoles.CSRoleId;
    else if (TargetScreenRoles.CBORoleId > 0)
      LoggedInUserRole = TargetScreenRoles.CBORoleId;
    else if (TargetScreenRoles.HRRoleId > 0)
      LoggedInUserRole = TargetScreenRoles.HRRoleId;
    else if (TargetScreenRoles.SELARoleId > 0)
      LoggedInUserRole = TargetScreenRoles.SELARoleId;
    else if (TargetScreenRoles.FunctionHeadId > 0)
      LoggedInUserRole = TargetScreenRoles.FunctionHeadId;

 

    if (this.uploadedFile != null) {
      this.appService.SetLoadingShow(true);
      this.appService.TargetMappingAndWeightagesUpload(this.configs.UploadTargetMappingURL(), this.uploadedFile, this.periodid, this.userInfo.AppType, this.userInfo.PS_No, LoggedInUserRole)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.warningdata = response.ResponseData.retmsgs;
            this.TargetForUploadAfterConfirm = response.ResponseData.approveTargets;
            this.confirmModel.openModal();

            this.uploadedFile = null;
            //this.appService.SetLoadingShow(false);
            //this.dialog.open(DialogDataExampleDialog, {
            //  data: response.ResponseData
            //});
          }

        });
    }
  }
  onCancelClick(): void {
    this.confirmModel.closeModal();
  }
  UpdateTargetsAfterConfirmation(): void {
    if (this.ShowIOptionTypeSection == 26 && this.typeSelectednew == '1') {
      this.onCancelClick();
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.UpdateTargetsAfterConfirmationQualitativeURL(), this.TargetForUploadAfterConfirm)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.onCancelClick();
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.UpadateTargetMessage = response.ResponseData;
            if (this.UpadateTargetMessage.length > 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            this.TargetForUploadAfterConfirm = [];
          }
        });
    }
    else if (this.ShowIOptionTypeSection == 26 && this.typeSelectednew == '2') {
      this.onCancelClick();
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.UpdateTargetsAfterConfirmationQualitativeURL(), this.TargetForUploadAfterConfirm)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.onCancelClick();
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.UpadateTargetMessage = response.ResponseData;
            if (this.UpadateTargetMessage.length > 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            this.TargetForUploadAfterConfirm = [];
          }
        });
    }
    else {
      this.onCancelClick();
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.UpdateTargetsAfterConfirmationURL(), this.TargetForUploadAfterConfirm)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.onCancelClick();
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.UpadateTargetMessage = response.ResponseData;
            if (this.UpadateTargetMessage.length > 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            this.TargetForUploadAfterConfirm = [];
          }
        });
    }
  }
  UploadFileForWeightageMapping() {
 var TargetScreenRoles = this.appService.GetUserRoleForTargetUpload(this.userInfo.SPMRoles);
    var LoggedInUserRole = '0';
    if (TargetScreenRoles.CSRoleId > 0)
      LoggedInUserRole = TargetScreenRoles.CSRoleId;
    else if (TargetScreenRoles.CBORoleId > 0)
      LoggedInUserRole = TargetScreenRoles.CBORoleId;
    else if (TargetScreenRoles.HRRoleId > 0)
      LoggedInUserRole = TargetScreenRoles.HRRoleId;
    else if (TargetScreenRoles.SELARoleId > 0)
      LoggedInUserRole = TargetScreenRoles.SELARoleId;


    if (this.uploadedFile != null) {
      this.appService.SetLoadingShow(true);
      this.appService.TargetMappingAndWeightagesUpload(this.configs.UploadWeightageMappingURL(), this.uploadedFile, this.periodid, this.userInfo.AppType, this.userInfo.PS_No, LoggedInUserRole)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.warningdata = response.ResponseData.retmsgs;
            this.uploadedFile = null;
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data:  this.warningdata
            });
          }

        });
    }
  }
  UploadPreTargetCSATVAlues() {

      if (this.uploadedFile != null) {
        this.appService.SetLoadingShow(true);
        this.appService.FileUpLoadActuals(this.configs.UploadPreTargetCSATURL(), this.uploadedFile, this.periodid, this.userInfo.AppType, '', this.userInfo.PS_No, '', 'PreTarget')
          .then(response => {
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.appService.SetLoadingShow(false);
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
                this.appService.SetLoadingShow(false);
                this.uploadedFile = null;
                this.dialog.open(DialogDataExampleDialog, {
                  data: response.ResponseData.retmsgs
                });
              }

          });
     
    }
  }

  ShowTemplate(value) {
    this.ShowEmployeeMapping = value;
  }
  

  GetEmployeeMappingData() {
  
     
    if ((this.ShowIOptionTypeSection == 6 && this.typeSelected == 1 && this.selectedPSNO != '')) {
      this.GetEmployeePIDData(this.selectedPSNO);
    }
    else if ((this.ShowIOptionTypeSection == 6 && this.typeSelected == 2 && this.selectedPSNO != '')) {
      this.GetEmployeeAccountData(this.selectedPSNO);
    }
    else {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Enter PSNO"
      });
    }
  }
  GetEmployeePIDData(PSNO) {

    var data = {
      'PSNo': PSNO,
      'AppType': this.userInfo.AppType,
      'PeriodId': this.periodid
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetPidmappingforEmployeeURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {


          
          this.PidmappingforEmployeeFiltered = response.ResponseData.dtPIDmappingForEmployee;
          this.appService.SetLoadingShow(false);
          
          if (this.PidmappingforEmployeeFiltered.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            // return;
          }
          else {
          
            this.DownloadFilePathForPID = this.DownloadFilePathBaseForPID +".xlsx";
            window.open(this.DownloadFilePathForPID);
          }
          
        }
      }
        , error => {

        });
  }

  GetEmployeeAccountData(PSNO) {

    var data = {
      'PSNo': PSNO,
      'AppType': this.userInfo.AppType,
      'PeriodId': this.periodid
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetAccountmappingforEmployeeURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {



          this.AccountmappingforEmployeeFiltered = response.ResponseData.dtAccountmappingForEmployee;
          this.appService.SetLoadingShow(false);
          
          if (this.AccountmappingforEmployeeFiltered.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            // return;
          }
          else {
            this.DownloadFilePathForAccount = this.DownloadFilePathBaseForAccount+ ".xlsx";
            window.open(this.DownloadFilePathForAccount);
          }

        }
      }
        , error => {

        });
  }
  UploadFileForTargetMappingQualitative() {
    var TargetScreenRoles = this.appService.GetUserRoleForTargetUpload(this.userInfo.SPMRoles);
    var LoggedInUserRole = '0';
    if (TargetScreenRoles.CSRoleId > 0)
      LoggedInUserRole = TargetScreenRoles.CSRoleId;
    else if (TargetScreenRoles.CBORoleId > 0)
      LoggedInUserRole = TargetScreenRoles.CBORoleId;
    else if (TargetScreenRoles.HRRoleId > 0)
      LoggedInUserRole = TargetScreenRoles.HRRoleId;
    else if (TargetScreenRoles.SELARoleId > 0)
      LoggedInUserRole = TargetScreenRoles.SELARoleId;
    else if (TargetScreenRoles.FunctionHeadId > 0)
      LoggedInUserRole = TargetScreenRoles.FunctionHeadId;

    if (this.typeSelectednew == '1') {

      if (this.uploadedFile != null) {
        this.appService.SetLoadingShow(true);
        this.appService.TargetMappingAndWeightagesUpload(this.configs.UploadTargetMappingQualitativeURL(), this.uploadedFile, this.periodid, this.userInfo.AppType, this.userInfo.PS_No, LoggedInUserRole)
          .then(response => {
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.appService.SetLoadingShow(false);
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.appService.SetLoadingShow(false);
              this.SuccessFlag = response.ResponseData.SuccessFlag;
              if (this.SuccessFlag == 1) {
                this.warningdata = response.ResponseData.retmsgs;
                this.TargetForUploadAfterConfirm = response.ResponseData.approveTargets;
                this.confirmModel.openModal();
              }
              else {
                var msg = response.ResponseData.retmsgs;
                if (msg.length > 0) {
                  this.dialog.open(DialogDataExampleDialog, {
                    data: msg
                  });
                }
              }
            

              this.uploadedFile = null;
              //this.appService.SetLoadingShow(false);
              //this.dialog.open(DialogDataExampleDialog, {
              //  data: response.ResponseData
              //});
            }

          });
      }
    }
    if (this.typeSelectednew == '2') {

      if (this.uploadedFile != null) {
        this.appService.SetLoadingShow(true);
        this.appService.TargetMappingAndWeightagesUpload(this.configs.UploadTargetMappingQualitativeCustomURL(), this.uploadedFile, this.periodid, this.userInfo.AppType, this.userInfo.PS_No, LoggedInUserRole)
          .then(response => {
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.appService.SetLoadingShow(false);
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.appService.SetLoadingShow(false);
              this.SuccessFlag = response.ResponseData.SuccessFlag;
              if (this.SuccessFlag == 1) {
                this.warningdata = response.ResponseData.retmsgs;
                this.TargetForUploadAfterConfirm = response.ResponseData.approveTargets;
                this.confirmModel.openModal();
              }
              else {
               var msg = response.ResponseData.retmsgs;
                if (msg.length > 0) {
                  this.dialog.open(DialogDataExampleDialog, {
                    data: msg
                  });
                }
              }
              

              this.uploadedFile = null;
              //this.appService.SetLoadingShow(false);
              //this.dialog.open(DialogDataExampleDialog, {
              //  data: response.ResponseData
              //});
            }

          });
      }
    }
  }
  ChangeType() {
    if (this.typeSelectednew == '1') {
      this.DownloadFilePath = '../../../UploadTemplates/QualitativeTargetMapping_' + this.userInfo.AppType + '.xlsx';
    }
    if (this.typeSelectednew == '2') {
      this.DownloadFilePath = '../../../UploadTemplates/CustomQualitativeTargetMapping_' + this.userInfo.AppType + '.xlsx';
    }
  }

}

