import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
  selector: 'app-pid-approval',
  templateUrl: './pid-approval.component.html',
  styleUrls: ['./pid-approval.component.less']
})
export class PIDAPPROVALComponent implements OnInit {
  periodid: any;
  ShowIOptionTypeSection: number;
  UserPsno: any;
  ShowSection: number;
  userInfo: any;
  masterData: any;
  UploadOptionTypes: any = [];
  selectedCGC: any=0;
  selectedCGC_name: any='';
  AccountWiseData_ApprovalContent: any = [];
  AccountWiseData_ApprovalHeaders: any[];
  filters: any;
  ProjectWiseData_ApprovalContent: any = [];
  ProjectWiseData_ApprovalHeaders: any[];
  showAccounttable: boolean = false;
  showProjecttable: boolean = false;
  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.periodid = '0';
    this.ShowIOptionTypeSection = 1;
    this.filters = {
      'dtSBU': [],
      'dtProjectId': [],
      'dtProjectIdCG': [],
      'dtCGC': [],
      'dtRole': {},
      'dtUserInfo': [],
      'dtPeriodMaster': []
    };
    this.masterData = {};
    this.UserPsno = '';
    this.ShowSection = 1;
    this.UploadOptionTypes = [{ 'OptionTypeId': 1, 'OptionType': 'Account' }, { 'OptionTypeId': 2, 'OptionType': 'Project' }]


  }
  ngOnInit() {
    const getFullDate = new Date();
    const currentDate = new Date().toISOString().slice(0, 10);
    const getYear = getFullDate.getFullYear();
    const fullYrAprilm = getYear + '-04-01';
    const fullYrSeptm = getYear + '-09-30';
    var hfyOctm = getYear + '-10-01';
    var hfyMarchm = getYear + '-03-31';

    this.appService.SetLoadingShow(false);
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {

        this.UserPsno = this.userInfo.PS_No;
        this.masterData = this.appService.GetMasterDetails();

        var octdt = null;
        if (currentDate > hfyMarchm) {
          octdt = getYear + '-10-01';
          if (currentDate < hfyOctm) {
            hfyMarchm = getYear + '-03-31';
          }
          else {
            hfyMarchm = getYear + 1 + '-03-31';
          }
        }
        else if (currentDate <= hfyMarchm) {
          octdt = getYear - 1 + '-10-01';
          if (currentDate >= octdt) {
            hfyMarchm = getYear + '-03-31';
          }
          else {
            hfyMarchm = getYear + 1 + '-03-31';
          }
        }

        if (currentDate >= octdt && currentDate <= hfyMarchm) {
          this.periodid = this.masterData.dtPeriodDet[1].PeriodID;
        }
        else if (currentDate >= fullYrAprilm && currentDate <= fullYrSeptm) {
          this.periodid = this.masterData.dtPeriodDet[2].PeriodID;
        }

        //this.periodid = '0';
        this.ShowSection = 0;
        this.ShowIOptionTypeSection = 0;
      }
      else {
        this.router.navigate(['no-access']);
      }

    }
    else {
      window.location.href = "../Index.html";
    }

  }
 
  ShowOptiontype(value: number) {
    this.ShowIOptionTypeSection = value;
    this.GetDataForPIDMapping();
  }

  GetDataForPIDMapping() {
    if (this.selectedCGC != 0) {
      if (this.ShowIOptionTypeSection == 1) {
        this.GetAccountwiseDataForPIDApproval(this.selectedCGC);
      }
      else {
        this.GetProjectwiseDataForPIDApproval(this.selectedCGC);
      }
    }
  }
  onChangeOFPeriod(id) {
    if (id != '') {
      this.periodid = id;
      this.getfilters(this.periodid);
      this.GetDataForPIDMapping();
    }
    else {
      this.periodid = '0';
    }

  }

  onclickcheckbox(x) {

    if (this.AccountWiseData_ApprovalContent[x].Select == 0) {
      this.AccountWiseData_ApprovalContent[x].Select = 1;
    }

    else {
      this.AccountWiseData_ApprovalContent[x].Select = 0;
    }

  }
  onclickcheckboxProject(x) {

    if (this.ProjectWiseData_ApprovalContent[x].Select == 0) {
      this.ProjectWiseData_ApprovalContent[x].Select = 1;
    }

    else {
      this.ProjectWiseData_ApprovalContent[x].Select = 0;
    }

  }
  getfilters(value: string): void {
    var Input = {
      "Period": value,
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetFilterMasterForPIDMappingURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.filters.dtCGC = response.ResponseData.dtCGC;
          this.filters.dtSBU = response.ResponseData.dtSBU;
          this.filters.dtProjectId = response.ResponseData.dtProjectId;
          this.filters.dtRole = response.ResponseData.dtRole;
          this.filters.dtUserInfo = response.ResponseData.dtUserInfo;
          this.filters.dtPeriodMaster = response.ResponseData.dtPeriodMaster;

          //this.ShowFilters = true;
        }
      }
        ,
        error => {

        });
  }
  GetAccountwiseDataForPIDApproval(value_cgcode: string): void {
    this.showAccounttable = false;
    this.showProjecttable = false;
    var Input = {
      "PeriodId": this.periodid,
      "CGCode": value_cgcode,
      "AppType":this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetAccountwiseDataForPIDApprovalURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.AccountWiseData_ApprovalContent = response.ResponseData.dtAccountWiseData_ApprovalContent;
          this.AccountWiseData_ApprovalHeaders = response.ResponseData.dtAccountWiseData_ApprovalHeaders;
          if (this.AccountWiseData_ApprovalContent.length== 0) {
            this.showAccounttable = true;
          }
    
        }
      }
        ,
        error => {
        });
  }
  GetProjectwiseDataForPIDApproval(value_cgcode: string): void {
    this.showAccounttable = false;
    this.showProjecttable = false;
    var Input = {
      "PeriodId": this.periodid,
      "CGCode": value_cgcode,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetProjectwiseDataForPIDApprovalURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.ProjectWiseData_ApprovalContent = response.ResponseData.dtProjectWiseData_ApprovalContent;
          this.ProjectWiseData_ApprovalHeaders = response.ResponseData.dtProjectWiseData_ApprovalHeaders;
          if (this.ProjectWiseData_ApprovalContent.length == 0) {
            this.showProjecttable = true;
          }
        }
      }
        ,
        error => {
        });
  }

  onClickOfApproval(Status) {

    var data = [];

    if (this.ShowIOptionTypeSection == 1) {
      for (var x in this.AccountWiseData_ApprovalContent) {
        if (this.AccountWiseData_ApprovalContent[x].Select == 1) {
          data.push({
            PK: this.AccountWiseData_ApprovalContent[x].PK,
            Type: 'A',
            Status: Status,
            UpdatedBy: this.userInfo.PS_No,
            Remarks : ''

          })
        }
      }
    }
    else {
      for (var x in this.ProjectWiseData_ApprovalContent) {
        if (this.ProjectWiseData_ApprovalContent[x].Select == 1) {
          data.push({
            PK: this.ProjectWiseData_ApprovalContent[x].PK,
            Type: 'P',
            Status: Status,
            UpdatedBy: this.userInfo.PS_No,
            Remarks: ''

          })
        }
      }
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.PIDApprovalURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.GetDataForPIDMapping();
          var msg = '';
          if (Status == 2) {
            msg = 'PID(s) approved successfully';
          }
          else if (Status == 3) {
            msg = 'PID(s) rejected successfully';
          }
          if (response.ResponseData == "SUCCESS") {
            this.dialog.open(DialogDataExampleDialog, {
              data: msg
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: 'Unable to process transaction. Please try again'
            });
          }
        }
      }
        ,
        error => {
        });
  }
  
}

 

   


