import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MzModalComponent } from 'ngx-materialize';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'incentive-details',
  templateUrl: './incentive-details.component.html',
  styleUrls: ['./incentive-details.component.less']
})
export class IncentiveDetailsComponent implements OnInit {
  @ViewChild('history') history: MzModalComponent
  @ViewChild('parameterwiseData') parameterwiseData: MzModalComponent
/*  @ViewChild('h1computation') h1computation: MzModalComponent*/
  displayedColumns: string[] = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'AchievementWeighted'];
  selectedPeriod: number;
  masterData: any;
  roleSelected: string;
  userInfo: any;
  errorMessage: string;
  tblMetrics: any;
  H1tblMetrics: any;
  showMetricsTable: boolean = false;
  isSubmissionAllowed: boolean = false;
  PSNO: string;
  dtAccounts: any;
  IsDBApplicable: number;
  IsAppraisalApplicable: number;
  IsSPIApplicable: number;
  Proratedvalue: string = '';
  TotalVCP: string = '';
  FinalPayout: string = '';
  TotalPayable: string = '';
  DisagreementData: any;
  CompanyPerFormance: string = '';
  WeightedPercentage: string = '';
  H1WeightedPercentage: string = '';
  TotalWeightedAverage: string = '';
  RevenueAchievement: string = '';
  NewJoineeMsg: string = '';
  NewJoinee: number = 0;
  UserType: string = '';
  SPIAmount: string = '';
  ApprovedComissionAmount: string = '';
  OtherParamArr: any = [];
  ManagerStartDoing: string = '';
  ManagerContinueDoing: string = '';
  ManagerStopDoing: string = '';
  showOtherMetricsTable: boolean = false;
  OtherParameterRatingMaster: any = [];
  PeriodType: string = 'Half-Yearly';
  OtherdisplayedColumns: string[] = ['ParameterName', 'DBWeightage', 'Target', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
  KickerdisplayedColumns: string[];
  GrowthKickerdisplayedColumns: string[];
  isSubmissionAllowedOtherParams: boolean = false;
  VCPStatusId: number;
  StrataTable: any = [];
  QuantitativePercentage: string = '';
  QualitativePercentage: string = '';
  showStratatable: boolean = false;
  isAppraisal: boolean = false;
  isVCP: boolean = false;
  NetAmount: string = '';
  PaidAmount: string = '';
  SalesIncentiveText: string = '';
  SalesIncentiveURL: string = '';
  KickerData: any = [];
  KickerDataTemp: any = [];
  GrowthKickerData: any = [];
  GrowthKickerValue: string = '';
  GrowthKickerStatus: string = '';
  GrowthKickerStatusName: string = '';
  GrowthComments: string = '';
  ApproverGrowthComments: string = '';
  KickerApplicable: boolean = false;
  QualitativeApplicable: boolean = false;
  FeedbackDetails: any = [];
  FeedbackApplicable: boolean = false;
  IsFeedbackDetails: boolean = false;
  FeedbackdisplayedColumns: string[];
  showKickerTable: boolean = false;
  GrowthKickerPercentage: string = '';
  FinalGrowthKickerPercentage: string = '';
  AchievementincludingMBO: string = '';
  MosaicValue: string = '';
  MosaicStatus: string = '';
  MSAValue: string = '';
  MSAStatus: string = '';
  LDValue: string = '';
  LDStatus: string = '';
  TotalAmountPayable: string = '';

  show: boolean = false;
  PortfolioGrowth: string = '';
  TotalAmountPaid: string = '';
  RemainingPayable: string = '';
  SPIName: string = '';
  MosaicKickerValue: string = '';
  MSAKickerValue: string = '';
  LDKickerValue: string = '';
  tblIncentive: any;
  H1tblIncentive: any;
  GrowthApplicable: number = 0;
  UpdatedAchievement: string = '';
  H1UpdatedAchievement: string = '';
  TotalManagerDBRating: number = 0;
  TotalReviewerDBRating: number = 0;
  TotalExecutiveDBRating: number = 0;
  tblDiscretionary: any;
  showDiscretionarytable: boolean = false;
  DiscretionaryTotalvalue: string = '';
  DiscretionaryProrated: string = '';
  DiscretionaryPayout: string = '';
  TotalExecutiveWeightage: string = '';
  DbPaid: string = '';
  RemainingDbPayable: string = '';
  PlanType: string = '';
  PlanTypeFlag: boolean = false;
  TotalWeightage: number = 0;
  TotalSelfRating: number = 0;
  TotalManagerRating: number = 0;
  TotalReviewerRating: number = 0;
  FinalReviewerAssessment: number = 0;
  AppraisalWeightageApplicable: number = 0;
  DbNAFlag: boolean = false;
  DBStatus: number = 0;
  isDBSubmissionAllowed: boolean = false;
  FinalPayoutDB: string = '';
  H1overallachievement: any;
  parameterwiseDatarr: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblMetrics = [];
    this.PSNO = '';
    this.roleSelected = '';
    this.userInfo = {};
    this.selectedPeriod = 0;
    this.dtAccounts = [];
    this.IsDBApplicable = 0;
    this.IsAppraisalApplicable = 0;
    this.IsSPIApplicable = 0;
    this.DisagreementData = {};
    this.UserType = '';
    this.OtherParamArr = [];
    this.VCPStatusId = 0;
    this.KickerData = [];
    this.KickerDataTemp = [];
    this.GrowthKickerData = [];
    this.FeedbackDetails = [];
    this.tblIncentive = [];
    this.H1tblIncentive = [];
    this.tblDiscretionary = [];
    this.PlanType = "0";
    this.AppraisalWeightageApplicable = 0;
    this.parameterwiseDatarr = []
  }

  ngOnInit() {
    this.TotalManagerDBRating = 0;
    this.TotalReviewerDBRating = 0;
    this.TotalExecutiveDBRating = 0;
    this.TotalWeightage = 0;
    this.TotalSelfRating = 0;
    this.TotalManagerRating = 0;
    this.TotalReviewerRating = 0;
    this.masterData = this.appService.GetMasterDetails();
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo.SPMRoles.length > 0) {
      for (var o in this.userInfo.SPMRoles) {
        if (this.userInfo.SPMRoles[o].RoleID == 13) {
          this.isVCP = true;
        }
        if (this.userInfo.SPMRoles[o].RoleID == 15) {
          this.isAppraisal = true;
        }
      }
    }
    var ActualDataObj = {};
    ActualDataObj = this.appService.GetActualData();
    if (ActualDataObj == undefined) {
      this.roleSelected = this.appService.GetRoleId();
      this.selectedPeriod = this.appService.GetperiodId();
      this.PSNO = this.appService.GetPsno();
      this.UserType = this.appService.GetRoleForIncentivePage();
    }
    else {
      this.selectedPeriod = parseInt(ActualDataObj['PeriodID']);
      this.roleSelected = ActualDataObj['Role'];
      this.PSNO = ActualDataObj['PSNo'];
      this.UserType = this.appService.GetRoleForIncentivePage();
      this.appService.ResetSetActualData();
    }
    this.PlanTypeFlag = false;
    this.tblMetrics = [];

    if (this.selectedPeriod != null && this.selectedPeriod != 0 && this.selectedPeriod != null && this.PSNO != "") {

      var Input = {
        "PeriodId": this.selectedPeriod,
        "Role": this.roleSelected,
        "psNo": parseInt(this.PSNO),
        "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.GetIncentiveDetailsURL();
      this.appService.GetDataFromAPI(url, Input)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.tblMetrics = response.ResponseData.dtIncentive;
            this.tblIncentive = response.ResponseData.dtIncentiveDet;
            this.tblDiscretionary = response.ResponseData.dtDiscretionaryDet;
            this.dtAccounts = response.ResponseData.dtAccounts;
            this.IsDBApplicable = response.ResponseData.IsDBApplicable;
            this.IsAppraisalApplicable = response.ResponseData.IsAppraisalApplicable;
            this.IsSPIApplicable = response.ResponseData.IsSPIApplicable;
            if (this.tblMetrics.length == 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: "No details found for the selected employee."
              });
              return;
            }
            else {
              this.PlanType = this.tblMetrics[0].PlanType;
              if (this.PlanType == "1") {
                //this.EmpPlans = response.ResponseData.EmpPlans;
                //this.AccountList = response.ResponseData.dtAccounts;
                this.PlanTypeFlag = true;
              }
              else {
                this.PlanTypeFlag = false;

                this.showMetricsTable = true;
              }
              var YearTextArr = this.masterData.dtPeriodDet.filter((period: any) => period.PeriodID == this.selectedPeriod.toString());
              var YearText = YearTextArr[0].TargetUploadYearText;

              //this.SalesIncentiveText = 'Sales Incentive Plan ' + YearText;
              //this.SalesIncentiveURL = 'assets/images/SPMProcessDoc' + YearText + '.pdf';

              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanText != '') && (this.tblMetrics[0].IncentivePlanURL != '') && (this.tblMetrics[0].IncentivePlanURL != null)) {
                this.SalesIncentiveText = this.tblMetrics[0].IncentivePlanText + YearText;
                this.SalesIncentiveURL = this.tblMetrics[0].IncentivePlanURL + YearText + '.pdf';
              }
              //this.getOtherParametersData();
              //this.getKickerMasterData();
              //this.getGrowthKickerMasterData();
              this.PeriodType = this.tblMetrics[0].PeriodType;
              this.KickerApplicable = this.tblMetrics[0].KickerApplicable;
              this.QualitativeApplicable = this.tblMetrics[0].QualitativeApplicable;
              this.FeedbackApplicable = this.tblMetrics[0].FeedbackApplicable;
              if (this.QualitativeApplicable) {
                this.getOtherParametersData();
              }
              if (this.KickerApplicable) {
                this.getKickerMasterData();
                this.getGrowthKickerMasterData();
              }


              if (this.FeedbackApplicable) {
                this.GetFeedbackDetails();
              }

              this.SPIAmount = this.tblIncentive[0].SPIAmount;
              this.TotalPayable = this.tblIncentive[0].PayoutAmount;
              this.Proratedvalue = this.tblIncentive[0].ProRatedValue;
              this.NetAmount = this.tblIncentive[0].NetAmount;
              this.PaidAmount = this.tblIncentive[0].PaidAmount;
              this.TotalVCP = this.appService.AddPercentageSign(this.tblIncentive[0].TotalVCP);
              this.FinalPayout = this.appService.AddPercentageSign(this.tblIncentive[0].FinalPayout);
              this.CompanyPerFormance = this.appService.AddPercentageSign(this.tblIncentive[0].CompanyPerformance);
              this.NewJoinee = this.tblMetrics[0].NewJoinee;
              this.GrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].GrowthKickerPercentage);
              this.FinalGrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].FinalGrowthKickerPercentage);
              this.MosaicValue = this.tblIncentive[0].MosaicValue;
              this.AchievementincludingMBO = this.appService.AddPercentageSign(this.tblIncentive[0].AchievementincludingMBO);
              this.MosaicStatus = this.tblIncentive[0].MosaicStatus;
              this.MSAValue = this.tblIncentive[0].MSAValue;
              this.MSAStatus = this.tblIncentive[0].MSAStatus;
              this.LDValue = this.tblIncentive[0].LDValue;
              this.LDStatus = this.tblIncentive[0].LDStatus;
              this.TotalAmountPayable = this.tblIncentive[0].TotalAmountPayable;
              this.PortfolioGrowth = this.tblIncentive[0].PortfolioGrowth;
              this.TotalAmountPaid = this.tblIncentive[0].TotalAmountPaid;
              this.RemainingPayable = this.tblIncentive[0].RemainingPayable;
              this.SPIName = this.tblIncentive[0].SPIName;
              this.MosaicKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MosaicKickerValue);
              this.MSAKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MSAKickerValue);
              this.LDKickerValue = this.tblIncentive[0].LDKickerValue;
              this.GrowthApplicable = this.tblIncentive[0].GrowthApplicable;
              this.UpdatedAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].UpdatedAchievement);
              this.ApprovedComissionAmount = this.tblIncentive[0].ApprovedCommisionAmount;
              //if (this.tblDiscretionary.length > 0 && (this.tblMetrics[0].StatusID == 14 || this.tblMetrics[0].StatusID == 7) && this.tblMetrics[0].PeriodType == 'Yearly' && this.selectedPeriod > 7) {
              //  this.showDiscretionarytable = true;
              //}
              if (this.tblDiscretionary.length > 0) {
                this.DiscretionaryTotalvalue = this.tblDiscretionary[0].TotalvalueDisplay;
                this.DiscretionaryProrated = this.tblDiscretionary[0].ProratedValueDisplay;
                this.DiscretionaryPayout = this.tblDiscretionary[0].PayoutAmountDisplay;
                this.TotalExecutiveWeightage = this.tblDiscretionary[0].TotalExecutiveWeightage;
                this.DbPaid = this.tblDiscretionary[0].DbPaid;
                this.RemainingDbPayable = this.tblDiscretionary[0].RemainingDbPayable;
                this.FinalPayoutDB = this.tblDiscretionary[0].FinalPayoutDB;
              }
/*              this.openH1computationModal(0);*/

              if (this.NewJoinee == 1) {
                this.NewJoineeMsg = "New Joinee (Joined post " + this.tblMetrics[0].DOJCheckDate + ")";
                //this.RevenueAchievement = this.appService.GetRevenueAchievement(this.tblMetrics, parseFloat(this.CompanyPerFormance));
                this.RevenueAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].RevenueAcievement);
                this.TotalWeightedAverage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentage);
                //this.WeightedPercentage = this.appService.GetWeightedAverage(parseFloat(this.RevenueAchievement.replace('%', '')), parseFloat(this.TotalWeightedAverage.replace('%', '')));
              }
              else {
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
              }
              this.VCPStatusId = this.tblMetrics[0].StatusID;
              if (this.tblMetrics[0].StatusID == 7) {
                this.isSubmissionAllowed = true;
              } else {
                this.isSubmissionAllowed = false;
              }
            }
          }
        }
          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
    }
    else {
      this._router.navigate(['no-access']);
    }
  }
  
  onBackClick() {

    this.appService.SetRoleID(this.roleSelected);
    this.appService.SetperiodId(this.selectedPeriod);

    this._router.navigate([this.appService.GetNavbackPathForAssessmentDetails()]);
  }

  onViewAction(SalesMetricID, PSNo, PeriodID) {

    var ActualDataList = [];
    for (var o in this.tblMetrics) {
      //if (this.tblMetrics[o].SalesMetricID != 2) {
        var metric = {
          'MetricName': this.tblMetrics[o].SalesMetricName,
          'MetricId': this.tblMetrics[o].SalesMetricID,
          'Accounts': []
        }
        ActualDataList.push(metric);
      //}
    }

    var data = {
      'ActualDataList': ActualDataList,
      'SalesMetricID': SalesMetricID,
      'PSNo': PSNo,
      'PeriodID': PeriodID,
      'Path': 'incentive-details',
      'Role': this.roleSelected
    };
    this.appService.SetActualData(data);
    this._router.navigate(['actual-details']);
  }

  Publish(): void {
   var list = [];
    var emp = {
      'StatusId': 7,
      'PSNo': this.PSNO,
      'Period': this.selectedPeriod
    };
    list.push(emp);
    if (list.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select atleast one record"
      });
    }
    else {
      this.appService.SetLoadingShow(true);
      var data = {
        "Period": this.selectedPeriod.toString(),
        'UserData': list,
          'ChangedBy': this.userInfo.PS_No,
          'AppType': this.userInfo.AppType
      };
      this.appService.GetDataFromAPI(this.configs.PublishIncentiveURL(), data)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: "Calculated incentive details are published to the employees. The details will be visible to the employee(s) in the self assessment page."
            });
            this.isSubmissionAllowed = false;
            this.ngOnInit();
          }

        });
    }
  }
  PublishAppraisal(): void {
    var list = [];
    var emp = {
      'StatusId': 7,
      'PSNo': this.PSNO,
      'Period': this.selectedPeriod
    };
    list.push(emp);
    if (list.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select atleast one record"
      });
    }
    else {
      this.appService.SetLoadingShow(true);
      var data = {
        "Period": this.selectedPeriod.toString(),
        'UserData': list,
          'ChangedBy': this.userInfo.PS_No,
          'AppType': this.userInfo.AppType
      };
      this.appService.GetDataFromAPI(this.configs.PublishAppraisalURL(), data)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: "Appraisal details are published to the sales executives. The details will be visible to the sales executive(s) in the self assessment page."
            });
            this.isSubmissionAllowedOtherParams = false;
            this.ngOnInit();
          }

        });
    }
  }
  openhistoryModal() {
    var data = {
      "ApprovalId": this.tblMetrics[0].ApprovalID,
      "ActionType": 0
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.DisAgreedBy != undefined) {
            this.history.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closehistoryModal() {
    this.history.closeModal();
  }
  getOtherParametersData(): void {
    this.DbNAFlag = false;
    this.isSubmissionAllowedOtherParams = false;
    this.PeriodType = 'Half-Yearly';
    this.showOtherMetricsTable = false;
    this.showStratatable = true;
    this.DBStatus = 0;
    this.isDBSubmissionAllowed = false;
    var data = {
      'PSNO': this.PSNO,
      'RoleId': 1,// not used 
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetOtherParametersDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          var StatusId = "0";
          this.DBStatus = 0;
          var DBApproval = response.ResponseData.dtDBApproval;
          if (DBApproval.length > 0) {
            this.DBStatus = DBApproval[0].StatusID;
          }

          if (this.selectedPeriod >= 7) {
            if (this.IsDBApplicable == 1) {
              
              if (this.DBStatus == 7 || this.DBStatus == 14) {
                if (this.userInfo.AppType == 1) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage','SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
                }
                else {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'ManagerComments',  'ManagerWeightage', 'ReviewerComments',  'ReviewerWeightage'];
                }
                this.showDiscretionarytable = true;
                if (this.DBStatus == 7 ) {
                  this.isDBSubmissionAllowed = true;
                }
              }
              else {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage','SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage'];
              }
            }
            else {
              this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
            }
          }
          else {
            this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
          }

          this.OtherParamArr = response.ResponseData.dtAssessmentData;
          if (this.OtherParamArr.length > 0) {
            this.AppraisalWeightageApplicable = this.OtherParamArr[0].AppraisalWeightageApplicable;
            this.PeriodType = this.OtherParamArr[0].PeriodType;
              this.showOtherMetricsTable = true;
            
          }
          if (this.OtherParamArr.length > 0) {
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].Value == 0 && this.OtherParamArr[o].isTotal == 0 && this.selectedPeriod >= 7) {
                this.DbNAFlag = true;
              }
            }
          }
          this.OtherParameterRatingMaster = response.ResponseData.dtOtherParameterRatingMaster;
          this.StrataTable = response.ResponseData.StrataRangeMaster;
          this.FinalReviewerAssessment = response.ResponseData.FinalReviewerAssessment;
          var percentagemaster = response.ResponseData.dtOtherParameterWeightageMaster;
          for (var o in percentagemaster) {
            {
              this.QuantitativePercentage = percentagemaster[o].QuantitativeWeightage + '%';
              this.QualitativePercentage = percentagemaster[o].QualitativeWeightage + '%';
            }
          }
          var OtherParameterSMA = response.ResponseData.dtOtherParameterSalesMetricApproval;
          if (OtherParameterSMA.length > 0) {
            StatusId = OtherParameterSMA[0].StatusID;

            this.ManagerContinueDoing = OtherParameterSMA[0].ThingsManagerContinueDoing;
            this.ManagerStartDoing = OtherParameterSMA[0].ThingsManagerStartDoing;
            this.ManagerStopDoing = OtherParameterSMA[0].ThingsManagerStopDoing;
          }
          if (this.OtherParamArr.length > 0) {
            this.TotalWeightage = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalWeightage = this.TotalWeightage + this.OtherParamArr[o].Value;
              }
            }
            this.TotalManagerDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalManagerDBRating = this.TotalManagerDBRating + this.OtherParamArr[o].ManagerWeightage;
              }
            }
            this.TotalReviewerDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalReviewerDBRating = this.TotalReviewerDBRating + this.OtherParamArr[o].ReviewerWeightage;
              }
            }
            this.TotalExecutiveDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalExecutiveDBRating = this.TotalExecutiveDBRating + this.OtherParamArr[o].ExecutiveWeightage;
              }
            }
            this.TotalSelfRating = 0;
            this.TotalManagerRating = 0;
            this.TotalReviewerRating = 0;
            if (this.AppraisalWeightageApplicable == 1) {
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalSelfRating = this.TotalSelfRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalManagerRating = this.TotalManagerRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalReviewerRating = this.TotalReviewerRating / 100;
            }
            else {
              var count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment);
                  count = count + 1;
                }
              }
              this.TotalSelfRating = parseFloat((this.TotalSelfRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment);
                  count = count + 1;
                }
              }
              this.TotalManagerRating = parseFloat((this.TotalManagerRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment);
                  count = count + 1;
                }
              }
              this.TotalReviewerRating = parseFloat((this.TotalReviewerRating / count).toFixed(2));
            }
          }
          if (this.tblMetrics.length == 0) {
            this.showOtherMetricsTable = false;
          }

          if (parseInt(StatusId) == 6) {
            this.isSubmissionAllowedOtherParams = false;
            // this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment'];
          }
          //if (parseInt(StatusId) === 14) {

          //  this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
          //}
          if ((this.VCPStatusId === 14 || this.VCPStatusId === 7) && parseInt(StatusId) === 7 ) {
            this.isSubmissionAllowedOtherParams = true;
          }
          if (parseInt(StatusId) === 14) {
            this.isSubmissionAllowedOtherParams = false;
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
    }

    getKickerMasterData(): void {
        this.showKickerTable = false;
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "Type": "0"
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetKickerApprovalDataURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.KickerdisplayedColumns = ['KickerName', 'SPIPercentage', 'AmountPayable', 'Status', 'Comments' , 'ApproverComments', 'Action'];
                    this.KickerData = response.ResponseData.dtKickerData;
                  //  for (var o in this.KickerData) {
                  //    if (this.KickerData[o].StatusId != null) {
                  //      this.KickerDataTemp.push(this.KickerData[o]);
                  //    }
                  //  }
                  //if (this.KickerDataTemp.length > 0) {
                  //  this.showKickerTable = true;
                  //}
                  //else {
                  //  this.showKickerTable = false;
                  //}
                  for (var o in this.KickerData) {
                    if (this.KickerData[o].StatusId == 1 || this.KickerData[o].StatusId == 2 || this.KickerData[o].StatusId == 3) {
                      this.showKickerTable = true;
                      break;
                    }
                    else {
                      this.showKickerTable = false;
                    }
                  }
                    if (this.tblMetrics.length == 0) {
                        this.showOtherMetricsTable = false;
                    }
                }
            });
    }
    getGrowthKickerMasterData(): void {
        this.showKickerTable = false;
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "Type": "4"
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetKickerDataURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                  this.GrowthKickerdisplayedColumns = ['KickerName', 'IsApplicable', 'Status', 'GrowthComments', 'ApproverGrowthComments', 'Action'];
                    this.GrowthKickerData = response.ResponseData.dtKickerData;
                    for (var o in this.GrowthKickerData) {
                        this.GrowthKickerValue = this.GrowthKickerData[o].GrowthKickerValue;
                        this.GrowthKickerStatus = this.GrowthKickerData[o].StatusId;
                        this.GrowthKickerStatusName = this.GrowthKickerData[o].Status;
                        this.GrowthComments = this.GrowthKickerData[o].GrowthComments;
                      this.ApproverGrowthComments = this.GrowthKickerData[o].ApproverGrowthComments;
                      if (this.GrowthKickerStatus != "1" && this.GrowthKickerStatus != "2") {
                        this.GrowthKickerData[o].Select = 1;
                      }
                    }
                    //this.showKickerTable = true;
                    if (this.tblMetrics.length == 0) {
                        this.showOtherMetricsTable = false;
                  }
                }
            });
    }

    SubmitKickerApprovalValues(o, value) {
        if (this.KickerData[o].ApproverComments == undefined || this.KickerData[o].ApproverComments == null) {
            this.KickerData[o].ApproverComments = "";
        }
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "KickerId": this.KickerData[o].KickerId,
            "StatusId": value,
            "ApproverComments": this.KickerData[o].ApproverComments,
            "LoggedInPSNo": this.userInfo.PS_No
        }
        this.appService.SetLoadingShow(true);
        var url = this.configs.InsertKickerApprovalDataURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                  this.ngOnInit();
                    //this.getKickerMasterData();
                   // this.getGrowthKickerMasterData();
                }
            }

                ,
                error => {
                    this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
                });
    }
    SubmitGrowthKickerApprovalValues(value) {
        
        if (this.ApproverGrowthComments == undefined || this.ApproverGrowthComments == null) {
            this.ApproverGrowthComments = "";
        }
        if (value == 2) {
            var data = {
                "PSNo": this.PSNO,
                "PeriodId": this.selectedPeriod,
                "AppType": this.userInfo.AppType,
                "StatusId": value,
                "ApproverComments": this.ApproverGrowthComments,
                "LoggedInPSNo": this.userInfo.PS_No,
                "KickerValue": '0'
            }
        }
        else {
            var data = {
                "PSNo": this.PSNO,
                "PeriodId": this.selectedPeriod,
                "AppType": this.userInfo.AppType,
                "StatusId": value,
                "ApproverComments": this.ApproverGrowthComments,
                "LoggedInPSNo": this.userInfo.PS_No,
                "KickerValue": '1'
            }
      }
        this.appService.SetLoadingShow(true);
        var url = this.configs.InsertGrowthKickerApprovalDataURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });

                  this.ngOnInit();
                    //this.getKickerMasterData();
                    //this.getGrowthKickerMasterData();
                }
            }

                ,
                error => {
                    this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
                });
    }
    GetFeedbackDetails(): void {
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "LoggedInPSNo": this.userInfo.PS_No
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetFeedbackDetailsURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.FeedbackdisplayedColumns = ['FeedbackName', 'CNBFeedback', 'SalesHeadFeedback'];
                    this.FeedbackDetails = response.ResponseData.dtFeedbackDetails;
                    this.IsFeedbackDetails = true;
                    if (this.FeedbackDetails.length == 0) {
                        this.IsFeedbackDetails = false;
                    }
                }
            });
  }

  GetParameterwiseData(salesMetricId) {
    var data = {
      psNo: this.appService.GetPsno(),
      PeriodId: this.selectedPeriod,
      AppType: this.userInfo.AppType,
      SalesMetricsID: salesMetricId
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetParameterwiseURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.parameterwiseDatarr = response.ResponseData;
          if (this.parameterwiseDatarr.length > 0) {
            this.parameterwiseData.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  closeparameterwiseDataModal() {
    this.parameterwiseData.closeModal();
  }
  // PublishDB(): void {
  //   var list = [];
  //   var emp = {
  //     'StatusId': 7,
  //     'PSNo': this.PSNO,
  //     'Period': this.selectedPeriod
  //   };
  //   list.push(emp);
  //   if (list.length == 0) {
  //     this.dialog.open(DialogDataExampleDialog, {
  //       data: "Please select atleast one record"
  //     });
  //   }
  //   else {
  //     this.appService.SetLoadingShow(true);
  //     var data = {
  //       "Period": this.selectedPeriod.toString(),
  //       'UserData': list,
  //       'ChangedBy': this.userInfo.PS_No,
  //       'AppType': this.userInfo.AppType
  //     };
  //     this.appService.GetDataFromAPI(this.configs.PublishDBURL(), data)
  //       .then(response => {
  //         if (response.ResponseCode == this.configs.RetCodeFailure()) {
  //           this.appService.SetLoadingShow(false);
  //           this.dialog.open(DialogDataExampleDialog, {
  //             data: response.ResponseData
  //           });
  //         }
  //         else {
  //           this.appService.SetLoadingShow(false);
  //           this.dialog.open(DialogDataExampleDialog, {
  //             data: "Calculated DB details are published to the sales executives. The details will be visible to the sales executive(s) in the self assessment page."
  //           });
  //           this.isSubmissionAllowed = false;
  //           this.ngOnInit();
  //         }

  //       });
  //   }
  // }

  //openH1computationModal(type: any) {
  //  var data = {
  //    "PS_No": parseInt(this.PSNO),
  //    "PeriodId": '11',
  //    'Role': 'Employee',
  //    'UserType': '',
  //    "AppType": this.userInfo.AppType
  //  };
  //  this.appService.SetLoadingShow(true);
  //  var url = this.configs.GetH1MetricsDetailsURL();
  //  this.appService.GetDataFromAPI(url, data)
  //    .then(response => {
  //      this.appService.SetLoadingShow(false);
  //      if (response.ResponseCode == this.configs.RetCodeFailure()) {
  //        this.dialog.open(DialogDataExampleDialog, {
  //          data: response.ResponseData
  //        });
  //      }
  //      else {
  //        this.H1tblMetrics = response.ResponseData.dtMertricsDet;
  //        this.H1tblIncentive = response.ResponseData.dtIncentiveDet;
  //        this.H1WeightedPercentage = this.appService.AddPercentageSign(this.H1tblIncentive[0].WeightedPercentage);
  //        this.H1UpdatedAchievement = this.appService.AddPercentageSign(this.H1tblIncentive[0].UpdatedAchievement);
  //        this.H1overallachievement = this.appService.AddPercentageSign(((this.tblIncentive[0].WeightedPercentage) + (this.H1tblIncentive[0].UpdatedAchievement)) / 2);

  //        if (type == 1) {
  //          if (this.H1tblMetrics.length > 0) {
  //            this.h1computation.openModal();
  //          }
  //          else {
  //            this.dialog.open(DialogDataExampleDialog, {
  //              data: "No data available"
  //            });
  //          }
  //        }

  //      }
  //    }
  //      ,
  //      error => {
  //        this.errorMessage = <string>error;
  //      });

  //}
}
