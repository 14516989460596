import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';

import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'loginloading',
  templateUrl: './loginloading.component.html',
  styleUrls: ['./loginloading.component.less']
})
export class LoginLoadingComponent {

  
  constructor(private appService: AppService, private _router: Router) {
    this.appService.SetLoadingShow(false);
 
    //this.appService.ClearVariables();
  }
  ngOnInit(): void {
    var userInfo = this.appService.GetLoginUserInfo();
    if (userInfo != null && userInfo.isValidUser != null) {
      if (userInfo.isValidUser) {
        this._router.navigate(['home']);
      } 
    }
  }
  //
 

}
