import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
  selector: 'app-spm-report',
  templateUrl: './spm-report.component.html',
  styleUrls: ['./spm-report.component.less']
})
export class SPMREPORTComponent implements OnInit {
  UserPsno: any;
  userInfo: any;
  masterData: any;
  FilteredSalesMetricMaster: any;
  dtScoreCard_Content: any = [];
  dtScoreCard_Header: any = [];
  newPeriodIdArray: any = [];
  newParametervalueArray: any = [];
  SBUArray: any = [];
  selectedstrata: any = '';
  dtRole: any = [];
  RoleFlag: any = '';
  ShowOptionTypes: any = [];
  ShowOptionForStrata: any = [];
  ShowOptionTypeSelected: number;
  DownloadFilePath: string = '../../../UploadTemplates/ScoreCard.xlsx';
  ShowSection: number;
  ShowFilters: boolean = false;
  dtScoreCard_ContentOrg: any = [];
  SearchText: any = '';
  PeriodId: any = '';
  ManagerPSNOForScoreCard: any = '';
  DirectOrAllReporteesForScoreCard: any = '';
  ShowOptionForReporteeFilter: any = [];
  dtManagersOrg: any = [];
  dtManagers: any = [];
  dtSBU: any = [];
  SearchTextManager: any = '';
  StrataOptionVisible: boolean = true;
  PeriodList: any = [];
  PeriodIdArray: any = [];

  SBUAValuerray: any = [];
  ParametervalueArray: any = [];
  dropdownListPeriod: any = [];
  dropdownListParameter: any = [];
  dropdownListSBU: any = [];
  dropdownSettings: any = {};
  DownloadFilePathBaseForDetailedReport: any;
  DownloadFilePathForDetailedReport: any;
  DownloadFilePathBaseFeedback: any;
  DownloadFilePathFeedback: any;
  FyPeriodIds: any = [];
  DownloadFilePathBaseAppraisal: any;
  DownloadFilePathAppraisal: any;
  DownloadFilePathBaseDiscretionaryBonus: any;
  DownloadFilePathDiscretionaryBonus: any;
  DownloadFilePathBaseDB: any;
  DownloadFilePathBaseStrata: any;
  DownloadFilePathBasePerformance: any;
  DownloadFilePathBaseMultiYearPerformance: any;
  DownloadFilePathBaseAccountMappingsFromTargets: any;
  DownloadFilePathBaseCompDetailed: any;
  DownloadFilePathBasePayoutH1: any;
  DownloadFilePathBaseTargetVSPayout; any;
  DownloadFilePathBaseStatusReport: any;
  DownloadFilePathBaseTargetStatusReport: any;
  DownloadFilePathBaseHyperscalerRevenueReport: any;
  DownloadFilePathBasePayoutFY: any;
  LoggedInUserRoleText: any;

  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.masterData = {};
    this.FilteredSalesMetricMaster = [];
    this.UserPsno = '';


    this.ShowSection = 1;
    this.ShowOptionTypeSelected = 1;

    this.ShowOptionTypes = [];
    this.ShowOptionForStrata = [{ 'StrataID': 1, 'StrataValue': 'Yes' }, { 'StrataID': 2, 'StrataValue': 'No' }]
    this.ShowOptionForReporteeFilter = [{ 'OptionId': '1', 'OptionValue': 'Direct' }, { 'OptionId': '2', 'OptionValue': 'Entire Hierarchy' }]
    this.DownloadFilePathBaseForDetailedReport = '../../../UploadTemplates/DetailedReport_';
    this.DownloadFilePathForDetailedReport = '';
    this.DownloadFilePathBaseFeedback = '../../../UploadTemplates/FeedbackReport_';
    this.DownloadFilePathFeedback = '';
    this.DownloadFilePathBaseAppraisal = '../../../UploadTemplates/AppraisalReport_';
    this.DownloadFilePathAppraisal = '';
    this.DownloadFilePathBaseDiscretionaryBonus = '../../../UploadTemplates/DiscretionaryBonusReport_';
    this.DownloadFilePathDiscretionaryBonus = '';
    this.DownloadFilePathBaseStatusReport = '../../../UploadTemplates/StatusReport_';
    this.DownloadFilePathBaseTargetStatusReport = '../../../UploadTemplates/TargetStatusReport_';

    this.DownloadFilePathBaseDB = '../../../UploadTemplates/DBReport_';
    this.DownloadFilePathBaseStrata = '../../../UploadTemplates/StrataReport_';
    this.DownloadFilePathBasePerformance = '../../../UploadTemplates/PerformanceReport_';
    this.DownloadFilePathBaseMultiYearPerformance = '../../../UploadTemplates/MultiYearPerformanceReport_';
    this.DownloadFilePathBaseAccountMappingsFromTargets = '../../../UploadTemplates/AccountMappingsFromTargetsReport_';
    this.DownloadFilePathBaseCompDetailed = '../../../UploadTemplates/CompReportDetailed_';
    this.DownloadFilePathBasePayoutH1 = '../../../UploadTemplates/PayoutReportH1_';
    this.DownloadFilePathBasePayoutFY = '../../../UploadTemplates/PayoutReportFY_';
    this.DownloadFilePathBaseTargetVSPayout = '../../../UploadTemplates/TargetVSPayout_';
    this.DownloadFilePathBaseHyperscalerRevenueReport = '../../../UploadTemplates/HyperscalerRevenueReport_';
    this.LoggedInUserRoleText = '';
  }

  ngOnInit() {
    this.appService.SetLoadingShow(false);
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {

        this.UserPsno = this.userInfo.PS_No;
        this.masterData = this.appService.GetMasterDetails();
        for (var o in this.masterData.dtPeriodDet) {
          this.dropdownListPeriod.push({ "id": this.masterData.dtPeriodDet[o].PeriodID, "itemName": this.masterData.dtPeriodDet[o].Name });
        }
        this.LoadUploadsForUser();
        for (var i in this.masterData.dtMetricsMaster) {
          if (this.masterData.dtMetricsMaster[i].AppType == this.userInfo.AppType) {
            this.FilteredSalesMetricMaster.push(this.masterData.dtMetricsMaster[i]);
          }
        }
        for (var o in this.FilteredSalesMetricMaster) {
          this.dropdownListParameter.push({ "id": this.FilteredSalesMetricMaster[o].SalesMetricsID, "itemName": this.FilteredSalesMetricMaster[o].MetricName });
        }

        this.GetHalfYearsListForFeedback();
        this.ShowSection = 0;
        this.ShowOptionTypeSelected = 0;
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: false,
          badgeShowLimit: 1,
          classes: ""
        };
      }
      else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }


  getMasterTable(): void {
    var data = {
      'PeriodId': this.PeriodIdArray.join(),
      'SalesMetricsId': this.ParametervalueArray.join(),
      'AppType': this.userInfo.AppType,
      'Showstrata': this.selectedstrata,
      'PSNo': this.UserPsno,
      'DirectOrAll': this.DirectOrAllReporteesForScoreCard,
      'ManagerPSNO': this.ManagerPSNOForScoreCard,
      'SBU': this.SBUAValuerray.join()
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetScoreCardDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dtScoreCard_Content = response.ResponseData.dtScoreCard_Content;
          this.dtScoreCard_ContentOrg = response.ResponseData.dtScoreCard_Content;
          this.dtScoreCard_Header = response.ResponseData.dtScoreCard_Header;
          if (this.dtScoreCard_Content.length > 0) {
            this.ShowFilters = true;
          }
          else {

            this.ShowFilters = false;
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available. "
            });
          }
        }
      }
        ,
        error => {

        });
  }

  ShowOptiontype(value: number) {
    this.FyPeriodIds = [];
    this.PeriodId = '';
    this.ShowOptionTypeSelected = value;
    if (value == 2) {
      this.DirectOrAllReporteesForScoreCard = '1';
      this.StrataOptionVisible = false;
      this.GetFiltersForScoreCard();
    }
    if (value == 4 || value == 6 || value == 7 || value == 8 || value == 9 || value == 10 || value == 11 || value == 12 || value == 13 || value == 14 ) {
      this.FyPeriodIds = [];
      for (var o in this.masterData.dtPeriodDet) {
        if (this.masterData.dtPeriodDet[o].PeriodType == "Yearly") {
          this.FyPeriodIds.push({ "id": this.masterData.dtPeriodDet[o].PeriodID, "itemName": this.masterData.dtPeriodDet[o].Name });

        }

      }

    }
    if (value == 5) {
      this.FyPeriodIds = [];
      for (var o in this.masterData.dtPeriodDet) {
        if (this.masterData.dtPeriodDet[o].PeriodType == "Yearly") {
          this.FyPeriodIds.push({ "Pid": this.masterData.dtPeriodDet[o].PeriodID, "item": this.masterData.dtPeriodDet[o].Name });

        }

      }

    }

    if (value == 15 || value == 17) {
      this.FyPeriodIds = [];
      for (var o in this.masterData.dtPeriodDet) {
        if (this.masterData.dtPeriodDet[o].PeriodType == "Yearly" || this.masterData.dtPeriodDet[o].PeriodType == "Half-Yearly") {
          this.FyPeriodIds.push({ "Pid": this.masterData.dtPeriodDet[o].PeriodID, "item": this.masterData.dtPeriodDet[o].Name });

        }

      }

    }
    // this.getMasterTable();
  }


  onclickReset() {
    //this.dtScoreCard_Content = [];
    //this.dtScoreCard_ContentOrg = [];
    //this.newParametervalueArray = [];
    //this.newPeriodIdArray = [];
    //this.selectedstrata = '';
    //this.ShowFilters = false;
    this.SearchTextManager = '';
    this.DirectOrAllReporteesForScoreCard = '1';
    this.SBUArray = [];
    this.SearchText = '';
    this.ManagerPSNOForScoreCard = '';
    this.dtManagers = this.dtManagersOrg;
  }


  LoadDataReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }

    var TargetScreenRoles = this.appService.GetUserRoleForTargetApproval(this.userInfo.SPMRoles);
    if (TargetScreenRoles.CNBRoleId > 0) {
      this.LoggedInUserRoleText = 'CNB';
    }
    else {
      this.LoggedInUserRoleText = 'NONCNB';
    }

    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      'DataFor': this.LoggedInUserRoleText,
      'PeriodName': PeriodName,
      'RoleFlag': this.RoleFlag
    };

    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDetailedReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          var ReportNumber = response.ResponseData.ReportNumber;
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + PeriodName + '_version_' + ReportNumber + ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);

        }
      });
  }

  LoadUploadsForUser() {
    var data = {
      "PSNo": this.userInfo.PS_No,
      'AppType': this.userInfo.AppType
    };

    this.appService.GetDataFromAPI(this.configs.GetReportsByUserURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.ShowOptionTypes = response.ResponseData.dtReport;
          var stsr = { "ReportId": 16, "ReportName": 'Status Report' }; this.ShowOptionTypes.push(stsr);
          var stsr = { "ReportId": 17, "ReportName": 'Target Status Report' }; this.ShowOptionTypes.push(stsr);
          this.dtRole = response.ResponseData.dtRoles;
          for (var j in this.dtRole) {
            if (this.dtRole[j].RoleId.toString().includes('12')) {
              this.RoleFlag = '1'
              break;
            }
            else {
              this.RoleFlag = '0'
            }
          }

          //this.ChangeView(2);
        }
      });
  }
  SearchFor(value): any {
    var data = JSON.parse(JSON.stringify(this.dtScoreCard_ContentOrg));
    if (value != '' && value != undefined) {
      if (value.length > 2) {
        const filterValue = value.toLowerCase();
        this.SearchText = value;
        for (var o in data) {
          if (data[o].Name == null)
            data[o].Name = "";
        }
        data = data.filter(option => option.Name.toLowerCase().includes(filterValue));
      }
    }
    this.dtScoreCard_Content = data;
  }

  ValidateScoreCardParameters() {
    if (this.newParametervalueArray.length == 0 || this.newPeriodIdArray.length == 0 || this.selectedstrata == '') {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select mandatory parameters to load scorecard data"
      });
    }
    else {
      if ((this.ManagerPSNOForScoreCard != '0' && this.ManagerPSNOForScoreCard != '') && (this.DirectOrAllReporteesForScoreCard == '' || this.DirectOrAllReporteesForScoreCard == '0')) {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Please select Reportee Filter value"
        });
      }


      this.getMasterTable();
    }

  }
  GetFiltersForScoreCard() {
    var data = {
      'AppType': this.userInfo.AppType,
      'PS_No': this.userInfo.PS_No
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetFiltersForScoreCardURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dtManagersOrg = response.ResponseData.Table;
          this.dtManagers = response.ResponseData.Table;
          this.dtSBU = response.ResponseData.Table1;
          for (var o in this.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.dtSBU[o].LOB });
          }
          for (var o in response.ResponseData.Table2) {
            if (response.ResponseData.Table2[o]['StrataAccess'] == 1) {
              this.StrataOptionVisible = true;
            }
            else {
              this.StrataOptionVisible = false;
              this.selectedstrata = 2;
            }
            break;
          }
          //this.ChangeView(2);
        }
      });
  }
  SearchForManager(value): any {
    const filterValue = value.toLowerCase();
    this.dtManagers = JSON.parse(JSON.stringify(this.dtManagersOrg));
    this.dtManagers = this.dtManagers.filter(option => option.Name.toLowerCase().includes(filterValue));
  }
  SelectManagerForScoreCard(name) {
    var Name = name.option.value;
    for (var o in this.dtManagers) {
      if (this.dtManagers[o].Name == Name) {
        this.ManagerPSNOForScoreCard = this.dtManagers[o].PSNo;
        break;
      }
    }
  }

  GetHalfYearsListForFeedback(): void {
    this.appService.GetDataFromAPI(this.configs.GetH1PeriodIdsURL(), null)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.PeriodList = response.ResponseData.dtPeriods;

          if (this.PeriodId == null || this.PeriodId == undefined) {
            this.PeriodId = '';
          }

          //this.ChangeView(2);
        }
      });
  }
  LoadFeedbackDataReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetFeedbackReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathFeedback = this.DownloadFilePathBaseFeedback + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathFeedback);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }


  onChangeOFPeriod(newPeriodIdArray) {
    this.PeriodIdArray = [];
    for (var o in newPeriodIdArray) {
      this.PeriodIdArray.push(newPeriodIdArray[o].id);
    }
  }
  onChangeOFParameter(newParametervalueArray) {
    this.ParametervalueArray = [];
    for (var o in newParametervalueArray) {
      this.ParametervalueArray.push(newParametervalueArray[o].id);
    }
  }
  onSBUChange(SBUArray) {
    this.SBUAValuerray = [];
    for (var o in SBUArray) {
      this.SBUAValuerray.push(SBUArray[o].itemName);
    }
  }
  LoadAppraisalDataReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.FyPeriodIds) {
      if (this.FyPeriodIds[o].id == PeriodId)
        PeriodName = this.FyPeriodIds[o].itemName;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName,
      "DataFor": this.userInfo.PS_No
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetAppraisalReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathAppraisal = this.DownloadFilePathBaseAppraisal + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathAppraisal);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }
  LoadDiscretionaryBonusReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.FyPeriodIds) {
      if (this.FyPeriodIds[o].Pid == PeriodId)
        PeriodName = this.FyPeriodIds[o].item;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName,
      "DataFor": this.userInfo.PS_No
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDiscretionaryBonusReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathDiscretionaryBonus = this.DownloadFilePathBaseDiscretionaryBonus + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathDiscretionaryBonus);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }
  LoadDBReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDBReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathBaseDB = '../../../UploadTemplates/DBReport_';
          this.DownloadFilePathBaseDB = this.DownloadFilePathBaseDB + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBaseDB);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }
  LoadStratareport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetStrataReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathBaseStrata = '../../../UploadTemplates/StrataReport_';

          this.DownloadFilePathBaseStrata = this.DownloadFilePathBaseStrata + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBaseStrata);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }
  LoadPerformanceReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetPerformanceReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathBasePerformance = '../../../UploadTemplates/PerformanceReport_';

          this.DownloadFilePathBasePerformance = this.DownloadFilePathBasePerformance + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBasePerformance);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }
  LoadMultiYearPerformanceReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetMultiYearPerformanceReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathBaseMultiYearPerformance = '../../../UploadTemplates/MultiYearPerformanceReport_';

          this.DownloadFilePathBaseMultiYearPerformance = this.DownloadFilePathBaseMultiYearPerformance + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBaseMultiYearPerformance);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }
  LoadAccountMappingsFromTargetsReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetAccountMappingsFromTargetsReportDetailsURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathBaseAccountMappingsFromTargets = '../../../UploadTemplates/AccountMappingsFromTargetsReport_';

          this.DownloadFilePathBaseAccountMappingsFromTargets = this.DownloadFilePathBaseAccountMappingsFromTargets + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBaseAccountMappingsFromTargets);
          //window.open(this.configs.DetailedFeedBackReportExcelFileURl());
        }
      });
  }

  LoadCompReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetCompReportDetailedURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          this.DownloadFilePathBaseCompDetailed = '../../../UploadTemplates/CompReportDetailed_';

          this.DownloadFilePathBaseCompDetailed = this.DownloadFilePathBaseCompDetailed + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBaseCompDetailed);
        }
      });
  }

  LoadPayoutH1(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetPayoutH1ReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          this.DownloadFilePathBasePayoutH1 = '../../../UploadTemplates/PayoutReportH1_';

          this.DownloadFilePathBasePayoutH1 = this.DownloadFilePathBasePayoutH1 + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBasePayoutH1);
        }
      });
  }

  LoadPayoutFY(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetPayoutFYReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          this.DownloadFilePathBasePayoutFY = '../../../UploadTemplates/PayoutReportFY_';

          this.DownloadFilePathBasePayoutFY = this.DownloadFilePathBasePayoutFY + PeriodName + ".xlsx";

          window.open(this.DownloadFilePathBasePayoutFY);
        }
      });
  }

  LoadHyperscalerRevenueReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      'PeriodName': PeriodName,
    };
    console.log(data);

    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetHyperscalerRevenueReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.DownloadFilePathBaseHyperscalerRevenueReport = '../../../UploadTemplates/HyperscalerRevenueReport_';
          this.DownloadFilePathBaseHyperscalerRevenueReport = this.DownloadFilePathBaseHyperscalerRevenueReport + PeriodName + ".xlsx";
          window.open(this.DownloadFilePathBaseHyperscalerRevenueReport);

        }
      });
  }

  LoadTargetVSPayout(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetTargetVSPayoutReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          this.DownloadFilePathBaseTargetVSPayout = '../../../UploadTemplates/TargetVSPayout_';

          this.DownloadFilePathBaseTargetVSPayout = this.DownloadFilePathBaseTargetVSPayout + PeriodName + ".xlsx";

          window.open(this.DownloadFilePathBaseTargetVSPayout);
        }
      });

  }
  NewStatusReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetStatusReport(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          this.DownloadFilePathBaseStatusReport = '../../../UploadTemplates/StatusReport_';

          this.DownloadFilePathBaseStatusReport = this.DownloadFilePathBaseStatusReport + PeriodName + ".xlsx";

          window.open(this.DownloadFilePathBaseStatusReport);
        }
      });


  }
  TargetStatusReport(PeriodId): void {
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == PeriodId)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": PeriodId,
      "AppType": this.userInfo.AppType,
      "PeriodName": PeriodName
     
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetTargetStatusReport(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          this.DownloadFilePathBaseTargetStatusReport = '../../../UploadTemplates/TargetStatusReport_';

          this.DownloadFilePathBaseTargetStatusReport = this.DownloadFilePathBaseTargetStatusReport + PeriodName + ".xlsx";

          window.open(this.DownloadFilePathBaseTargetStatusReport);
        }
      });


  }
  }



  



 

   


