import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MzModalComponent } from 'ngx-materialize';
@Component({
  selector: 'app-reject-assessment',
  templateUrl: './reject-assessment.component.html',
  styleUrls: ['./reject-assessment.component.less']
})
export class RejectAssessmentComponent implements OnInit {
  @ViewChild('bottomSheetModal') bottomSheetModal: MzModalComponent
  public modalOptions: Materialize.ModalOptions = {
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: .5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    startingTop: '100%', // Starting top style attribute
    endingTop: '10%', // Ending top style attribute
  };
  userInfo: any;
  DisagreementRemarks: string;
    tblRecords: any;
    tblRecordsCopy: any;
  //showRecordsTable: boolean = false;
  selectedApprovalId: any;
    UserRemarks: string;

    PeriodYear: any;
    Roles: any;
    masterData: any;
    roleSelected: any;
    selectedPeriod: any;

  displayedColumns: string[] = [
    'Name',
    'Manager',
    'SBU',
    'Plan',
    'Period',
    'Reason',
    'DisagreedBy',
  ];
  RoleID: any;
    DisagreementData: any;
  Type: number = 1;
  dropdownListRole: any = [];
  dropdownListPeriod: any = [];
  dropdownSettings: any = {};
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.DisagreementRemarks = '';
      this.tblRecords = [];
      this.tblRecordsCopy = [];
    this.selectedApprovalId = '';
    this.UserRemarks = '';
      this.RoleID = 0;
      this.PeriodYear = [];
      this.Roles = [];
      this.roleSelected = [];
      this.selectedPeriod = [];
      
    //this.route.params.subscribe(params =>
    //  this.RoleID = params.Flag);
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      badgeShowLimit: 1,
      classes: ""
    };
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {

        this.masterData = this.appService.GetMasterDetails();
        this.PeriodYear = this.masterData.dtPeriodDet;
        this.dropdownListPeriod = [];
        for (var o in this.PeriodYear) {
          this.dropdownListPeriod.push({ "id": this.PeriodYear[o].PeriodID, "itemName": this.PeriodYear[o].Name });
        }
        this.selectedPeriod = JSON.parse(JSON.stringify(this.dropdownListPeriod[1]));
        this.masterData.dtRolesMaster.sort((a, b) => a.RoleName.localeCompare(b.RoleName));
        this.Roles = this.masterData.dtRolesMaster.filter((item: any) => item.AppType == this.userInfo.AppType);

        this.dropdownListRole = [];
        for (var o in this.Roles) {
          this.dropdownListRole.push({ "id": this.Roles[o].RoleId, "itemName": this.Roles[o].RoleName });
        }
        this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
        this.onReset();
        this.GetPendingRecords(this.Type);
      }
      else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
  GetPendingRecords(Type : number): void {
      this.RoleID = 0;// ALL Records 
      this.Type = Type;
      this.onReset();
      var UserData = {
          "Role_ID": this.RoleID,
          "PS_No": this.userInfo.PS_No,
          "AppType": this.userInfo.AppType,
          "Type": this.Type,
      }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetActualDisAgreeDataURL();
    this.appService.GetDataFromAPI(url, UserData)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
            this.tblRecords = response.ResponseData.dtApprovals;
            this.tblRecordsCopy = response.ResponseData.dtApprovals;
            this.onReset();
            if (response.ResponseData.dtRoles.length > 0) {
            if (response.ResponseData.dtRoles.length > 1) {
              this.appService.SetCorporateUserType("0");
            }
            else {
              this.appService.SetCorporateUserType(response.ResponseData.dtRoles[0].RoleId.toString());
            }
          }
          else {
            this.appService.SetCorporateUserType("0");
          }
          if (this.tblRecords.length == 0) {
            // this.showRecordsTable = true;
            //}
            //else {
            // this.showRecordsTable = false;
            if (this.appService.GetFromHistory() != 'Y') {

              this.dialog.open(DialogDataExampleDialog, {
                data: "No record found."
              });
              return;
            }
          }
        }
      }
        ,
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  RevertActualsSubmit() {
    if (this.DisagreementRemarks.trim() == '') {
      this.bottomSheetModal.closeModal();
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please enter Comments"
      });
      return;
    }
    this.bottomSheetModal.closeModal();

    var PendingWith = 0;

    var Data = {
      "PS_No": this.userInfo.PS_No,
      "ApprovalId": this.selectedApprovalId,
      "Role": 'Approver',
      "Remarks": this.DisagreementRemarks,
      "ReasonSelected": '',
      "PendingWith": PendingWith,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.SubmitDisagreementDataURL();
    this.appService.GetDataFromAPI(url, Data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
            this.GetPendingRecords(1);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.DisagreementRemarks = '';

        }
      },
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  DisagreeWithActualsPopup(id, Remarks) {
    this.selectedApprovalId = id;
    this.UserRemarks = Remarks;
    this.openhistoryModal(id);
  }
  openhistoryModal(id: any) {
    var data = {
      "ApprovalId": id,
      "ActionType": 0
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.length > 0) {
            this.bottomSheetModal.openModal();
          }
        }
      },
        error => {
          // this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
    }
    onViewAction(value: any, type: number): void {

    if (value != undefined && value != 0) {
        this.appService.SetApprovalID(value);
        this.appService.SetApprovalType(type);
      this._router.navigate(['history'])

      }
    }

  onFilterChange(selectedPeriod, roleSelected) {
    var data = [];
    if (selectedPeriod.length < this.dropdownListPeriod.length) {
      for (var o in selectedPeriod) {
        if (selectedPeriod[o].itemName != '' || selectedPeriod[o].itemName != undefined || selectedPeriod[o].itemName != "SELECT") {
          for (var i in this.tblRecordsCopy) {
            if (this.tblRecordsCopy[i].Period == selectedPeriod[o].itemName) {
              data.push(this.tblRecordsCopy[i]);
            }
          }
        }
      }
    }
    else {
     // data = [];
      data = this.tblRecordsCopy;
    }
    var dataRole = [];
    if (roleSelected.length < this.dropdownListRole.length) {
      for (var o in roleSelected) {
        if (roleSelected[o].itemName != '' || roleSelected[o].itemName != undefined || roleSelected[o].itemName != "SELECT") {
          for (var i in data) {
            if (data[i].Plan == roleSelected[o].itemName) {
              dataRole.push(data[i]);
            }
          }
        }
      }
    }
    else {
       dataRole = data;
      //dataRole = [];
    }
    this.tblRecords = dataRole;
    //if (this.tblRecords.length == 0) {
    //  this.dialog.open(DialogDataExampleDialog, {
    //    data: "No record found."
    //  });
    //  return;
    //}
  }

  onReset(): void {
    this.selectedPeriod = [];
    this.selectedPeriod = JSON.parse(JSON.stringify(this.dropdownListPeriod));
    this.roleSelected = [];
    this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
    this.tblRecords = this.tblRecordsCopy;
  }

}
