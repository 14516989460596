import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MzModalComponent } from 'ngx-materialize';
import { forEach } from '@angular/router/src/utils/collection';
import { parse } from 'url';

@Component({
  selector: 'app-assessment-details',
  templateUrl: './assessment-details.component.html',
  styleUrls: ['./assessment-details.component.less']
})
export class AssessmentDetailsComponent implements OnInit {
  @ViewChild('history') history: MzModalComponent
  @ViewChild('targethistory') targetHistory: MzModalComponent
  @ViewChild('parameterwiseData') parameterwiseData: MzModalComponent
  @ViewChild('parameterwiseThresoldData') parameterwiseThresoldData: MzModalComponent
/*  @ViewChild('h1computation') h1computation: MzModalComponent*/
  displayedColumns: string[] = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue'];
  selectedPeriod: number;
  roleSelected: string;  
  userInfo: any;
  masterData: any;
  errorMessage: string;
  tblMetrics: any;
  showMetricsTable: boolean = false;
  isSubmissionAllowed: boolean = false;
  dueDate: string = "";
  warningdata: string;
  action: string;
  DisagreementData: any;
  MetricsDisagreementData: any
  DisagreementMessage: string;
  IsDBApplicable: number;
  IsSPIApplicable: number;
  IsAppraisalApplicable: number;
  PSNO: string;
  isManagerOrReviewer: boolean;
  Proratedvalue: string = '';
  TotalVCP: string = '';
  TotalPayable: string = '';
  AccountList: any;
  UserData: any;
  loadUser: boolean = false;
  PeriodName: string = '';
  CompanyPerFormance: string = '';
  WeightedPercentage: string = '';
  TotalWeightedAverage: string = '';
  RevenueAchievement: string = '';
  NewJoineeMsg: string = '';
  NewJoinee: number = 0;
  SPIAmount: string = '';
  targetHistoryArr: any = [];
  NavbackPath: string = 'dashboard';
  OtherParamArr: any = [];
  ManagerStartDoing: string = '';
  ManagerContinueDoing: string = '';
  ManagerStopDoing: string = '';
  showOtherMetricsTable: boolean = false;
  OtherParameterRatingMaster: any = [];
  PeriodType: string = 'Half-Yearly';
  OtherdisplayedColumns: string[] = ['ParameterName', 'DBWeightage', 'Target', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
  KickerdisplayedColumns: string[];
  GrowthKickerdisplayedColumns: string[];
  StrataTable: any = [];
  QuantitativePercentage: string = '';
  QualitativePercentage: string = '';
  showStratatable: boolean = false;
  NetAmount: string = '';
  PaidAmount: string = '';
  AppraisalStatus : number = 0;
  SalesIncentiveText: string = '';
  SalesIncentiveURL: string = '';
  AppType: string = '';
  KickerData: any = [];
  IncentiveData: any = [];
  GrowthKickerData: any = [];
  GrowthKickerValue: string = '';
  GrowthKickerStatus: string = '';
  GrowthComments: string = '';
  ApproverGrowthComments: string = '';
  EmpPlans: string = '';
  EmpRoleId: string = '';
  Plan: string = '';
  KickerApplicable: boolean = false;
  QualitativeApplicable: boolean = false;
  FeedbackDetails: any = [];
  FeedbackApplicable: boolean = false;
  IsFeedbackDetails: boolean = false;
  FeedbackdisplayedColumns: string[];
  GrowthKickerPercentage: string = '';
  FinalGrowthKickerPercentage: string = '';
  AchievementincludingMBO: string = '';
  MosaicValue: string = '';
  MosaicStatus: string = '';
  MSAValue: string = '';
  MSAStatus: string = '';
  LDValue: string = '';
  LDStatus: string = '';
  TotalAmountPayable: string = '';
  TotalExecutiveWeightage: string = '';
  DiscretionaryTotalvalue: string = '';
  DiscretionaryProrated: string = '';
  DiscretionaryPayout: string = '';
  DbPaid: string = '';
  RemainingDbPayable: string = '';
  FinalPayoutDB: string = '';
  tblDiscretionary: any;
  tblDBApproval: any ;
  H1tblMetrics: any;
  H1tblIncentive: any;
  H1WeightedPercentage: string = '';
  H1UpdatedAchievement: string = '';
  show: boolean = false;
  DisPeriodArray: any = [];
  showButton: boolean = false;
  PortfolioGrowth: string = '';
  TotalAmountPaid: string = '';
  RemainingPayable: string = '';
  showKickertable: boolean = false;
  showKickerSubmit: boolean = false;
  SPIName: string = '';
  MosaicKickerValue: string = '';
  MSAKickerValue: string = '';
  LDKickerValue: string = '';
  tblIncentive: any;
  FinalPayout: string = '';
  GrowthApplicable: number = 0;
  UpdatedAchievement: string = '';
  SalesIncentiveURLByCountry: string = '';
  SalesIncentiveTextByCountry: string = '';
  TotalManagerDBRating: number = 0;
  TotalReviewerDBRating: number = 0;
  TotalExecutiveDBRating: number = 0;
  PlanType: string = '';
  PlanTypeFlag: boolean = false;
  TotalWeightage: number = 0;
  TotalSelfRating: number = 0;
  TotalManagerRating: number = 0;
  TotalReviewerRating: number = 0;
  FinalReviewerAssessment: number = 0;
  AppraisalWeightageApplicable: number = 0;
  DbNAFlag: boolean = false;
  DBStatus: number = 0;
  showDiscretionarytable: boolean = false;
  H1overallachievement: any;
  parameterwiseDatarr: any = [];
  parameterwiseThresoldDatarr: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblMetrics = [];
    this.tblDBApproval = [];
    this.DisagreementData = {};
    this.MetricsDisagreementData = [];
    this.DisagreementMessage = '';
    this.IsDBApplicable = 0;
    this.IsSPIApplicable = 0;
    this.IsAppraisalApplicable = 0;
    this.PSNO = '';
    this.isManagerOrReviewer = false;
    this.AccountList = [];
    this.loadUser = false;
    this.UserData = {};
    this.targetHistoryArr = [];
    this.OtherParamArr = [];
    this.KickerData = [];
    this.IncentiveData = [];
    this.GrowthKickerData = [];
    this.FeedbackDetails = [];
    this.tblIncentive = [];
    this.PlanType = "0";
    this.AppraisalWeightageApplicable = 0;
    this.H1tblIncentive = [];
    this.parameterwiseDatarr = [];
    this.parameterwiseThresoldDatarr = [] 
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    this.masterData = this.appService.GetMasterDetails();
    if (this.userInfo.AccessType[0].Showperiodyear == 'isFullyearly') {
      for (var o in this.masterData.dtPeriodDet) {
        if ((this.masterData.dtPeriodDet[o].PeriodID) % 2 == 0) {
          this.DisPeriodArray.push(this.masterData.dtPeriodDet[o]);
        }
      }
      this.masterData.dtPeriodDet = this.DisPeriodArray;
    }
    this.selectedPeriod = this.appService.GetperiodId();
    for (var o in this.masterData.dtPeriodDet) {
      if (this.selectedPeriod == this.masterData.dtPeriodDet[o].PeriodID) {
        this.PeriodName = this.masterData.dtPeriodDet[o].Name;
        break;
      }
    }
    this.isManagerOrReviewer = this.appService.GetIsManagerOrReviewer();
    this.PSNO = this.appService.GetPsno();
    this.roleSelected = "2";
    var ActualDataObj = {};
    ActualDataObj = this.appService.GetActualData();
    if (ActualDataObj == undefined) {
      if (this.selectedPeriod == null || this.selectedPeriod == undefined || this.selectedPeriod == 0)
        this.selectedPeriod = this.appService.AutoPeriodId();
    }
    else {
      this.selectedPeriod = ActualDataObj['PeriodID'];
      this.appService.ResetSetActualData();
    }
    if (this.userInfo != null && this.PeriodName != '') {
      this.GetEmployeeData();
    }
      else {
        this._router.navigate(['no-access']);
      }
     
  }

  onPeriodChange(id: number, Bu: string): void {
    this.PlanTypeFlag = false;
    this.TotalManagerDBRating = 0;
    this.TotalReviewerDBRating = 0;
    this.TotalExecutiveDBRating = 0;
    this.TotalWeightage = 0;
    this.TotalSelfRating = 0;
    this.TotalManagerRating = 0;
    this.TotalReviewerRating = 0;
    this.SalesIncentiveURLByCountry = '';
    this.showDiscretionarytable = false;
    var dueArray = this.UserData.dtSBUPeriodDet.filter((due: any) => due.PeriodID == id && due.SBU == Bu && due.PSNo == this.PSNO);
    if (dueArray.length > 0 && (dueArray[0].EmpDeadline != undefined || dueArray[0].EmpDeadline != null)) {
      this.dueDate = dueArray[0].EmpDeadline.replace("T00:00:00", "");
    }
    else if (dueArray.length == 0 || dueArray[0].EmpDeadline == undefined || dueArray[0].EmpDeadline == null) {
      this.dueDate = '';
    }
    if (id.toString() != '') {
      var UserData = {
        "PS_No": this.PSNO ,
        "PeriodId": id.toString(),
        'Role': 'Employee',
          'UserType': 'ADMIN',
          'AppType': this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.MetricsDetailsURL();
      this.appService.GetDataFromAPI(url, UserData)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            var YearTextArr = this.masterData.dtPeriodDet.filter((period: any) => period.PeriodID == id.toString());
            var YearText = YearTextArr[0].TargetUploadYearText;

            //this.SalesIncentiveText = 'Sales Incentive Plan ' + YearText;
            //this.SalesIncentiveURL = 'assets/images/SPMProcessDoc' + YearText + '.pdf';
            this.selectedPeriod = id;
           
            this.tblMetrics = response.ResponseData.dtMertricsDet;
            this.tblIncentive = response.ResponseData.dtIncentiveDet;          
            this.tblDiscretionary = response.ResponseData.dtDiscretionaryDet;
            //.this.tblDBApproval = response.ResponseData.dtDBApprovalDet;
            this.isSubmissionAllowed = response.ResponseData.isSubmissionAllowed;
            this.DisagreementMessage = response.ResponseData.DisagreementStatus;
            this.IsDBApplicable = response.ResponseData.IsDBApplicable;
            this.AccountList = response.ResponseData.dtAccounts;
            this.EmpPlans = response.ResponseData.EmpPlans;
            this.EmpRoleId = response.ResponseData.EmpRoleId;
            this.masterData.EmpPlans = response.ResponseData.EmpPlans;
            this.IsSPIApplicable = response.ResponseData.IsSPIApplicable;
            this.IsAppraisalApplicable = response.ResponseData.IsAppraisalApplicable;
            if (this.tblMetrics.length > 0) {
              this.PlanType = this.tblMetrics[0].PlanType;
              this.Plan = this.tblMetrics[0].ROLE;
              if (this.PlanType == "1") {
                //this.EmpPlans = response.ResponseData.EmpPlans;
                //this.AccountList = response.ResponseData.dtAccounts;
                this.PlanTypeFlag = true;
              }
              else {
                this.PlanTypeFlag = false;

                this.showMetricsTable = true;
              }
              this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue'];
              if (this.tblMetrics[0].StatusID == 5) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments'];
              }
              else if (this.tblMetrics[0].StatusID == 6) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments', 'ManagerComments'];
              }
              else if (this.tblMetrics[0].StatusID == 7 || this.tblMetrics[0].StatusID == 15) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
              }
              else if (this.tblMetrics[0] != undefined && this.tblMetrics[0].StatusID == 14) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'AchievementWeighted', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanText != '') && (this.tblMetrics[0].IncentivePlanURL != '') && (this.tblMetrics[0].IncentivePlanURL != null)) {
                this.SalesIncentiveText = this.tblMetrics[0].IncentivePlanText + YearText;
                this.SalesIncentiveURL = this.tblMetrics[0].IncentivePlanURL + YearText + '.pdf';
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanTextByCountry != '') && (this.tblMetrics[0].IncentivePlanURLByCountry != '' && this.tblMetrics[0].IncentivePlanURLByCountry != null)) {
                this.SalesIncentiveTextByCountry = this.tblMetrics[0].IncentivePlanTextByCountry + YearText; 
                this.SalesIncentiveURLByCountry = this.tblMetrics[0].IncentivePlanURLByCountry + YearText + '.pdf';
              }
                this.PeriodType = this.tblMetrics[0].PeriodType;
                this.KickerApplicable = this.tblMetrics[0].KickerApplicable;
                this.QualitativeApplicable = this.tblMetrics[0].QualitativeApplicable;
                this.FeedbackApplicable = this.tblMetrics[0].FeedbackApplicable;              
              if (this.QualitativeApplicable) {
                this.getOtherParametersData();
              }
              
                    if (this.KickerApplicable) {
                        this.getKickerMasterData();
                        this.getGrowthKickerMasterData();
                    }
                
                    if (this.FeedbackApplicable) {
                        this.GetFeedbackDetails();
                    }
                

              this.SPIAmount = this.tblIncentive[0].SPIAmount;
              this.TotalPayable = this.tblIncentive[0].PayoutAmount;
              this.Proratedvalue = this.tblIncentive[0].ProRatedValue;
              this.NetAmount = this.tblIncentive[0].NetAmount;
              this.PaidAmount = this.tblIncentive[0].PaidAmount;
              this.TotalVCP = this.appService.AddPercentageSign(this.tblIncentive[0].TotalVCP);
              this.CompanyPerFormance = this.appService.AddPercentageSign(this.tblIncentive[0].CompanyPerformance);
              this.NewJoinee = this.tblMetrics[0].NewJoinee;
              this.AppType = this.tblMetrics[0].AppType;
              this.GrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].GrowthKickerPercentage);
              this.FinalGrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].FinalGrowthKickerPercentage);
              this.MosaicValue = this.tblIncentive[0].MosaicValue;
              this.AchievementincludingMBO = this.appService.AddPercentageSign(this.tblIncentive[0].AchievementincludingMBO);
              this.MosaicStatus = this.tblIncentive[0].MosaicStatus;
              this.MSAValue = this.tblIncentive[0].MSAValue;
              this.MSAStatus = this.tblIncentive[0].MSAStatus;
              this.LDValue = this.tblIncentive[0].LDValue;
              this.LDStatus = this.tblIncentive[0].LDStatus;
              this.TotalAmountPayable = this.tblIncentive[0].TotalAmountPayable;
              this.PortfolioGrowth = this.tblIncentive[0].PortfolioGrowth;
              this.TotalAmountPaid = this.tblIncentive[0].TotalAmountPaid;
              this.RemainingPayable = this.tblIncentive[0].RemainingPayable;
              this.SPIName = this.tblIncentive[0].SPIName;
              this.MosaicKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MosaicKickerValue);
              this.MSAKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MSAKickerValue);
              this.LDKickerValue =  this.tblIncentive[0].LDKickerValue;
              this.FinalPayout = this.appService.AddPercentageSign(this.tblIncentive[0].FinalPayout);
              this.GrowthApplicable = this.tblIncentive[0].GrowthApplicable;
              this.UpdatedAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].UpdatedAchievement);

              if (this.NewJoinee == 1) {
                this.NewJoineeMsg = "New Joinee (Joined post " + this.tblMetrics[0].DOJCheckDate + ")";
                //this.RevenueAchievement = this.appService.GetRevenueAchievement(this.tblMetrics, parseFloat(this.CompanyPerFormance));
                this.RevenueAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].RevenueAcievement);
                this.TotalWeightedAverage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentage);
                //this.WeightedPercentage = this.appService.GetWeightedAverage(parseFloat(this.RevenueAchievement.replace('%', '')), parseFloat(this.TotalWeightedAverage.replace('%', '')));
              }
              else {
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
              }
              if (this.tblDiscretionary.length > 0) {
                this.DiscretionaryTotalvalue = this.tblDiscretionary[0].TotalvalueDisplay;
                this.DiscretionaryProrated = this.tblDiscretionary[0].ProratedValueDisplay;
                this.DiscretionaryPayout = this.tblDiscretionary[0].PayoutAmountDisplay;
                this.TotalExecutiveWeightage = this.tblDiscretionary[0].TotalExecutiveWeightage;
                this.DbPaid = this.tblDiscretionary[0].DbPaid;
                this.RemainingDbPayable = this.tblDiscretionary[0].RemainingDbPayable;
                this.FinalPayoutDB = this.tblDiscretionary[0].FinalPayoutDB;
              }
/*                this.openH1computationModal(0);*/
              
            }
            else {
              this.showMetricsTable = false;
              this.dialog.open(DialogDataExampleDialog, {
                data: "No record found."
              });
              return;
            }
          }
        }
          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
    }
  }

  onBackClick() {
    this.NavbackPath = this.appService.GetNavbackPathForAssessmentDetails();
    if (this.NavbackPath.length == 0) {
      this.NavbackPath = 'dashboard';
    }
    this._router.navigate([this.NavbackPath]);
  }
 

    GetEmployeeData() {
    //this.showOtherMetricsTable = false;
    this.IsFeedbackDetails = false;
    var data = {
      "psNo": this.PSNO,
      "PeriodId": this.selectedPeriod,
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetEmployeeDetailsForDashBoardURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.UserData = response.ResponseData;
          this.loadUser = true;
          this.onPeriodChange(this.selectedPeriod, this.UserData.dtUserDet[0].DeputedBU);
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }



  openhistoryModal() {
    var data = {
      "ApprovalId": this.tblMetrics[0].ApprovalID,
      "ActionType": 0
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.DisAgreedBy != undefined) {
            this.history.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }
           
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
   
  }
  closehistoryModal() {
    this.history.closeModal();
  }
  onViewAction(SalesMetricID, PSNo, PeriodID) {

    var ActualDataList = [];
    for (var o in this.tblMetrics) {
      //if (this.tblMetrics[o].SalesMetricID != 2) {
        var metric = {
          'MetricName': this.tblMetrics[o].SalesMetricName,
          'MetricId': this.tblMetrics[o].SalesMetricID,
          'Accounts': []
        }
        ActualDataList.push(metric);
      //}
    }

    var data = {
      'ActualDataList': ActualDataList,
      'SalesMetricID': SalesMetricID,
      'PSNo': PSNo,
      'PeriodID': PeriodID,
      'Path': 'assessment-details',
      'Plan': this.Plan

    };
    this.appService.SetActualData(data);
    this._router.navigate(['actual-details']);
  }
  GettargetHistory() {
    var data = {
      psNo: this.PSNO,
        PeriodId: this.selectedPeriod,
        AppType: this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetHistoryURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.targetHistoryArr = response.ResponseData;
          if (this.targetHistoryArr.length>0) {
            this.targetHistory.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closeTargethistoryModal() {
    this.targetHistory.closeModal();
  }
  DownLoadTargets(): void {

    this.DownLoadTargetsApi(this.PSNO, this.selectedPeriod, 'Employee', 'Released', 'Final', this.AppType);
  }
  DownLoadTargetsApi(Psno, FYPeriodId, Role, TargetStatus, TargetLetterType, AppType): void {

    var data = {
      "PSNO": Psno,
      "PeriodId": FYPeriodId,
      "Role": Role,
      "TargetStatus": TargetStatus,
      "TargetLetterType": TargetLetterType,
      "AppType": AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetLetterURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.appService.DownloadTargetletter(response.ResponseData);
        }
      });


  }
  getOtherParametersData(): void {
    this.DbNAFlag = false;
    this.AppraisalStatus = 0;
    //this.PeriodType = 'Half-Yearly';
    this.showStratatable = false;
    this.showOtherMetricsTable = false;
    this.DBStatus = 0;
    var data = {
      'PSNO': this.PSNO,
      'RoleId': 1,
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetOtherParametersDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.AppraisalStatus = 0;
          if (this.selectedPeriod >= 7) {
            if (this.IsDBApplicable == 1) {
              this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment'];
            }
            else {
              this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment'];
            }
          }
          else {
            this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment'];
          }
          this.OtherParamArr = response.ResponseData.dtAssessmentData;
          if (this.OtherParamArr.length > 0) {
            this.AppraisalWeightageApplicable = this.OtherParamArr[0].AppraisalWeightageApplicable;
            //this.PeriodType = this.OtherParamArr[0].PeriodType;
              this.showOtherMetricsTable = true;
              
            
          }
          if (this.OtherParamArr.length > 0) {
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].Value == 0 && this.OtherParamArr[o].isTotal == 0 && this.selectedPeriod >= 7) {
                this.DbNAFlag = true;
              }
            }
          }
          this.OtherParameterRatingMaster = response.ResponseData.dtOtherParameterRatingMaster;
          var OtherParameterSMA = response.ResponseData.dtOtherParameterSalesMetricApproval;
          this.StrataTable = response.ResponseData.StrataRangeMaster;
          this.FinalReviewerAssessment = response.ResponseData.FinalReviewerAssessment;
          var percentagemaster = response.ResponseData.dtOtherParameterWeightageMaster;
          for (var o in percentagemaster) {
           {
              this.QuantitativePercentage = percentagemaster[o].QuantitativeWeightage  + '%';
              this.QualitativePercentage = percentagemaster[o].QualitativeWeightage  + '%';
            }
          }
          if (this.OtherParamArr.length > 0) {
            this.TotalWeightage = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalWeightage = this.TotalWeightage + this.OtherParamArr[o].Value;
              }
            }
            this.TotalManagerDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalManagerDBRating = this.TotalManagerDBRating + this.OtherParamArr[o].ManagerWeightage;
              }
            }
            this.TotalReviewerDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalReviewerDBRating = this.TotalReviewerDBRating + this.OtherParamArr[o].ReviewerWeightage;
              }
            }
            this.TotalExecutiveDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalExecutiveDBRating = this.TotalExecutiveDBRating + this.OtherParamArr[o].ExecutiveWeightage;
              }
            }
            this.TotalSelfRating = 0;
            this.TotalManagerRating = 0;
            this.TotalReviewerRating = 0;
            if (this.AppraisalWeightageApplicable == 1) {
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalSelfRating = this.TotalSelfRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalManagerRating = this.TotalManagerRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalReviewerRating = this.TotalReviewerRating / 100;
            }
            else {
              var count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment);
                  count = count + 1;
                }
              }
              this.TotalSelfRating = parseFloat((this.TotalSelfRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment);
                  count = count + 1;
                }
              }
              this.TotalManagerRating = parseFloat((this.TotalManagerRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment);
                  count = count + 1;
                }
              }
              this.TotalReviewerRating = parseFloat((this.TotalReviewerRating / count).toFixed(2));
            }
          }
          if (OtherParameterSMA.length > 0) {
            this.AppraisalStatus  = OtherParameterSMA[0].StatusID;

            this.ManagerContinueDoing = OtherParameterSMA[0].ThingsManagerContinueDoing;
            this.ManagerStartDoing = OtherParameterSMA[0].ThingsManagerStartDoing;
            this.ManagerStopDoing = OtherParameterSMA[0].ThingsManagerStopDoing;
          }
          if (this.tblMetrics.length == 0) {
            this.showOtherMetricsTable = false;
          }
          this.DBStatus = 0;
          var DBApproval = response.ResponseData.dtDBApproval;
          if (DBApproval.length > 0) {
            this.DBStatus = DBApproval[0].StatusID;
            if (this.DBStatus === 14 || this.DBStatus === 7) {
              this.showDiscretionarytable = true;
            }
          }
          if (this.selectedPeriod >= 7) {
            if (this.IsDBApplicable == 1) {
              if (this.userInfo.AppType == 1) {
                if (this.AppraisalStatus == 6) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage'];
                }
                if ((this.AppraisalStatus == 7 || this.AppraisalStatus == 14) && (this.DBStatus != 7 && this.DBStatus != 14)) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage'];
                  if (this.AppraisalStatus == 14 && this.IsAppraisalApplicable == 1) {
                    this.showStratatable = true;
                  }
                }
                if ((this.AppraisalStatus == 7 || this.AppraisalStatus == 14) && (this.DBStatus == 7 || this.DBStatus == 14) && this.userInfo.AppType == 1) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
                  if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.IsAppraisalApplicable == 1) {
                    this.showStratatable = true;
                  }
                }
                if ((this.AppraisalStatus == 7 || this.AppraisalStatus == 14) && (this.DBStatus == 7 || this.DBStatus == 14) && this.userInfo.AppType == 2) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage'];
                  if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.IsAppraisalApplicable == 1) {
                    this.showStratatable = true;
                  }
                }
              }
              else {
                if (this.AppraisalStatus == 6) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage','SelfComments', 'ManagerComments', 'ManagerWeightage'];
                }
                if ((this.AppraisalStatus == 7 || this.AppraisalStatus == 14) && (this.DBStatus != 7 && this.DBStatus != 14)) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'ManagerComments', 'ManagerWeightage', 'ReviewerComments', 'ReviewerWeightage'];
                  if (this.AppraisalStatus == 14 && this.IsAppraisalApplicable == 1 ) {
                    this.showStratatable = true;
                  }
                }
                if ((this.AppraisalStatus == 7 || this.AppraisalStatus == 14) && (this.DBStatus == 7 || this.DBStatus == 14) &&  this.userInfo.AppType == 1) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments','ManagerComments','ManagerWeightage', 'ReviewerComments','ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
                  if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.IsAppraisalApplicable == 1) {
                    this.showStratatable = true;
                  }
                }
                if ((this.AppraisalStatus == 7 || this.AppraisalStatus == 14) && (this.DBStatus == 7 || this.DBStatus == 14) &&  this.userInfo.AppType == 2) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'ManagerComments', 'ManagerWeightage', 'ReviewerComments', 'ReviewerWeightage'];
                  if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.IsAppraisalApplicable == 1) {
                    this.showStratatable = true;
                  }
                }
              }
            }
            else {
              if (this.AppraisalStatus == 6) {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment'];
              }
              if (this.AppraisalStatus == 15 || this.AppraisalStatus == 7 || this.AppraisalStatus  == 14) {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
                if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.IsAppraisalApplicable == 1) {
                  this.showStratatable = true;
                }
              }
            }

          }
          else {
            if (this.AppraisalStatus == 6) {
              this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment'];
            }
            if (this.AppraisalStatus == 15 || this.AppraisalStatus == 7 || this.AppraisalStatus == 14) {
              this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
              if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.IsAppraisalApplicable == 1) {
                this.showStratatable = true;
              }
            }
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
    }

    getKickerMasterData(): void {
        this.showKickertable = false;
        this.showKickerSubmit = false;
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "Type": "0"
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetKickerDataURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.KickerdisplayedColumns = ['Select', 'KickerName', 'SPIPercentage', 'AmountPayable', 'Status', 'Comments', 'ApproverComments'];
                    this.KickerData = response.ResponseData.dtKickerData;
                    this.IncentiveData = response.ResponseData.dtIncentiveData;
                    this.showKickertable = true;
                    if (this.tblMetrics.length == 0) {
                      this.showKickertable = false;
                  }
                  for (var o in this.KickerData) {
                    if (this.KickerData[o].StatusId != 1 && this.KickerData[o].StatusId != 2 ) {
                      this.showKickerSubmit = true;
                    }
                  }
                }
            });
    }
    getGrowthKickerMasterData(): void {
        this.showKickertable = false;
        //this.showStratatable = true;
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "Type" : "4"
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetKickerDataURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                  this.GrowthKickerdisplayedColumns = ['KickerName', 'IsApplicable', 'Status', 'GrowthComments', 'ApproverGrowthComments'];
                    this.GrowthKickerData = response.ResponseData.dtKickerData;
                    for (var o in this.GrowthKickerData) {
                        this.GrowthKickerValue = this.GrowthKickerData[o].GrowthKickerValue;
                        this.GrowthKickerStatus = this.GrowthKickerData[o].StatusId;
                        this.GrowthComments = this.GrowthKickerData[o].GrowthComments;
                      this.ApproverGrowthComments = this.GrowthKickerData[o].ApproverGrowthComments;
                      if (this.GrowthKickerStatus != "1" && this.GrowthKickerStatus != "2") {
                        this.GrowthKickerData[o].Select = 1;
                      }
                    }
                    this.showKickertable = true;
                    if (this.tblMetrics.length == 0) {
                      this.showKickertable = false;
                  }
                }
            });
    }

    SubmitKickerValues() {
        var dtKickerDataTableFiltered = [];
        var msg = [];
        for (var o in this.KickerData) {
            if (this.KickerData[o].Select) {
                dtKickerDataTableFiltered.push({
                    'KickerId': this.KickerData[o].KickerId,
                    'KickerValue': this.KickerData[o].KickerValue,
                    'AmountPayable': this.KickerData[o].AmountPayable,
                    'Comments': this.KickerData[o].Comments
                });
            }
        };
        for (var o in this.KickerData) {
            if ((this.KickerData[o].KickerId == 1 || this.KickerData[o].KickerId == 2 ) && (this.KickerData[o].KickerValue < 0 || this.KickerData[o].KickerValue > 10)) {
                msg.push("Please enter a value between 0 and 10");
            }
            if (this.KickerData[o].Select) {
                if (this.KickerData[o].KickerValue == "" || this.KickerData[o].KickerValue == undefined || this.KickerData[o].KickerValue == null) {
                    msg.push("Please enter a value for Percentage of SPI");
                }
            }
        }
       
        if (dtKickerDataTableFiltered.length == 0) {
            msg.push("Please select a kicker for submission");
        }
        if (msg.length > 0) {
            this.dialog.open(DialogDataExampleDialog, {
                data: msg
            });
        }

        else {

            var data = {
                "PSNo": this.PSNO,
                "PeriodId": this.selectedPeriod,
                "AppType": this.userInfo.AppType,
                "dtKickerDataTable": dtKickerDataTableFiltered,
                "LoggedInPSNo": this.userInfo.PS_No
            }
            this.appService.SetLoadingShow(true);
            var url = this.configs.InsertKickerDataURL();
            this.appService.GetDataFromAPI(url, data)
                .then(response => {
                    this.appService.SetLoadingShow(false);
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                      this.onPeriodChange(this.selectedPeriod, this.UserData.dtUserDet[0].DeputedBU);
                       // this.getKickerMasterData();
                       // this.getGrowthKickerMasterData();
                    }
                }

                    ,
                    error => {
                        this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
                    });
        }
    }
    SubmitGrowthKickerValues() {
        if (this.GrowthComments == undefined || this.GrowthComments == null) {
            this.GrowthComments = "";
        }
         var data = {
             "PSNo": this.PSNO,
             "PeriodId": this.selectedPeriod,
             "AppType": this.userInfo.AppType,
             "KickerValue": '0',
             "GrowthComments": this.GrowthComments,
             "LoggedInPSNo": this.userInfo.PS_No
      }
         this.appService.SetLoadingShow(true);
            var url = this.configs.InsertGrowthKickerDataURL();
            this.appService.GetDataFromAPI(url, data)
                .then(response => {
                    this.appService.SetLoadingShow(false);
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                      this.onPeriodChange(this.selectedPeriod, this.UserData.dtUserDet[0].DeputedBU);
                      //this.getKickerMasterData();
                       // this.getGrowthKickerMasterData();
                    }
                }

                    ,
                    error => {
                        this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
                    });
        
    }

    GetAmountPayableData(i, value): void {
        var msg = [];
        var AmountPayable = '';
      if (i == 0) {

        if (value != null && value != '' && value != undefined) {
          if (value < 0 || value > 10) {
            msg.push("Please enter a value between 0 and 10");
          }
          if (msg.length > 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: msg
            });
          }
          else {
            for (var o in this.IncentiveData) {
              var CalculatedValue = ((value / 100) * this.IncentiveData[o].ProratedValue ).toFixed(2);
              if (AmountPayable != '') {
                AmountPayable = AmountPayable + " , " + this.IncentiveData[o].Currency + " " + CalculatedValue;
              }
              else {
                AmountPayable = this.IncentiveData[o].Currency + " " + CalculatedValue;
              }
            }
            this.KickerData[i].AmountPayable = AmountPayable;
          }
        }

      }

      else if (i == 1) {
        AmountPayable = '';
        if (value != null && value != '' && value != undefined) {
          if (value < 0 || value > 10) {
            msg.push("Please enter a value between 0 and 10");
          }
          if (msg.length > 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: msg
            });
          }
          else {
            for (var o in this.IncentiveData) {
              var CalculatedValue = ((value / 100) * this.IncentiveData[o].ProratedValue).toFixed(2);
              if (AmountPayable != '') {
                AmountPayable = AmountPayable + " , " + this.IncentiveData[o].Currency + " " + CalculatedValue;
              }
              else {
                AmountPayable = this.IncentiveData[o].Currency + " " + CalculatedValue;
              }
            }
            this.KickerData[i].AmountPayable = AmountPayable;
          }
        }

      }
      else if (i == 2) {
          if (value != null && value != '' && value != undefined) {
            if (value < 0 || value > 10000) {
              msg.push("Please enter a value between 0 and 10000");
            }
            else {
              this.KickerData[i].AmountPayable = "USD " + value;
            }
            if (msg.length > 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: msg
              });
            }
          }
        else {
          this.KickerData[i].AmountPayable = '';
        }
      }
 
    }
    GetFeedbackDetails(): void {
        this.showOtherMetricsTable = false;
        var data = {
            "PSNo": this.PSNO,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "LoggedInPSNo": this.userInfo.PS_No
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetFeedbackDetailsURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.FeedbackdisplayedColumns = ['FeedbackName', 'CNBFeedback', 'SalesHeadFeedback'];
                    this.FeedbackDetails = response.ResponseData.dtFeedbackDetails;
                    this.IsFeedbackDetails = true;
                    if (this.FeedbackDetails.length == 0) {
                        this.IsFeedbackDetails = false;
                    }
                }
            });
  }
  CheckButton(value) {
    if (value == 1) {
      this.showButton = true;
    }
    else {
      this.showButton = false;
    }

  }


  GetParameterwiseData(salesMetricId) {
    var data = {
      psNo: this.PSNO,
      PeriodId: this.selectedPeriod,
      AppType: this.userInfo.AppType,
      SalesMetricsID: salesMetricId
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetParameterwiseURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.parameterwiseDatarr = response.ResponseData;
          if (this.parameterwiseDatarr.length > 0) {
            this.parameterwiseData.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  closeparameterwiseDataModal() {
    this.parameterwiseData.closeModal();
  }

  GetParameterwiseThresoldData(salesMetricId) {
    var data = {
      psNo: this.PSNO,
      PeriodId: this.selectedPeriod,
      AppType: this.userInfo.AppType,
      SalesMetricsID: salesMetricId
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetParameterwiseThresoldURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.parameterwiseThresoldDatarr = response.ResponseData;
          if (this.parameterwiseThresoldDatarr.length > 0) {
            this.parameterwiseThresoldData.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  //openH1computationModal(type: any) {
  //  var data = {
  //    "PS_No": this.PSNO,
  //    "PeriodId": '11',
  //    'Role': 'Employee',
  //    'UserType': '',
  //    "AppType": this.userInfo.AppType
  //  };
  //  this.appService.SetLoadingShow(true);
  //  var url = this.configs.GetH1MetricsDetailsURL();
  //  this.appService.GetDataFromAPI(url, data)
  //    .then(response => {
  //      this.appService.SetLoadingShow(false);
  //      if (response.ResponseCode == this.configs.RetCodeFailure()) {
  //        this.dialog.open(DialogDataExampleDialog, {
  //          data: response.ResponseData
  //        });
  //      }
  //      else {
  //        this.H1tblMetrics = response.ResponseData.dtMertricsDet;
  //        this.H1tblIncentive = response.ResponseData.dtIncentiveDet;
  //        this.H1WeightedPercentage = this.appService.AddPercentageSign(this.H1tblIncentive[0].WeightedPercentage);
  //        this.H1UpdatedAchievement = this.appService.AddPercentageSign(this.H1tblIncentive[0].UpdatedAchievement);
  //        this.H1overallachievement = this.appService.AddPercentageSign(((this.tblIncentive[0].WeightedPercentage) + (this.H1tblIncentive[0].UpdatedAchievement)) / 2);

  //        if (type == 1) {
  //          if (this.H1tblMetrics.length > 0) {
  //            this.h1computation.openModal();
  //          }
  //          else {
  //            this.dialog.open(DialogDataExampleDialog, {
  //              data: "No data available"
  //            });
  //          }
  //        }

  //      }
  //    }
  //      ,
  //      error => {
  //        this.errorMessage = <string>error;
  //      });

  //}
}
