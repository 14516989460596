import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogDataExampleDialog } from '../popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';
@Component({
  selector: 'finalstrata-update',
  templateUrl: './finalstrata-update.component.html',
  styleUrls: ['./finalstrata-update.component.less']
})
export class FinalstrataUpdateComponent implements OnInit {
  // autocomplete
  autocomplete: { data: { [key: string]: string } };
   
  masterData: any;
  userInfo: any;

  FinalRatingtable: any;
  selectedPeriod: any;

  errorMessage: string;
  List: any;

  PeriodList: any = [];
 
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.selectedPeriod = '';
    this.FinalRatingtable = [];
   
    this.List = [];
    
    
   
    
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo != null) {
    var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
    if (checkAccess) {
    this.masterData = this.appService.GetMasterDetails();
      this.appService.GetDataFromAPI(this.configs.GetFYPeriodIdsURL(), null)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.PeriodList = response.ResponseData.dtPeriods;

            if (this.selectedPeriod == null || this.selectedPeriod == undefined) {
              this.selectedPeriod = '';
            }
            if (this.selectedPeriod != '') {
              this.onChangeOFPeriod(this.selectedPeriod);


            }
            //this.ChangeView(2);
          }
      
      });
    
    } else {
      this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
  onChangeOFPeriod(value: any): void {
    var Input = {
      "PeriodId": value
    }

    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDataforFinalRatingURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          

          this.FinalRatingtable = response.ResponseData.dtFinalRating;
          for (var o in this.FinalRatingtable) {
            this.FinalRatingtable[o].EnableEdit = "N";
          }

          if (this.FinalRatingtable.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            return;
          }
          

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
  }

 
  
  SelectRecords(value: string): void {
    for (var o in this.FinalRatingtable) {
      if (value.toString() == this.FinalRatingtable[o].PSNO) {
        this.FinalRatingtable[o].select = !this.FinalRatingtable[o].select;
        this.List.push(this.FinalRatingtable[o]);
        if (this.FinalRatingtable[o].EnableEdit == "N") {
          this.FinalRatingtable[o].EnableEdit = "Y";
        }
        else {
          this.FinalRatingtable[o].EnableEdit = "N";
        }
        break;
      }

    }

  }

  UpdateFinalRating(): void {
    var data = [];
    for (var o in this.FinalRatingtable) {
      if (this.FinalRatingtable[o].EnableEdit == "Y") {
        data.push({
          'PeriodId': this.selectedPeriod.toString(),
          'PSNO': this.FinalRatingtable[o].PSNO,
          'FinalRating': this.FinalRatingtable[o].FinalRating,
          'FinalStrata': this.FinalRatingtable[o].FinalStrata
        });
      }
    }
    
    if (data.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select atleast one record"
      });
    }
    else {
      
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.SubmitDataforFinalRatingURL(), data)
          .then(response => {
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.appService.SetLoadingShow(false);
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              if (response.ResponseData) {
                this.dialog.open(DialogDataExampleDialog, {
                  data: "Final ratings are updated successfully ."
                });
                this.onChangeOFPeriod(this.selectedPeriod);
              }
              else {
                this.dialog.open(DialogDataExampleDialog, {
                  data: "An Error Occured while saving the Final ratings. Please try again."
                });
              }
            }

          });
      }
    
  }



  
  onBackClick() {
    
    this._router.navigate(['appraisal-dashboard']);
  }

}
