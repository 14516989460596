import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA, MatTabGroup, MatTab } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
import { NumberValueAccessor } from '@angular/forms/src/directives';
import { NUMBER_VALUE_ACCESSOR } from '@angular/forms/src/directives/number_value_accessor';

@Component({
  selector: 'app-pre-target',
  templateUrl: './pre-target.component.html',
  styleUrls: ['./pre-target.component.less']
})
export class PreTargetComponent implements OnInit {
  
  @ViewChild('confirmModel') confirmModel: MzModalComponent
  autocomplete: { data: { [key: string]: string } };
  uploadedFile: File;
  periodid: any;
  UserPsno: any;
  periodValus: any;
  ShowSection: number;
  userInfo: any;
  Message: any;
  DownloadFilePath: any;

  DownloadFilePathBase: any;
  roleSelected: any;
  masterData: any;
  roleSelectedName: any;
  dataSource: any;
  FilteredData: any;
  mappingData: any;
  selectAll: boolean = false;
  SalesMetricColumns: any[] = [];
  AllowedPeriodUpload: any;
  tblStatusOrginal: any;
  LoggedInUserRole: any;
  LoggedInUserRoleText: any;
  ShowApprRejBtns: boolean = false;
  ShowReleaseBtns: boolean = false;
  ShowEditBtn: boolean = false;
  ShowSubmitApprovalBtn: boolean = false;
  IsDownload: boolean = false;
  RoleByPeriodMaster: any = [];
  AllowedRolesForPeriod: any = [];
  SelectedRecords: any = [];
  warningdata: string = '';
  GridId: string = '1';
  TotalEligibleNumber: number = 0;
  TotalUploadedNumber: number = 0;
  TargetsNotUploadedNumber: number = 0;
  PendingWithSENumber: number = 0;
  TargetReleasedNUmber: number = 0;
  PendingWithCNBNumber: number = 0;
  PendingWithMgrNumber: number = 0;
  isCBoAndManager: boolean = false;
  TargetRevisionNUmber: number = 0;
  NewSBU: string = '';
 // dropdownListFunction: any = [];
    //dataSource = ELEMENT_DATA;
    isH1Applicable: boolean = false;
    AppType : string = 'SPM';
  SBUFilter: any = [];
  FunctionFilter: any = [];
  tblselectSBUs: any = [];
  SearchText: any='';
  ApplicablePeriods = [];
  TargetForUploadAfterConfirm: any = [];
  selectSBUs = [];
  selectFunctions = [];
  dropdownSettings: any = {};
  dropdownListSBU = [];
  dropdownListFunction: any = [];
    filters: any;
    selectFunction: any;
    FuntionFilter: any;
  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.masterData = {};
    this.periodid = '0';
    this.UserPsno = '';
    this.uploadedFile = null;
    this.periodValus = [];
    this.ShowSection = 1;
    this.Message = '';
    this.roleSelectedName = '';
    this.DownloadFilePath = '';
    this.dataSource = [];
    this.FilteredData = [];
    this.tblStatusOrginal = [];
    this.mappingData = [];
    this.AllowedPeriodUpload = [];
    this.LoggedInUserRole = 0;
    this.LoggedInUserRoleText = '';
   
  }
  ngOnInit() {
    this.appService.SetLoadingShow(false);
    this.isCBoAndManager = false;
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.UserPsno = this.userInfo.PS_No;
        this.LoggedInUserRole = this.userInfo.SPMRoles[0].RoleID;
        this.LoggedInUserRoleText = this.userInfo.SPMRoles[0].Role;
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: false,
          badgeShowLimit: 1,
          classes: ""

        };
        var UserData = {
          "PS_No": this.userInfo.PS_No,
          "AppType": this.userInfo.AppType
        }
        this.appService.SetLoadingShow(true);
        this.masterData = this.appService.GetMasterDetails();
        this.appService.GetDataFromAPI(this.configs.GetYearsForTargetUpload(), UserData)
          .then(response => {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {

              var TargetScreenRoles = this.appService.GetUserRoleForTargetUpload(this.userInfo.SPMRoles);
              if (TargetScreenRoles.CSRoleId > 0)
                this.LoggedInUserRole = TargetScreenRoles.CSRoleId;
              else if (TargetScreenRoles.CBORoleId > 0)
                this.LoggedInUserRole = TargetScreenRoles.CBORoleId;
              else if (TargetScreenRoles.HRRoleId > 0)
                this.LoggedInUserRole = TargetScreenRoles.HRRoleId;
              else if (TargetScreenRoles.SELARoleId > 0)
                this.LoggedInUserRole = TargetScreenRoles.SELARoleId;

              this.periodValus = response.ResponseData.dtYearsForTrgts;
              this.RoleByPeriodMaster = response.ResponseData.dtApplicablePlanForYear;
              this.periodid = response.ResponseData.dtYearsForTrgts[0].TargetUploadYearText;
              this.onChangeOFPeriod(this.periodid);
              this.ShowSection = 1;
            }
          });
      }
      else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
  ChangeView(value: number) {
    this.ShowSection = value;
  }
  
  onChangeOFPeriod(id) {
    if (id != '') {
      this.periodid = id;
      this.FilterPlans(id);
    }
    else {
      this.periodid = '0';
    }
  }
  FilterPlans(periodid) {
    this.AllowedRolesForPeriod = [];
    for (var o in this.RoleByPeriodMaster) {
      if (this.RoleByPeriodMaster[o].TargetUploadYearText == periodid) {
        this.AllowedRolesForPeriod.push(this.RoleByPeriodMaster[o]);
      }
    }

  }
  ChangeRole(id) {
    if (id != '') {
      this.roleSelected = id;
      if (this.roleSelected != '0') {
        this.appService.SetLoadingShow(true);
        this.Reload();
        for (var o in this.AllowedRolesForPeriod) {
          if (this.AllowedRolesForPeriod[o].RoleId == this.roleSelected) {
            this.appService.SetRoleID(this.roleSelected);
            this.roleSelectedName = this.AllowedRolesForPeriod[o].RoleName;
           // this.DownloadFilePath = this.DownloadFilePathBase + this.periodid + '_' + this.roleSelectedName + ".xlsx";
            this.ChangeView(2);
            break;
          }
        }
      }
      else {
        this.roleSelectedName = '';
        this.DownloadFilePath = '';
      }
    }
  }
  Reload() {
    this.SalesMetricColumns = [];
    this.ApplicablePeriods = [];
    this.dataSource = [];
    this
    this.uploadedFile = null;
    var data = {
      'psNo': this.userInfo.PS_No,
      'periodYear': this.periodid,
      'Role': "CET",
      'SelectedRole': this.roleSelected,
      'AppType': this.userInfo.AppType
    };
    this.appService.GetDataFromAPI(this.configs.GetPreTargetDataForYearByRoleURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          if (response.ResponseData.empSalesMatricsInfos.length > 0) {
            this.dataSource = response.ResponseData.empSalesMatricsInfos;
            this.SalesMetricColumns = response.ResponseData.metricsMasters;
            this.ApplicablePeriods = response.ResponseData.ApplicablePeriods;
            this.DownloadFilePath = response.ResponseData.FileName;
            this.SBUFilter = response.ResponseData.dtSBU;
            this.FilteredData = JSON.parse(JSON.stringify(this.dataSource));
            this.FunctionFilter = response.ResponseData.dtFunction;

            //this.filters.dtFunction = response.ResponseData.dtFunction;
            //for (var o in this.filters.dtFunction) {
            //  this.dropdownListFunction.push({ "id": this.filters.dtFunction[o].FunctionId, "itemName": this.filters.dtFunction[o].FunctionName });
            //}
            //this.selectFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
            this.dropdownListFunction = [];

            for (var o in this.FunctionFilter) {
              this.dropdownListFunction.push({ "id": this.FunctionFilter[o].FunctionId, "itemName": this.FunctionFilter[o].FunctionName});
            }
            this.selectFunctions = JSON.parse(JSON.stringify(this.dropdownListFunction));

            this.dropdownListSBU = [];
            for (var o in this.SBUFilter) {
              this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.SBUFilter[o].DeputedBU });
            }
            this.selectSBUs = JSON.parse(JSON.stringify(this.dropdownListSBU));
              //return;
            }
          if (this.dataSource.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available."
            });

          }
          this.ShowSubmitApprovalBtn = false;
         
          if (this.dataSource.length > 0) {
            if (!this.ShowSubmitApprovalBtn) {
              this.ShowSubmitApprovalBtn = true;
            }
            if (this.dataSource[0].IsH1PeriodApplicable == '1') {
              this.isH1Applicable = true;
            }
            else {
              this.isH1Applicable = false;
            }
            this.AppType = this.dataSource[0].AppType;

            this.selectAll = false;
            for (var o in this.dataSource) {
              this.dataSource[o].select = false;
              this.dataSource[o].EnableEdit = "N";
            }
          }
          if (response.ResponseData.allowedPeriodUploads.length > 0) {
            this.AllowedPeriodUpload = response.ResponseData.allowedPeriodUploads;
          }
        }
      });
  }

  PublishForApproval() {

   
 

    var finaltable = [];

    for (var i in this.dataSource) {
      if (this.dataSource[i].select) {
        var data = {
          'PSNO': 0,
          'Employee Name': '',
          'ACCOUNT MAPPING': ""
        };

        data.PSNO = this.dataSource[i].psNo;
        data["ACCOUNT MAPPING"] = this.dataSource[i].Account_New_Save;
        data["Employee Name"] = this.dataSource[i].name;
        for (var o in this.SalesMetricColumns) {
          for (var x in this.ApplicablePeriods) {

            for (var z in this.dataSource[i].matricsDataList) {
              if (this.dataSource[i].matricsDataList[z].SalesMetricID == this.SalesMetricColumns[o].SalesMetricsID && this.ApplicablePeriods[x].PeriodId == this.dataSource[i].HalfYearPeriodId) {
                data[this.ApplicablePeriods[x].Name + " - " + this.SalesMetricColumns[o].MetricName] = this.dataSource[i].matricsDataList[z].H1Target_New_Save;
                break;
              }
              else if (this.dataSource[i].matricsDataList[z].SalesMetricID == this.SalesMetricColumns[o].SalesMetricsID && this.ApplicablePeriods[x].PeriodId == this.dataSource[i].FYPeriodId) {
                data[this.ApplicablePeriods[x].Name + " - " + this.SalesMetricColumns[o].MetricName] = this.dataSource[i].matricsDataList[z].FYTarget_New_Save;
                break;
              }
            }
          }
        }
        data["IsPID"] = "No";
        finaltable.push(JSON.parse(JSON.stringify(data)));
      } 
    }


    if (finaltable.length > 0) {




      var submitData = {
        'PSNo': this.userInfo.PS_No,
        'PeriodId': this.periodid,
        'RoleId': this.LoggedInUserRole,
        'PlanName': this.roleSelectedName,
        'AppType': this.userInfo.AppType,
        'dtData': finaltable,
        'allowedPeriodUploads': this.AllowedPeriodUpload
      };
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.MoveDatafromPretargetsURL(), submitData)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.Message = response.ResponseData;
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            if (response.ResponseData.approveTargets.length == 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData.retmsgs
              });
              this.Reload();
            }
            else {
              this.appService.SetLoadingShow(false);
              this.warningdata = response.ResponseData.retmsgs;
              this.TargetForUploadAfterConfirm = response.ResponseData.approveTargets;
              this.confirmModel.openModal();
            }
            //this.Navigate('/bulk-upload-grid');
          }
          

        });
    }
    else {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select a record to proceed"
      });
    }

  }

  SelectRecords(val, period) {
    if (val.toString() == 'ALL') {
      this.selectAll = !this.selectAll;
      for (var o in this.dataSource) {
        this.dataSource[o].select = this.selectAll;
        // if (this.dataSource[o].EnableEdit == "N") {
        //   this.dataSource[o].EnableEdit = "Y";
        // }
        // else {
        //   this.dataSource[o].EnableEdit = "N";
        // }
      }
    }
    else {
      for (var o in this.dataSource) {
        if (val.toString() == this.dataSource[o].psNo.toString()) {
          this.dataSource[o].select = !this.dataSource[o].select;
          // if (this.dataSource[o].EnableEdit == "N") {
          //   this.dataSource[o].EnableEdit = "Y";
          // }
          // else {
          //   this.dataSource[o].EnableEdit = "N";
          // }
          break;
        }
      }
    }
    this.ResetAllCheckBox();
  
  }
  ResetAllCheckBox() {

    for (var o in this.dataSource) {
      if (this.dataSource[o].select == false) {
        this.selectAll = false;
        break;
      }
    }
  }
  
  DownLoadPreTargetsExcel(PeriodId, Role, SBU, Name): void {
    this.AppType = this.userInfo.AppType;// refer target mangemen
 
    var sbulist = [];
    for (var o in SBU) {
      sbulist.push(SBU[o].itemName);
    }
    this.NewSBU = sbulist.join('|');
    this.NewSBU = escape(this.NewSBU);
    window.open("PreTarget.ashx?PSNO=" + this.UserPsno + "&UserRole=" + "CET" + "&PeriodId=" + PeriodId + "&Role=" + Role + " &SBU=" + this.NewSBU + " &Name=" + Name + " &AppType=" + this.AppType + " &SelectedRoleName=" + this.roleSelectedName);
  }
  ToUploadMapping() {
    this.appService.SetPreTargetData({ 'PeriodYear': this.periodid });
   // if (this.LoggedInUserRole == 14) {
      this.router.navigate(['actual-upload/15']);
   // }
   // else if (this.LoggedInUserRole == 11) {
   //   this.router.navigate(['actual-upload/13']);
    //}
  }
  onCancelClick(): void {
    this.confirmModel.closeModal();
  }

  UpdateTargetsAfterConfirmation(): void {
    this.onCancelClick();
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.UpdateTargetsAfterConfirmationURL(), this.TargetForUploadAfterConfirm)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.onCancelClick();
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.TargetForUploadAfterConfirm = [];
          this.dataSource = null;
          this.Reload();
        }
      });

  }
  onFilterChange(selectedsbus) {
    this.dataSource = JSON.parse(JSON.stringify(this.FilteredData));
  
    this.tblselectSBUs = [];
    var data = [];

   

    if (selectedsbus.length > 0) {
      for (var o in selectedsbus) {
        if (selectedsbus[o].itemName != '' || selectedsbus[o].itemName != undefined || selectedsbus[o].itemName != "SELECT") {
          for (var i in this.dataSource) {
            if (this.dataSource[i].sbu == selectedsbus[o].itemName) {
              data.push(this.dataSource[i]);
            }
          }
        }
      }
    }
    else {
      data = this.dataSource;
    }
    this.tblselectSBUs = data;
    this.dataSource = this.tblselectSBUs;
    if (this.tblselectSBUs.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "No record found for the selected filter(s)."
      });
      return;
    }
  }

  SearchFor(value): any {
    this.onFilterChange(this.selectSBUs);
    //this.dataSource = this.tblselectSBUs;
      var data = JSON.parse(JSON.stringify(this.dataSource));
      if (value != '' && value != undefined) {
        if (value.length > 2) {
          const filterValue = value.toLowerCase();
          this.SearchText = value;
          for (var o in data) {
            if (data[o].name == null)
              data[o].name = "";
            if (data[o].manager == null)
              data[o].manager = "";
          }
          data = data.filter(option => option.name.toLowerCase().includes(filterValue) || option.manager.toLowerCase().includes(filterValue) );
        }
      }
      this.dataSource = data;
  }

  onReset() {
    this.selectSBUs = [];
    this.selectSBUs = JSON.parse(JSON.stringify(this.dropdownListSBU));
    //this.selectFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
    this.SearchText = '';
    this.tblselectSBUs = [];
    this.dataSource = JSON.parse(JSON.stringify(this.FilteredData));

  }

  }


