import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogDataExampleDialog } from '../popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'app-team-dashboard',
    templateUrl: './team-dashboard.component.html',
    styleUrls: ['./team-dashboard.component.less']
})
export class TeamDashboardComponent implements OnInit {
  // autocomplete
  autocomplete: { data: { [key: string]: string } };

  displayedColumns: string[] = ['psNo', 'name', 'role', 'manager', 'reviewer', 'sbu', 'status'];
  //dataSource = ELEMENT_DATA;
  masterData: any;
  userInfo: any;
  RoleID: any;
  tblStatus: any;
  tblStatusOrginal: any;
  tblStatusData: any;
  selectedStatus: any;
  selectedPeriod: any;
  filters: any;
  errorMessage: string;
  ShowFilters: boolean = false;
  selectedSBU: any;
  selectedFunction: any;
  DownloadFilePath: any;
  DashBoardTeamDataObject: any;
  SearchText: any;
  SelfAssessmentNumber: number = 0;
  NotInitiatedNumber: number = 0;
  ManagerAssessmentNumber: number = 0;
  ReviewerAssessmentNumber: number = 0;
  DisPendingWithSENumber: number = 0;
  CompletedNumber: number = 0;
  DisPendingWithCPNumber: number = 0;
  AppraisalPublishedNumber: number = 0;
  AppraisalPendingNumber: number = 0;
  dropdownListStatus: any = [];
  dropdownListSBU: any = [];
  dropdownListFunction: any = [];
  dropdownListRole: any = [];
  dropdownSettings: any = {};
  DisPeriodArray: any = [];
  isManagerOrReviewer: boolean = false;
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblStatus = [];
    this.tblStatusOrginal = [];
    this.tblStatusData = [];
    this.filters = {};
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.selectedPeriod = '';
    this.selectedStatus = [];
    this.RoleID = [];
    this.SearchText = '';
    this.filters = {
      'dtStatusType': [],
      'dtSBU': [],
      'dtRole': [],
      'dtStatusTiles': {},
      'dtFunction': []
    };
    this.DownloadFilePath = this.configs.TeamDashBoardExcelFileURl();
    this.DashBoardTeamDataObject = {};
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.masterData = this.appService.GetMasterDetails();
        for (var o in this.userInfo.SPMRoles) {
          if (this.userInfo.SPMRoles[o].RoleID == 9 || this.userInfo.SPMRoles[o].RoleID == 10) {
            this.isManagerOrReviewer = true;
            break;
          }
        }
        if (this.userInfo.AccessType[0].Showperiodyear == 'isFullyearly') {
          for (var o in this.masterData.dtPeriodDet) {
            if ((this.masterData.dtPeriodDet[o].PeriodID) % 2 == 0) {
              this.DisPeriodArray.push(this.masterData.dtPeriodDet[o]);
            }
          }
          this.masterData.dtPeriodDet = this.DisPeriodArray;
        }
        this.DashBoardTeamDataObject = this.appService.GetDashBoardData();
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: false,
          badgeShowLimit: 1,
          classes: ""

        };
        if (this.DashBoardTeamDataObject != undefined) {
          this.selectedSBU = this.DashBoardTeamDataObject.selectedSBU;
          this.selectedFunction = this.DashBoardTeamDataObject.selectedFunction;
          this.tblStatusOrginal = this.DashBoardTeamDataObject.tblStatusOrginal;
          this.selectedPeriod = this.DashBoardTeamDataObject.selectedPeriod;
          this.selectedStatus = this.DashBoardTeamDataObject.selectedStatus;
          this.ShowFilters = this.DashBoardTeamDataObject.ShowFilters;
          this.RoleID = this.DashBoardTeamDataObject.RoleID;
          this.filters = this.DashBoardTeamDataObject.filters;
          this.filters.dtStatusType = this.DashBoardTeamDataObject.dtStatusType;
          this.dropdownListStatus = [];
          for (var o in this.filters.dtStatusType) {
            this.dropdownListStatus.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtStatusType[o].Type });
          }
          this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
          this.filters.dtSBU = this.DashBoardTeamDataObject.dtSBU;
          this.dropdownListSBU = [];
          for (var o in this.filters.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
          }
          this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));

          this.filters.dtFunction = this.DashBoardTeamDataObject.dtFunction;
          this.dropdownListFunction = [];
          for (var o in this.filters.dtFunction) {
            this.dropdownListFunction.push({ "id": this.filters.dtFunction[o].FunctionId, "itemName": this.filters.dtFunction[o].FunctionName });
          }
          this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));


          this.filters.dtRole = this.DashBoardTeamDataObject.dtRole;
          this.dropdownListRole = [];
          for (var o in this.filters.dtRole) {
            this.dropdownListRole.push({ "id": this.filters.dtRole[o].RoleID, "itemName": this.filters.dtRole[o].RoleName });
          }
          this.RoleID = JSON.parse(JSON.stringify(this.dropdownListRole));

          this.filters.dtStatusTiles = this.DashBoardTeamDataObject.dtStatusTiles;
          this.InitializeNumbers();
          this.tblStatus = this.tblStatusOrginal;
          this.SearchText = this.DashBoardTeamDataObject.SearchText;
          this.onFunctionChange(this.selectedFunction)
          this.onFilterChange(this.selectedSBU, this.selectedStatus, this.RoleID);

        }
      }
      else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
  onChangeOFPeriod(value: string): void {
    this.selectedStatus = [];
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.RoleID = [];
    this.dropdownListStatus = [];
    this.dropdownListSBU = [];
    this.dropdownListFunction = [];
    this.dropdownListRole = [];
    this.tblStatusOrginal = [];
    this.tblStatus = [];
    var Input = {
      "Period": value,
      "PSNO": this.userInfo.PS_No,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTeamDashBoardGridDataURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.tblStatusOrginal = response.ResponseData.dtGridData;
          this.tblStatus = response.ResponseData.dtGridData;
          this.tblStatusData = response.ResponseData.dtGridData;
          this.filters.dtStatusType = response.ResponseData.dtStatusType;
          for (var o in this.filters.dtStatusType) {
            this.dropdownListStatus.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtStatusType[o].Type });
          }
          this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
          this.filters.dtSBU = response.ResponseData.dtSBU;
          for (var o in this.filters.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
          }
          this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));

          this.filters.dtFunction = response.ResponseData.dtFunction;
          for (var o in this.filters.dtFunction) {
            this.dropdownListFunction.push({ "id": this.filters.dtFunction[o].FunctionId, "itemName": this.filters.dtFunction[o].FunctionName });
          }
          this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));

          this.filters.dtRole = response.ResponseData.dtRole;

          for (var o in this.filters.dtRole) {
            this.dropdownListRole.push({ "id": this.filters.dtRole[o].RoleID, "itemName": this.filters.dtRole[o].RoleName });
          }
          this.RoleID = JSON.parse(JSON.stringify(this.dropdownListRole));

          this.filters.dtStatusTiles = response.ResponseData.dtStatusTiles;
          this.InitializeNumbers();

          this.ShowFilters = true;

          if (this.tblStatus.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            return;
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  onReset(): void {
    this.appService.SetLoadingShow(true);
    this.selectedStatus = [];
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.RoleID = [];
    this.setDropdownListSBU();
    //this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
    this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
    this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
    this.RoleID = JSON.parse(JSON.stringify(this.dropdownListRole));
    this.SearchText = '';
    this.tblStatus = this.tblStatusData;
    this.InitializeNumbers();
    this.appService.SetLoadingShow(false);
  }
  OnViewDetails(value) {
    this.DashBoardTeamDataObject = {
      'selectedFunction': this.selectedFunction,
      'selectedSBU': this.selectedSBU,
      'tblStatusOrginal': this.tblStatusOrginal,
      'selectedPeriod': this.selectedPeriod,
      'selectedStatus': this.selectedStatus,
      'ShowFilters': this.ShowFilters,
      'RoleID': this.RoleID,
      'filters': this.filters,
      'SearchText': this.SearchText,
      'dtStatusType': this.filters.dtStatusType,
      'dtSBU': this.filters.dtSBU,
      'dtRole': this.filters.dtRole,
      'dtStatusTiles': this.filters.dtStatusTiles,
      'dtFunction': this.filters.dtFunction
    }
    this.appService.SetDashBoardData(this.DashBoardTeamDataObject);
    this.appService.SetperiodId(this.selectedPeriod);
    this.appService.SetPsno(value);
    this.appService.SetIsManagerOrReviewer(this.isManagerOrReviewer)
    this.appService.SetNavbackPathForAssessmentDetails('team-dashboard');
    this._router.navigate(['assessment-details']);
  }

  setDropdownListSBU() {
    this.dropdownListSBU = [];
    for (var o in this.filters.dtSBU) {
      this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
    }
    this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
  }

  onFunctionChange(selectedFunction) {
    this.setDropdownListSBU();
    this.tblStatusOrginal = this.tblStatusData;
    var data = [];
    var dataselectedSBU = [];

    if (selectedFunction.length < this.dropdownListFunction.length) {
      for (var o in selectedFunction) {
        if (selectedFunction[o].itemName != '' || selectedFunction[o].itemName != undefined || selectedFunction[o].itemName != "SELECT") {
          for (var i in this.tblStatusOrginal) {
            if (this.tblStatusOrginal[i].FunctionName == selectedFunction[o].itemName) {
              data.push(this.tblStatusOrginal[i]);
            }
          }

          for (var j in this.selectedSBU) {
            if (this.selectedSBU[j].Functionid == selectedFunction[o].id) {
              dataselectedSBU.push(this.selectedSBU[j])
            }
          }

        }

      }
      this.selectedSBU = JSON.parse(JSON.stringify(dataselectedSBU))
      this.dropdownListSBU = JSON.parse(JSON.stringify(this.selectedSBU))
      this.tblStatusOrginal = data

    }
    this.onFilterChange(this.selectedSBU, this.selectedStatus, this.RoleID)
  }

  onFilterChange(selectedSBU, StatusID, Role) {

    //var data = JSON.parse(JSON.stringify(this.tblStatusOrginal));
    this.tblStatus = [];
    if (this.selectedFunction.length == this.dropdownListFunction.length) {
      this.tblStatusOrginal = this.tblStatusData
    }


    var dataSBU = [];

    if (selectedSBU.length < this.dropdownListSBU.length) {
      for (var o in selectedSBU) {
        if (selectedSBU[o].itemName != '' || selectedSBU[o].itemName != undefined || selectedSBU[o].itemName != "SELECT") {
          for (var i in this.tblStatusOrginal) {
            if (this.tblStatusOrginal[i].LOB == selectedSBU[o].itemName) {
              dataSBU.push(this.tblStatusOrginal[i]);
            }
          }
        }
      }

      this.selectedSBU = selectedSBU
    }
    else {
      dataSBU = this.tblStatusOrginal;
        // data = [];
    }

    var dataStatus = [];

    if (StatusID.length < this.dropdownListStatus.length) {
      for (var o in StatusID) {
        if (StatusID[o].itemName != '' || StatusID[o].itemName != undefined || StatusID[o].itemName != "SELECT") {
          for (var i in dataSBU) {
            if (dataSBU[i].status == StatusID[o].itemName) {
              dataStatus.push(dataSBU[i]);
            }
          }
        }
      }
    }
    else {
      dataStatus = dataSBU;
      //dataStatus = [];
    }

    var dataRole = [];

    if (Role.length < this.dropdownListRole.length) {
      for (var o in Role) {
        if (Role[o].id != '' || Role[o].id != undefined || Role[o].id != "SELECT") {
          for (var i in dataStatus) {
            if (dataStatus[i].RoleId == Role[o].id) {
              dataRole.push(dataStatus[i]);
            }
          }
        }
      }
    }
    else {
      dataRole = dataStatus;
      //dataRole = [];
    }
    this.tblStatus = dataRole;

    this.SelfAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles.SelfAssessmentStatus);
    this.ManagerAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles.ManagerAssessmentStatus);
    this.ReviewerAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles.ReviewerAssessmentStatus);
    this.DisPendingWithSENumber = this.RefreshCount(this.filters.dtStatusTiles.DisPendingWithSEStatus);
    this.CompletedNumber = this.RefreshCount(this.filters.dtStatusTiles.CompletedStatus);
    this.DisPendingWithCPNumber = this.RefreshCount(this.filters.dtStatusTiles.DisPendingWithCPStatus);
    this.AppraisalPendingNumber = this.RefreshCount(this.filters.dtStatusTiles.AppraisalPendingStatus);
    this.AppraisalPublishedNumber = this.RefreshCount(this.filters.dtStatusTiles.AppraisalPublishedStatus);
    this.NotInitiatedNumber = this.RefreshCount(this.filters.dtStatusTiles.NotInitiatedStatus);
    //if (this.tblStatus.length == 0) {
    //  this.dialog.open(DialogDataExampleDialog, {
    //    data: "No record found for the selected filter(s)."
    //  });
    //  return;
    //}
  }
  SearchFor(value): any {
    var data = JSON.parse(JSON.stringify(this.tblStatusOrginal));
    if (value != '' && value != undefined) {
      if (value.length > 2) {
        const filterValue = value.toLowerCase();
        this.SearchText = value;
        for (var o in data) {
          if (data[o].name == null)
            data[o].name = "";
          if (data[o].manager == null)
            data[o].manager = "";
          if (data[o].reviewer == null)
            data[o].reviewer = "";
        }
        data = data.filter(option => option.name.toLowerCase().includes(filterValue) || option.manager.toLowerCase().includes(filterValue) || option.reviewer.toLowerCase().includes(filterValue));
      }
    }
    this.tblStatus = data;
  }

  RefreshCount(StatusID: string): number {
    var data = JSON.parse(JSON.stringify(this.tblStatus));
    if (StatusID != '' && StatusID != undefined && StatusID != "SELECT") {
      if (StatusID == 'Review Pending with Corporate Planning') {
        data = data.filter((data: any) => data.status.indexOf('Corporate Planning') != -1);
      }
      else if (StatusID == 'Review Pending with ' + this.getTeamName()) {
        data = data.filter((data: any) => data.status.indexOf(this.getTeamName()) != -1);
      }
      else {
        data = data.filter((data: any) => data.status == StatusID);
      }
    }
    return data.length;
  }


  InitializeNumbers(): void {
    this.SelfAssessmentNumber = this.filters.dtStatusTiles.SelfAssessmentNumber;
    this.ManagerAssessmentNumber = this.filters.dtStatusTiles.ManagerAssessmentNumber;
    this.ReviewerAssessmentNumber = this.filters.dtStatusTiles.ReviewerAssessmentNumber;
    this.DisPendingWithSENumber = this.filters.dtStatusTiles.DisPendingWithSENumber;
    this.CompletedNumber = this.filters.dtStatusTiles.CompletedNumber;
    this.DisPendingWithCPNumber = this.filters.dtStatusTiles.DisPendingWithCPNumber;
    this.AppraisalPendingNumber = this.filters.dtStatusTiles.AppraisalPendingNumber;
    this.AppraisalPublishedNumber = this.filters.dtStatusTiles.AppraisalPublishedNumber;
    this.NotInitiatedNumber = this.filters.dtStatusTiles.NotInitiatedNumber;
  }
  onTileClick(selectedFunction, selectedSBU, status, selectedRole) {
    var isValid = true;
    this.selectedStatus = [];
    for (var o in this.dropdownListStatus) {
      if (this.dropdownListStatus[o].itemName == status) {
        this.selectedStatus.push({ "id": this.dropdownListStatus[o].id, "itemName": this.dropdownListStatus[o].itemName });
        break;
      }
    }

    //this.selectedStatus.push(status);
    this.onFunctionChange(selectedFunction)
    this.onFilterChange(selectedSBU, this.selectedStatus, selectedRole);

  }
  getTeamName() {
    return this.appService.TeamName(this.userInfo.AppType);
  }
}

