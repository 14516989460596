import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogDataExampleDialog } from '../popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';
@Component({
  selector: 'appraisal-dashboard',
  templateUrl: './appraisal-dashboard.component.html',
  styleUrls: ['./appraisal-dashboard.component.less']
})
export class AppraisalDashboardComponent implements OnInit {
  // autocomplete
  autocomplete: { data: { [key: string]: string } };
   
  masterData: any;
  userInfo: any;
  tblData: any;
  tblDataNew: any;
  selectedPeriod: any;
  ShowFilters: any;
  errorMessage: string;
  List: any;
  Headers: any;
  PeriodList: any = [];
  filters: any;
  roleSelected: any;
  selectedSBU: any;
  selectedFunction: any;
  FilterData: any;
  SearchText: any;
  statusSelected: any;
  SelfAssessmentNumber: number = 0;
  NotInitiatedNumber: number = 0;
  ManagerAssessmentNumber: number = 0;
  ReviewerAssessmentNumber: number = 0;
  DisPendingWithSENumber: number = 0;
  CompletedNumber: number = 0;
  DisPendingWithCPNumber: number = 0;
  AppraisalPublishedNumber: number = 0;
  AppraisalPendingNumber: number = 0;
  dropdownListRole: any = [];
  dropdownListStatus: any = [];
  dropdownListSBU: any = [];
  dropdownListFunction: any = [];
  dropdownSettings: any = {};
  isManagerOrReviewer: boolean = false;
  SBUDistinct: any = [];
  SBUDistinctModel: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.selectedPeriod = '';
    this.tblData = [];
    this.tblDataNew = [];
    this.Headers = [];
    this.List = [];
    this.ShowFilters = false;
    this.filters = {
      'dtSBU': [],
      'dtRole': [],
      'dtStatus': [],
      'dtStatusTiles': [],
      'dtFunction': []
    };
    this.roleSelected = [];
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.SearchText = '';
    this.statusSelected = [];
    this.FilterData = [];
    this.SBUDistinct = [];
    this.SBUDistinctModel = [];
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.masterData = this.appService.GetMasterDetails();
        this.appService.GetDataFromAPI(this.configs.GetFYPeriodIdsURL(), null)
          .then(response => {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.PeriodList = response.ResponseData.dtPeriods;

              this.selectedPeriod = response.ResponseData.dtPeriods[1].PeriodID;
              if (this.selectedPeriod == null || this.selectedPeriod == undefined) {
                this.selectedPeriod = '';
              }
              if (this.selectedPeriod != '') {
                this.onChangeOFPeriod(this.selectedPeriod);


              }
              //this.ChangeView(2);
            }
          });
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: false,
          badgeShowLimit: 1,
          classes: ""
        };
      }
      else {
        this._router.navigate(['no-access']);
      }

    }
    else {
      window.location.href = "../Index.html";
    }
  }
  onChangeOFPeriod(value: any): void {
    this.List=[];
    var Input = {
      "PSNO": this.userInfo.PS_No,
        "PeriodId": value,
        "AppType": this.userInfo.AppType
    }

    this.appService.SetLoadingShow(true);
    var url = this.configs.GetAppraisalDataURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.FilterData = JSON.parse(JSON.stringify(response.ResponseData.appraisalsList));
          this.tblData = response.ResponseData.appraisalsList;
          this.tblDataNew = response.ResponseData.appraisalsList;
          this.Headers = response.ResponseData.appraisalParamterMasters;
          this.filters.dtRole = response.ResponseData.dtRole;
          this.dropdownListRole = [];
          for (var o in this.filters.dtRole) {
            this.dropdownListRole.push({ "id": this.filters.dtRole[o].RoleID, "itemName": this.filters.dtRole[o].RoleName });
          }
          this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
          this.filters.dtStatus = response.ResponseData.dtStatus;
          this.dropdownListStatus = [];
          for (var o in this.filters.dtStatus) {
            this.dropdownListStatus.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtStatus[o].Type });
          }
          this.statusSelected = JSON.parse(JSON.stringify(this.dropdownListStatus));
          this.filters.dtSBU = response.ResponseData.dtSBU;
          this.dropdownListSBU = [];
          for (var o in this.filters.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
          }
          this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
          var SBU = [];
          for (var j in this.selectedSBU) {
            if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
              SBU.push(this.selectedSBU[j].itemName)
            }
          }
          for (var o in SBU) {
            this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
          }

          this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

          this.filters.dtFunction = response.ResponseData.dtFunction;
          this.dropdownListFunction = [];
          for (var o in this.filters.dtFunction) {
            this.dropdownListFunction.push({ "id": this.filters.dtFunction[o].FunctionId, "itemName": this.filters.dtFunction[o].FunctionName });
          }
          this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));

          this.filters.dtStatusTiles = response.ResponseData.dtStatusTiles
          this.InitializeNumbers();
          //this.roleSelected = [];
         // this.selectedSBU = [];
          this.SearchText = '';
          if (this.tblData.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            return;
          }
          else {
            this.ShowFilters = true;
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
  }
  onReset(): void {
    this.selectedSBU = [];
    this.setDropdownListSBU();
    var SBU = [];
    this.SBUDistinctModel = [];
    this.SBUDistinct = [];
    for (var j in this.selectedSBU) {
      if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
        SBU.push(this.selectedSBU[j].itemName)
      }
    }
    for (var o in SBU) {
      this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
    }
    this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

    //this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
    this.selectedFunction = [];
    this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
    this.roleSelected = [];
    this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
    this.statusSelected = [];
    this.statusSelected = JSON.parse(JSON.stringify(this.dropdownListStatus));
    this.SearchText = '';
    this.FilterData = this.tblDataNew;

    this.InitializeNumbers();
  }

  OnViewDetails(value, Role) {
   
    this.appService.SetRoleID(Role);
    this.appService.SetperiodId(this.selectedPeriod);
    this.appService.SetPsno(value);
    this.appService.SetIsManagerOrReviewer(this.isManagerOrReviewer)
    this.SelectRecords(value);

    var Data = [];
    for (var o in this.FilterData) {
      if (value.toString() == this.FilterData[o].PSNO) {
        Data.push(this.FilterData[o]);
        break;
      }
    }
    this.appService.SetIncentiveDetailsList(Data);
    this.appService.SetAppraisalDetailsList(Data);
    this.appService.SetRoleForIncentivePage('CNB');
    this.appService.SetNavbackPathForAssessmentDetails('appraisal-dashboard');
    this._router.navigate(['incentive-details']);
  }

  
  SelectRecords(value: string): void {
    for (var o in this.FilterData) {
      if (value.toString() == this.FilterData[o].PSNO && this.FilterData[o].StatusId == '7' && (this.FilterData[o].VCPStatusId == '14' || this.FilterData[o].VCPStatusId == '7')) {
        this.FilterData[o].select = !this.FilterData[o].select;
        this.List.push(this.FilterData[o]);
        break;
      }

    }

  }

  Publish(): void {
    if (this.List.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select atleast one record"
      });
    }
    else {
    this.appService.SetLoadingShow(true);
    var data = {
      "Period": this.selectedPeriod.toString(),
      'UserData': this.List,
        'ChangedBy': this.userInfo.PS_No,
        "AppType": this.userInfo.AppType
      
    };
      this.appService.GetDataFromAPI(this.configs.PublishAppraisalURL(), data)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            if (response.ResponseData) {
              this.dialog.open(DialogDataExampleDialog, {
                data: "Appraisal details are published to the sales executive(s). The details will be visible to the sales executive(s) in the self assessment page."
              });
              this.onChangeOFPeriod(this.selectedPeriod);
            }
            else {
              this.dialog.open(DialogDataExampleDialog, {
                data: "An Error Occured while publishing the data. Please try again."
              });
            }
          }
        
      });
    }
  }

  setDropdownListSBU() {
    this.dropdownListSBU = [];
    for (var o in this.filters.dtSBU) {
      this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
    }
    this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));

  }

  onFunctionChange(selectedFunction) {
    this.setDropdownListSBU();
    this.tblData = this.tblDataNew;
    var data = [];
    var dataselectedSBU = [];

    if (selectedFunction.length <= this.dropdownListFunction.length) {
      for (var o in selectedFunction) {
        if (selectedFunction[o].itemName != '' || selectedFunction[o].itemName != undefined || selectedFunction[o].itemName != "SELECT") {
          for (var i in this.tblData) {
            if (this.tblData[i].FunctionName == selectedFunction[o].itemName) {
              data.push(this.tblData[i]);
            }
          }

          for (var j in this.selectedSBU) {
            if (this.selectedSBU[j].Functionid == selectedFunction[o].id) {
              dataselectedSBU.push(this.selectedSBU[j])
            }
          }

        }

      }
      this.selectedSBU = JSON.parse(JSON.stringify(dataselectedSBU))
      this.dropdownListSBU = JSON.parse(JSON.stringify(this.selectedSBU))
      var SBU = [];
      this.SBUDistinctModel = [];
      this.SBUDistinct = [];
      for (var j in this.selectedSBU) {
        if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
          SBU.push(this.selectedSBU[j].itemName)
        }
      }
      for (var o in SBU) {
        this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
      }
      this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

      this.tblData = data

    }
    this.onFilterChange(this.SBUDistinctModel, this.statusSelected, this.roleSelected, this.SearchText)
  }
 
  onFilterChange(selectedSBU, status, selectedRole, SearchText) {

    //var data = JSON.parse(JSON.stringify(this.tblData));

    var data = [];
    if (this.selectedFunction.length == this.dropdownListFunction.length) {
      this.tblData = this.tblDataNew
    }

    if (selectedRole.length < this.dropdownListRole.length) {
      for (var o in selectedRole) {
        if (selectedRole[o].itemName != '' || selectedRole[o].itemName != undefined || selectedRole[o].itemName != "SELECT") {
          for (var i in this.tblData) {
            if (this.tblData[i].Role == selectedRole[o].itemName) {
              data.push(this.tblData[i]);
            }
          }
        }
      }
    }
    else {
      data = this.tblData;
     // data = [];
    }
    var dataStatus = [];

    if (status.length < this.dropdownListStatus.length) {
      for (var o in status) {
        if (status[o].itemName != '' || status[o].itemName != undefined || status[o].itemName != "SELECT") {
          for (var i in data) {
            if (data[i].StatusText == status[o].itemName) {
              dataStatus.push(data[i]);
            }
          }
        }
      }
    }
    else {
      dataStatus = data;
      //dataStatus = [];
    }

    var dataSBU = [];

    if (selectedSBU.length < this.dropdownListSBU.length) {
      for (var o in selectedSBU) {
        if (selectedSBU[o].itemName != '' || selectedSBU[o].itemName != undefined || selectedSBU[o].itemName != "SELECT") {
          for (var i in dataStatus) {
            if (dataStatus[i].LOB == selectedSBU[o].itemName) {
              dataSBU.push(dataStatus[i]);
            }
          }
        }
      }
    }
    else {
        dataSBU = dataStatus;
        // dataSBU = [];
    }
    if (SearchText != '' && SearchText != undefined) {
      if (SearchText.length > 2) {
        const filterValue = SearchText.toLowerCase();
        this.SearchText = filterValue;
        for (var o in dataSBU) {
          if (dataSBU[o].Name == null)
            dataSBU[o].Name = "";
        }
        dataSBU = dataSBU.filter(option => option.Name.toLowerCase().includes(filterValue));
        //data = data.filter(option => option.Name.toLowerCase().includes(filterValue));
      }
    }
    this.FilterData = dataSBU;
    for (var o in this.filters.dtStatusTiles) {
      if (this.filters.dtStatusTiles[o].Type == 'Pending for Self Assessment') {
        this.SelfAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Assessment Not Initiated') {
        this.NotInitiatedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Pending for Manager Assessment') {
        this.ManagerAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Pending for Reviewer Assessment') {
        this.ReviewerAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Review Pending with ' + this.getTeamName()) {
        this.DisPendingWithSENumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Pending with C&B') {
        this.CompletedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Review Pending with Corporate Planning') {
        this.DisPendingWithCPNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Appraisal Published') {
        this.AppraisalPublishedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Type == 'Appraisal Publish Pending') {
        this.AppraisalPendingNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
    }

    //if (this.FilterData.length == 0) {
    //  this.dialog.open(DialogDataExampleDialog, {
    //    data: "No record found for the selected filter(s)."
    //  });
    //  return;
    //}
  }
  RefreshCount(StatusID: string): number {
    var data = JSON.parse(JSON.stringify(this.FilterData));
    if (StatusID != '' && StatusID != undefined && StatusID != "ALL") {
      if (StatusID == 'Review Pending with Corporate Planning') {
        data = data.filter((data: any) => data.StatusText.indexOf('Corporate Planning') != -1);
      }
      else if (StatusID == 'Review Pending with' + this.getTeamName()) {
        data = data.filter((data: any) => data.StatusText.indexOf(this.getTeamName()) != -1);
      }
      else {
        data = data.filter((data: any) => data.StatusText == StatusID);
      }
    }
    return data.length;
  }

  InitializeNumbers(): void {

    for (var o in this.filters.dtStatusTiles) {
      if (this.filters.dtStatusTiles[o].Stage == 'Self Assessment') {
        this.SelfAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Not Initiated') {
        this.NotInitiatedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Manager Assessment') {
        this.ManagerAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Reviewer Assessment') {
        this.ReviewerAssessmentNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Review Pending with '+this.getTeamName()) {
        this.DisPendingWithSENumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Completed') {
        this.CompletedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Review Pending with Corporate Planning') {
        this.DisPendingWithCPNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Appraisal Published') {
        this.AppraisalPublishedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
      if (this.filters.dtStatusTiles[o].Stage == 'Appraisal Pending') {
        this.AppraisalPendingNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Type);
      }
    }

    //this.SelfAssessmentNumber = this.filters.dtStatusTiles.SelfAssessmentNumber;
    //this.ManagerAssessmentNumber = this.filters.dtStatusTiles.ManagerAssessmentNumber;
    //this.ReviewerAssessmentNumber = this.filters.dtStatusTiles.ReviewerAssessmentNumber;
    //this.DisPendingWithSENumber = this.filters.dtStatusTiles.DisPendingWithSENumber;
    //this.CompletedNumber = this.filters.dtStatusTiles.CompletedNumber;
    //this.DisPendingWithCPNumber = this.filters.dtStatusTiles.DisPendingWithCPNumber;
  }
   DownLoadExcel(): void {
     window.open("Appraisal-Dashboard.ashx?PSNO=" + this.userInfo.PS_No + "&PeriodId=" + this.selectedPeriod + "&AppType=" + this.userInfo.AppType);
  }

  //onTileClick(selectedSBU, status, selectedRole, SearchText) {
  //   this.statusSelected=[];
  //  this.statusSelected.push(status);
  //  this.onFilterChange(selectedSBU, this.statusSelected, selectedRole, SearchText);
  //}

  onTileClick(selectedFunction, selectedSBU, status, selectedRole, SearchText) {
    var isValid = true;
    this.statusSelected = [];
    for (var o in this.dropdownListStatus) {
      if (this.dropdownListStatus[o].itemName == status) {
        this.statusSelected.push({ "id": this.dropdownListStatus[o].id, "itemName": this.dropdownListStatus[o].itemName });
        break;
      }
    }
    this.onFunctionChange(selectedFunction)
    this.onFilterChange(selectedSBU, this.statusSelected, selectedRole, SearchText);
  }

  viewFinalRatingList() {

    this._router.navigate(['finalstrata-update']);
  }
  getTeamName() {
    return this.appService.TeamName(this.userInfo.AppType);
  }
}
