import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { FormControl } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';

@Component({
  selector: 'app-reviewer-view',
  templateUrl: './reviewer-view.component.html',
  styleUrls: ['./reviewer-view.component.less']
})
export class ReviewerViewComponent implements OnInit {
  @ViewChild('warningModel') warningModel: MzModalComponent
  @ViewChild('history') history: MzModalComponent
  @ViewChild('targethistory') targetHistory: MzModalComponent
  @ViewChild('parameterwiseData') parameterwiseData: MzModalComponent
/*  @ViewChild('h1computation') h1computation: MzModalComponent*/
  displayedColumns: string[] = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue'];
  //dataSource = ELEMENT_DATA;
  selectedPeriod: number;
  roleSelected: string;
  selectedPsno: number;
  selectedEmpText: string;
  userInfo: any;
  masterData: any;
  errorMessage: string;
  tblMetrics: any;
  showMetricsTable: boolean = false;
  showReporteeInfo: boolean = false;
  reporteeData: any;
  isSubmissionAllowed: boolean = false;
  searchTerm: FormControl = new FormControl();
  ReporteeSearchArray: any;
  ReporteeSearchArrayAll: any;
  dueDate: string = "";
  warningdata: string;
  action: string;
  DisagreementMessage: string;
  IsDBApplicable: number;
  IsAppraisalApplicable: number;
  IsSPIApplicable: number;
  Proratedvalue: string = '';
  TotalVCP: string = '';
  TotalPayable: string = '';
  AccountList: any;
  CompanyPerFormance: string = '';
  WeightedPercentage: string = '';
  TotalWeightedAverage: string = '';
  RevenueAchievement: string = '';
  NewJoineeMsg: string = '';
  NewJoinee: number = 0;
  NewJoineeCheckForAppraisal: number = 0;
  DisagreementData: any;
  MetricsDisagreementData: any;
  SPIAmount: string = '';
  targetHistoryArr: any = [];
  OtherParamArr: any = [];
  ManagerStartDoing: string = '';
  ManagerContinueDoing: string = '';
  ManagerStopDoing: string = '';
  showOtherMetricsTable: boolean = false;
  OtherParameterRatingMaster: any = [];
  PeriodType: string = 'Half-Yearly';
  OtherdisplayedColumns: string[] = ['ParameterName', 'DBWeightage', 'Target', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
  StrataTable: any = [];
  QuantitativePercentage: string = '';
  QualitativePercentage: string = '';
  showStratatable: boolean = false;
  NetAmount: string = '';
  PaidAmount: string = '';
  AppraisalStatus: number = 0;
  SalesIncentiveText: string = '';
  SalesIncentiveURL: string = '';
  AppType: string = '';
  EmpPlans: string = '';
  FeedbackDetails: any = [];
  FeedbackApplicable: boolean = false;
  IsFeedbackDetails: boolean = false;
  FeedbackdisplayedColumns: string[];
  PeriodTypeNew: string = 'Half-Yearly';
  GrowthKickerPercentage: string = '';
  FinalGrowthKickerPercentage: string = '';
  GrowthKickerValue: string = '';
  MosaicValue: string = '';
  MosaicStatus: string = '';
  MSAValue: string = '';
  MSAStatus: string = '';
  LDValue: string = '';
  LDStatus: string = '';
  TotalAmountPayable: string = '';
  DisPeriodArray: any = [];
  H1tblMetrics: any;
  H1tblIncentive: any;
  H1WeightedPercentage: string = '';
  H1UpdatedAchievement: string = '';
  show: boolean = false;
  PortfolioGrowth: string = '';
  TotalAmountPaid: string = '';
  RemainingPayable: string = '';
  SPIName: string = '';
  tblIncentive: any;
  GrowthKickerStatus: string = '';
  MosaicKickerValue: string = '';
  MSAKickerValue: string = '';
  LDKickerValue: string = '';
  UpdatedAchievement: string = '';
  FinalPayout: string = '';
  SalesIncentiveURLByCountry: string = '';
  SalesIncentiveTextByCountry: string = '';
  TotalManagerDBRating: number = 0;
  TotalReviewerDBRating: number = 0;
  TotalExecutiveDBRating: number = 0;
  PlanType: string = '';
  Plan: string = '';
  PlanTypeFlag: boolean = false;
  TotalWeightage: number = 0;
  TotalSelfRating: number = 0;
  TotalManagerRating: number = 0;
  TotalReviewerRating: number = 0;
  FinalReviewerAssessment: number = 0;
  AppraisalWeightageApplicable: number = 0;
  DbNAFlag: boolean = false;
  DBStatus: number = 0;
  DiscretionaryTextFlag: boolean = false;
  ManagerComponentTextFlag: boolean = false;
  H1overallachievement: any;
  parameterwiseDatarr: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblMetrics = [];
    this.selectedPsno = 0;
    this.roleSelected = "4";
    this.selectedPeriod = 8;
    this.ReporteeSearchArray = [];
    this.ReporteeSearchArrayAll = [];
    this.selectedEmpText = "Select";
    this.DisagreementMessage = '';
    this.IsDBApplicable = 0;
    this.IsAppraisalApplicable = 0;
    this.IsSPIApplicable = 0;
    this.AccountList = [];
    this.DisagreementData = {};
    this.MetricsDisagreementData = [];
    this.targetHistoryArr = [];
    this.OtherParamArr = [];
    this.FeedbackDetails = [];
    this.tblIncentive = [];
    this.PlanType = "0";
    this.AppraisalWeightageApplicable = 0;
    this.H1tblIncentive = [];
    this.parameterwiseDatarr = []
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();


    this.masterData = this.appService.GetMasterDetails();
    if (this.userInfo.AccessType[0].Showperiodyear == 'isFullyearly') {
      for (var o in this.masterData.dtPeriodDet) {
        if ((this.masterData.dtPeriodDet[o].PeriodID) % 2 == 0) {
          this.DisPeriodArray.push(this.masterData.dtPeriodDet[o]);
        }
      }
      this.masterData.dtPeriodDet = this.DisPeriodArray;
    }

    for (var o in this.masterData.dtReviewee) {
      var obj = {
        'Text': this.masterData.dtReviewee[o].Name.toString() + ' (' + this.masterData.dtReviewee[o].PSNo.toString() + ')',
        'PSNo': this.masterData.dtReviewee[o].PSNo,
        'DeputedBU': this.masterData.dtReviewee[o].DeputedBU,
      };
      this.ReporteeSearchArrayAll.push(obj);
      this.ReporteeSearchArray.push(obj);
    }


    this.roleSelected = "4";
    var ActualDataObj = {};
    ActualDataObj = this.appService.GetActualData();
    if (ActualDataObj == undefined) {
      this.selectedPeriod = this.appService.GetperiodId();
      this.selectedPsno = parseInt(this.appService.GetPsno());
    }
    else {
      this.selectedPeriod = ActualDataObj['PeriodID'];
      this.selectedPsno = ActualDataObj['PSNo'];
      this.appService.ResetSetActualData();
    }
    if (this.selectedPeriod == undefined) { this.selectedPeriod = 8; }

    var repArray = this.ReporteeSearchArrayAll.filter((reportee: any) => reportee.PSNo == this.selectedPsno);
    if (repArray.length > 0) {
      this.selectedEmpText = repArray[0].Text;
      this.onPeriodChange(this.selectedPeriod, repArray[0].DeputedBU);
    }
    else {
      this._router.navigate(['no-access']);
    }
  }
  onPeriodChange(id, Bu: string): void {
    this.TotalManagerDBRating = 0;
    this.TotalReviewerDBRating = 0;
    this.TotalExecutiveDBRating = 0;
    this.TotalWeightage = 0;
    this.TotalSelfRating = 0;
    this.TotalManagerRating = 0;
    this.TotalReviewerRating = 0;
    this.selectedPeriod = id;
    this.IsFeedbackDetails = false;
    this.DiscretionaryTextFlag = false;
    this.ManagerComponentTextFlag = false;
    if (this.selectedPeriod != 0 && id != "") {
        var dueArray = this.masterData.dtSBUPeriodDet.filter((due: any) => due.PeriodID == this.selectedPeriod && due.SBU == Bu && due.PSNo == this.selectedPsno);
      if (dueArray.length > 0 && (dueArray[0].ReviewerDeadline != undefined || dueArray[0].ReviewerDeadline != null)) {
        this.dueDate = dueArray[0].ReviewerDeadline.replace("T00:00:00", "");
      }
      else if (dueArray.length == 0 || dueArray[0].ReviewerDeadline == undefined || dueArray[0].ReviewerDeadline == null) {
        this.dueDate = '';
      }
    }
    if (this.selectedPsno != 0 && this.selectedPeriod != 0 && this.selectedEmpText.toUpperCase() != 'SELECT') {     
      this.GetMetricDetails();
/*      this.openH1computationModal(0);*/
    }
  }

  onEmpChange(id): void {
    this.selectedPsno = id;
    if (this.selectedPsno != 0 && this.selectedPeriod != 0 && this.selectedEmpText.toUpperCase() != 'SELECT') {
      this.GetMetricDetails();
/*      this.openH1computationModal(0);*/

    }
  }

  GetMetricDetails(): void {
    this.PlanTypeFlag = false;
    this.SalesIncentiveURLByCountry = '';
    if (this.selectedPsno != 0 && this.selectedPeriod != 0) {
      var that = this;
      this.reporteeData = this.masterData.dtReviewee.filter((reportee: any) => reportee.PSNo == that.selectedPsno);
      if (this.reporteeData.length > 0) {
        this.showReporteeInfo = true;
      }
      var UserData = {
        "PS_No": that.selectedPsno.toString(),
        "PeriodId": this.selectedPeriod.toString(),
        'Role': 'Reviewer',
          'UserType': '',
          "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.MetricsDetailsURL();
      this.appService.GetDataFromAPI(url, UserData)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            var YearTextArr = this.masterData.dtPeriodDet.filter((period: any) => period.PeriodID == this.selectedPeriod.toString());
            var YearText = YearTextArr[0].TargetUploadYearText;

            //this.SalesIncentiveText = 'Sales Incentive Plan ' + YearText;
            //this.SalesIncentiveURL = 'assets/images/SPMProcessDoc' + YearText + '.pdf';

            this.getOtherParametersData();
            this.tblMetrics = response.ResponseData.dtMertricsDet;
            this.tblIncentive = response.ResponseData.dtIncentiveDet;
            this.masterData.EmpPlans = response.ResponseData.EmpPlans;
            this.masterData.dtAccounts = response.ResponseData.dtAccounts;
            this.isSubmissionAllowed = response.ResponseData.isSubmissionAllowed;
            this.DisagreementMessage = response.ResponseData.DisagreementStatus;
            this.IsDBApplicable = response.ResponseData.IsDBApplicable;
            this.IsAppraisalApplicable = response.ResponseData.IsAppraisalApplicable;
            this.IsSPIApplicable = response.ResponseData.IsSPIApplicable;
            this.Plan = this.tblMetrics[0].ROLE;
            if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].StatusID == 6) && this.tblMetrics[0].isReviewerAndExecutive == 1) {
              this.tblMetrics[0].CopyReviewerToExecutive = 1;
            }
            if (this.tblMetrics.length > 0) {
              this.PlanType = this.tblMetrics[0].PlanType;
              if (this.PlanType == "1") {
                //this.EmpPlans = response.ResponseData.EmpPlans;
                //this.AccountList = response.ResponseData.dtAccounts;
                this.PlanTypeFlag = true;
              }
              else {
                this.PlanTypeFlag = false;
                this.showMetricsTable = true;
              }
              this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue'];
              if (this.tblMetrics[0].StatusID == 5) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments'];
              }
              else if (this.tblMetrics[0].StatusID == 6 || this.tblMetrics[0].StatusID == 7 || this.tblMetrics[0].StatusID == 15) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'SelfComments', 'ManagerComments', 'ReviewerComments'];
              }
              else if (this.tblMetrics[0] != undefined && this.tblMetrics[0].StatusID == 14) {
                this.displayedColumns = ['SalesMetricName', 'Weightage', 'Target', 'Threshold', 'Actual', 'AchievementValue', 'AchievementWeighted'];
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanText != '') && (this.tblMetrics[0].IncentivePlanURL != '') && (this.tblMetrics[0].IncentivePlanURL != null)) {
                this.SalesIncentiveText = this.tblMetrics[0].IncentivePlanText + YearText;
                this.SalesIncentiveURL = this.tblMetrics[0].IncentivePlanURL + YearText + '.pdf';
              }
              if (this.tblMetrics[0] != undefined && (this.tblMetrics[0].IncentivePlanTextByCountry != '')  && (this.tblMetrics[0].IncentivePlanURLByCountry != '' && this.tblMetrics[0].IncentivePlanURLByCountry != null)) {
                this.SalesIncentiveTextByCountry = this.tblMetrics[0].IncentivePlanTextByCountry + YearText;
                this.SalesIncentiveURLByCountry = this.tblMetrics[0].IncentivePlanURLByCountry + YearText + '.pdf';
              }
              this.PeriodTypeNew = this.tblMetrics[0].PeriodType;
              if (this.PeriodTypeNew == 'Half-Yearly') {
                this.GetFeedbackDetails();
              }
              this.AccountList = response.ResponseData.dtAccounts;
              this.EmpPlans = response.ResponseData.EmpPlans;
              this.SPIAmount = this.tblIncentive[0].SPIAmount;
              this.TotalPayable = this.tblIncentive[0].PayoutAmount;
              this.Proratedvalue = this.tblIncentive[0].ProRatedValue;
              this.NetAmount = this.tblIncentive[0].NetAmount;
              this.PaidAmount = this.tblIncentive[0].PaidAmount;
              this.TotalVCP = this.appService.AddPercentageSign(this.tblIncentive[0].TotalVCP);
              this.CompanyPerFormance = this.appService.AddPercentageSign(this.tblIncentive[0].CompanyPerformance);
              this.NewJoinee = this.tblMetrics[0].NewJoinee;
              this.AppType = this.tblMetrics[0].AppType;
              this.NewJoineeCheckForAppraisal = this.tblMetrics[0].NewJoineeCheckForAppraisal;
              this.GrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].GrowthKickerPercentage);
              this.FinalGrowthKickerPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].FinalGrowthKickerPercentage);
              this.GrowthKickerValue = this.tblIncentive[0].GrowthKickerValue;
              this.MosaicValue = this.tblIncentive[0].MosaicValue;
              this.MosaicStatus = this.tblIncentive[0].MosaicStatus;
              this.MSAValue = this.tblIncentive[0].MSAValue;
              this.MSAStatus = this.tblIncentive[0].MSAStatus;
              this.LDValue = this.tblIncentive[0].LDValue;
              this.LDStatus = this.tblIncentive[0].LDStatus;
              this.TotalAmountPayable = this.tblIncentive[0].TotalAmountPayable;
              this.PortfolioGrowth = this.tblIncentive[0].PortfolioGrowth;
              this.TotalAmountPaid = this.tblIncentive[0].TotalAmountPaid;
              this.RemainingPayable = this.tblIncentive[0].RemainingPayable;
              this.SPIName = this.tblIncentive[0].SPIName;
              this.GrowthKickerStatus = this.tblIncentive[0].GrowthKickerStatus;
              this.MosaicKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MosaicKickerValue);
              this.MSAKickerValue = this.appService.AddPercentageSign(this.tblIncentive[0].MSAKickerValue);
              this.LDKickerValue =  this.tblIncentive[0].LDKickerValue;
              this.UpdatedAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].UpdatedAchievement);
              this.FinalPayout = this.appService.AddPercentageSign(this.tblIncentive[0].FinalPayout);
              for (var o in this.tblMetrics) {
                if (this.tblMetrics[o].SalesMetricID == 38) {
                  this.DiscretionaryTextFlag = true;
                }
                if (this.tblMetrics[o].SalesMetricID == 79) {
                  this.ManagerComponentTextFlag = true;
                }
              }

              if (this.NewJoinee == 1) {
                this.NewJoineeMsg = "New Joinee (Joined post " + this.tblMetrics[0].DOJCheckDate + ")";
                //this.RevenueAchievement = this.appService.GetRevenueAchievement(this.tblMetrics, parseFloat(this.CompanyPerFormance));
                this.RevenueAchievement = this.appService.AddPercentageSign(this.tblIncentive[0].RevenueAcievement);
                this.TotalWeightedAverage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentage);
                //this.WeightedPercentage = this.appService.GetWeightedAverage(parseFloat(this.RevenueAchievement.replace('%', '')), parseFloat(this.TotalWeightedAverage.replace('%', '')));
              }
              else {
                this.WeightedPercentage = this.appService.AddPercentageSign(this.tblIncentive[0].WeightedPercentageDisplay);
              }
              //}
            }
            else {
              this.showMetricsTable = false;
              this.dialog.open(DialogDataExampleDialog, {
                data: "No record found."
              });
              return;
            }
          }
        }
          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
    }
  }



  ChangeRole(id) {
    if (id != "") {
      this.roleSelected = id;

      switch (this.roleSelected) {

        case "2": this._router.navigate(['self-assessment']);
          break;
        case "3": this._router.navigate(['manager-view']);
          break;
        case "4": this._router.navigate(['reviewer-view']);
          break;
        case "1": this._router.navigate(['bulk-upload']);
          break;
        case "0": this._router.navigate(['bulk-upload']);
          break;
        case "6": this._router.navigate(['reject-assessment/6']);
          break;
        case "7": this._router.navigate(['reject-assessment/7']);
          break;
        default: this._router.navigate(['self-assessment']);
      }
    }
  }

  SubmitChanges(action: string, ShowMessage: string): void {
    var FlagforReviewerAssessment = true;
    var FlagforReviewerWeightage = true;
    for (var o in this.tblMetrics) {
      if (this.tblMetrics[o].SalesMetricID == 38) {
        if (this.tblMetrics[o].ReviewerAssessmentText == null || this.tblMetrics[o].ReviewerAssessmentText == undefined) {
          FlagforReviewerAssessment = false;
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please enter a valid value for Reviewer Assessment for Discretionary Component"
          });
          return;
        }
        else {
          if (this.tblMetrics[o].ReviewerAssessmentText < 0 || this.tblMetrics[o].ReviewerAssessmentText > 100) {
            FlagforReviewerAssessment = false;
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please enter a value between 0 and 100  for Reviewer Assessment for Discretionary Component"
            });
            return;
          }
        }
      }
      if (this.tblMetrics[o].SalesMetricID == 79) {
        if (this.tblMetrics[o].ReviewerAssessmentText == null || this.tblMetrics[o].ReviewerAssessmentText == undefined) {
          FlagforReviewerAssessment = false;
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please enter a valid value for Reviewer Assessment for Manager Component"
          });
          return;
        }
        else {
          if (this.tblMetrics[o].ReviewerAssessmentText < 0 || this.tblMetrics[o].ReviewerAssessmentText > 100) {
            FlagforReviewerAssessment = false;
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please enter a value between 0 and 100  for Reviewer Assessment for Manager Component"
            });
            return;
          }
        }
      }
    }
    if (FlagforReviewerAssessment) {
      this.action = action;
      var otherparameterfiltered = [];
      var ReviewerWeightage;
      var ReviewerAssessment;
      for (var i in this.OtherParamArr) {
        if (this.OtherParamArr[i].ReviewerWeightage == null) {
          ReviewerWeightage = this.OtherParamArr[i].ReviewerWeightage;
        }
        else {
          ReviewerWeightage = this.OtherParamArr[i].ReviewerWeightage.toString();
        }
        if (this.OtherParamArr[i].isAppraisal == 1) {
          ReviewerAssessment = this.OtherParamArr[i].ReviewerAssessment;
        }
        else {
          ReviewerAssessment = 0;
        }
        if (this.OtherParamArr[i].isTotal == 0) {
          otherparameterfiltered.push({
            'ParameterId': this.OtherParamArr[i].ParameterId,
            'AssessmentValue': ReviewerAssessment,
            'Comments': this.OtherParamArr[i].ReviewerComments,
            'Weightage': ReviewerWeightage
          });
        }
      };
      var UserData = {
        "PS_No": this.userInfo.PS_No,//this.userInfo.PS_No,
        "action": action,
        "role": "Reviewer",
        "Comments": this.tblMetrics[0].ReviewerRemarks,
        "CopyReviewerToExecutive": this.tblMetrics[0].CopyReviewerToExecutive,
        "dtMertricsDet": this.tblMetrics,
        "PeriodId": this.selectedPeriod,
        "tbl": otherparameterfiltered,
        "ThingsManagerContinueDoing": this.ManagerContinueDoing,
        "ThingsManagerStopDoing": this.ManagerStopDoing,
        "ThingsManagerStartDoing": this.ManagerStartDoing,
        "AppType": this.userInfo.AppType,
        "FinalReviewerAssessment": this.FinalReviewerAssessment,
        "IsDBApplicable": this.IsDBApplicable,
        "IsAppraisalApplicable": this.IsAppraisalApplicable,
        "IsSPIApplicable": this.IsSPIApplicable
      }
      ///////check if the weghtages are within range
      var msg = [];
      if (this.selectedPeriod >= 7) {
        for (var o in this.OtherParamArr) {
          if (this.OtherParamArr[o].ReviewerWeightage != null && this.OtherParamArr[o].ReviewerWeightage != undefined && this.OtherParamArr[i].isTotal == 0 && this.OtherParamArr[o].isDB == 1) {
            if (this.OtherParamArr[o].ReviewerWeightage < 0 || this.OtherParamArr[o].ReviewerWeightage > this.OtherParamArr[o].Value) {
              FlagforReviewerWeightage = false;
              msg.push("Please enter valid db rating values between 0 and " + this.OtherParamArr[o].Value + " for " + this.OtherParamArr[o].ParameterName);

            }
          }
        }
      }
      if (msg.length > 0) {
        this.dialog.open(DialogDataExampleDialog, {
          data: msg
        });
        return;
      }
      if (this.userInfo.AppType == 2) {
        this.ManagerContinueDoing = "NA"
        this.ManagerStartDoing = "NA"
        this.ManagerStopDoing = "NA"
        this.FinalReviewerAssessment = 3
      }
      if (FlagforReviewerWeightage) {
      if (action != "SAV") {
        if (!this.appService.CheckIfReviewerAssessmentNoAreFilled(this.tblMetrics) && this.PlanTypeFlag == false) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please enter valid assessment values"
          });
          return;
        }
        if (this.showOtherMetricsTable) {
          if (!this.appService.CheckOtherParametersReviewer(this.OtherParamArr)) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please select reviewer rating"
            });
            return;
          }
          //if (!this.appService.CheckOtherParametersForReviewerComments(this.OtherParamArr)) {
          //  this.dialog.open(DialogDataExampleDialog, {
          //    data: "Please enter reviewer assessment comments."
          //  });
          //  return;
          //}
          if (this.selectedPeriod >= 7) {
            if (this.IsDBApplicable == 1) {
              if (!this.appService.CheckOtherParametersForReviewerWeightage(this.OtherParamArr)) {
                this.dialog.open(DialogDataExampleDialog, {
                  data: "Please enter reviewer db rating."
                });
                return;
              }
            }  
          }
          if (this.IsAppraisalApplicable == 1) {
            if (!this.appService.CheckFinalReviewerAssessment(this.FinalReviewerAssessment)) {
              this.dialog.open(DialogDataExampleDialog, {
                data: "Please select Proposed Appraisal Rating"
              });
              return;
            }
          }
        }
        //else {
        var showWarning = false;
        //for (var o in this.tblMetrics) {
        //  if (this.tblMetrics[o].ReviewerComments == undefined || this.tblMetrics[o].ReviewerComments == null || this.tblMetrics[o].ReviewerComments.trim() == '') {
        //    showWarning = true;
        //    break;
        //  }
        //}
        if (this.tblMetrics[0].ReviewerRemarks == undefined || this.tblMetrics[0].ReviewerRemarks == null || this.tblMetrics[0].ReviewerRemarks.trim() == '') {
          //this.dialog.open(DialogDataExampleDialog, {
          //  data:"Please enter overall feedback comments"
          //});
          //return;
        }
        else {
          this.warningdata = "You are about to submit the assessment. You will not be able to make any changes once it is submitted.";
          this.warningModel.openModal();
          return;
        }
      }
      if (ShowMessage == 'Y') {
        this.appService.SetLoadingShow(true);
      }
      var url = this.configs.UpdateMetricsDetailsURL();
      this.appService.GetDataFromAPI(url, UserData)
        .then(response => {
          if (ShowMessage == 'Y') {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
              this.tblMetrics = [];
              this.GetMetricDetails();
/*              this.openH1computationModal(0);*/

              //this.tblMetrics =
              //this.showMetricsTable = true;
            }
          }
        }
          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
      }
    }
  }
  onEmpChangeFilter(value):any {
    const filterValue = value.toLowerCase();
    this.ReporteeSearchArray = JSON.parse(JSON.stringify(this.ReporteeSearchArrayAll));
    this.ReporteeSearchArray = this.ReporteeSearchArrayAll.filter(option => option.Text.toLowerCase().includes(filterValue));
  }
  optionSelected(value) {
    for (var o in this.ReporteeSearchArrayAll) {
      if (this.ReporteeSearchArrayAll[o].Text.trim() == value.option.value.trim()) {
        this.selectedPsno = this.ReporteeSearchArrayAll[o].PSNo;
        this.onEmpChange(this.selectedPsno);
        break;
      }
    }
  }

  onBackClick(): void {
    this._router.navigate(['reportee-view/10']);
  }

  onCancelClick(): void {
    this.warningModel.closeModal();
  }

  onSubmitClick(): void {
    var otherparameterfiltered = [];
    var ReviewerWeightage;
    var ReviewerAssessment;
    for (var i in this.OtherParamArr) {
        if (this.OtherParamArr[i].ReviewerWeightage == null) {
          ReviewerWeightage = this.OtherParamArr[i].ReviewerWeightage;
        }
        else {
          ReviewerWeightage = this.OtherParamArr[i].ReviewerWeightage.toString();
      }
      if (this.OtherParamArr[i].isAppraisal == 1) {
        ReviewerAssessment = this.OtherParamArr[i].ReviewerAssessment;
      }
      else {
        ReviewerAssessment = 0;
      }
      if (this.OtherParamArr[i].isTotal == 0) {
        otherparameterfiltered.push({
          'ParameterId': this.OtherParamArr[i].ParameterId,
          'AssessmentValue': ReviewerAssessment,
          'Comments': this.OtherParamArr[i].ReviewerComments,
          'Weightage': ReviewerWeightage
        });
      }
    };
    var UserData = {
      "PS_No": this.userInfo.PS_No,//this.userInfo.PS_No,
      "action": this.action,
      "role": "Reviewer",
      "CopyReviewerToExecutive": this.tblMetrics[0].CopyReviewerToExecutive,
      "Comments": this.tblMetrics[0].ReviewerRemarks,
      "dtMertricsDet": this.tblMetrics,
      "PeriodId": this.selectedPeriod,
      "tbl": otherparameterfiltered,
      "ThingsManagerContinueDoing": this.ManagerContinueDoing,
      "ThingsManagerStopDoing": this.ManagerStopDoing,
      "ThingsManagerStartDoing": this.ManagerStartDoing,
      "AppType": this.userInfo.AppType,
      "FinalReviewerAssessment": this.FinalReviewerAssessment,
      "IsDBApplicable": this.IsDBApplicable,
      "IsAppraisalApplicable": this.IsAppraisalApplicable,
      "IsSPIApplicable": this.IsSPIApplicable
    }
    this.warningModel.closeModal();
    this.appService.SetLoadingShow(true);
    var url = this.configs.UpdateMetricsDetailsURL();
    this.appService.GetDataFromAPI(url, UserData)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.tblMetrics = [];
          this.GetMetricDetails();
/*          this.openH1computationModal(0);*/
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  onViewAction(SalesMetricID, PSNo, PeriodID) {

    var ActualDataList = [];
    for (var o in this.tblMetrics) {
      //if (this.tblMetrics[o].SalesMetricID != 2) {
        var metric = {
          'MetricName': this.tblMetrics[o].SalesMetricName,
          'MetricId': this.tblMetrics[o].SalesMetricID,
          'Accounts': []
        }
        ActualDataList.push(metric);
      //}
    }

    var data = {
      'ActualDataList': ActualDataList,
      'SalesMetricID': SalesMetricID,
      'PSNo': PSNo,
      'PeriodID': PeriodID,
      'Path': 'reviewer-view',
      'Plan': this.Plan,

    };
    this.appService.SetActualData(data);
    this.SubmitChanges('SAV', 'N');
    this._router.navigate(['actual-details']);
  }
  openhistoryModal() {
    var data = {
      "ApprovalId": this.tblMetrics[0].ApprovalID,
      "ActionType": 0
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetDisagreementDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.DisagreementData = response.ResponseData;
          if (this.DisagreementData.DisAgreedBy != undefined) {
            this.history.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closehistoryModal() {
    this.history.closeModal();
  }
  GettargetHistory() {
    var data = {
      psNo: this.tblMetrics[0].PSNo,
        PeriodId: this.selectedPeriod,
        AppType: this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetHistoryURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.targetHistoryArr = response.ResponseData;
          if (this.targetHistoryArr.length > 0) {
            this.targetHistory.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }
  closeTargethistoryModal() {
    this.targetHistory.closeModal();
  }
  DownLoadTargets(): void {
    this.DownLoadTargetsApi(this.tblMetrics[0].PSNo, this.selectedPeriod, 'Reviewer', 'Released', 'Final', this.AppType);
  }
  DownLoadTargetsApi(Psno, FYPeriodId, Role, TargetStatus, TargetLetterType, AppType): void {

    var data = {
      "PSNO": Psno,
      "PeriodId": FYPeriodId,
      "Role": Role,
      "TargetStatus": TargetStatus,
      "TargetLetterType": TargetLetterType,
      "AppType": AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetLetterURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.appService.DownloadTargetletter(response.ResponseData);
        }
      });


  }
  getOtherParametersData(): void {
    this.DbNAFlag = false;
    this.AppraisalStatus = 0;
    this.PeriodType = 'Half-Yearly';
    this.showOtherMetricsTable = false;
    this.showStratatable = false;
    this.DBStatus = 0;
    var data = {
      'PSNO': this.selectedPsno,
      'RoleId': 1,
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetOtherParametersDataURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.AppraisalStatus = 0;
          if (this.selectedPeriod >= 7) {
            this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'Target', 'SelfComments', 'SelfAssessment'];
          }
          else {
            this.OtherdisplayedColumns = ['ParameterName',  'SelfComments', 'SelfAssessment'];
          }


          this.OtherParamArr = response.ResponseData.dtAssessmentData;
          if (this.OtherParamArr.length > 0) {
            this.PeriodType = this.OtherParamArr[0].PeriodType;
            this.AppraisalWeightageApplicable = this.OtherParamArr[0].AppraisalWeightageApplicable;   
              this.showOtherMetricsTable = true;
            
          }
          if (this.OtherParamArr.length > 0) {
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].Value == 0 && this.OtherParamArr[o].isTotal == 0 && this.selectedPeriod >= 7) {
                this.DbNAFlag = true;
              }
            }
          }
          this.OtherParameterRatingMaster = response.ResponseData.dtOtherParameterRatingMaster;
          var OtherParameterSMA = response.ResponseData.dtOtherParameterSalesMetricApproval;
          this.StrataTable = response.ResponseData.StrataRangeMaster;
          var percentagemaster = response.ResponseData.dtOtherParameterWeightageMaster;
          this.FinalReviewerAssessment = response.ResponseData.FinalReviewerAssessment;
          for (var o in percentagemaster) {
            {
              this.QuantitativePercentage = percentagemaster[o].QuantitativeWeightage + '%';
              this.QualitativePercentage = percentagemaster[o].QualitativeWeightage + '%';
            }
          }
          if (this.OtherParamArr.length > 0) {
            this.TotalWeightage = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalWeightage = this.TotalWeightage + this.OtherParamArr[o].Value;
              }
            }
            this.TotalManagerDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalManagerDBRating = this.TotalManagerDBRating + this.OtherParamArr[o].ManagerWeightage;
              }
            }
            this.TotalReviewerDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalReviewerDBRating = this.TotalReviewerDBRating + this.OtherParamArr[o].ReviewerWeightage;
              }
            }
            this.TotalExecutiveDBRating = 0;
            for (var o in this.OtherParamArr) {
              if (this.OtherParamArr[o].isTotal == 0) {
                this.TotalExecutiveDBRating = this.TotalExecutiveDBRating + this.OtherParamArr[o].ExecutiveWeightage;
              }
            }
            this.TotalSelfRating = 0;
            this.TotalManagerRating = 0;
            this.TotalReviewerRating = 0;
            if (this.AppraisalWeightageApplicable == 1) {
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalSelfRating = this.TotalSelfRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalManagerRating = this.TotalManagerRating / 100;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment * this.OtherParamArr[o].Value);
                }
              }
              this.TotalReviewerRating = this.TotalReviewerRating / 100;
            }
            else {
              var count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalSelfRating = this.TotalSelfRating + (this.OtherParamArr[o].SelfAssessment);
                  count = count + 1;
                }
              }
              this.TotalSelfRating = parseFloat((this.TotalSelfRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalManagerRating = this.TotalManagerRating + (this.OtherParamArr[o].ManagerAssessment);
                  count = count + 1;
                }
              }
              this.TotalManagerRating = parseFloat((this.TotalManagerRating / count).toFixed(2));
              count = 0;
              for (var o in this.OtherParamArr) {
                if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
                  this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment);
                  count = count + 1;
                }
              }
              this.TotalReviewerRating = parseFloat((this.TotalReviewerRating / count).toFixed(2));
            }
          }

          if (OtherParameterSMA.length > 0) {
            this.AppraisalStatus  = OtherParameterSMA[0].StatusID;

            this.ManagerContinueDoing = OtherParameterSMA[0].ThingsManagerContinueDoing;
            this.ManagerStartDoing = OtherParameterSMA[0].ThingsManagerStartDoing;
            this.ManagerStopDoing = OtherParameterSMA[0].ThingsManagerStopDoing;
          }
          this.DBStatus = 0;
          var DBApproval = response.ResponseData.dtDBApproval;
          if (DBApproval.length > 0) {
            this.DBStatus = DBApproval[0].StatusID;
          }
          if (this.selectedPeriod >= 7) {
            if (this.IsDBApplicable == 1) {
              if (this.userInfo.AppType == 1) {

                if (this.AppraisalStatus === 15 || this.AppraisalStatus === 6) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage'];
                }
                if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.DBStatus != 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage'];
                }
                if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.DBStatus == 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ManagerWeightage', 'ReviewerComments', 'ReviewerAssessment', 'ReviewerWeightage', 'ExecutiveWeightage', 'ExecutiveComments'];
                }
                if (this.AppraisalStatus === 5 || this.AppraisalStatus === 4 || this.AppraisalStatus === 12 || this.AppraisalStatus === 13) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments', 'SelfAssessment'];
                }
              }
              else {
                if (this.AppraisalStatus === 15 || this.AppraisalStatus === 6) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'ManagerComments', 'ManagerWeightage', 'ReviewerComments', 'ReviewerWeightage'];
                }
                if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.DBStatus != 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'ManagerComments', 'ManagerWeightage', 'ReviewerComments', 'ReviewerWeightage'];
                }
                if ((this.AppraisalStatus === 14 || this.AppraisalStatus === 7) && this.DBStatus == 14) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'ManagerComments', 'ManagerWeightage', 'ReviewerComments', 'ReviewerWeightage'];
                }
                if (this.AppraisalStatus === 5 || this.AppraisalStatus === 4 || this.AppraisalStatus === 12 || this.AppraisalStatus === 13) {
                  this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage', 'SelfComments'];
                }
              }
            }
            else {
              if (this.AppraisalStatus === 15 || this.AppraisalStatus === 6 || this.AppraisalStatus === 14 || this.AppraisalStatus === 7) {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment',  'ReviewerComments', 'ReviewerAssessment'];
              }
              if (this.AppraisalStatus === 5 || this.AppraisalStatus === 4 || this.AppraisalStatus === 12 || this.AppraisalStatus === 13) {
                this.OtherdisplayedColumns = ['ParameterName', 'DBWeightage',  'SelfComments', 'SelfAssessment'];
              }
            }
          }
          else {
            if (this.AppraisalStatus === 15 || this.AppraisalStatus === 6 || this.AppraisalStatus === 14 || this.AppraisalStatus === 7) {
              this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment', 'ManagerComments', 'ManagerAssessment', 'ReviewerComments', 'ReviewerAssessment'];
            }
            if (this.AppraisalStatus === 5 || this.AppraisalStatus === 4 || this.AppraisalStatus === 12 || this.AppraisalStatus === 13) {
              this.OtherdisplayedColumns = ['ParameterName', 'SelfComments', 'SelfAssessment'];
            }
          }
          if (this.AppraisalStatus === 14) {
            this.showStratatable = true;
          }
          if (this.tblMetrics.length == 0) {
            this.showOtherMetricsTable = false;
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
    }
    GetFeedbackDetails(): void {
        this.showStratatable = false;
        this.showOtherMetricsTable = false;
        var data = {
            "PSNo": this.selectedPsno,
            "PeriodId": this.selectedPeriod,
            "AppType": this.userInfo.AppType,
            "LoggedInPSNo": this.userInfo.PS_No
        };
        this.appService.SetLoadingShow(true);
        var url = this.configs.GetFeedbackDetailsURL();
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.FeedbackdisplayedColumns = ['FeedbackName', 'CNBFeedback', 'SalesHeadFeedback'];
                    this.FeedbackDetails = response.ResponseData.dtFeedbackDetails;
                    this.IsFeedbackDetails = true;
                    if (this.FeedbackDetails.length == 0) {
                        this.IsFeedbackDetails = false;
                    }
                }
            });
  }

  AddDBRatingValues(i, value): void {
    if (this.CheckNegativeNumbersForDiscretionaryComponent(value, this.OtherParamArr[i].Value)) {
      this.OtherParamArr[i].ReviewerWeightage = this.OtherParamArr[i].Value;
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please enter valid DB rating values between 0 and " + this.OtherParamArr[i].Value + " for " + this.OtherParamArr[i].ParameterName
      });
      return;
    }
    else {
      this.TotalReviewerDBRating = 0;
      for (var o in this.OtherParamArr) {
        if (this.OtherParamArr[o].isTotal == 0) {
          this.TotalReviewerDBRating = this.TotalReviewerDBRating + this.OtherParamArr[o].ReviewerWeightage;
        }
      }
      for (var o in this.OtherParamArr) {
        if (this.OtherParamArr[o].isTotal == 1) {
          this.OtherParamArr[o].ReviewerWeightage = this.TotalReviewerDBRating;
        }
      }
    }
  }

  AddRatingValues(): void {
    this.TotalReviewerRating = 0;
    if (this.AppraisalWeightageApplicable == 1) {
      for (var o in this.OtherParamArr) {
        if (this.OtherParamArr[o].isTotal == 0) {
          this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment * this.OtherParamArr[o].Value);
        }
      }
      this.TotalReviewerRating = this.TotalReviewerRating / 100;
    }
    else {
      var count = 0;
      for (var o in this.OtherParamArr) {
        if (this.OtherParamArr[o].isTotal == 0 && this.OtherParamArr[o].isAppraisal == 1) {
          this.TotalReviewerRating = this.TotalReviewerRating + (this.OtherParamArr[o].ReviewerAssessment);
          count = count + 1;
        }
      }
      this.TotalReviewerRating = parseFloat((this.TotalReviewerRating / count).toFixed(2));
    }
  }

  TogggleCheckBox() {
    if (this.tblMetrics[0].isReviewerAndExecutive == 1)
      this.tblMetrics[0].isReviewerAndExecutive = 0;
    else
      this.tblMetrics[0].isReviewerAndExecutive = 1;
  }
  CheckNegativeNumbersForDiscretionaryComponent(value, Maxvalue) {
    return this.appService.CheckNegativeNumbersForDiscretionaryComponent(value, Maxvalue);
  }

  GetParameterwiseData(salesMetricId) {
    var data = {
      psNo: this.selectedPsno,
      PeriodId: this.selectedPeriod,
      AppType: this.userInfo.AppType,
      SalesMetricsID: salesMetricId
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetParameterwiseURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.parameterwiseDatarr = response.ResponseData;
          if (this.parameterwiseDatarr.length > 0) {
            this.parameterwiseData.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  closeparameterwiseDataModal() {
    this.parameterwiseData.closeModal();
  }
  //openH1computationModal(type: any) {
  //  var data = {
  //    "PS_No": this.selectedPsno.toString(),
  //    "PeriodId": '11',
  //    'Role': 'Employee',
  //    'UserType': '',
  //    "AppType": this.userInfo.AppType
  //  };
  //  this.appService.SetLoadingShow(true);
  //  var url = this.configs.GetH1MetricsDetailsURL();
  //  this.appService.GetDataFromAPI(url, data)
  //    .then(response => {
  //      this.appService.SetLoadingShow(false);
  //      if (response.ResponseCode == this.configs.RetCodeFailure()) {
  //        this.dialog.open(DialogDataExampleDialog, {
  //          data: response.ResponseData
  //        });
  //      }
  //      else {
  //        this.H1tblMetrics = response.ResponseData.dtMertricsDet;
  //        this.H1tblIncentive = response.ResponseData.dtIncentiveDet;
  //        this.H1WeightedPercentage = this.appService.AddPercentageSign(this.H1tblIncentive[0].WeightedPercentage);
  //        this.H1UpdatedAchievement = this.appService.AddPercentageSign(this.H1tblIncentive[0].UpdatedAchievement);
  //        this.H1overallachievement = this.appService.AddPercentageSign(((this.tblIncentive[0].WeightedPercentage) + (this.H1tblIncentive[0].UpdatedAchievement)) / 2);

  //        if (type == 1) {
  //          if (this.H1tblMetrics.length > 0) {
  //            this.h1computation.openModal();
  //          }
  //          else {
  //            this.dialog.open(DialogDataExampleDialog, {
  //              data: "No data available"
  //            });
  //          }
  //        }

  //      }
  //    }
  //      ,
  //      error => {
  //        this.errorMessage = <string>error;
  //      });

  //}
}

