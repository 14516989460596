import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'no-access',
  templateUrl: './noaccess.component.html',
})
export class NoAccessComponent {

    Message: string = 'You do not have access to the requested resource.';
    ShowLoginUrl: boolean = false;
    ShowImage: boolean = true;
  constructor(private appService: AppService, private _router: Router, private route: ActivatedRoute) {
    this.appService.SetLoadingShow(false);
    this.ShowLoginUrl = false;
    this.ShowImage = true;
    if (this._router.url == '/logout') {

      this.Message = 'You Have Successfully Logged Out!';
      this.ShowLoginUrl = true;
      this.ShowImage = false;
      this.appService.SetLoginUserInfo(null);
      window.history.forward();
    } else if (this._router.url == '/session') {
      this.Message = 'Your session has expired.';
      this.ShowLoginUrl = true;
      this.appService.SetLoginUserInfo(null);
      window.history.forward();
    }
  }
}

