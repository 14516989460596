import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA, MatTabGroup, MatTab } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
import { NumberValueAccessor } from '@angular/forms/src/directives';
import { NUMBER_VALUE_ACCESSOR } from '@angular/forms/src/directives/number_value_accessor';


@Component({
  selector: 'app-view-targets',
  templateUrl: './view-targets.component.html',
  styleUrls: ['./view-targets.component.less']
})
export class ViewTargetsComponent implements OnInit {
  @ViewChild('warningModel') warningModel: MzModalComponent
  @ViewChild('targethistory') targetHistory: MzModalComponent
  @ViewChild('qualitativedata') qualitativedata: MzModalComponent
  autocomplete: { data: { [key: string]: string } };
  uploadedFile: File;
  periodid: any;
  UserPsno: any;
  periodValus: any;
  ShowSection: number;
  userInfo: any;
  Message: any;
  DownloadFilePath: any;
  sum: number = 0;
  count: number = 0;
  DownloadFilePathBase: any;
  roleSelected: any;
  masterData: any;
  roleSelectedName: any;
  dataSource: any;
  dataSource_Open_WithMe: any;
  dataSource_Closed: any;
  dataSource_Open_InProcess: any;
  mappingData: any;
  selectAll: boolean = true;
  displayedColumns: string[] = ['select', 'name', 'manager', 'sbu', 'role'];
  displayedColumnsMobile: any[] = [];
  SalesMetricColumns: string[] = [];
  AllowedPeriodUpload: any;
  filters: any;
  tblStatusOrginal: any;
  tblStatusData: any;
  SearchText: any;
  selectedStatus: any;
  selectedSBU: any;
  selectedFunction: any;
  ShowFilters: boolean = false;
  LoggedInUserRole: any;
  LoggedInUserRoleText: any;
  ShowApprRejBtns: boolean = false;
  ShowReleaseBtns: boolean = false;
  ShowEditBtn: boolean = false;
  ShowSubmitApprovalBtn: boolean = false;
  IsDownload: boolean = false;

  SelectedRecords: any = [];
  warningdata: string = '';
  GridId: string = '1';
  TotalEligibleNumber: number = 0;
  TotalUploadedNumber: number = 0;
  TargetsNotUploadedNumber: number = 0;
  PendingWithSENumber: number = 0;
  TargetReleasedNUmber: number = 0;
  PendingWithCNBNumber: number = 0;
  PendingWithMgrNumber: number = 0;
  isCBoAndManager: boolean = false;
  TargetRevisionNUmber: number = 0;
    //dataSource = ELEMENT_DATA;
    isH1Applicable: boolean = false;
    AppType : string = 'SPM';
  targetHistoryArr: any = [];
  dropdownListRole: any = [];
  dropdownListSBU: any = [];
  dropdownListFunction: any = [];
  dropdownListStatus: any = [];
  dropdownSettings: any = {};
  AllowedRolesForPeriod: any = [];
  RoleByPeriodMaster: any = [];
  Selectedrole: any = [];
  RoleByPeriodMasterDIS: any = [];
  isManagerOrReviewer: boolean = false;
  dropdownRole: any = [];
  SelectedRoleIdArray: any = [];
  ShowMetricsFlag: boolean;
  SBUDistinct: any = [];
  SBUDistinctModel: any = [];
  OtherParamArr: any = [];
  OtherParamArrSBUWise: any = [];
  MBOSubmitValidation: string = '';
  qualitativeArr: any = [];
  psno: any;
  ShowSBUColumn: boolean = false;
  ShowUnitColumn: boolean = false;
  selectAllPsno: boolean = false;
  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
   this.appService.SetLoadingShow(false);
    this.masterData = {};
    this.periodid = '0';
    this.UserPsno = '';
    this.uploadedFile = null;
    this.periodValus = [];
    this.ShowSection = 1;
    this.Message = '';
    this.DownloadFilePathBase = '../../../UploadTemplates/UploadedTargets.xlsx';
    this.roleSelectedName = '';
    this.DownloadFilePath = '';
    this.dataSource = [];
    this.dataSource_Open_WithMe = [];
    this.dataSource_Open_InProcess = [];
    this.dataSource_Closed = [];
    this.tblStatusOrginal = [];
    this.tblStatusData = [];
    this.mappingData = [];
    this.AllowedPeriodUpload = [];
    this.filters = {
      'dtStatusType': [],
      'dtSBU': [],
      'dtRole': [],
      'dtStatusTiles': [],
      'FiltersDataArr': [],
      'dtFunction': []
    };
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.selectedStatus = [];
    this.roleSelected = [];
    this.SearchText = '';
    this.LoggedInUserRole = 0;
    this.LoggedInUserRoleText = '';
    this.Selectedrole = [];
    this.ShowMetricsFlag = false;
    this.SBUDistinct = [];
    this.SBUDistinctModel = [];
  }
  ngOnInit() {
    this.appService.SetLoadingShow(false);
    this.isCBoAndManager = false;
    this.userInfo = this.appService.GetLoginUserInfo();
   if (this.userInfo != null) {
      if (this.userInfo.AppType == 2) {
        this.ShowUnitColumn = true;
        this.ShowSBUColumn = false;
      }
      else {
        this.ShowUnitColumn = false;
        this.ShowSBUColumn = true;
      }
      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
              this.UserPsno = this.userInfo.PS_No;
              this.LoggedInUserRole = this.userInfo.SPMRoles[0].RoleID;
              this.dropdownSettings = {
                singleSelection: false,
                text: "Select",
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: false,
                badgeShowLimit: 1,
                classes: ""
              };
              var TargetScreenRoles = this.appService.GetUserRoleForTargetApproval(this.userInfo.SPMRoles);
              if (TargetScreenRoles.CSRoleId > 0) {
                this.LoggedInUserRoleText = 'SE';
                this.IsDownload = true;
                this.LoggedInUserRole = TargetScreenRoles.CSRoleId;
              }

              else if (TargetScreenRoles.CNBRoleId > 0) {
                this.LoggedInUserRoleText = 'CNB';
                this.IsDownload = true;
                this.LoggedInUserRole = TargetScreenRoles.CNBRoleId;
              }
              else if (TargetScreenRoles.SELARoleId > 0) {
                this.LoggedInUserRoleText = 'SELA';
                this.IsDownload = true;
                this.LoggedInUserRole = TargetScreenRoles.SELARoleId;
              }
              else if (TargetScreenRoles.HRRoleId > 0) {
                this.LoggedInUserRoleText = 'HR';
                this.IsDownload = true;
                this.LoggedInUserRole = TargetScreenRoles.HRRoleId;
              }
              // Some Manager also have HR Role - Access Override  
              if (TargetScreenRoles.MGRRoleId > 0) {
                this.LoggedInUserRoleText = 'Manager';
                this.IsDownload = false;
                this.LoggedInUserRole = TargetScreenRoles.MGRRoleId;
              }
              // if sales excellence then only use this role
              if (TargetScreenRoles.CSRoleId > 0) {
                this.LoggedInUserRoleText = 'SE';
                this.IsDownload = true;
                this.LoggedInUserRole = TargetScreenRoles.CSRoleId;
              }

              // CBO Role Priority  
              if (TargetScreenRoles.CBORoleId > 0) {
                this.LoggedInUserRoleText = 'CBO';
                if (TargetScreenRoles.MGRRoleId > 0) {
                  this.isCBoAndManager = true;
                  this.IsDownload = true;
                }
                this.LoggedInUserRole = TargetScreenRoles.CBORoleId;
              }
              else if (TargetScreenRoles.FunctionHeadId > 0) {
                this.LoggedInUserRoleText = 'FunctionHead';
                this.IsDownload = true;
                this.LoggedInUserRole = TargetScreenRoles.FunctionHeadId;
              }
              var UserData = {
                "PS_No": this.userInfo.PS_No,
                "AppType": this.userInfo.AppType
              }
              this.appService.SetLoadingShow(true);
              this.masterData = this.appService.GetMasterDetails();
              this.appService.GetDataFromAPI(this.configs.GetYearsForTargetUpload(), UserData)
                .then(response => {
                  this.appService.SetLoadingShow(false);
                  if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                      data: response.ResponseData
                    });
                  }
                  else {
                    this.periodValus = response.ResponseData.dtYearsForTrgts;
                    this.RoleByPeriodMaster = response.ResponseData.dtApplicablePlanForYear;
                    this.RoleByPeriodMasterDIS = response.ResponseData.dtApplicablePlanForYearDIS;
                    this.periodid = response.ResponseData.dtYearsForTrgts[0].TargetUploadYearText;
                  //  this.onChangeOFPeriod(this.periodid);
                   
                    var IsNavBwtweenTargetDashBoardAndMyTargets = this.appService.GetIsNavBwtweenTargetDashBoardAndMyTargets();
                    if (IsNavBwtweenTargetDashBoardAndMyTargets) {
                      this.periodid = this.appService.GetperiodId();
                      this.onChangeOFPeriod(this.periodid);             
                      }     
                    else {
                      this.onChangeOFPeriod(this.periodid);
                      this.appService.SetLoadingShow(false);
                    }
                    this.ShowSection = 1;
                    //this.ChangeView(2);
                  }               
                });
            }
            else {
              this.router.navigate(['no-access']);
            }
          }
    else {
            window.location.href = "../Index.html";
          }

  }
  ChangeView(value: number) {
    this.ShowSection = value;
  }
  fileChange(event) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadedFile = fileList[0];
    }
    else {
      this.uploadedFile = null;
    }
  }

  onChangeOFPeriod(id) {
    if (id != '') {
      this.appService.SetLoadingShow(true);
      this.periodid = id;
      this.ShowApprRejBtns = false;
      this.ShowReleaseBtns = false;
      this.ShowEditBtn = false;
      this.ShowSubmitApprovalBtn = false;
      this.dropdownListFunction = [];
      this.SBUDistinct = [];
      this.dropdownRole = [];
      this.dropdownListRole = [];
      this.dropdownListStatus = [];
      this.ShowFilters = false;
      this.filters.FiltersDataArr = [];
      this.dataSource_Open_WithMe = [];
      this.dataSource_Open_InProcess = [];
      this.dataSource_Closed = [];
      this.SalesMetricColumns = [];
      this.dataSource = [];
      this.FilterPlans(id);
     // this.appService.SetLoadingShow(false);
      //if (this.periodid != '0') {
        //this.appService.SetLoadingShow(true);
        //this.Reload();
        //for (var o in this.masterData.dtPeriodDet) {
        //  if (this.masterData.dtPeriodDet[o].PeriodID == this.periodid) {
        //    this.DownloadFilePath = this.DownloadFilePathBase;
        //    this.ChangeView(2);
        //    break;
        //  }
        //}
      //}
     // else {
       // this.roleSelectedName = '';
       // this.DownloadFilePath = '';
      //}
    }
    else {
      this.periodid = '0';
    }
  }
  Reload() {
    this.filters.FiltersDataArr = [];
    this.dataSource_Open_WithMe = [];
    this.dataSource_Open_InProcess = [];
    this.dataSource_Closed = [];
    this.SalesMetricColumns = [];
    this.dataSource = [];

    this 
    this.uploadedFile = null;
    var data = {
      'periodYear': this.periodid,
      'psNo': this.userInfo.PS_No,
      'Role': this.LoggedInUserRoleText,
      'AppType': this.userInfo.AppType,
      'SelectedRole': this.SelectedRoleIdArray.join()
    };
     this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetUploadedTargetsURL(), data)
      .then(response => {
       // this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {        
          this.filters.dtStatusType = response.ResponseData.dtStatusType;
          this.dropdownListStatus = [];
          for (var o in this.filters.dtStatusType) {
            this.dropdownListStatus.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtStatusType[o].Type });
          }
          this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
          this.SalesMetricColumns = response.ResponseData.metricsMasters;
          this.filters.dtSBU = response.ResponseData.dtSBU;
          this.dropdownListSBU = [];
          for (var o in this.filters.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
          }
          this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
          var SBU = [];
          for (var j in this.selectedSBU) {
            if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
              SBU.push(this.selectedSBU[j].itemName)
            }
          }
          for (var o in SBU) {
            this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o]})
          }
          this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));
          this.filters.dtFunction = response.ResponseData.dtFunction;
          this.dropdownListFunction = [];
          for (var o in this.filters.dtFunction) {
            this.dropdownListFunction.push({ "id": this.filters.dtFunction[o].FunctionId, "itemName": this.filters.dtFunction[o].FunctionName });
          }
          this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));

          this.filters.dtRole = response.ResponseData.dtRole;
          this.dropdownListRole = [];
          for (var o in this.filters.dtRole) {
            this.dropdownListRole.push({ "id": this.filters.dtRole[o].RoleID, "itemName": this.filters.dtRole[o].RoleName, "itemValue": this.filters.dtRole[o].RoleID });
          }
          this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));

          this.filters.dtStatusTiles = response.ResponseData.dtTiles;
   
          if (response.ResponseData.empSalesMatricsInfos.length > 0) {


            this.appService.SetBulkUploadList(response.ResponseData.empSalesMatricsInfos);
            var data = this.appService.GetBulkUploadList();
            for (var o in data) {
              //if (parseInt(data[o].StatusID) > 0) {
                if (this.LoggedInUserRoleText == 'Manager') {


                  if (parseInt(data[o].StatusID) > 2) {
                    this.dataSource.push(data[o]);
                  }
                }
                else {
                  this.dataSource.push(data[o]);
                }
             // }
              }
              if (this.dataSource.length > 0) {
                  if (this.dataSource[0].IsH1PeriodApplicable == '1') {
                      this.isH1Applicable = true;
                  }
                  else {
                      this.isH1Applicable = false;
                  }
                  this.AppType = this.dataSource[0].AppType;
              }
            
            this.tblStatusOrginal = JSON.parse(JSON.stringify(this.dataSource));
            this.tblStatusData = JSON.parse(JSON.stringify(this.dataSource));
            this.filters.FiltersDataArr = JSON.parse(JSON.stringify(this.dataSource));

            for (var o in this.filters.dtStatusTiles) {
              if (this.filters.dtStatusTiles[o].Status == 'Total Eligible') {
                this.TotalEligibleNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
              }
              if (this.filters.dtStatusTiles[o].Status == 'Targets Not Uploaded') {
                this.TargetsNotUploadedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
              }
              if (this.filters.dtStatusTiles[o].Status == 'Total Uploaded') {
                this.TotalUploadedNumber = this.RefreshCount('Total Eligible') - this.RefreshCount('Targets Not Uploaded');
              }
            }
          
            this.InitializeNumbers();
            this.ShowFilters = true;
            if (this.dataSource.length == 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: "No Data available"
              });
              //return;
            }           
            this.DownloadFilePath = this.DownloadFilePathBase;
           
            for (var x in this.dataSource) {

              if (this.LoggedInUserRoleText == 'CBO') {
                if (this.dataSource[x].ManagerPSNO == this.userInfo.PS_No && this.dataSource[x].PendingWith == 'Manager') {
                  this.LoggedInUserRoleText = 'Manager'; // Overriding for manager approvals
                  this.isCBoAndManager = true;
                }
              }



              for (var o in this.dataSource[x].matricsDataList) {
                if (this.dataSource[x].matricsDataList[o].Target == null || this.dataSource[x].matricsDataList[o].Target == undefined) {
                  this.dataSource[x].matricsDataList[o].Target = 'NA';
                }
                if (this.dataSource[x].matricsDataList[o].OldTarget == null || this.dataSource[x].matricsDataList[o].OldTarget == undefined) {
                  this.dataSource[x].matricsDataList[o].OldTarget = 'NA';
                }
                if (this.dataSource[x].matricsDataList[o].Actual == null || this.dataSource[x].matricsDataList[o].Actual == undefined) {
                  this.dataSource[x].matricsDataList[o].Actual = 'NA';
                }


              }
            }


            for (var o in this.dataSource) {
              this.dataSource[o].select = false;
              if (this.dataSource[o].status == 'Target Letter Released' || this.dataSource[o].status == 'Targets Not Uploaded') {
                this.dataSource_Closed.push(this.dataSource[o])
              }
              else {
               if (this.dataSource[o].PendingWith != this.LoggedInUserRoleText) {
                 if (this.isCBoAndManager && this.dataSource[o].StatusID == "8" || (this.dataSource[o].PendingWith == 'HR' && ('FunctionHead' == this.LoggedInUserRoleText ||'SELA' == this.LoggedInUserRoleText))) {
                    this.dataSource_Open_WithMe.push(this.dataSource[o]);
                  }
                  else {
                    this.dataSource_Open_InProcess.push(this.dataSource[o]);
                  }

                 // this.dataSource_Open_InProcess.push(this.dataSource[o]);
                }
                else {
                  if (this.LoggedInUserRoleText == 'Manager') {
                    if (this.dataSource[o].ManagerPSNO == this.userInfo.PS_No) {
                      this.dataSource_Open_WithMe.push(this.dataSource[o]);
                    }
                    else {
                      this.dataSource_Open_InProcess.push(this.dataSource[o]);
                    }
                  }
                  else {
                    this.dataSource_Open_WithMe.push(this.dataSource[o]);
                  }

                }

              }


            }
            this.ShowApprRejBtns = false;
            this.ShowReleaseBtns = false;
            this.ShowSubmitApprovalBtn = false;
            for (var o in this.dataSource_Open_WithMe) {
              if (!this.ShowSubmitApprovalBtn && this.dataSource_Open_WithMe[o].StatusID == "8" && (this.LoggedInUserRoleText == 'HR' || this.LoggedInUserRoleText == 'CBO' || this.LoggedInUserRoleText == 'SELA' || this.LoggedInUserRoleText=='FunctionHead')) {
                this.ShowSubmitApprovalBtn = true;
              }
              if (!this.ShowApprRejBtns && (this.dataSource_Open_WithMe[o].StatusID == "1" || this.dataSource_Open_WithMe[o].StatusID == "9") && (this.LoggedInUserRoleText == 'SE' )) {
                this.ShowApprRejBtns = true;

              }
              if (!this.ShowApprRejBtns && (this.dataSource_Open_WithMe[o].StatusID == "2") && this.LoggedInUserRoleText == 'CNB') {
                this.ShowApprRejBtns = true;

              }
              if (!this.ShowReleaseBtns && (this.dataSource_Open_WithMe[o].StatusID == "3") && this.LoggedInUserRoleText == 'Manager') {
                this.ShowReleaseBtns = true;

              }
            }
            for (var o in this.dataSource_Closed) {

              if (!this.ShowEditBtn && this.dataSource_Closed[o].StatusID != "14" && (this.LoggedInUserRoleText == 'HR' || this.LoggedInUserRoleText == 'SE' || this.LoggedInUserRoleText == 'CBO' || this.LoggedInUserRoleText == 'SELA' || this.LoggedInUserRoleText == 'FunctionHead' )) {
                this.ShowEditBtn = true;
              }

            }
            this.selectAll = false;

            this.displayedColumns = ['select', 'psNo', 'name', 'manager', 'sbu', 'role'];
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No Data available"
            });
          }
          if (response.ResponseData.allowedPeriodUploads.length > 0) {
            this.AllowedPeriodUpload = response.ResponseData.allowedPeriodUploads;
          }       
        }
        if (response.ResponseCode == "SUCCESS") {
          this.appService.SetLoadingShow(false);
        }
        else {
          if (response.ResponseCode == "FAILURE") {
            this.appService.SetLoadingShow(false);
          }
        }
      //  this.appService.SetLoadingShow(false);
      });   
  }

  setDropdownListSBU() {
    this.dropdownListSBU = [];
    for (var o in this.filters.dtSBU) {
      this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
    }
    this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));

  }

  onFunctionChange(selectedFunction) {
    this.setDropdownListSBU();
    this.tblStatusOrginal = this.tblStatusData;
    var data = [];
    var dataselectedSBU = [];

    if (selectedFunction.length <= this.dropdownListFunction.length) {
      for (var o in selectedFunction) {
        if (selectedFunction[o].itemName != '' || selectedFunction[o].itemName != undefined || selectedFunction[o].itemName != "SELECT") {
          for (var i in this.tblStatusOrginal) {
            if (this.tblStatusOrginal[i].FunctionName == selectedFunction[o].itemName) {
              data.push(this.tblStatusOrginal[i]);
            }
          }

          for (var j in this.selectedSBU) {
            if (this.selectedSBU[j].Functionid == selectedFunction[o].id) {
              dataselectedSBU.push(this.selectedSBU[j])
            }
          }

        }

      }
      this.selectedSBU = JSON.parse(JSON.stringify(dataselectedSBU))
      this.dropdownListSBU = JSON.parse(JSON.stringify(this.selectedSBU))
      var SBU = [];
      this.SBUDistinctModel = [];
      this.SBUDistinct = [];
      for (var j in this.selectedSBU) {
        if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
          SBU.push(this.selectedSBU[j].itemName)
        }
      }
      for (var o in SBU) {
        this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
      }
      this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));
      //for (var j in this.selectedSBU) {
      //  if (this.SBUDistinctModel.indexOf(this.selectedSBU[j].itemName) == -1) {
      //    this.SBUDistinctModel.push(this.selectedSBU[j].itemName)
      //  }
      //}

      this.tblStatusOrginal = data

    }
    this.onFilterChange(this.SBUDistinctModel, this.selectedStatus)
  }

  onFilterChange(selectedSBU, StatusID) {
    
    this.filters.FiltersDataArr = [];
    this.dataSource_Closed = [];
    this.dataSource_Open_WithMe = [];
    this.dataSource_Open_InProcess = [];
    
    if (this.selectedFunction.length == this.dropdownListFunction.length) {
      this.tblStatusOrginal = this.tblStatusData
    }
    var dataSBU = [];

    if (selectedSBU.length < this.dropdownListSBU.length) {
      for (var o in selectedSBU) {
        if (selectedSBU[o].itemName != '' || selectedSBU[o].itemName != undefined || selectedSBU[o].itemName != "SELECT") {
          for (var i in this.tblStatusOrginal) {
            if (this.tblStatusOrginal[i].sbu == selectedSBU[o].itemName) {
              dataSBU.push(this.tblStatusOrginal[i]);
            }
          }
        }
      }

    }
    else {
      dataSBU = this.tblStatusOrginal;
      //data = [];
    } 
    var NotUploaded = JSON.parse(JSON.stringify(dataSBU)).filter((dataSBU: any) => dataSBU.status == 'Targets Not Uploaded');
  

    for (var o in this.filters.dtStatusTiles) {
      if (this.filters.dtStatusTiles[o].Status == 'Total Eligible') {
        this.TotalEligibleNumber = dataSBU.length;
      }
      if (this.filters.dtStatusTiles[o].Status == 'Targets Not Uploaded') {
        this.TargetsNotUploadedNumber = NotUploaded.length;
      }
      if (this.filters.dtStatusTiles[o].Status == 'Total Uploaded') {
        this.TotalUploadedNumber = dataSBU.length - NotUploaded.length;
      }
    }
    var dataStatus = [];

    if (StatusID.length < this.dropdownListStatus.length) {
      for (var o in StatusID) {
        if (StatusID[o].itemName != '' || StatusID[o].itemName != undefined || StatusID[o].itemName != "SELECT") {
          for (var i in dataSBU) {
            if (dataSBU[i].status == StatusID[o].itemName) {
              dataStatus.push(dataSBU[i]);
            }
          }
        }
      }
    }
    else {
      dataStatus = dataSBU;
      //dataStatus = [];
    }
  
    for (var o in dataStatus) {

      if (dataStatus[o].status == 'Target Letter Released' || dataStatus[o].status == 'Targets Not Uploaded') {
        this.dataSource_Closed.push(dataStatus[o]);
        this.filters.FiltersDataArr.push(dataStatus[o]);
      }
      else {
        if (dataStatus[o].PendingWith != this.LoggedInUserRoleText) {
          if (this.isCBoAndManager && dataStatus[o].StatusID == "8" || (dataStatus[o].PendingWith == 'HR' && ('FunctionHead' == this.LoggedInUserRoleText|| 'SELA' == this.LoggedInUserRoleText))) {
            this.dataSource_Open_WithMe.push(dataStatus[o]);
            this.filters.FiltersDataArr.push(dataStatus[o]);
          }
          else {
            this.dataSource_Open_InProcess.push(dataStatus[o]);
            this.filters.FiltersDataArr.push(dataStatus[o]);
          }
        }
        else {
          if (this.LoggedInUserRoleText == 'Manager') {
            if (dataStatus[o].ManagerPSNO == this.userInfo.PS_No) {
              this.dataSource_Open_WithMe.push(dataStatus[o]);
              this.filters.FiltersDataArr.push(dataStatus[o]);
            }
            else {
              this.dataSource_Open_InProcess.push(dataStatus[o]);
              this.filters.FiltersDataArr.push(dataStatus[o]);
            }
          }
          else {
            this.dataSource_Open_WithMe.push(dataStatus[o]);
            this.filters.FiltersDataArr.push(dataStatus[o]);
          }
          //this.dataSource_Open_WithMe.push(dataRole[o]);
        }
      }
    }
    this.InitializeNumbers();
  }
  SearchFor(value): any {
    this.filters.FiltersDataArr = [];
    this.dataSource_Closed = [];
    this.dataSource_Open_WithMe = [];
    this.dataSource_Open_InProcess = [];
    var data = JSON.parse(JSON.stringify(this.tblStatusOrginal));
    if (value != '' && value != undefined) {
      if (value.length > 2) {
        const filterValue = value.toLowerCase();
        this.SearchText = value;
        for (var o in data) {
          if (data[o].name == null)
            data[o].name = "";
          if (data[o].manager == null)
            data[o].manager = "";
        }
        data = data.filter(option => option.name.toLowerCase().includes(filterValue) || option.manager.toLowerCase().includes(filterValue) || option.psNo.toString().toLowerCase().includes(filterValue));
      }
    }
    //if (this.GridId == "1") {
    //  this.dataSource_Open_WithMe = data;
    //}
    //else if (this.GridId == "2") {
    //  this.dataSource_Open_InProcess = data;
    //}
    //else if (this.GridId == "3") {
    //  this.dataSource_Closed = data;
    //}
    for (var o in data) {

      if (data[o].status == 'Target Letter Released' || data[o].status == 'Targets Not Uploaded') {
        this.dataSource_Closed.push(data[o]);
        this.filters.FiltersDataArr.push(data[o]);
      }
      else {
        if (data[o].PendingWith != this.LoggedInUserRoleText) {
          if (this.isCBoAndManager && data[o].StatusID == "8" || (data[o].PendingWith == 'HR' && ('FunctionHead' == this.LoggedInUserRoleText || 'SELA' == this.LoggedInUserRoleText))) {
            this.dataSource_Open_WithMe.push(data[o]);
            this.filters.FiltersDataArr.push(data[o]);
          }
          else {
            this.dataSource_Open_InProcess.push(data[o]);
            this.filters.FiltersDataArr.push(data[o]);
          }
        }
        else {
          if (this.LoggedInUserRoleText == 'Manager') {
            if (data[o].ManagerPSNO == this.userInfo.PS_No) {
              this.dataSource_Open_WithMe.push(data[o]);
              this.filters.FiltersDataArr.push(data[o]);
            }
            else {
              this.dataSource_Open_InProcess.push(data[o]);
              this.filters.FiltersDataArr.push(data[o]);
            }
          }
          else {
            this.dataSource_Open_WithMe.push(data[o]);
            this.filters.FiltersDataArr.push(data[o]);
          }
          //this.dataSource_Open_WithMe.push(dataRole[o]);
        }
      }
    }
    this.InitializeNumbers();

  }
  onReset(): void {
    this.selectedSBU = [];
    this.setDropdownListSBU();
    var SBU = [];
    this.SBUDistinctModel = [];
    this.SBUDistinct = [];
    for (var j in this.selectedSBU) {
      if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
        SBU.push(this.selectedSBU[j].itemName)
      }
    }
    for (var o in SBU) {
      this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
    }
    this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));
    //this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
    this.selectedFunction = [];
    this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
    this.selectedStatus = [];
    this.selectedStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
    this.roleSelected = [];
    this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
    this.SearchText = '';
    this.dataSource = this.tblStatusData;
    this.dataSource_Closed = [];
    this.dataSource_Open_InProcess = [];
    this.dataSource_Open_WithMe = [];
    this.filters.FiltersDataArr = this.tblStatusData;
    for (var o in this.dataSource) {

      if (this.dataSource[o].status == 'Target Letter Released' || this.dataSource[o].status == 'Targets Not Uploaded') {
        this.dataSource_Closed.push(this.dataSource[o])
      }
      else {
        if (this.dataSource[o].PendingWith != this.LoggedInUserRoleText) {
          if (this.isCBoAndManager && this.dataSource[o].StatusID == "8" || (this.dataSource[o].PendingWith == 'HR' && ('FunctionHead' == this.LoggedInUserRoleText ||'SELA' == this.LoggedInUserRoleText))) {
            this.dataSource_Open_WithMe.push(this.dataSource[o]);
          }
          else {
            this.dataSource_Open_InProcess.push(this.dataSource[o]);
          }
        }
        else {
          if (this.LoggedInUserRoleText == 'Manager') {
            if (this.dataSource[o].ManagerPSNO == this.userInfo.PS_No) {
              this.dataSource_Open_WithMe.push(this.dataSource[o]);
            }
            else {
              this.dataSource_Open_InProcess.push(this.dataSource[o]);
            }
          }
          else {
            this.dataSource_Open_WithMe.push(this.dataSource[o]);
          }
          //this.dataSource_Open_WithMe.push(this.dataSource[o]);
        }
      }
    }
    for (var o in this.filters.dtStatusTiles) {
      if (this.filters.dtStatusTiles[o].Status == 'Total Eligible') {
        this.TotalEligibleNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
      }
      if (this.filters.dtStatusTiles[o].Status == 'Targets Not Uploaded') {
        this.TargetsNotUploadedNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
      }
      if (this.filters.dtStatusTiles[o].Status == 'Total Uploaded') {
        this.TotalUploadedNumber = this.RefreshCount('Total Eligible') - this.RefreshCount('Targets Not Uploaded');
      }
    }
    this.InitializeNumbers();
  }
  ApproveRejectTargets(actionType: number) {
    this.SelectedRecords = [];
    for (var o in this.dataSource_Open_WithMe) {
      if (this.dataSource_Open_WithMe[o].select == true) {
        var data = {
          //'ApprovalId': this.dataSource[o].ApprovalId,
          'ActionType': actionType.toString(),
          'LoggedInUserPSno': this.UserPsno,
          'Comments': this.dataSource_Open_WithMe[o].Comments,
          'PeriodId': this.periodid,
          'psNo': this.dataSource_Open_WithMe[o].psNo,
          'StatusId': this.dataSource_Open_WithMe[o].StatusID,
          //'TargetApprovalID': this.dataSource[o].TargetApprovalID,
          'HalfYearPeriodId': this.dataSource_Open_WithMe[o].HalfYearPeriodId,
          'FYPeriodId': this.dataSource_Open_WithMe[o].FYPeriodId,
          'LoggedInUserRole': this.LoggedInUserRole,
          'name': this.dataSource_Open_WithMe[o].name,
          'UpdateTargets': 'N',
          'matricsDataList': this.dataSource_Open_WithMe[o].matricsDataList,
          'RoleId': this.dataSource_Open_WithMe[o].RoleId,
          'UpdateAccounts': 'N',
          'Account_New_Save': this.dataSource_Open_WithMe[o].Account_New_Save,
          'AutoReleaseDate': this.dataSource_Open_WithMe[o].AutoReleaseDate,
            'UploadedBy': this.dataSource_Open_WithMe[o].UploadedBy,
          'AppType': this.userInfo.AppType,
          'RoleName': this.dataSource_Open_WithMe[o].role
        }
        if (actionType.toString() == '1') {
          for (var x in this.dataSource_Open_WithMe[o].matricsDataList) {
            if ((this.dataSource_Open_WithMe[o].matricsDataList[x].H1Target_New_Save != this.dataSource_Open_WithMe[o].matricsDataList[x].H1Target_New) || (this.dataSource_Open_WithMe[o].matricsDataList[x].FYTarget_New_Save != this.dataSource_Open_WithMe[o].matricsDataList[x].FYTarget_New)) {
              data.UpdateTargets = 'Y';
              break;
            }
          }
          if (this.dataSource_Open_WithMe[o].Account_New_Save.toLowerCase().trim() != this.dataSource_Open_WithMe[o].Account_New.toLowerCase().trim()) {
            data.UpdateAccounts = 'Y';
          }
        }
        this.SelectedRecords.push(data);
      }
    }
    if (this.SelectedRecords.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select atleast one record to proceed"
      });
    }
    else {
      var validations = [];

      if (actionType == 2) {
        for (var o in this.SelectedRecords) {
          if (this.SelectedRecords[o].Comments == null || this.SelectedRecords[o].Comments == undefined) {
            validations.push("Please enter comments for review");
            break;
          }
          if (this.SelectedRecords[o].Comments.length == 0)
            validations.push("Please enter comments for review");
          break;
        }
      }
    }
    if (validations.length > 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: validations
      });
    }
    else {
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.ApproveRejectTargetsURL(), this.SelectedRecords)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.Reload();
            //this.ChangeView(2);
          }
        });
    }
  }


  SelectRecords(val, period) {
    if (this.GridId == "1") {
      if (val.toString() == 'ALL') {
        this.selectAll = !this.selectAll;
        for (var o in this.dataSource_Open_WithMe) {
          this.dataSource_Open_WithMe[o].select = this.selectAll;
          if (this.dataSource_Open_WithMe[o].EnableEdit == "N") {
            this.dataSource_Open_WithMe[o].EnableEdit = "Y";
          }
          else {
            this.dataSource_Open_WithMe[o].EnableEdit = "N";
          }
        }
      }
      else {
        for (var o in this.dataSource_Open_WithMe) {
          if (val.toString() == this.dataSource_Open_WithMe[o].psNo.toString()) {
            this.dataSource_Open_WithMe[o].select = !this.dataSource_Open_WithMe[o].select;
            if (this.dataSource_Open_WithMe[o].EnableEdit == "N") {
              this.dataSource_Open_WithMe[o].EnableEdit = "Y";
            }
            else {
              this.dataSource_Open_WithMe[o].EnableEdit = "N";
            }
            break;
          }
        }
      }
      this.ResetAllCheckBox();
    }
    else if (this.GridId == "2") {
      if (val.toString() == 'ALL') {
        this.selectAll = !this.selectAll;
        for (var o in this.dataSource_Open_InProcess) {
          this.dataSource_Open_InProcess[o].select = this.selectAll;
          if (this.dataSource_Open_InProcess[o].EnableEdit == "N") {
            this.dataSource_Open_InProcess[o].EnableEdit = "Y";
          }
          else {
            this.dataSource_Open_InProcess[o].EnableEdit = "N";
          }
        }
      }
      else {
        for (var o in this.dataSource_Open_InProcess) {
          if (val.toString() == this.dataSource_Open_InProcess[o].psNo.toString()) {
            this.dataSource_Open_InProcess[o].select = !this.dataSource_Open_InProcess[o].select;
            if (this.dataSource_Open_InProcess[o].EnableEdit == "N") {
              this.dataSource_Open_InProcess[o].EnableEdit = "Y";
            }
            else {
              this.dataSource_Open_InProcess[o].EnableEdit = "N";
            }
            break;
          }
        }
      }
      this.ResetAllCheckBox();
    }





    else if (this.GridId == "3") {
      if (val.toString() == 'ALL') {
        this.selectAll = !this.selectAll;
        for (var o in this.dataSource_Closed) {
          this.dataSource_Closed[o].select = this.selectAll;
          if (this.dataSource_Closed[o].EnableEdit == "N") {
            this.dataSource_Closed[o].EnableEdit = "Y";
          }
          else {
            this.dataSource_Closed[o].EnableEdit = "N";
          }
        }
      }
      else {
        for (var o in this.dataSource_Closed) {
          if (val.toString() == this.dataSource_Closed[o].psNo.toString()) {
            this.dataSource_Closed[o].select = !this.dataSource_Closed[o].select;
            if (this.dataSource_Closed[o].EnableEdit == "N") {
              this.dataSource_Closed[o].EnableEdit = "Y";
            }
            else {
              this.dataSource_Closed[o].EnableEdit = "N";
            }
            break;
          }
        }
      }
      this.ResetAllCheckBox();
    }
  }
  ResetAllCheckBox() {
    if (this.GridId == "1") {
      this.selectAll = true;
      for (var o in this.dataSource_Open_WithMe) {
        if (this.dataSource_Open_WithMe[o].select == false) {
          this.selectAll = false;
         break;
        }
      }
    }
    else if (this.GridId == "2") {
      this.selectAll = true;
      for (var o in this.dataSource_Open_InProcess) {
        if (this.dataSource_Open_InProcess[o].select == false) {
          this.selectAll = false;
          break;
        }
      }
    }
    else if (this.GridId == "3") {
      this.selectAll = true;
      for (var o in this.dataSource_Closed) {
        if (this.dataSource_Closed[o].select == false) {
          this.selectAll = false;
          break;
        }
      }
    }
  }

  OnViewDetails(value) {
    this.appService.SetperiodId(value.HalfYearPeriodId);
    this.appService.SetPsno(value);
    this.appService.SetIsManagerOrReviewer(this.isManagerOrReviewer)
    this.appService.SetNavbackPathForAssessmentDetails('view-targets');
    this.router.navigate(['assessment-details']);
  }
  //OnViewDetailsMyTaregets(psno: string, value: string):void {
  //  this.appService.SetperiodId(value);
  //  this.appService.SetPsno(psno);
  //  this.appService.SetIsNavBwtweenTargetDashBoardAndMyTargets(true);
  //  this.appService.SetDashBoardData({ 'Selectedrole': this.Selectedrole});
  //  this.appService.SetNavbackPathForAssessmentDetails('view-targets');
  //  this.router.navigate(['my-targets'])
  //}
  OnViewDetailsMyTaregets(value) {
    this.appService.SetperiodId(this.periodid);
    this.appService.SetPsno(value);
    this.appService.SetIsNavBwtweenTargetDashBoardAndMyTargets(true);
    this.appService.SetDashBoardData({ 'Selectedrole': this.Selectedrole });
    this.appService.SetIsManagerOrReviewer(this.isManagerOrReviewer)
    /*this.appService.SetTargetHistoryToggleTab("3");*/
    this.appService.SetNavbackPathForAssessmentDetails('view-targets');
    this.router.navigate(['my-targets']);
  }
  EditAndSaveTargets() {
    var HasSelectedEmployees = false;
    this.SelectedRecords = [];
    for (var o in this.dataSource_Closed) {
      if (this.dataSource_Closed[o].select == true) {
          HasSelectedEmployees = true;

        for (var x in this.dataSource_Closed[o].matricsDataList) {
          if ((this.dataSource_Closed[o].matricsDataList[x].H1Target_New_Save != this.dataSource_Closed[o].matricsDataList[x].H1Target_New) || (this.dataSource_Closed[o].matricsDataList[x].FYTarget_New_Save != this.dataSource_Closed[o].matricsDataList[x].FYTarget_New) || this.dataSource_Closed[o].Account_New_Save != this.dataSource_Closed[o].Account_New) {
            var data = {
              //'ApprovalId': this.dataSource[o].ApprovalId,
              'ActionType': '1',
              'LoggedInUserPSno': this.UserPsno,
              'Comments': this.dataSource_Closed[o].Comments,
              'PeriodId': this.periodid,
              'psNo': this.dataSource_Closed[o].psNo,
              'StatusId': this.dataSource_Closed[o].StatusID,
              //'TargetApprovalID': this.dataSource[o].TargetApprovalID,
              'HalfYearPeriodId': this.dataSource_Closed[o].HalfYearPeriodId,
              'FYPeriodId': this.dataSource_Closed[o].FYPeriodId,
              'LoggedInUserRole': this.LoggedInUserRole,
              'name': this.dataSource_Closed[o].name,
              'UpdateTargets': 'Y',
              'matricsDataList': this.dataSource_Closed[o].matricsDataList,
              'RoleId': this.dataSource_Closed[o].RoleId,
              'UpdateAccounts': 'N',
              'Account_New_Save': this.dataSource_Closed[o].Account_New_Save,
              'AutoReleaseDate': this.dataSource_Closed[o].AutoReleaseDate,
                'UploadedBy': this.dataSource_Closed[o].UploadedBy,
              'AppType': this.userInfo.AppType,
              'RoleName': this.dataSource_Open_WithMe[o].role
            }

            if (this.dataSource_Closed[o].Account_New_Save != this.dataSource_Closed[o].Account_New) {
              data.UpdateAccounts = 'Y';
            }

            if (this.LoggedInUserRoleText  == 'SE' ) {
              data.StatusId = "1";
            }
            else if (this.LoggedInUserRoleText == 'HR' || this.LoggedInUserRoleText == 'CBO' || this.LoggedInUserRoleText == "SELA" || this.LoggedInUserRoleText=="FunctionHead") {
              data.StatusId = "0";
            }




            this.SelectedRecords.push(data);
            break;
          }
        }
       
      }
    }
    if (this.SelectedRecords.length == 0) {
      if (HasSelectedEmployees) {
        this.dialog.open(DialogDataExampleDialog, {
          data: "No changes were detected in targets for selected employees. Please edit targets to proceed."
        });
      }
      else {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Please select atleast one record to proceed"
        });
      }
    }
    else {
      if (this.LoggedInUserRoleText == 'SE' ) {
        this.warningdata = "Targets will be overwritten for selected employees. Please click 'OK' to confirm.";
      }
      else {
        this.warningdata = "Targets will be overwritten for selected employees and re-submitted for approval. Please click 'OK' to confirm.";
      }
        this.warningModel.openModal();
    }

  }

  SubmitTargetApproveReject() {
    this.onCancelClick();
    this.warningdata = '';
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.ApproveRejectTargetsURL(), this.SelectedRecords)
      .then(response => {
       this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.SelectedRecords = [];
          this.Reload();
          //this.ChangeView(2);
        }
      });
  }

  onCancelClick(): void {
    this.warningModel.closeModal();
  }
  DownLoadTargetsExcel(): void {
    window.open("TargetData.ashx");
  }
  ChangeEditStatus(index) {
    if (this.GridId == "1"  ) {
      if (this.dataSource_Open_WithMe[index].EnableEdit == "N") {
        this.dataSource_Open_WithMe[index].EnableEdit = "Y";
      }
      else {
        this.dataSource_Open_WithMe[index].EnableEdit = "N";
      }
    }
    else if (this.GridId == "2") {
      if (this.dataSource_Open_InProcess[index].EnableEdit == "N") {
        this.dataSource_Open_InProcess[index].EnableEdit = "Y";
      }
      else {
        this.dataSource_Open_InProcess[index].EnableEdit = "N";
      }
    }
    else if(this.GridId == "3") {
      if (this.dataSource_Closed[index].EnableEdit == "N") {
        this.dataSource_Closed[index].EnableEdit = "Y";
      }
      else {
        this.dataSource_Closed[index].EnableEdit = "N";
      }
    }
  }

  ToggleGrid(i) {
    var time = 1000;
    if ((i.index + 1).toString() == "1") {
      time = this.dataSource_Open_WithMe.length * 2.5;
    }
    else if ((i.index + 1).toString() == "2") {
      time = this.dataSource_Open_InProcess.length * 2.5;
    }
    else if ((i.index + 1).toString() == "3") {
      time = this.dataSource_Closed.length * 2.5;
    }
    if (time > 0) {
      var that = this;
      that.GridId = (i.index + 1).toString();
      that.appService.SetLoadingShow(true);
      window.setTimeout(function () {

        that.appService.SetLoadingShow(false); 
      },


        time);
    }
    else {
      this.GridId = (i.index + 1).toString();
    }
  }
  DownLoadTargets(Psno, FYPeriodId, Status, PendingWith, AppType): void {

    var IsReleased = '';
    var TargetLetterType = '';
    if (Status === 'Target Letter Released' || PendingWith === 'Manager')
    {
      TargetLetterType = 'Final'; IsReleased = 'Released';
    }
    else {

      TargetLetterType = 'Draft'; IsReleased = 'NonReleased';
    }


    this.DownLoadTargetsApi(Psno, FYPeriodId, this.LoggedInUserRoleText, IsReleased, TargetLetterType, AppType);
 
  }
  DownLoadTargetsApi(Psno, FYPeriodId, Role, TargetStatus, TargetLetterType, AppType): void {

    var data = {
      "PSNO": Psno,
      "PeriodId": FYPeriodId,
      "Role": Role,
      "TargetStatus": TargetStatus,
      "TargetLetterType": TargetLetterType,
      "AppType": AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetLetterURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.appService.DownloadTargetletter(response.ResponseData);
        }
      });


  }
  InitializeNumbers(): void {
    this.TargetRevisionNUmber = this.RefreshCount('Targets Sent For Review By C&B') + this.RefreshCount('Targets Sent For Review By ' + this.getTeamName());
    for (var o in this.filters.dtStatusTiles) {
      
      if (this.filters.dtStatusTiles[o].Status == 'Targets Pending With Manager') {
        this.PendingWithMgrNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
      }
      if (this.filters.dtStatusTiles[o].Status == 'Targets Pending With ' + this.getTeamName()) {
        this.PendingWithSENumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status) + this.RefreshCount('Targets Sent For Review By C&B');
      }
      if (this.filters.dtStatusTiles[o].Status == 'Targets Pending With C&B') {
        this.PendingWithCNBNumber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
      }
      if (this.filters.dtStatusTiles[o].Status == 'Target Letter Released') {
        this.TargetReleasedNUmber = this.RefreshCount(this.filters.dtStatusTiles[o].Status);
      }
    }
     
  }
  RefreshCount(StatusID: string): number {
    var data = JSON.parse(JSON.stringify(this.filters.FiltersDataArr));
    if (StatusID != '' && StatusID != undefined && StatusID != "ALL") {
      if (StatusID == 'Total Eligible') {
        data = data;
      }
      
      else {
        data = data.filter((data: any) => data.status == StatusID);
      }
    }
    return data.length;
  }
  onTileClick(selectedFunction, selectedSBU, status) {
    this.selectedStatus = [];
    if (status == 'Total Eligible') {
      for (var o in this.dropdownListStatus) {
        this.selectedStatus.push({ "id": this.dropdownListStatus[o].id, "itemName": this.dropdownListStatus[o].itemName });
      }

    }
    else if (status == 'Total Uploaded') {
      for (var o in this.dropdownListStatus) {
        if (this.dropdownListStatus[o].itemName != 'Targets Not Uploaded') {
          this.selectedStatus.push({ "id": this.dropdownListStatus[o].id, "itemName": this.dropdownListStatus[o].itemName });
        }
      }

    }
    else if (status == 'Sent for Review') {
      this.selectedStatus.push({ "id": 1, "itemName": 'Targets Sent For Review By C&B' });
      this.selectedStatus.push({ "id": 2, "itemName": 'Targets Sent For Review By ' + this.getTeamName() });
      //this.selectedStatus.push({ "id": 3, "itemName": 'Targets Sent For Review By Business Operations' });
    }
    else if (status == 'Targets Pending With ' + this.getTeamName()){
      this.selectedStatus.push({ "id": 1, "itemName": 'Targets Sent For Review By C&B' });
      this.selectedStatus.push({ "id": 2, "itemName": status });

    }
    else {
      this.selectedStatus.push({ "id": 1, "itemName": status });
    }
    this.onFunctionChange(selectedFunction)
    this.onFilterChange(selectedSBU, this.selectedStatus);
  }
  GettargetHistory(psno) {
    this.targetHistoryArr = [];
    var data = {
      PSNo: psno,
        PeriodId: this.periodid,
        AppType: this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetTargetHistoryForYearURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.targetHistoryArr = response.ResponseData;
          if (this.targetHistoryArr.length > 0) {
            this.targetHistory.openModal();
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No data available"
            });
          }

        }
      }
        ,
        error => {
        
        });

  }
  closeTargethistoryModal() {
    this.targetHistory.closeModal();
  }
  DownLoadNewExcelTarget(): void {
    //call api  >
    var data = {
        "PeriodYear": this.periodid,
        "PS_No": this.UserPsno,
        "AppType": this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetUploadTargetsPendingForApproval(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          window.open(this.configs.UploadTargetsExcelFileURl());
        }
      });
  }
  DownLoadExcelTargetFORSE(): void {
    //call api  >
    var data = {
      "PeriodYear": this.periodid,
      "PS_No": this.userInfo.PS_No,
      "Role": this.LoggedInUserRoleText,
      "AppType": this.userInfo.AppType

    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetUploadTargetsPendingForSalesExcellenceURL(), data)

      .then(response => {
       this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {

          window.open(this.configs.UploadTargetsExcelFORSEFileURl());
        }
      });
  }
  getTeamName() {
    return this.appService.TeamName(this.userInfo.AppType);
  }
  ChangeRole(role) {
    //if (id != '') {
    //  this.Selectedrole = id;
    //  if (this.Selectedrole != '0') {
    //    this.appService.SetLoadingShow(true);
    //    this.Reload();
    //    for (var o in this.AllowedRolesForPeriod) {
    //      if (this.AllowedRolesForPeriod[o].RoleId == this.Selectedrole) {
    //        this.appService.SetRoleID(this.Selectedrole);
    //        this.roleSelectedName = this.AllowedRolesForPeriod[o].RoleName;
    //        //this.DownloadFilePath = this.DownloadFilePathBase;
    //        this.ChangeView(2);
    //        break;
    //      }
    //    }
    //  }
    //  else {
    //    this.roleSelectedName = '';
    //    //this.DownloadFilePath = '';
    //  }
    //}
    
    this.SelectedRoleIdArray = [];
    for (var o in role) {
      this.SelectedRoleIdArray.push(role[o].itemName);
    }
    this.ShowMetricsFlag = false;
    if (this.SelectedRoleIdArray.length == 1) {
      this.ShowMetricsFlag = true;
    }
    this.Reload();
    this.ChangeView(2);
  }
  FilterPlans(periodid) {
    this.Selectedrole = [];
    this.AllowedRolesForPeriod = [];
    this.dropdownRole = [];
    if (this.userInfo.AppType == 1) {
      for (var o in this.RoleByPeriodMaster) {
        if (this.RoleByPeriodMaster[o].TargetUploadYearText == periodid) {
          this.AllowedRolesForPeriod.push(this.RoleByPeriodMaster[o]);
        }
      }
    }
    else if (this.userInfo.AppType == 2) {
      for (var o in this.RoleByPeriodMasterDIS) {
        if (this.RoleByPeriodMasterDIS[o].TargetUploadYearText == periodid) {
          this.AllowedRolesForPeriod.push(this.RoleByPeriodMasterDIS[o]);
        }
      }
      //this. AllowedRolesForPeriod = this.RoleByPeriodMasterDIS;
    }
    for (var o in this.AllowedRolesForPeriod) {
      this.dropdownRole.push({ "id": this.AllowedRolesForPeriod[o].RoleId, "itemName": this.AllowedRolesForPeriod[o].RoleName });
    }
    var IsNavBwtweenTargetDashBoardAndMyTargets = this.appService.GetIsNavBwtweenTargetDashBoardAndMyTargets();
    if (IsNavBwtweenTargetDashBoardAndMyTargets) {
      this.appService.SetIsNavBwtweenTargetDashBoardAndMyTargets(false);
      var dataForLoading = this.appService.GetDashBoardData();
      if (dataForLoading != null) {
        this.Selectedrole = dataForLoading['Selectedrole'];
        this.ChangeRole(this.Selectedrole);
      }
    }
    else {
      this.appService.SetLoadingShow(false);
    }
    
  }

  OnViewDetailsMyTargets(psno: string, periodid: string) {
    this.psno = psno;
   var data = {
  'PSNO': psno,
  'RoleId': 1,
  'PeriodYear': periodid,
  'AppType': this.userInfo.AppType
}

this.appService.SetLoadingShow(true);
var url = this.configs.GetQualitativeParametersDataURL();

this.appService.GetDataFromAPI(url, data)
  .then(response => {
    this.appService.SetLoadingShow(false);
    if (response.ResponseCode == this.configs.RetCodeFailure()) {
      this.dialog.open(DialogDataExampleDialog, {
        data: response.ResponseData
      });
    }
    else {

      var OtherParam = [];
      this.OtherParamArr = [];
      OtherParam = response.ResponseData.dtAssessmentData;
      this.OtherParamArrSBUWise = response.ResponseData.dtMBOWeightageSBUWise;

      if (OtherParam.length > 0) {
        for (var o in OtherParam) {
          if (OtherParam[o].ParameterName != 'Total') {
            this.OtherParamArr.push(OtherParam[o]);
          }
        }
        this.qualitativedata.openModal();
      }
      else {
        this.dialog.open(DialogDataExampleDialog, {
          data: "No data available"
        });

      }


    }
  }
    ,
    error => {

    });
  }

  Savequalitativepercent() {
    this.sum = 0;
    this.count = 0;
    var SBUMinPercentage;

    this.MBOSubmitValidation = '';
    if (this.OtherParamArr.length > 0) {
      for (var e in this.OtherParamArr) {
        this.sum += (this.OtherParamArr[e].Value);
        if (this.OtherParamArr[e].Value > 100) {
          //  this.dialog.open(DialogDataExampleDialog, {
          //  data: "The weightage should not be greater than 40"
          //  });
          this.MBOSubmitValidation = 'Please ensure weightage of individual parameters(s) is not greater than 100';
        }

        if(this.OtherParamArrSBUWise.length > 0) {
          SBUMinPercentage = this.OtherParamArrSBUWise.find(s => s.Parameter === this.OtherParamArr[e].ParameterId);
          if (SBUMinPercentage && this.OtherParamArr[e].Value < SBUMinPercentage.MinimumPercentage) {
            this.MBOSubmitValidation = 'Please ensure weightage of individual parameters(s) qualifies the minimum requirement as per your SBU - '
            + this.OtherParamArr[e].ParameterName + ' - MIN(' + SBUMinPercentage.MinimumPercentage + ')';
          }
        }

        if (this.OtherParamArr[e].Value != 0) {
          this.count = this.count + 1;
        }
        if (this.count > 3) {
          //this.dialog.open(DialogDataExampleDialog, {
          //  data: "Only maximum of 5 parameters can have weightage "
          //});
          //this.type = 1;
          this.MBOSubmitValidation = "Weightages can be assigned to maximum of 3 Parameters ";

        }
      }
      if (this.sum == 100) {

      }
      else {
        //this.dialog.open(DialogDataExampleDialog, {
        //  data: "The sum of weightage should be 100"
        //});
        //this.type = 1;
        this.MBOSubmitValidation = "The sum of weightage should be 100";
      }
      if (this.MBOSubmitValidation.length == 0) {
        //this.dialog.closeAll();
        this.qualitativedata.closeModal();

        this.qualitativeArr = [];
        for (var o in this.OtherParamArr) {
          this.qualitativeArr.push({ "SalesMetricId": this.OtherParamArr[o].ParameterId, "Weightage": this.OtherParamArr[o].Value });
        }

        var data = {
          'PSNO': this.psno,
          'dtPercentagedata': this.qualitativeArr,
          'PeriodId': this.periodid,
          'AppType': this.userInfo.AppType
        }
        this.appService.SetLoadingShow(true);
        var url = this.configs.SaveQualitativePercentageURL();
        this.appService.GetDataFromAPI(url, data)
          .then(response => {
            this.appService.SetLoadingShow(false);
            if (response.ResponseCode == this.configs.RetCodeFailure()) {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });
            }
            else {
              this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
              });

            }
          },
            error => {

            });
      }
    }
  }

  SelectAllForPSNO() {
    this.selectAll = !this.selectAll;
    for (var o in this.dataSource_Open_WithMe) {
      this.dataSource_Open_WithMe[o].select = this.selectAll;
    }
  }

}

