import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
  selector: 'app-pid-mapping',
  templateUrl: './pid-mapping.component.html',
  styleUrls: ['./pid-mapping.component.less']
})
export class PIDMAPPINGComponent implements OnInit {
  periodid: any;
  ShowIOptionTypeSection: number;
  filters: any;
  selectedSBU: any;
  selectedCGC: any;
  selectedCGCNew: string = '';
  selectedRole: any;
  tblData: any;
  tblDataProject: any = [];
  AccountwiseData_Content: any;
  AccountwiseData_Header: any;
  ResetData: any = [];
  SearchText: any = '';
  selectedCGC_name: any;
  CountPSNo: number = 0;
  RowCount: number = 0;
  //selectedOwnDelivery: any = 0;
  selectedProjectID: any = 0;
  ResetDataProject: any = 0;
  PageNumber: number = 1;
  PageNumberNew: number = 10;
  PrevButtonAllowed: boolean = false;
  NextButtonAllowed: boolean = false;
  UserPsno: any;
  ShowSection: number;
  userInfo: any;
  masterData: any;
  UploadOptionTypes: any = [];
  ProjectwiseData_Header: any = [];
  ProjectwiseData_Content: any = [];
  Months: any = [];
  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.periodid = '0';
    this.ShowIOptionTypeSection = 1;
    this.filters = {
      'dtSBU': [],
      'dtProjectId': [],
      'dtProjectIdCG': [],
      'dtCGC': [],
      'dtCGCNew': [],
      'dtRole': {},
      'dtUserInfo': [],
      'dtPeriodMaster': []
    };
    this.selectedSBU = 0;
    this.selectedCGC = 0;
    this.selectedCGCNew = 'Select';
    this.selectedRole = 0;
    this.tblData = [];
    this.AccountwiseData_Content = [];
    this.AccountwiseData_Header = [];
    this.ProjectwiseData_Header = [];
    this.ProjectwiseData_Content = [];

    this.masterData = {};
    this.UserPsno = '';
    this.ShowSection = 1;
    this.selectedCGC_name = '';
    this.UploadOptionTypes = [{ 'OptionTypeId': 1, 'OptionType': 'Account' }, { 'OptionTypeId': 2, 'OptionType': 'Project' }]


  }
  ngOnInit() {
    const getFullDate = new Date();
    const currentDate = new Date().toISOString().slice(0, 10);
    const getYear = getFullDate.getFullYear();
    const fullYrAprilm = getYear + '-04-01';
    const fullYrSeptm = getYear + '-09-30';
    var hfyOctm = getYear + '-10-01';
    var hfyMarchm = getYear + '-03-31';

    this.appService.SetLoadingShow(false);
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.UserPsno = this.userInfo.PS_No;
        this.masterData = this.appService.GetMasterDetails();
        var octdt = null;
        if (currentDate > hfyMarchm) {
          octdt = getYear + '-10-01';
          if (currentDate < hfyOctm) {
            hfyMarchm = getYear + '-03-31';
          }
          else {
            hfyMarchm = getYear + 1 + '-03-31';
          }
        }
        else if (currentDate <= hfyMarchm) {
          octdt = getYear - 1 + '-10-01';
          if (currentDate >= octdt) {
            hfyMarchm = getYear + '-03-31';
          }
          else {
            hfyMarchm = getYear + 1 + '-03-31';
          }
        }

        if (currentDate >= octdt && currentDate <= hfyMarchm) {
          this.periodid = this.masterData.dtPeriodDet[1].PeriodID;
        }
        else if (currentDate >= fullYrAprilm && currentDate <= fullYrSeptm) {
          this.periodid = this.masterData.dtPeriodDet[2].PeriodID;
        }
        this.ShowSection = 0;
        this.ShowIOptionTypeSection = 0;
      }
      else {
        this.router.navigate(['no-access']);
      }

    }
    else {
      window.location.href = "../Index.html";
    }

  }
  //ChangeView(value: number) {
  //  this.ShowSection = value;
  //}
  Refresh() {
    this.selectedCGCNew = "Select";
    this.filters.dtCGCNew = [];
    this.AccountwiseData_Content = [];
    this.AccountwiseData_Header = [];
    this.AccountwiseData_Content = [];
    this.AccountwiseData_Header = [];
    this.PrevButtonAllowed = false;
    this.NextButtonAllowed = false;
  }
  ShowOptiontype(value: number) {
    this.ShowIOptionTypeSection = value;
    this.Refresh();
    this.getfilters(this.periodid);
    this.GetDataForPIDMapping();
  }
  onChangeOFPeriod(id) {
    if (id != '') {
      this.periodid = id;
      //this.getfilters(this.periodid);
      if (this.selectedCGC != 0 && this.ShowIOptionTypeSection == 1) {
        this.GetAccountwiseDataForPIDMapping(this.selectedCGC);
      }
      else if (this.selectedCGC != 0 && this.ShowIOptionTypeSection == 2) {
        this.GetProjectwiseDataForPIDMapping(this.selectedCGC, this.PageNumber, this.PageNumberNew);
      }
    }
    else {
      this.periodid = '0';
    }

  }

  getfilters(value: string): void {
    var Input = {
      "Period": value,
      "Type": this.ShowIOptionTypeSection
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetFilterMasterForPIDMappingURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.filters.dtCGC = response.ResponseData.dtCGC;
          this.filters.dtCGCNew = JSON.parse(JSON.stringify(this.filters.dtCGC));
          this.filters.dtSBU = response.ResponseData.dtSBU;
          this.filters.dtProjectId = response.ResponseData.dtProjectId;
          this.filters.dtRole = response.ResponseData.dtRole;
          this.filters.dtUserInfo = response.ResponseData.dtUserInfo;
          this.filters.dtPeriodMaster = response.ResponseData.dtPeriodMaster;
          if (this.filters.dtPeriodMaster.length > 0) {
            this.Months = this.appService.GetPidmappingMonths(this.filters.dtPeriodMaster[0].PeriodYear);
          }

          //this.ShowFilters = true;
        }
      }
        ,
        error => {

        });
  }

  onFilterChange(selectedSBU, selectedCGC, selectedRole) {
    var SBUdataarray = [];

    if (selectedSBU != 0) {
      for (var i in this.tblData) {
        if (this.tblData[i].SBU == selectedSBU) {
          SBUdataarray.push(this.tblData[i]);
        }
      }
    }
    else {
      SBUdataarray = this.tblData;
    }
    var CGCdataarray = [];

    if (selectedCGC != 0) {
      for (var i in SBUdataarray) {
        if (SBUdataarray[i].CGCode == selectedCGC) {
          CGCdataarray.push(SBUdataarray[i]);
        }
      }
    }
    else {
      CGCdataarray = SBUdataarray;
    }
    var ProjectIddataarray = [];

    if (selectedRole != 0) {
      for (var i in CGCdataarray) {
        if (CGCdataarray[i].PlanName == selectedRole) {
          ProjectIddataarray.push(CGCdataarray[i]);
        }
      }
    }

    else {
      ProjectIddataarray = CGCdataarray;
    }
    this.AccountwiseData_Content = ProjectIddataarray;
    if (this.AccountwiseData_Content.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "No record found for the selected filter(s)."
      });
      return;
    }
  }

  SearchFor(value): any {
    if (this.ShowIOptionTypeSection == 1) {
      var data = this.ResetData
      if (value != '' && value != undefined) {
        if (value.length >= 2) {
          const filterValue = value.toLowerCase();
          this.SearchText = value;
          for (var o in data) {
            if (data[o].Name == null)
              data[o].Name = "";
          }
          data = data.filter(option => option.Name.toLowerCase().includes(filterValue));

        }
      }
    }
    else {
      var data = this.ResetDataProject;
      var dataFinal = [];
      if (value != '' && value != undefined) {
        if (value.length >= 2) {
          const filterValue = value.toLowerCase();
          var cntr = 1;

          while (cntr <= this.CountPSNo) {
            for (var o in data) {
              if (data[o]['Name ' + cntr].toLowerCase().includes(filterValue)) {
                dataFinal.push(data[o]);
                continue;
              }

            }
            cntr++;

          }
        }
      }
      else {
        dataFinal = data;
      }

    }
    if (this.ShowIOptionTypeSection == 1) {
      this.AccountwiseData_Content = data;
    }
    else {
      this.ProjectwiseData_Content = dataFinal;
    }

  }

  onReset(): void {

    if (this.ShowIOptionTypeSection == 1) {
      this.selectedSBU = 0;

      this.selectedRole = 0;
      this.AccountwiseData_Content = this.ResetData;
      this.SearchText = '';
    }
    else {
      this.selectedProjectID = 0;
      //this.selectedOwnDelivery = 0;
      this.ProjectwiseData_Content = this.ResetDataProject;
      this.SearchText = '';
    }

  }

  GetAccountwiseDataForPIDMapping(value_cgcode: string): void {

    for (var o in this.filters.dtCGC) {
      if (this.filters.dtCGC[o].CGCode == value_cgcode) {
        this.selectedCGC_name = this.filters.dtCGC[o].CGName;
        break;
      }
    }

    var Input = {
      "Period": this.periodid,
      "CGCode": value_cgcode,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetAccountwiseDataForPIDMappingURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.onReset();
          this.AccountwiseData_Content = response.ResponseData.dtAccountwiseData_Content;
          this.AccountwiseData_Header = response.ResponseData.dtAccountwiseData_Header;
          this.tblData = this.AccountwiseData_Content;
          this.ResetData = this.AccountwiseData_Content;

        }
      }
        ,
        error => {
        });
  }

  AddNewRowToAccountData() {

    var Newdata = {
      'IsSaved': 0,
      'CGCode': this.selectedCGC,
      'CustomerName': this.selectedCGC_name,
      'SBU': '',
      'Effective From': '0',
      'Effective To': '0',
      'PSno': "0",
      'Name': '0',
      'PlanName': '',
      'PK':''
    };

    this.AccountwiseData_Content.push(Newdata);
  }

  addrowdata(PSNO, index) {
    var flagpresent = 0;
    for (var i in this.AccountwiseData_Content) {
      if (this.AccountwiseData_Content[i].PSno == PSNO && i != index) {
        flagpresent = 1;
        break;
      }
    }
    if (flagpresent == 0) {
      for (var o in this.filters.dtUserInfo) {
        if (this.filters.dtUserInfo[o].PSno == PSNO) {

          this.AccountwiseData_Content[index].PlanName = this.filters.dtUserInfo[o].PlanName;
          this.AccountwiseData_Content[index].SBU = this.filters.dtUserInfo[o].SBU;
          this.AccountwiseData_Content[index].PSno = PSNO;
          this.AccountwiseData_Content[index].Name = this.filters.dtUserInfo[o].Name;
          break;
        }
      }
    }

    else {
      this.AccountwiseData_Content[index].PlanName = null;
      this.AccountwiseData_Content[index].SBU = null;
      this.AccountwiseData_Content[index].PSno = 0;
      this.AccountwiseData_Content[index].Name = 0;
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please choose another person."
      });
    }
  }




  onclickcheckbox(x) {

    if (this.AccountwiseData_Content[x].Edit == 0) {
      this.AccountwiseData_Content[x].Edit = 1;
    }

    else {
      this.AccountwiseData_Content[x].Edit = 0;
    }

  }

  onsavevalidate(value) {


    var PeriodStDate = this.filters.dtPeriodMaster[0].StartDate.split("-");
    var PeriodEndDate = this.filters.dtPeriodMaster[0].EndDate.split("-");

    var dtPeriodStDate = new Date(PeriodStDate[0], parseInt(PeriodStDate[1]) - 1, parseInt(PeriodStDate[2]));
    var dtPeriodEndDate = new Date(PeriodEndDate[0], parseInt(PeriodEndDate[1]) - 1, parseInt(PeriodEndDate[2]));


    var errorArray = [];
    for (var i in this.AccountwiseData_Content) {

      if (this.AccountwiseData_Content[i].Name == "" || this.AccountwiseData_Content[i].Name == null || this.AccountwiseData_Content[i].Name == '0' || this.AccountwiseData_Content[i].PSno == 0) {
        errorArray.push("Please select name.")

      }
      else {
        if (this.AccountwiseData_Content[i]['Effective From'] == "" || this.AccountwiseData_Content[i]['Effective From'] == 0) {
          errorArray.push("Please select effective from date for: " + this.AccountwiseData_Content[i].Name)

        }
        else {
          if (dtPeriodStDate > new Date(this.AccountwiseData_Content[i]['Effective From'].split("-")[0], parseInt(this.AccountwiseData_Content[i]['Effective From'].split("-")[1]) - 1, 1)) {
            errorArray.push("Please select appropriate effective from date for: " + this.AccountwiseData_Content[i].Name)
          }
        }
        if (this.AccountwiseData_Content[i]['Effective To'] == "" || this.AccountwiseData_Content[i]['Effective To'] == 0) {
          errorArray.push("Please select effective to date for: " + this.AccountwiseData_Content[i].Name)
        }
        else {
          if (dtPeriodEndDate < new Date(this.AccountwiseData_Content[i]['Effective To'].split("-")[0], parseInt(this.AccountwiseData_Content[i]['Effective To'].split("-")[1]) - 1, 1)) {
            errorArray.push("Please select appropriate effective to date for: " + this.AccountwiseData_Content[i].Name)
          }
        }



        if ((new Date(this.AccountwiseData_Content[i]['Effective To'].split("-")[0], parseInt(this.AccountwiseData_Content[i]['Effective To'].split("-")[1]) - 1, 1)) < (new Date(this.AccountwiseData_Content[i]['Effective From'].split("-")[0], parseInt(this.AccountwiseData_Content[i]['Effective From'].split("-")[1]) - 1, 1))) {
          errorArray.push("Please select effective to date greater than effective from date for: " + this.AccountwiseData_Content[i].Name)
        }
      }
    }
    if (errorArray.length <= 0) {
      this.onclicksaveAccount(value);
    }
    else {
      this.dialog.open(DialogDataExampleDialog, {
        data: errorArray
      });

    }

  }

  onclicksaveAccount(value) {

    var data = [];

    for (var i in this.AccountwiseData_Content) {
      if (this.AccountwiseData_Content[i].IsSaved == 0 || this.AccountwiseData_Content[i].Edit == 1) {
        var newdata = {
          'PeriodId': value,
          'PSno': this.AccountwiseData_Content[i].PSno,
          'CGCode': this.AccountwiseData_Content[i].CGCode,
          'EffectiveFrom': this.AccountwiseData_Content[i]['Effective From'],
          'EffectiveTo': this.AccountwiseData_Content[i]['Effective To'],
          'IsSaved': 0,
          'SBU': this.AccountwiseData_Content[i].SBU,
          'Percentage': 100,
          'PK': ''

        }
        if (this.AccountwiseData_Content[i].Edit == 1) {
          newdata.PK = this.AccountwiseData_Content[i].PK;
        }
        else {
          newdata.PK = newdata.CGCode + newdata.PSno + newdata.PeriodId;
        }
        data.push(newdata);

      }
    }

    var Input = {
      "Period": value,
      "LoggedInPSNo": this.userInfo.PS_No,
      "dtAccountwiseData_Content": data
    };

    if (data.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "There is no changes to save."
      });
    }
    else {
      this.appService.SetLoadingShow(true);
      var url = this.configs.SaveAccountwiseDataForPIDMappingURL();
      this.appService.GetDataFromAPI(url, Input)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.GetAccountwiseDataForPIDMapping(this.selectedCGC);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
        }
          ,
          error => {

          });
    }
  }

  onclickdelete(Period, index) {

    if (this.AccountwiseData_Content[index].IsSaved == 0) {
      this.AccountwiseData_Content.splice(index, 1);
    }
    var data = {
      'PK': this.AccountwiseData_Content[index].PK,
      'PSno': this.AccountwiseData_Content[index].PSno,
      'UpdatedBy': this.userInfo.PS_No,
      'AppType': this.userInfo.AppType
    };

    this.appService.SetLoadingShow(true);
    var url = this.configs.DeleteAccountwiseDataForPIDMappingURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.GetAccountwiseDataForPIDMapping(this.selectedCGC);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
      },
        error => {

        });


  }

  GetDataForPIDMapping() {
    if (this.selectedCGCNew != 'Select') {
      this.selectedCGC = this.selectedCGCNew.split("-")[0];

      if (this.selectedCGC != 0) {
        if (this.ShowIOptionTypeSection == 1) {
          this.GetAccountwiseDataForPIDMapping(this.selectedCGC);
        }
        else {
          this.PageNumber = 1;
          this.PageNumberNew = 10;
          this.PrevButtonAllowed = false;
          this.GetProjectwiseDataForPIDMapping(this.selectedCGC, this.PageNumber, this.PageNumberNew);
        }
      }
    }
  }

  optionSelected(value) {
    const filterValue = value.toLowerCase();

    this.filters.dtCGCNew = JSON.parse(JSON.stringify(this.filters.dtCGC));
    this.filters.dtCGCNew = this.filters.dtCGC.filter(option => option.CGCodeNew.toLowerCase().includes(filterValue));
  }

  //GetDataAfterFilter(value) {
  //    for (var o in this.filters.dtCGC) {
  //        if (this.filters.dtCGC[o].Text.trim() == value.option.value.trim()) {
  //            this.selectedCGC = this.filters.dtCGC[o].CGCode;
  //            this.GetDataForPIDMapping();
  //            break;
  //        }
  //    }
  //}

  GetProjectwiseDataForPIDMapping(value_cgcode: string, PageNumber : number, PageNumberNew : number): void {

    if (this.PageNumber == 1) {
      this.PrevButtonAllowed = false;
    }
    else {
      this.PrevButtonAllowed = true;
    }


    for (var o in this.filters.dtCGC) {
      if (this.filters.dtCGC[o].CGCode == value_cgcode) {
        this.selectedCGC_name = this.filters.dtCGC[o].CGName;
        break;
      }
    }
    this.filters.dtProjectIdCG = this.filters.dtProjectId.filter(option => option.CGCode == value_cgcode);
    var Input = {
      "Period": this.periodid,
      "CGCode": value_cgcode,
      "MinPageNumber": PageNumber,
      "MaxPageNumber": PageNumberNew
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetProjectwiseDataForPIDMappingURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.onReset();
          this.CountPSNo = response.ResponseData.CountPSNo;
          this.RowCount = response.ResponseData.RowCount;
          this.ProjectwiseData_Content = response.ResponseData.dtProjectWiseData_Content;
          this.ProjectwiseData_Header = response.ResponseData.dtProjectWiseData_Headers;
          this.tblDataProject = this.ProjectwiseData_Content;
          this.ResetDataProject = this.ProjectwiseData_Content;
          if (this.ProjectwiseData_Content.length < 10) {
            this.NextButtonAllowed = false;
            this.PageNumberNew = this.RowCount;
          }
          else {
            this.NextButtonAllowed = true;
          }
          if (this.ProjectwiseData_Content.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found for the selected filter(s)."
            });
            return;
          }
        }
      },
        error => {
        });
  }

  addrowdataForProject(Name, ColumnName, i) {
    var flagpresent = 0;

    var cntr = 1;
    while (cntr <= this.CountPSNo) {
      if ('Name ' + cntr != ColumnName && this.ProjectwiseData_Content[i]['Name ' + cntr] != 0 && this.ProjectwiseData_Content[i]['Name ' + cntr] != null && this.ProjectwiseData_Content[i]['Name ' + cntr] != undefined && this.ProjectwiseData_Content[i]['Name ' + cntr] == Name) {
        flagpresent = 1;
        break;
      }
      cntr++;
    }
    var k = ColumnName.split(' ')[1];

    if (flagpresent == 0) {
      for (var o in this.filters.dtUserInfo) {
        if (this.filters.dtUserInfo[o].Name == Name) {
          this.ProjectwiseData_Content[i]['PSNo ' + k] = this.filters.dtUserInfo[o].PSno;
          this.ProjectwiseData_Content[i][ColumnName] = this.filters.dtUserInfo[o].Name;
          this.ProjectwiseData_Content[i]['PK ' + k] = '';
          break;
        }
      }
    }

    else {
      this.ProjectwiseData_Content[i]['PSNo ' + k] = 0;
      this.ProjectwiseData_Content[i]['Name ' + k] = 0;

      this.dialog.open(DialogDataExampleDialog, {
        data: "Please choose another person."
      });
    }

  }
  addrowdataProject(PSNO, index) {
    for (var o in this.filters.dtUserInfo) {
      if (this.filters.dtUserInfo[o].PSno == PSNO) {

        this.ProjectwiseData_Content[index].Name = this.filters.dtUserInfo[o].Name;
        break;
      }
    }
  }

  onclicksaveProject(value) {

    var data = [];
    for (var i in this.ProjectwiseData_Content) {
      if (this.ProjectwiseData_Content[i].IsEdit == '1') {
        var cntr = 1;
        while (cntr <= this.CountPSNo) {
          if (this.ProjectwiseData_Content[i]['PSNo ' + cntr] != 0 && this.ProjectwiseData_Content[i]['PSNo ' + cntr] != null && this.ProjectwiseData_Content[i]['PSNo ' + cntr] != undefined) {
            data.push({
              'PeriodId': value,
              'PSNo': this.ProjectwiseData_Content[i]['PSNo ' + cntr],
              'CGCode': this.selectedCGC,
              'PID': this.ProjectwiseData_Content[i].PID,
              'CustomerCode': this.ProjectwiseData_Content[i]['Customer Code'],
              'EffectiveFrom': this.ProjectwiseData_Content[i]['From ' + cntr],
              'EffectiveTo': this.ProjectwiseData_Content[i]['To ' + cntr],
              'OwnDelivery': this.ProjectwiseData_Content[i]['Own Delivery'],
              'Percentage': this.ProjectwiseData_Content[i]['% ' + cntr],
              'PK': this.ProjectwiseData_Content[i]['PK ' + cntr],
            });
          }
          cntr++;
        }

      }
    }

    var Input = {
      "Period": value,
      "LoggedInPSNo": this.userInfo.PS_No,
      "dtProjectWiseData_Content": data,
      'AppType': this.userInfo.AppType
    };

    if (data.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "There is no changes to save."
      });
    }
    else {
      this.appService.SetLoadingShow(true);
      var url = this.configs.SaveProjectwiseDataForPIDMappingURL();
      this.appService.GetDataFromAPI(url, Input)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.ProjectwiseData_Content = [];
            this.GetProjectwiseDataForPIDMapping(this.selectedCGC, this.PageNumber, this.PageNumberNew);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
        }
          ,
          error => {

          });
    }
  }


  onclickcheckboxforProject(x) {

    if (this.ProjectwiseData_Content[x].IsEdit == '0') {
      this.ProjectwiseData_Content[x].IsEdit = '1';
    }

    else {
      this.ProjectwiseData_Content[x].IsEdit = '0';
    }

  }

  onclicksavevalidateProject(periodid) {
    var PeriodStartDate = this.filters.dtPeriodMaster[0].StartDate.split("-");
    var PeriodEndDate = this.filters.dtPeriodMaster[0].EndDate.split("-");

    var dtPeriodStartDate = new Date(PeriodStartDate[0], parseInt(PeriodStartDate[1]) - 1, parseInt(PeriodStartDate[2]));
    var dtPeriodEndDate = new Date(PeriodEndDate[0], parseInt(PeriodEndDate[1]) - 1, parseInt(PeriodEndDate[2]));

    var errorArray = [];

    for (var i in this.ProjectwiseData_Content) {
      if (this.ProjectwiseData_Content[i].IsEdit == '1') {
        var cntr = 1;
        while (cntr <= this.CountPSNo ) {
          if (this.ProjectwiseData_Content[i]['Name ' + cntr] != null && this.ProjectwiseData_Content[i]['Name ' + cntr] != "" && this.ProjectwiseData_Content[i]['Name ' + cntr] != undefined) {
            if (this.ProjectwiseData_Content[i]['From ' + cntr] == null || this.ProjectwiseData_Content[i]['From ' + cntr] == "") {
              errorArray.push("Please select effective from date for: " + this.ProjectwiseData_Content[i]['Name ' + cntr])
            }
            else {
              if (dtPeriodStartDate > new Date(this.ProjectwiseData_Content[i]['From ' + cntr].split("-")[0], parseInt(this.ProjectwiseData_Content[i]['From ' + cntr].split("-")[1]) - 1, 1))
                errorArray.push("Please select appropriate effective from date for: " + this.ProjectwiseData_Content[i]['Name ' + cntr])
            }


            if (this.ProjectwiseData_Content[i]['To ' + cntr] == null || this.ProjectwiseData_Content[i]['To ' + cntr] == "") {
              errorArray.push("Please select effective to date for: " + this.ProjectwiseData_Content[i]['Name ' + cntr])
            }
            else {
              if (dtPeriodEndDate < new Date(this.ProjectwiseData_Content[i]['To ' + cntr].split("-")[0], parseInt(this.ProjectwiseData_Content[i]['To ' + cntr].split("-")[1]) - 1, 1))
                errorArray.push("Please select appropriate effective To date for: " + this.ProjectwiseData_Content[i]['Name ' + cntr])
            }

            if (this.ProjectwiseData_Content[i]['% ' + cntr] == null || this.ProjectwiseData_Content[i]['% ' + cntr] == "") {
              errorArray.push("Please enter percentage value for: " + this.ProjectwiseData_Content[i]['Name ' + cntr])
            }

            if (this.ProjectwiseData_Content[i]['To ' + cntr]!= null && this.ProjectwiseData_Content[i]['From ' + cntr] != null) {
              if ((new Date(this.ProjectwiseData_Content[i]['To ' + cntr].split("-")[0], parseInt(this.ProjectwiseData_Content[i]['To ' + cntr].split("-")[1]) - 1, 1)) < (new Date(this.ProjectwiseData_Content[i]['From ' + cntr].split("-")[0], parseInt(this.ProjectwiseData_Content[i]['From ' + cntr].split("-")[1]) - 1, 1))) {
                errorArray.push("Please select effective to date greater than effective from date for: " + this.ProjectwiseData_Content[i]['Name ' + cntr])
              }
            }

          }
          cntr++;
        }

      }

    }



    if (errorArray.length <= 0) {
      this.onclicksaveProject(periodid);
    }
    else {
      this.dialog.open(DialogDataExampleDialog, {
        data: errorArray
      });

    }
  }

  onFilterChangeProject(selectedProjectID) {

    var ProjectIDdataarray = [];

    if (selectedProjectID != 0) {
      for (var i in this.tblDataProject) {
        if (this.tblDataProject[i].PID == selectedProjectID) {
          ProjectIDdataarray.push(this.tblDataProject[i]);
        }
      }
    }
    else {
      ProjectIDdataarray = this.tblDataProject;
    }
    this.ProjectwiseData_Content = ProjectIDdataarray;
    if (this.ProjectwiseData_Content.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "No record found for the selected filter(s)."
      });
      return;
    }
  }

  onclickOfPrevious() {
    if (this.NextButtonAllowed == false) {
      this.PageNumberNew = this.PageNumberNew  - ((this.PageNumberNew - this.PageNumber) + 1);
    }
    else {
      this.PageNumberNew = this.PageNumberNew - 10;
    }

    this.PageNumber = this.PageNumber - 10;
    this.GetProjectwiseDataForPIDMapping(this.selectedCGC, this.PageNumber, this.PageNumberNew,);
  }
  onclickOfNext() {
    this.PageNumber = this.PageNumber + 10;

    this.PageNumberNew = this.PageNumberNew + 10;
    this.GetProjectwiseDataForPIDMapping(this.selectedCGC, this.PageNumber, this.PageNumberNew);
  }
}






