import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  emailID: string = "";
  userInfo: any;
  errorMessage: string;
    Message: string;
    PSNO: string;
    themeInfo: any[];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.appService.ClearVariables();
  }

    loginAsUser() {
    let loginInput = {
        //"EmailId": url
        "EmailId": this.emailID.trim()
    }
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetUserDetailsViaAdminURL(), loginInput)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.Message = response.ResponseData;
            this.dialog.open(DialogDataExampleDialog, {
                data: response.ResponseData
            });
        }
        else {
          this.appService.SetThemeValue('Light');
            this.userInfo = response.ResponseData;
            this.PSNO = this.userInfo.PS_No;
            //this.MyLTIThemeInfo();
            if (this.userInfo.PS_No != null && this.userInfo.PS_No != undefined && this.userInfo.PS_No.trim() != '') {
                this.userInfo.AppType = this.userInfo.AccessType[0].AppType;
            this.GetUserData();
            this.appService.SetLoginUserInfo(this.userInfo);
           // this.appService.SetSessionId("1m2n3b", this.userInfo.SessionId);
          }
          else {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: "Please enter valid user details"
            });
          }
        }
      },
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }

  login() {
    this.CheckAdminAccess();
  }
  
  ngOnInit(): void {
    if (!this.configs.isAppEnvironmentSet) {
      this.appService.ClearVariables();
      this.emailID = '';
      var url = this.configs.EnvBaseURL() + this.configs.GetEnvironmentDetailsURL();
      this.appService.GetDataFromAPI(url, null)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this._router.navigate(['1a0y3h']);
          }
          else {
            this.configs.setENV(response.ResponseData);
          }
        },
          error => { this.errorMessage = <string>error; this.appService.SetLoadingShow(false); });
    }
  }
  GetUserData(): void {
    var UserData = {
      "PS_No": this.userInfo.PS_No
    }
    // this.appService.SetLoadingShow(true);
    var url = this.configs.MasterInfoDetailsURL();
    this.appService.GetDataFromAPI(url, UserData)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          // this.appService.SetLoadingShow(false);
          //this.appService.ShowMessagePopup(this.configs.MESSAGETYPEERROR(), response.ReturnMessage);
        }
        else {
          this.appService.ShareMasterDetails(response.ResponseData);
          if (this.userInfo.UserAccessOptions.length > 0) {
            if (this.userInfo.UserAccessOptions.length > 1) {
              this._router.navigate(['home']);
            }
            else {
              this._router.navigate([this.userInfo.UserAccessOptions[0].OptionRoute]);
            }
          }
          else{
             this.dialog.open(DialogDataExampleDialog, {
              data: "You do not have access to the system."
            });
          }

        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  };
    CheckAdminAccess(): void {
        //if (this.configs.isPROD_Web || this.configs.isQA_Auth_Web) {
        //    this.CheckAdminAccessProd();
        //}
        //else {
            this.appService.SetLoadingShow(true);
            var url = this.configs.CheckAdminAccessURL();
            var userInfo = {
                'EmailId': this.appService.GetAdUserMailId()
            };
            this.appService.GetDataFromAPI(url, userInfo)
                .then(response => {
                    this.appService.SetLoadingShow(false);
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.errorMessage = response.ReturnMessage;
                    }
                    else {
                        if (response.ResponseData == true) {
                            this.loginAsUser();
                        }
                        else {
                            this._router.navigate(['1a0y3h']);
                        }

                    }
                },
                    error => { this.errorMessage = <string>error; this.appService.SetLoadingShow(false) });
       // }
    };
    CheckAdminAccessProd(): void {
        this.appService.SetLoadingShow(true);
        //  this.route.params.subscribe(params => this.appFrom = params.from);
        var url = this.configs.ADAuthURL();
        this.appService.GetADLogin(url)
            .then(users => {
                if (users) {
                    if (users.length > 0) {
                        // this.emailID = users[0]["user_id"];
                        this.appService.SetAdUserMailId(this.emailID);
                        //this.appService.SetCookieTokenId("1a2l3z", users[0]["id_token"]);
                        var url = this.configs.CheckAdminAccessURL();
                        var userInfo = {
                            'EmailId': this.appService.GetAdUserMailId()
                        };
                        this.appService.GetDataFromAPI(url, userInfo)
                            .then(response => {
                                this.appService.SetLoadingShow(false);
                                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                                    this.errorMessage = response.ReturnMessage;
                                }
                                else {
                                    if (response.ResponseData == true) {
                                        this.loginAsUser();
                                    }
                                    else {
                                        this._router.navigate(['1a0y3h']);
                                    }

                                }
                            },
                                error => { this.errorMessage = <string>error; this.appService.SetLoadingShow(false) });
                    }
                }
            }
                ,
                error => { this.errorMessage = <string>error; });

    }

    //MyLTI Theme
    MyLTIThemeInfo() {
        var url = this.configs.GetMyLTIThemeURL();
        let data = {
            'psno': this.PSNO
        }
        //this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                //this.appService.SetLoadingShow(false);
                this.themeInfo;
                if (response.ResponseData.length > 0) {
                    let a = JSON.parse(response.ResponseData);
                    if (a != null && a != undefined) {
                        if (a.length>0) {
                            this.appService.SetThemeValue(a[0].theme);
                        }
                    }
                }
            },
                error => {
                    this.errorMessage = <string>error;
                });
    }

}

