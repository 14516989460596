import { Component, OnInit, AfterContentChecked, OnDestroy, HostListener} from '@angular/core';
import { MatCardModule } from '@angular/material';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { Configs } from './app.config';
//import { UserIdleService } from 'angular-user-idle';//for Autologout Functionality
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from './popup.component';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentChecked {
    title = 'SPM';
    loaderShow: boolean = true;
    emailID: string = "";
    tokenId: string = "";
    SessionId: string = ""; 
    userInfo: any;
    errorMessage: string;
    Message: string;
    PSNO: string;
    themeInfo: any;
  constructor(private appService: AppService, private configs: Configs, private _router: Router, public dialog: MatDialog, private adalSvc: MsAdalAngular6Service) {
        this.appService.loadingShowChange.subscribe((data) => {
            this.loaderShow = JSON.parse(data);
        });
        this.appService.ClearVariables();
        //window.addEventListener("beforeunload", (event) => {
        //    this.LogoutSessionNew();
        //    var delayInMilliseconds = 1000; //1 second

        //    setTimeout(function () {
        //        event.preventDefault();
        //        // Chrome requires returnValue to be set.
        //        event.returnValue = '';
        //    }, 1000);
        //    // Cancel the event as stated by the standard.
    
        //});
    }
  ngOnInit(): void {
    this.AdalLogin();
  }



  LoginAfterAdal(): void {

        //Session timeout//
        //this.userIdle.startWatching();
 
        //this.userIdle.onTimerStart().subscribe();
        //this.userIdle.onTimerStart().subscribe(count => {
        //    var eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'];
        //    for (let event of eventList) {
        //        document.body.addEventListener(event, () => this.userIdle.resetTimer());
        //    }
        //});
        
        //this.userIdle.onTimeout().subscribe(() => this.Autologout());
        //Session timeout//
        

        if (!this.configs.isAppEnvironmentSet) {
            this.appService.ClearVariables();
            var url = this.configs.EnvBaseURL() + this.configs.GetEnvironmentDetailsURL();
            this.appService.GetDataFromAPI(url, null)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        //this.AdLogin();
                    }
                    else {
                        this.configs.setENV(response.ResponseData);
                        //if (!this.configs.isDev && !this.configs.isPre_PROD_Web && !this.configs.isPre_PROD_Mob && !this.configs.isQA_Mob && !this.configs.isQA_Web) {
                        //    if (this.configs.isMobile) {
                        //        this.emailID = localStorage.getItem('UserEmailId');
                        //        this.Login('');
                        //    }
                        //    else {
                        //        this.AdLogin();
                        //    }
                        //}
                        //else {
                        //    this._router.navigate(['1a0y3h']);
                        //}
                      this.Login('');
                    }
                },
                    error => { this.errorMessage = <string>error; this.appService.SetLoadingShow(false); });
        }
        else {
            //if (!this.configs.isDev) {
            //    this.appService.ClearVariables();
            //    this.AdLogin();
            //}
            //else {
            //    this._router.navigate(['1a0y3h']);
            //}
          this.Login('');
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    OnDestroy($event) {
        if (!this.configs.isDev && !this.configs.isPre_PROD_Mob && !this.configs.isQA_Mob && !this.configs.isQA_Web && !this.configs.isPROD_Mob) {
            this.LogoutSessionNew();
            this._router.navigate(['logout']);
        }
        //$event.preventDefault();
        //$event.returnValue = 'A message.';
        
    }

    //FOR AUTOLOGOUT
    stop() {
       // this.userIdle.stopTimer();
    }

    stopWatching() {
       // this.userIdle.stopWatching();
    }

    startWatching() {
        //this.userIdle.startWatching();
    }

    restart() {
        //this.userIdle.resetTimer();
    }

    ngAfterContentChecked(): void {
        this.appService.loadingShowChange.subscribe((data) => {
            this.loaderShow = JSON.parse(data);
        });
    }
    GetUserData(): void {
        var UserData = {
            "PS_No": this.userInfo.PS_No
        }
        var url = this.configs.MasterInfoDetailsURL();
        this.appService.GetDataFromAPI(url, UserData)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this._router.navigate(['1a0y3h']);
                }
                else {
                    this.appService.ShareMasterDetails(response.ResponseData);
                    if (this.userInfo.UserAccessOptions.length > 0) {
                        if (this.userInfo.UserAccessOptions.length > 1) {
                            this._router.navigate(['home']);
                        }
                        else {
                            this._router.navigate([this.userInfo.UserAccessOptions[0].OptionRoute]);
                        }
                    }
                    else {
                        this._router.navigate(['1a0y3h']);
                    }

                }
            }
                ,
                error => {
                });
    };

    Login(token) {
        let loginInput = {
            "EmailId": this.emailID.trim()
        }
        this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPILogin(this.configs.GetUserDetailsURL(), loginInput,token )
            .then(response => {
                if (response.ReturnCode == this.configs.RetCodeFailure()) {
                    this.appService.SetLoadingShow(false);
                    this.Message = response.ResponseData;
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                  this.appService.SetThemeValue('Light');
                  this.userInfo = response.ResponseData;
                  this.PSNO = this.userInfo.PS_No;
                  //this.MyLTIThemeInfo();
                  if (this.userInfo.PS_No != null && this.userInfo.PS_No != undefined && this.userInfo.PS_No.trim() != '') {
                    this.userInfo.AppType = this.userInfo.AccessType[0].AppType;
                    this.GetUserData();
                    this.appService.SetLoginUserInfo(this.userInfo);
                    //this.appService.SetSessionId("1m2n3b", this.userInfo.SessionId);

                    }
                    else {
                        this.appService.SetLoadingShow(false);
                        this._router.navigate(['no-access']);
                    }
                }
            },
                error => {
                    this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
                });
    }

    AdLogin(): void {
        //  this.route.params.subscribe(params => this.appFrom = params.from);
        var url = this.configs.ADAuthURL();
        this.appService.GetADLogin(url)
            .then(users => {
                if (users) {
                    if (users.length > 0) {
                       // this.emailID = users[0]["user_id"];
                        this.appService.SetAdUserMailId(this.emailID);
                        this.tokenId = users[0]["id_token"];
                        //this.appService.SetCookieTokenId("1a2l3z", this.tokenId);
                        this.Login(users[0]["id_token"]);
                        
                    }
                }
            }
                ,
                error => { this.errorMessage = <string>error; });

    }
     //FOR AUTOLOGOUT
    Autologout(): void {
      if (!this.configs.isDev && !this.configs.isPre_PROD_Mob && !this.configs.isQA_Mob && !this.configs.isQA_Web && !this.configs.isPROD_Mob) {
            if (this.userInfo != null && this.userInfo != undefined) {
                this.LogoutSession('Y');
            }
            else {
                let autologout1url = this.configs.AutoLogoutURL();
      
                window.location.href = autologout1url;
            }
        }
    }

    LogoutSession(Redirect) {
        let data = {
            "SessionId": this.userInfo.SessionId
        }
        this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPI(this.configs.UpdateUserSessionInfoURL(), data)
            .then(response => {
                if (response.ReturnCode == this.configs.RetCodeFailure()) {
                }
                else {
                    if (Redirect == 'Y') {
                        let autologout1url = this.configs.AutoLogoutURL();
                    
                        window.location.href = autologout1url;
                    }
                }
            },
                error => {
                    this.errorMessage = <string>error;
                });
    }
    //LogoutSessionNew() {
    //    this.userInfo = this.appService.GetLoginUserInfo();
    //    let data = {
    //        "SessionId": this.userInfo.SessionId
    //    }
       
    //    this.appService.CloseSession(this.configs.UpdateUserSessionInfoURL(), data);
    //}
    LogoutSessionNew() {
      if (!this.configs.isDev && !this.configs.isPre_PROD_Mob && !this.configs.isQA_Mob && !this.configs.isQA_Web && !this.configs.isPROD_Mob) {
            this.userInfo = this.appService.GetLoginUserInfo();
            let data = {
                "SessionId": this.userInfo.SessionId
            }
            this.appService.GetDataFromAPI(this.configs.UpdateUserSessionInfoURL(), data)
                .then(response => {

                },
                    error => {
                        this.errorMessage = <string>error;
                    });

        }
    }

    MyLTIThemeInfo() {
        var url = this.configs.GetMyLTIThemeURL();
        let data = {
            'psno': this.PSNO
        }
        //this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPI(url, data)
            .then(response => {
                //this.appService.SetLoadingShow(false);
                this.themeInfo;
                if (response.ResponseData.length > 0) {
                    let a = JSON.parse(response.ResponseData);
                    if (a != null && a != undefined) {
                        if (a.length > 0) {
                            this.appService.SetThemeValue(a[0].theme);
                        }
                    }
                }
            },
                error => {
                    this.errorMessage = <string>error;
                });
  }

  themeLocalService() {
    return this.appService.GetThemeValue();
  }
    AdalLogin() {

      if (this.adalSvc.userInfo != null) {

          this.emailID = this.adalSvc.userInfo.userName;
          localStorage.setItem("user.app", window.location.origin);
          this.adalSvc.acquireToken(this.configs.GraphUrl()).subscribe((token: string) => {
              localStorage.setItem("user.token", token);
              this.LoginAfterAdal();
          });
      }
      else {
          this.adalSvc.login();
          this.adalSvc.acquireToken(this.configs.GraphUrl()).subscribe((token: string) => {
              localStorage.setItem("user.token", token);
              this.LoginAfterAdal();
          });
      }
  }

}
