import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MatTableDataSource } from '@angular/material';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.less']
})

export class SuperAdminComponent implements OnInit {
  minDate = new Date();
  public datepickerOptions: Pickadate.DateOptions = {
    clear: 'Clear', // Clear button text
    close: 'Ok',    // Ok button text
    today: 'Today', // Today button text
    closeOnClear: true,
    closeOnSelect: false,
    format: 'mm/dd/yyyy', // Visible date format (defaulted to formatSubmit if provided otherwise 'd mmmm, yyyy')
    formatSubmit: 'yyyy-mm-dd',   // Return value format (used to set/get value)
    //onClose: () => alert('Close has been invoked.'),
    //onOpen: () => alert('Open has been invoked.'),
    selectMonths: true, // Creates a dropdown to control month
    selectYears: 10,
    min: this.minDate,
    
    // Creates a dropdown of 10 years to control year,
  };



  autocomplete: { data: { [key: string]: string } };
  selectedPeriod: any;
  roleSelected: string;
  EmpReleaseDates
  selectedPsno: string;
  userInfo: any;

  masterData: any;
  errorMessage: string; parse
  actiontype: any;
  AdminTemplates: any;
  AdminTemplatesFiltered: any = [];
  PeriodData: any;
  PeriodDataFiltered: any;
  PlanFiltered: any = [];
  UserFiltered: any;
  EmpReleaseDatesFiltered: any;
  EmpReleaseDatesNew: any;
  dtSBU: any;
  dtFunction: any;
  dtRole: any;
  dtRoleFiltered: any;
  showTable: any;
  cetpsno: any;
  uploadedFile: File;
  PSNoTable: any = [];
  SBU: string = '';
  PSNo: string = '';
  MetricSearchArray: any;
  QuanPercentage: any = [];
  DownloadFilePath: any;
  SearchText1: any;
  TotalValues: any;
  sum: any = 0;

  ShowNewRowMetricConfig: boolean;
  showsubmit: boolean = true;
  displayedColumns1: string[] = ['MetricName', 'MetricValue', 'action'];
  dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);

  dataSourceForPlan = new MatTableDataSource(this.PlanFiltered);
  dataSourcePSNO = new MatTableDataSource(this.PSNoTable);
  //displayedColumnsFooter1: string[] = [];
  todaysDate: any;
  ShowNewRowMetricId: boolean;
  ShowNewPlan: boolean;
  displayedColumns3: string[] = ['SNo', 'MetricName', 'Measurement', 'ThresholdMeasurementBy', 'isMetricNegative', 'IsAlphaNumericTarget', 'IsPercentage', 'IsProrated', 'action'];
  displayedColumns6: string[] = ['PlanName', 'action', 'isHalfYearly'];
  displayedColumns5: string[] = ['SBU', 'AccessTo', 'action'];
  //displayedColumnsFooter3: string[] = [];
  MetricsMaster: any = [];
  //MetricsFilter: any;
  dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMaster);
  ManagerSubmissionDate: any;
  ReviewerSubmissionDate: any;
  EmpSubmissionDate: any;
  CETSubmissionDate: any;
  HRSubmissionDate: any;
  SubmissionDates: any;
  roleSelectedName: any;
  PeriodYearText: any;
  selectedSBU: any;
  selectedFunction: any;
  selectedrole: any;
  EmpViewDate: any;
  EmpSubStartDate: any;
  EmpDeadline: any;
  ManagerDeadline: any;
  ReviewerDeadline: any;
  SearchText: any;
  selectAll: boolean = false;
  dropdownListRole: any = [];
  dropdownListSBU: any = [];
  dropdownListFunction: any = [];
  dropdownSettings: any = {};
  ProrationArray: any;
  ThresholdMeasurementArray: any;
  MetricsMasterCopy: any;
  MetricConfig: any = [];
  displayedColumns9: string[] = ['MetricName', 'Threshold', 'CappedAtValue', 'ThresholdCappedValue', 'IgnoreInTargetLetter', 'Action'];
  dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);
  MetricConfigFiltered: any;
  ShowNewRowForMetricConfigFiltered: boolean;
  MetricConfigFilteredCopy: any;
  displayedColumns10: string[] = ['MetricName', 'Measurement', 'Sort'];
  MetricSort: any = [];
  dataSourceMetricSort = new MatTableDataSource(this.MetricSort);
  TypeMaster: any;
  typeSelected: string;
  QualitativeMetricsMaster: any = [];
  dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMaster);
  QualitativeMetricsMasterCopy: any;
  QualitativedisplayedColumns3: string[] = ['SNo', 'MetricName', 'Measurement', 'action'];
  //dueDate: string;
  SBUDistinct: any = [];
  SBUDistinctModel: any = [];
  dtStatusEmployeReleaseDates: any;
  dropdownListStatus = [];
  selectedItemsForStatus = [];
 
  Value: number = 0;
  inputValues: number[] = new Array(10).fill(0);
  
 
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) {
    this.appService.SetLoadingShow(false);
 
    

    this.selectedPeriod = this.appService.AutoPeriodId();
    this.actiontype = '0';
    this.roleSelected = '0';
    this.showTable = '0';
    //this.MetricValue = '';
    //this.MetricName = '';
    //this.MetricId = '0';
    this.ShowNewRowMetricConfig = false;
    //this.displayedColumnsFooter1 = [];
    //this.NewMetricName = '';
    this.ShowNewRowMetricId = false;
    this.ShowNewPlan = true;
    //this.displayedColumnsFooter3 = [];
    this.MetricsMaster = [];
    this.ManagerSubmissionDate = '';
    this.ReviewerSubmissionDate = '';
    this.EmpSubmissionDate = '';
    this.CETSubmissionDate = '';
    this.HRSubmissionDate = '';
    this.SubmissionDates = [];
    this.roleSelectedName = '';
    this.PeriodYearText = '';
    this.MetricSearchArray = [];
    this.dtSBU = [];
    this.dtFunction = [];
    this.dtRole = [];
    this.EmpReleaseDatesFiltered = [];
    this.EmpReleaseDatesNew = [];
    this.selectedSBU = [];
    this.selectedrole = [];
    this.SearchText = '';
    this.ProrationArray = [{ 'ID': 0, 'Text': 'No Proration' }, { 'ID': 1, 'Text': 'Proration by Month' }, { 'ID': 2, 'Text': 'Proration by Days' }];
    this.ThresholdMeasurementArray = [{ 'Text': 'Calculated By System' }, { 'Text': 'Uploaded with Targets' }];
    this.MetricsMasterCopy = [];
    this.MetricConfig = [];
    this.MetricConfigFiltered = [];
    this.ShowNewRowForMetricConfigFiltered = false;
    this.MetricConfigFilteredCopy = [];
    this.MetricSort = [];
    this.TypeMaster = [];
    this.typeSelected = '';
    this.QualitativeMetricsMaster = [];
    this.QualitativeMetricsMasterCopy = [];
    this.SBUDistinct = [];
    this.SBUDistinctModel = [];
    this.EmpViewDate = '';
    this.EmpSubStartDate = '';
    this.EmpDeadline = '';
    this.ManagerDeadline = '';
    this.ReviewerDeadline = "";
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo != null) {
      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.cetpsno = this.userInfo.PS_No;
        this.GetMasterData(false);
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: false,
          badgeShowLimit: 1,
          classes: ""
        };
      }
      else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }

  GetMasterData(isrefresh) {
    this.todaysDate = this.appService.GetTodaysDateInYYYYMMDD();
    if (isrefresh) {
      var UserData = {
        "PS_No": this.userInfo.PS_No,
        "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.MasterInfoDetailsURL();
      this.appService.GetDataFromAPI(url, UserData)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.appService.ShareMasterDetails(response.ResponseData);
            this.masterData = this.appService.GetMasterDetails();
            this.ShowNewRowMetricConfig = false;
            this.AdminTemplatesFiltered = JSON.parse(JSON.stringify(this.masterData.dtAdminTemplates));
            this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);
            this.AdminTemplates = JSON.parse(JSON.stringify(this.masterData.dtAdminTemplates));
            this.PeriodData = JSON.parse(JSON.stringify(this.masterData.dtPeriodDet));
            this.PeriodDataFiltered = this.PeriodData.filter((item: any) => item.PeriodType == "Yearly");
            this.ShowNewRowMetricId = false;
            let MetricsFilter = JSON.parse(JSON.stringify(this.masterData.dtMetricsMaster.filter((item: any) => item.isActive == 1)));
            this.MetricsMaster = JSON.parse(JSON.stringify(MetricsFilter));
            let QualitativeMetricsFilter = JSON.parse(JSON.stringify(this.masterData.dtQualitativeMetricsMaster.filter((item: any) => item.isActive == 1)));
            this.QualitativeMetricsMaster = JSON.parse(JSON.stringify(QualitativeMetricsFilter));
            this.MetricSearchArray = JSON.parse(JSON.stringify(this.masterData.dtMetricsMaster));
            this.MetricsMaster = JSON.parse(JSON.stringify(this.MetricSearchArray));
            for (var i in this.MetricsMaster) {
              if (this.MetricsMaster[i].IsProratedValue == 1 || this.MetricsMaster[i].IsProratedValue == 2) {
                this.MetricsMaster[i].alphanumericFlag = 1;
                this.MetricsMaster[i].percentageFlag = 1;
              }
              if (this.MetricsMaster[i].IsPercentageValue == 1) {
                this.MetricsMaster[i].alphanumericFlag = 1;
                this.MetricsMaster[i].proratedFlag = 1;
              }
              if (this.MetricsMaster[i].IsAlphaNumericTargetValue == 1) {
                this.MetricsMaster[i].percentageFlag = 1;
                this.MetricsMaster[i].proratedFlag = 1;
              }
              if (this.MetricsMaster[i].isMetricNegative == 1) {
                this.MetricsMaster[i].alphanumericFlag = 1;
              }
            }
            this.MetricsMasterCopy = JSON.parse(JSON.stringify(this.MetricsMaster.filter((item: any) => item.isActive == 1)));
            this.QualitativeMetricsMasterCopy = JSON.parse(JSON.stringify(QualitativeMetricsFilter));
            this.ShowNewRowForMetricConfigFiltered = false;
            this.TypeMaster = JSON.parse(JSON.stringify(this.masterData.dtTypeMaster));
            this.GetPlanData();
            this.GetSBUAccessToPSNos();
            if (this.MetricsMaster.length == 0) {
              this.dialog.open(DialogDataExampleDialog, {
                data: "No record found."
              });
              // return;
            }
            this.dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMasterCopy);
            if (this.actiontype == '1') {
              this.ChangeType();
              this.ChangeRole();
            }
            if (this.actiontype == '3') {
              this.ChangeType();
              this.dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMasterCopy);
            }
          }
        }
          , error => {
            this.errorMessage = <string>error;
          });
    }
    else {
      this.masterData = this.appService.GetMasterDetails();
      this.ShowNewRowMetricConfig = false;
      this.DownloadFilePath = '../../../UploadTemplates/Incentives.xlsx';
      this.AdminTemplatesFiltered = JSON.parse(JSON.stringify(this.masterData.dtAdminTemplates));
      this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);
      this.AdminTemplates = JSON.parse(JSON.stringify(this.masterData.dtAdminTemplates));
      this.PeriodData = JSON.parse(JSON.stringify(this.masterData.dtPeriodDet));
      this.PeriodDataFiltered = this.PeriodData.filter((item: any) => item.PeriodType == "Yearly");
      this.ShowNewRowMetricId = false;
      let MetricsFilter = JSON.parse(JSON.stringify(this.masterData.dtMetricsMaster.filter((item: any) => item.isActive == 1)));
      this.MetricsMaster = JSON.parse(JSON.stringify(MetricsFilter));
      let QualitativeMetricsFilter = JSON.parse(JSON.stringify(this.masterData.dtQualitativeMetricsMaster.filter((item: any) => item.isActive == 1)));
      this.QualitativeMetricsMaster = JSON.parse(JSON.stringify(QualitativeMetricsFilter));
      for (var i in this.MetricsMaster) {
        if (this.MetricsMaster[i].IsProratedValue == 1 || this.MetricsMaster[i].IsProratedValue == 2) {
          this.MetricsMaster[i].alphanumericFlag = 1;
          this.MetricsMaster[i].percentageFlag = 1;
        }
        if (this.MetricsMaster[i].IsPercentageValue == 1) {
          this.MetricsMaster[i].alphanumericFlag = 1;
          this.MetricsMaster[i].proratedFlag = 1;
        }
        if (this.MetricsMaster[i].IsAlphaNumericTargetValue == 1) {
          this.MetricsMaster[i].percentageFlag = 1;
          this.MetricsMaster[i].proratedFlag = 1;
        }
        if (this.MetricsMaster[i].isMetricNegative == 1) {
          this.MetricsMaster[i].alphanumericFlag = 1;
        }
      }
      this.ShowNewRowForMetricConfigFiltered = false;
      this.GetPlanData();

      this.GetSBUAccessToPSNos();
      this.TypeMaster = JSON.parse(JSON.stringify(this.masterData.dtTypeMaster));

      if (this.MetricsMaster.length == 0) {
        this.dialog.open(DialogDataExampleDialog, {
          data: "No record found."
        });
        //return;
      }
      this.dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMaster);
      if (this.actiontype == '1') {
        this.ChangeType();
        this.ChangeRole();
      }

      if (this.actiontype == '3') {
        this.ChangeType();
        this.dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMaster);
      }
    }

  }

  onPeriodChange(actionType) {
    if (actionType == '2') {
      this.Cancel();
      if (this.selectedPeriod == '0') {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Please select period"
        });
      }
      else {
        this.GetDeadLinesForSubmission();
      }
    }
    else if (actionType == '1') {
      this.ChangeType();
      this.ChangeRole();
    }
    else if (actionType == '8') {
      this.selectedSBU = [];
      this.selectedFunction = [];
      this.selectedrole = [];
      this.SearchText = '';
      this.EmpViewDate = '';
      this.EmpSubStartDate = '';
      this.EmpDeadline = "";
      this.ManagerDeadline = "";
      this.ReviewerDeadline = "";
      this.GetEmployeeReleaseDatesData();
      this.onReset();
    }
    else if (actionType == '9') {
      this.MetricsMaster;
      this.GetMetricConfig();
    }
  }
  ChangeTab() {
    this.showTable = '0';
    if (this.actiontype == '2') {
      this.showTable = '2';
      this.GetMasterData(false);
      /*this.selectedPeriod = '1';*/
      this.onPeriodChange(this.actiontype);
      this.roleSelected = '0';
      this.typeSelected = '0';
    }
    else if (this.actiontype == '1') {
      /*this.selectedPeriod = '0';*/
      this.roleSelected = '0';
      this.typeSelected = '0';
    }
    if (this.actiontype == '3') {
      this.GetMasterData(false);
      /*   this.selectedPeriod = '0';*/
      this.roleSelected = '0';
      this.typeSelected = '0';
    }
    if (this.actiontype == '5') {
      this.showTable = '5';
    }

    if (this.actiontype == '6') {
      this.showTable = '6';
      this.GetPlanData();

      /* this.selectedPeriod = '0';*/
      this.roleSelected = '0';
      this.typeSelected = '0';
    }

    if (this.actiontype == '7') {
      this.showTable = '7';
      this.GetUserData();

      /* this.selectedPeriod = '0';*/
      this.roleSelected = '0';
    }

    if (this.actiontype == '8') {
      this.showTable = '8';
      this.GetEmployeeReleaseDatesData();
      this.onReset();
      /*      this.selectedPeriod = '0';*/
      this.selectedrole = [];
      this.selectedSBU = [];
      this.selectedFunction = [];
    }
    if (this.actiontype == '9') {
      this.showTable = '9';
      /* this.selectedPeriod = '0';*/
    }
    if (this.actiontype == '10') {
      this.showTable = '10';
      /*this.selectedPeriod = '0';*/
      this.GetMetricSort();
    }

  }
  ChangeRole() {
    this.PeriodYearText = '';
    this.showTable = '0';
    this.Cancel();
    var that = this;
    this.TotalValues = 0;


    if (this.roleSelected != '0') {
      for (var o in this.masterData.dtRolesMaster) {
        if (this.masterData.dtRolesMaster[o].RoleId == this.roleSelected) {
          this.roleSelectedName = this.masterData.dtRolesMaster[o].RoleName;
          break;
        }
      }
      if (this.typeSelected == '1') {
        this.AdminTemplates = JSON.parse(JSON.stringify(this.masterData.dtAdminTemplates));
        this.GetQuanQualPercent();
      }
      else if (this.typeSelected == '2') {
        this.AdminTemplates = JSON.parse(JSON.stringify(this.masterData.dtQualitativeTemplate));
        this.GetQuanQualPercent();
      }

      this.AdminTemplatesFiltered = this.AdminTemplates.filter((item: any) => item.RoleId == that.roleSelected && item.PeriodId == that.selectedPeriod);
      this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);

    



      this.PlanFiltered = JSON.parse(JSON.stringify(this.masterData.dtPeriodDet));
      for (var o in this.PlanFiltered) {
        if (this.PlanFiltered[o].PeriodID.toString() == this.selectedPeriod) {
          this.PeriodYearText = this.PlanFiltered[o].TargetUploadYearText;
          break;
        }
      }


      this.dataSourceForPlan = new MatTableDataSource(this.PlanFiltered);

      if (this.AdminTemplatesFiltered.length > 0) {
        this.showTable = '1';
        this.AddValues();
      }
      else {
        this.showTable = '1';
        this.dialog.open(DialogDataExampleDialog, {
          data: "No record found."
        });
        return;
      }
    }
    else {
      this.roleSelectedName = '';
    }
  }
  RemoveMetric(i) {
    this.AdminTemplatesFiltered.splice(i, 1);
    this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);
    this.TotalValues = 0;
    this.AddValues();
  }
  Cancel() {

    this.ShowNewRowMetricConfig = false;

  }
  AddNewRow() {

    if (this.AdminTemplatesFiltered.length < 0 && (this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].SalesMetricsID == 0 || this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].Value == 0)) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Select Metric Details before Adding"
      });
    }
    else {

      if (this.ShowNewRowMetricConfig && this.AdminTemplatesFiltered.length > 0) {
        this.AdminTemplates.push(JSON.parse(JSON.stringify(this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1])));
      }


      this.ShowNewRowMetricConfig = true;
      var newRow = {
        'RoleId': this.roleSelected,
        'Value': 0,
        'SalesMetricsID': 0,
        'MetricName': "",
      };
      this.AdminTemplatesFiltered.push(newRow);
      this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);

    }

  }
  CheckExistingMetrics(name) {
    var MetricName = name.option.value;
    for (let o: number = 0; o < this.AdminTemplatesFiltered.length - 1; o++) {
      if (this.AdminTemplatesFiltered[o].MetricName == MetricName && this.AdminTemplatesFiltered[o].RoleId == this.roleSelected) {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Selected Metric already exists"
        });
        this.AdminTemplatesFiltered.splice(this.AdminTemplatesFiltered.length - 1, 1);
        this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);
        var newRow = {
          'RoleId': this.roleSelected,
          'Value': 0,
          'SalesMetricsID': 0,
          'MetricName': "",
        };
        this.AdminTemplatesFiltered.push(newRow);
        this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);
        break;
      }
    }
    if (this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].MetricName != "") {
      if (this.typeSelected == '1') {
        for (var o in this.masterData.dtMetricsMaster) {
          if (this.masterData.dtMetricsMaster[o].MetricName == MetricName) {
            this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].SalesMetricsID = this.masterData.dtMetricsMaster[o].SalesMetricsID;
            break;
          }
        }
      }
      if (this.typeSelected == '2') {
        for (var o in this.masterData.dtQualitativeMetricsMaster) {
          if (this.masterData.dtQualitativeMetricsMaster[o].MetricName == MetricName) {
            this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].SalesMetricsID = this.masterData.dtQualitativeMetricsMaster[o].SalesMetricsID;
            break;
          }
        }
      }
    }
    this.dataSourceAdminTemplates = new MatTableDataSource(this.AdminTemplatesFiltered);
  }
  SubmitChanges() {
    if (this.AdminTemplatesFiltered.length < 0 && (this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].SalesMetricsID == 0
      || this.AdminTemplatesFiltered[this.AdminTemplatesFiltered.length - 1].Value == 0)) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Select Metric Details before Adding"
      });
    }
    else if (this.CheckOVerallSum()) {
      this.Cancel();
      var data = {
        'PeriodId': this.selectedPeriod,
        'PS_No': this.userInfo.PS_No,
        'RoleName': this.roleSelectedName,
        'saveMetricTemplateForRoles': this.AdminTemplatesFiltered,
        'PeriodYearText': this.PeriodYearText,
        'AppType': this.userInfo.AppType,
        'typeSelected': this.typeSelected
      };
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.SaveMetricsForRoleURL(), data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.GetMasterData(true);
          }
        });
    }
  }

  //
  AddNewRowMetricMaster() {
    let MaxKey = 0;
    if (this.masterData.dtMetricsMaster.length > 0) {
      MaxKey = Math.max.apply(Math, this.masterData.dtMetricsMaster.map(function (o) { return o.SalesMetricsID; }));
      let MaxKeynew = Math.max.apply(Math, this.MetricsMaster.map(function (o) { return o.SalesMetricsID; }));
      if (MaxKeynew > MaxKey) {
        MaxKey = MaxKeynew;
      }
    }
    if (this.MetricsMaster.length == 0) {
      this.ShowNewRowMetricId = true;
      var newRow = {
        'SalesMetricsID': 0,
        'MetricName': '',
        'isActive': 1,
        'Measurement': '',
        'ThresholdMeasurementBytext': 'Calculated By System',
        'IsProratedValue': 0,
        'isMetricNegative': 0,
        'IsAlphaNumericTargetValue': 0,
        'IsPercentageValue': 0,
        'alphanumericFlag': 0,
        'percentageFlag': 0,
        'proratedFlag': 0
      };
      this.MetricsMaster.push(newRow);
      this.MetricsMasterCopy = [];
      for (let matrics in this.MetricsMaster) {
        if (this.MetricsMaster[matrics].isActive == 1) {
          this.MetricsMasterCopy.push(this.MetricsMaster[matrics]);
        }
      }
      this.dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMasterCopy);
    }
    else {
      if (this.ValidateNewMatric()) {
        this.ShowNewRowMetricId = true;
        var newRow = {
          'SalesMetricsID': 0,
          'MetricName': '',
          'isActive': 1,
          'Measurement': '',
          'ThresholdMeasurementBytext': 'Calculated By System',
          'IsProratedValue': 0,
          'isMetricNegative': 0,
          'IsAlphaNumericTargetValue': 0,
          'IsPercentageValue': 0,
          'alphanumericFlag': 0,
          'percentageFlag': 0,
          'proratedFlag': 0
        };
        this.MetricsMaster.push(newRow);
        this.MetricsMasterCopy = [];
        for (let matrics in this.MetricsMaster) {
          if (this.MetricsMaster[matrics].isActive == 1) {
            this.MetricsMasterCopy.push(this.MetricsMaster[matrics]);
          }
        }
        this.dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMasterCopy);
      }
    }
  }

  RemoveMetricMaster(i) {
    //for (let matrics in this.MetricsMaster) {
    //  if (this.MetricsMaster[matrics].SalesMetricsID == i) {
    //    this.MetricsMaster.splice(matrics, 1);
    //  }
    //}
    //for (let matrics in this.MetricsMasterCopy) {
    //  if (this.MetricsMasterCopy[matrics].SalesMetricsID == i) {
    //    this.MetricsMasterCopy[matrics].isActive = 0;
    //  }
    //}
    this.MetricsMasterCopy = [];
    for (let matrics in this.MetricsMaster) {
      if (this.MetricsMaster[matrics].SalesMetricsID == i) {
        this.MetricsMaster[matrics].isActive = 0;
      }
    }

    for (let matrics in this.MetricsMaster) {
      if (this.MetricsMaster[matrics].isActive == 1) {
        this.MetricsMasterCopy.push(this.MetricsMaster[matrics]);
      }
    }
    this.dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMasterCopy);
  }

  SubmitMetricMasterChanges() {
    if (this.ValidateNewMatric()) {

      for (let MatricsCount = 0; MatricsCount < this.masterData.dtMetricsMaster.length; MatricsCount++) {
        let metricUpdateFilter = this.MetricsMaster.filter((matrics: any) => matrics.MetricName.trim().toUpperCase() == this.masterData.dtMetricsMaster[MatricsCount].MetricName.trim().toUpperCase())
        if (metricUpdateFilter != undefined && metricUpdateFilter.length > 0) {
          this.masterData.dtMetricsMaster[MatricsCount].isActive = 1;
        }
        else {
          this.masterData.dtMetricsMaster[MatricsCount].isActive = 0;
        }
      }

      for (let newMatricsCount = 0; newMatricsCount < this.MetricsMaster.length; newMatricsCount++) {
        let metricUpdateFilter = this.masterData.dtMetricsMaster.filter((matrics: any) => matrics.MetricName.trim().toUpperCase() == this.MetricsMaster[newMatricsCount].MetricName.trim().toUpperCase())
        if (!(metricUpdateFilter != undefined && metricUpdateFilter.length > 0)) {
          this.masterData.dtMetricsMaster.push(JSON.parse(JSON.stringify(this.MetricsMaster[newMatricsCount])));
        }
      }
      //  this.masterData.dtMetricsMaster.push(JSON.parse(JSON.stringify(this.MetricsMaster[this.MetricsMaster.length - 1])));
      //}

      var data = {
        'PS_No': this.userInfo.PS_No,
        'metricsMasterData': this.MetricsMaster,
        'AppType': this.userInfo.AppType
      };
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.UpdateMetricsMasterURL(), data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.GetMasterData(true);
          }
        });
    }
  }

  ValidateNewMatric() {
    let isvalid = true;
    if (this.MetricsMaster[this.MetricsMaster.length - 1].MetricName == '') {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Select Metric Details before saving"
      });
      isvalid = false;
    }
    else {
      for (let o: number = 0; o < (this.MetricsMaster.length - 1); o++) {
        if (this.MetricsMaster[o].MetricName.trim().toUpperCase() == this.MetricsMaster[this.MetricsMaster.length - 1].MetricName.trim().toUpperCase()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Selected Metric already exists"
          });
          this.MetricsMaster[this.MetricsMaster.length - 1].MetricName = '';
          this.MetricsMasterCopy = [];
          for (let matrics in this.MetricsMaster) {
            if (this.MetricsMaster[matrics].isActive == 1) {
              this.MetricsMasterCopy.push(this.MetricsMaster[matrics]);
            }
          }
          this.dataSourceNewRowMetric = new MatTableDataSource(this.MetricsMasterCopy);
          isvalid = false;
        }
      }
    }
    return isvalid;
  }

  //
  GetDeadLinesForSubmission() {
    this.SubmissionDates = [];

    var data = {
      'PS_No': this.userInfo.PS_No,
      'PeriodId': this.selectedPeriod,
      'SBU': 'ALL',
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetDeadLinesForSubmissionURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.SubmissionDates = response.ResponseData;
          for (var o in this.SubmissionDates) {
            this.SubmissionDates[o].selectSBU = false;
          }
        }
      });
  }

  filterInactive(metric: any) {
    return metric.isActive == 1
  }

  GetQuanQualPercent() {

    this.QuanPercentage = 0;

    var data = {
      'Role': this.roleSelected,
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetQuanQualPercentageURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.QuanPercentage = response.ResponseData[0].QuantitativeWeightage;
        }
      });
  }

  CheckOVerallSum(): boolean {

    var isValid = true;
    var total = 0;
    var sum = 0;


    if (this.typeSelected == '1') {
      if (this.selectedPeriod >= 13) {
        for (var o in this.AdminTemplatesFiltered) {
          sum = sum + parseFloat(this.AdminTemplatesFiltered[o].Value);
        }
        if (this.QuanPercentage > 0) {
          if (sum != this.QuanPercentage) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Overall Sum of Metrics should be " + this.QuanPercentage + "."

            });
            isValid = false;
          }
        }
        else {
          if (sum != 80) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Overall Sum of Metrics should be 80."

            });
            isValid = false;
          }

        }

      }
      else {
        if (this.selectedPeriod >= 9 && this.userInfo.AppType == 2) {
          for (var o in this.AdminTemplatesFiltered) {
            sum = sum + parseFloat(this.AdminTemplatesFiltered[o].Value);
          }
          if (sum != 70) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Overall Sum of Metrics should be 70"

            });
            isValid = false;
          }
        }
        else {
          for (var o in this.AdminTemplatesFiltered) {
            total = total + parseFloat(this.AdminTemplatesFiltered[o].Value);
          }
          if (total != 100) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "Overall Sum of Metrics should be 100"
            });
            isValid = false;
          }
        }
      }
      //if (isValid) {
      //  for (var o in this.AdminTemplatesFiltered) {
      //    this.AdminTemplatesFiltered[o].Value /= 0.7;
      //  }
      //}
      return isValid;
    }
    else {
      return isValid;
    }
  }

  SubmitSubmissionDateChanges() {
    var data = {
      'PS_No': this.userInfo.PS_No,
      'PeriodId': this.selectedPeriod,
      'dtMertricsDet': this.SubmissionDates,
      'AppType': this.userInfo.AppType
    };
    for (var o in this.SubmissionDates) {
      if (this.SubmissionDates[o].selectSBU) {
        if (this.SubmissionDates[o].EmpViewDate == "" || this.SubmissionDates[o].EmpViewDate == null || this.SubmissionDates[o].EmpViewDate == undefined) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please select employee view date for SBU  : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (this.SubmissionDates[o].EmpSubStartDate == "" || this.SubmissionDates[o].EmpSubStartDate == null || this.SubmissionDates[o].EmpSubStartDate == undefined) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please select employee Submission start date for SBU  : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (this.SubmissionDates[o].EmpDeadline == "" || this.SubmissionDates[o].EmpDeadline == null || this.SubmissionDates[o].EmpDeadline == undefined) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please select employee submission deadline for SBU  : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (this.SubmissionDates[o].ManagerDeadline == "" || this.SubmissionDates[o].ManagerDeadline == null || this.SubmissionDates[o].ManagerDeadline == undefined) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please select manager submission deadline for SBU  : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (this.SubmissionDates[o].ReviewerDeadline == "" || this.SubmissionDates[o].ReviewerDeadline == null || this.SubmissionDates[o].ReviewerDeadline == undefined) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Please select reviewer submission deadline for SBU  : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (new Date(this.SubmissionDates[o].EmpSubStartDate) < new Date(this.SubmissionDates[o].EmpViewDate)) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Employee submission start date should be greater than employee view date for SBU : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (new Date(this.SubmissionDates[o].EmpDeadline) < new Date(this.SubmissionDates[o].EmpSubStartDate)) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Employee submission deadline should be greater than employee Submission start date for SBU : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (new Date(this.SubmissionDates[o].ManagerDeadline) < new Date(this.SubmissionDates[o].EmpDeadline)) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Manager deadline should be greater than employer submission deadline for SBU : " + this.SubmissionDates[o].SBU
          });
          return;
        }
        if (new Date(this.SubmissionDates[o].ReviewerDeadline) < new Date(this.SubmissionDates[o].ManagerDeadline)) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Reviewer deadline should be greater than manager deadline for SBU : " + this.SubmissionDates[o].SBU
          });
          return;
        }
      }
    }
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.SaveDeadLinesForSubmissionURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetMasterData(false);
          this.GetDeadLinesForSubmission();
        }
      });
  }

  onDateChange(actor: string, dateTarget: any, dateSource: any, index: number) {
    if (dateTarget == "" || dateSource == "" || dateTarget == null || dateSource == null || dateTarget == undefined || dateSource == undefined) {
      //this.dialog.open(DialogDataExampleDialog, {
      //  data: "Date cannot be blank."
      //});
      return;
    }
    var target = new Date(dateTarget);
    var source = new Date(dateSource);
    if (target < source) {
      if (actor == "EmpSubStart") {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Employee submission start date should be greater than employee view date."
        });
      }
      if (actor == "Emp") {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Employee submission deadline should be greater than employee submission start date."
        });
      }
      else if (actor == "Mgr") {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Manager submission deadline should be greater than employee submission deadline."
        });
      }
      else if (actor == "Rvr") {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Reviewer submission deadline should be greater than manager submission deadline."
        });
      }
    }
  }
  SendMail(index: number, SBU: string) {
    var data = {
      'PeriodID': this.selectedPeriod,
      'SBU': SBU,
      'AppType': this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.SendMailForSBUURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetMasterData(false);
          this.GetDeadLinesForSubmission();
        }
      });
  }
  fileChange(event) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadedFile = fileList[0];
    }
    else {
      this.uploadedFile = null;
    }
  }
  UploadFile() {

    var data = {
      'PeriodID': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    };


    if (this.uploadedFile != null) {
      this.appService.SetLoadingShow(true);
      this.appService.IncentiveEligibleAmountUpload(this.configs.UploadIncentiveEligibleAmountDetailedURL(), this.uploadedFile, this.selectedPeriod, this.userInfo.AppType)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.uploadedFile = null;
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }

        });
    }
  }

  GetPlanData() {

    var data = {
      'PSNO': this.userInfo.PS_No,
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetDataforPlanURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.ShowNewPlan = true;
          this.PlanFiltered = response.ResponseData.dtPlanMaster
          this.dataSourceForPlan = new MatTableDataSource(this.PlanFiltered);
          if (this.PlanFiltered.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            // return;
          }

        }
      }
        , error => {
          this.errorMessage = <string>error;
        });



  }

  ValidateNewPlan() {
    let isplanvalid = true;
    if (this.PlanFiltered[this.PlanFiltered.length - 1].PlanName == '') {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Enter Plan Details before saving"
      });
      isplanvalid = false;
    }
    else {
      for (let o: number = 0; o < (this.PlanFiltered.length - 1); o++) {
        if (this.PlanFiltered[o].PlanName.trim().toUpperCase() == this.PlanFiltered[this.PlanFiltered.length - 1].PlanName.trim().toUpperCase()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Entered plan already exists"
          });
          this.PlanFiltered[this.PlanFiltered.length - 1].PlanName = '';
          this.dataSourceForPlan = new MatTableDataSource(this.PlanFiltered);
          isplanvalid = false;
        }
      }
    }
    return isplanvalid;
  }

  AddNewPlan() {
    if (this.PlanFiltered.length == 0) {
      for (var o in this.PlanFiltered) {
        this.ShowNewPlan = false;
        var NewPlan = {
          'PlanId': this.PlanFiltered[o].PlanId + 1,
          'PlanName': '',
          'isActive': 1,
          'IsH1PeriodApplicable': 1

        }
      };

      this.PlanFiltered.push(NewPlan);
      this.dataSourceForPlan = new MatTableDataSource(this.PlanFiltered);
    }

    else {
      if (this.ValidateNewPlan()) {
        for (var o in this.PlanFiltered) {
          this.ShowNewPlan = false;
          var NewPlan = {
            'PlanId': this.PlanFiltered[o].PlanId + 1,
            'PlanName': '',
            'isActive': 1,
            'IsH1PeriodApplicable': 1

          }
        };

        this.PlanFiltered.push(NewPlan);
        this.dataSourceForPlan = new MatTableDataSource(this.PlanFiltered);
      }
    }


  }

  RemovePlan(i) {

    if (this.PlanFiltered[i].isActive == 1) {
      this.PlanFiltered[i].isActive = 0;
    }
    else if (this.PlanFiltered[i].isActive == 0) {
      this.PlanFiltered[i].isActive = 1;
    }
  }

  CheckIsHalfYearly(i) {

    if (this.PlanFiltered[i].IsH1PeriodApplicable == 1) {
      this.PlanFiltered[i].IsH1PeriodApplicable = 0;
    }
    else if (this.PlanFiltered[i].IsH1PeriodApplicable == 0) {
      this.PlanFiltered[i].IsH1PeriodApplicable = 1;
    }
  }
  //RemoveUser(i) {

  //    if (this.UserFiltered[i].isActive == 'Yes') {
  //        this.UserFiltered[i].isActive = 'No';
  //    }
  //    else if (this.UserFiltered[i].isActive == 'No') {
  //        this.UserFiltered[i].isActive = 'Yes';
  //    }
  //}

  SubmitDataforPlan() {

    if (this.ValidateNewPlan()) {
      var dtPlanMaster1 = [];
      for (var o in this.PlanFiltered) {
        dtPlanMaster1.push({
          'PlanId': this.PlanFiltered[o].PlanId,
          'PlanName': this.PlanFiltered[o].PlanName,
          'isActive': this.PlanFiltered[o].isActive,
          'AppType': this.userInfo.AppType,
          'IsH1PeriodApplicable': this.PlanFiltered[o].IsH1PeriodApplicable
        });
      };
      var data = {
        'dtPlanMaster': dtPlanMaster1
      };
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.SubmitDataforPlanURL(), data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.GetMasterData(true);
          }
        });
    }
  }

  SubmitDataforUserMaster(o) {


    if (this.UserFiltered[o].isActive == 1) {
      this.UserFiltered[o].isActive = 0
    }
    else if (this.UserFiltered[o].isActive == 0) {
      this.UserFiltered[o].isActive = 1
    }

    var data = {
      'PSno': this.UserFiltered[o].PSno,
      'isActive': this.UserFiltered[o].isActive,
      'AppType': this.userInfo.AppType

    };


    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.SubmitDataforUserMasterURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetMasterData(true);
        }
      });
  }



  GetSBUAccessToPSNos() {
    var data = {
      'AppType': this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetSBUAccessToPSNosURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.PSNoTable = response.ResponseData
          this.dataSourcePSNO = new MatTableDataSource(this.PSNoTable);
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  AddNewRowForGetPSnoAccess() {

    if (this.PSNoTable.length > 0 && (this.PSNoTable[this.PSNoTable.length - 1].SBU == "")) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please fill existing data"
      });
    }

    else {
      var newRow = {
        'SBU': this.SBU,
        'PSNo': this.PSNo,
        'isSBUEditable': 1,
        'isPSNoEditable': 1,
        'isActive': 1,
        'AppType': this.userInfo.AppType
      };
      this.PSNoTable.push(newRow);
      this.dataSourcePSNO = new MatTableDataSource(this.PSNoTable);

    }
  }

  RemoveRowForGetPSnoAccess(i) {
    if (this.PSNoTable[i].isActive == 0) {
      this.PSNoTable[i].isActive = 1;
    }
    else if (this.PSNoTable[i].isActive == 1) {
      this.PSNoTable[i].isActive = 0;
    }
    this.dataSourcePSNO = new MatTableDataSource(this.PSNoTable);

  }

  SubmitAccessList() {
    var data = {
      'dtPSNo': this.PSNoTable,
      "AppType": this.userInfo.AppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.InsertSBUAccessToURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetSBUAccessToPSNos();
        }
      }
        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
  }
  GetUserData() {

    var data = { 'AppType': this.userInfo.AppType };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetUserDataURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.ShowNewPlan = true;

          this.UserFiltered = response.ResponseData.dtUserMaster;
          this.appService.SetLoadingShow(false);
          if (this.UserFiltered.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            // return;
          }
        }
      }
        , error => {
          this.errorMessage = <string>error;
        });
  }

  onMetricChangeFilter(value): any {
    const filterValue = value.toLowerCase();
    if (this.typeSelected == '1') {
      this.MetricSearchArray = JSON.parse(JSON.stringify(this.MetricsMaster));
      this.MetricSearchArray = this.MetricsMaster.filter(option => option.MetricName.toLowerCase().includes(filterValue));
    }
    if (this.typeSelected == '2') {
      this.MetricSearchArray = JSON.parse(JSON.stringify(this.QualitativeMetricsMaster));
      this.MetricSearchArray = this.QualitativeMetricsMaster.filter(option => option.MetricName.toLowerCase().includes(filterValue));
    }
  }

  GetEmployeeReleaseDatesData() {
    var data = {
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetEmployeeReleaseDatesDataURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.selectAll = false;
          for (var o in this.EmpReleaseDatesFiltered) {
            if (this.EmpReleaseDatesFiltered[o].select) {
              this.EmpReleaseDatesFiltered[o].select = false;
            }
          }
          this.EmpReleaseDatesFiltered = response.ResponseData.dtEmployeeReleaseDates;
          this.EmpReleaseDates = response.ResponseData.dtEmployeeReleaseDates;
          this.EmpReleaseDatesNew = response.ResponseData.dtEmployeeReleaseDates;
          this.dtSBU = response.ResponseData.dtSBU;
          this.dropdownListSBU = [];
          for (var o in this.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.dtSBU[o].SBU, "Functionid": this.dtSBU[o].FunctionId });
          }
          this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
          var SBU = [];
          this.SBUDistinctModel = [];
          this.SBUDistinct = [];
          for (var j in this.selectedSBU) {
            if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
              SBU.push(this.selectedSBU[j].itemName)
            }
          }
          for (var o in SBU) {
            this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
          }

          this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));


          this.dtFunction = response.ResponseData.dtFunction;
          this.dropdownListFunction = [];
          for (var o in this.dtFunction) {
            this.dropdownListFunction.push({ "id": this.dtFunction[o].FunctionId, "itemName": this.dtFunction[o].FunctionName });
          }
          this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));

          this.dtRole = response.ResponseData.dtRole;
          this.dropdownListRole = [];
          for (var o in this.dtRole) {
            this.dropdownListRole.push({ "id": parseInt(o) + 1, "itemName": this.dtRole[o].Role });
          }
          this.selectedrole = JSON.parse(JSON.stringify(this.dropdownListRole));
          this.appService.SetLoadingShow(false);
          if (this.EmpReleaseDatesFiltered.length == 0) {
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            // return;
          }

          this.dtStatusEmployeReleaseDates = response.ResponseData.dtStatusEmployeReleaseDates;
          this.dropdownListStatus = [];
          for (var o in this.dtStatusEmployeReleaseDates) {
            this.dropdownListStatus.push({ "id": parseInt(o) + 1, "itemName": this.dtStatusEmployeReleaseDates[o].Status });
          }
          this.selectedItemsForStatus = JSON.parse(JSON.stringify(this.dropdownListStatus));
        }
      }
        , error => {
          this.errorMessage = <string>error;
        });
  }

  setDropdownListSBU() {
    this.dropdownListSBU = [];
    for (var o in this.dtSBU) {
      this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.dtSBU[o].SBU, "Functionid": this.dtSBU[o].FunctionId });
    }
    this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
  }

  onFunctionChange(selectedFunction) {
    this.setDropdownListSBU();
    this.EmpReleaseDates = this.EmpReleaseDatesNew;
    var data = [];
    var dataselectedSBU = [];

    if (selectedFunction.length <= this.dropdownListFunction.length) {
      for (var o in selectedFunction) {
        if (selectedFunction[o].itemName != '' || selectedFunction[o].itemName != undefined || selectedFunction[o].itemName != "SELECT") {
          for (var i in this.EmpReleaseDates) {
            if (this.EmpReleaseDates[i].FunctionName == selectedFunction[o].itemName) {
              data.push(this.EmpReleaseDates[i]);
            }
          }

          for (var j in this.selectedSBU) {
            if (this.selectedSBU[j].Functionid == selectedFunction[o].id) {
              dataselectedSBU.push(this.selectedSBU[j])
            }
          }

        }

      }
      this.selectedSBU = JSON.parse(JSON.stringify(dataselectedSBU))
      this.dropdownListSBU = JSON.parse(JSON.stringify(this.selectedSBU))
      var SBU = [];
      this.SBUDistinctModel = [];
      this.SBUDistinct = [];
      for (var j in this.selectedSBU) {
        if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
          SBU.push(this.selectedSBU[j].itemName)
        }
      }
      for (var o in SBU) {
        this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
      }
      this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

      this.EmpReleaseDates = data

    }
    this.onFilterChange(this.SBUDistinctModel, this.selectedSBU)
  }

  onFilterChange(selectedrole, selectedSBU) {
    if (this.selectedFunction.length == this.dropdownListFunction.length) {
      this.EmpReleaseDates = this.EmpReleaseDatesNew
    }

    this.showTable = '0';
    var data = [];
    if (selectedrole.length < this.dropdownListRole.length) {
      for (var o in selectedrole) {
        if (selectedrole[o].itemName != '' || selectedrole[o].itemName != undefined || selectedrole[o].itemName != "Select") {
          for (var i in this.EmpReleaseDates) {
            if (this.EmpReleaseDates[i].Role == selectedrole[o].itemName) {
              data.push(this.EmpReleaseDates[i]);
            }
          }
        }
      }
    }
    else {
      data = this.EmpReleaseDates;
      //data = [];
    }

    var dataSBU = [];

    if (selectedSBU.length < this.dropdownListSBU.length) {
      for (var o in selectedSBU) {
        if (selectedSBU[o].itemName != '' || selectedSBU[o].itemName != undefined || selectedSBU[o] != "Select") {
          for (var i in data) {
            if (data[i].SBU == selectedSBU[o].itemName) {
              dataSBU.push(data[i]);
            }
          }
        }
      }
    }
    else {
      dataSBU = data;
    }

    this.EmpReleaseDatesFiltered = dataSBU;

    if (this.EmpReleaseDatesFiltered.length > 0) {
      this.showTable = '8';
      this.showsubmit = true;
    }
    else {
      this.showTable = '8';
      this.showsubmit = false;
      //this.dialog.open(DialogDataExampleDialog, {
      //    data: "No record found."
      //});
      return;
    }

  }

  onReset(): void {
    this.selectedSBU = [];
    this.setDropdownListSBU();
    var SBU = [];
    this.SBUDistinctModel = [];
    this.SBUDistinct = [];
    for (var j in this.selectedSBU) {
      if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
        SBU.push(this.selectedSBU[j].itemName)
      }
    }
    for (var o in SBU) {
      this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
    }
    this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

    //this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
    this.selectedFunction = [];
    this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
    this.selectedrole = [];
    this.selectedrole = JSON.parse(JSON.stringify(this.dropdownListRole));
    this.showsubmit = true;
    this.SearchText = '';
    this.EmpReleaseDatesFiltered = this.EmpReleaseDatesNew;
    if (this.selectAll) {
      this.SelectAllForReleaseDates();
    }
  }
  SelectAllForReleaseDates() {
    this.selectAll = !this.selectAll;
    for (var o in this.EmpReleaseDatesFiltered) {
      this.EmpReleaseDatesFiltered[o].select = this.selectAll;
    }
  }
  RefreshSelectForReleaseDates(psno) {
    for (var o in this.EmpReleaseDatesFiltered) {
      if (this.EmpReleaseDatesFiltered[o].PSNO == psno) {
        this.selectAll = false; this.EmpReleaseDatesFiltered[o].select = !this.EmpReleaseDatesFiltered[o].select;
        if (!this.EmpReleaseDatesFiltered[o].select) {
          this.selectAll = false;
        }
        break;
      }
    }
  }
  SaveEmployeeReleaseDates() {
    var dtEmpReleaseDatesFiltered1 = [];
    for (var o in this.EmpReleaseDatesFiltered) {
      if (this.EmpReleaseDatesFiltered[o].select) {
        dtEmpReleaseDatesFiltered1.push({
          'PSNO': this.EmpReleaseDatesFiltered[o].PSNO
        });
      }
    };
    /* For Checking target status*/

    var dtEmpReleaseDatesFiltered2 = [];
    for (var o in this.EmpReleaseDatesFiltered) {
      if (this.EmpReleaseDatesFiltered[o].select) {
        dtEmpReleaseDatesFiltered2.push({
          'PSNO': this.EmpReleaseDatesFiltered[o].PSNO,
          'Status': this.EmpReleaseDatesFiltered[o].Status
        });
      }
    };
    //---
    var msg = [];
    var Flag = 0;

    //if (this.EmpViewDate == "" || this.EmpViewDate == null || this.EmpViewDate == undefined) {
    //  msg.push("Please select employee view date. ");
    //}
    var dtEmpReleaseDatesFiltered3 = [];
    for (var o in dtEmpReleaseDatesFiltered2) {
      if (dtEmpReleaseDatesFiltered2[o].Status == "Assessment Not Initiated") {
        dtEmpReleaseDatesFiltered3.push(dtEmpReleaseDatesFiltered2[o].PSNO)
      }
    }
    if (dtEmpReleaseDatesFiltered3.length > 0) {
      msg.push(" Since the targets are not released,the assessment dates cannot be released for the PSNO:" + dtEmpReleaseDatesFiltered3);
    }
    if (this.EmpViewDate == "" || this.EmpViewDate == null || this.EmpViewDate == undefined) {
      Flag = 0;
      msg.push("Please select employee view date. ");
      if (this.EmpSubStartDate == "" || this.EmpSubStartDate == null || this.EmpSubStartDate == undefined) {
        msg.push("Please select employee Submission start date.");
      }
      if (this.EmpDeadline == "" || this.EmpDeadline == null || this.EmpDeadline == undefined) {
        msg.push("Please select employee submission deadline.");
      }
      if (this.ManagerDeadline == "" || this.ManagerDeadline == null || this.ManagerDeadline == undefined) {
        msg.push("Please select manager submission deadline.");
      }
      if (this.ReviewerDeadline == "" || this.ReviewerDeadline == null || this.ReviewerDeadline == undefined) {
        msg.push("Please select reviewer submission deadline.");
      }
      if (new Date(this.EmpSubStartDate) < new Date(this.EmpViewDate)) {
        msg.push("Employee submission start date should be greater than employee view date.");
      }
      if (new Date(this.EmpDeadline) < new Date(this.EmpSubStartDate)) {
        msg.push("Employee submission deadline should be greater than employee Submission start date.");
      }
      if (new Date(this.ManagerDeadline) < new Date(this.EmpDeadline)) {
        msg.push("Manager deadline should be greater than employer submission deadline.");
      }
      if (new Date(this.ReviewerDeadline) < new Date(this.ManagerDeadline)) {
        msg.push("Reviewer deadline should be greater than manager deadline.");
      }
      if (dtEmpReleaseDatesFiltered1.length == 0) {
        msg.push("Please select an employee for submission");
      }
      //if (this.Status == "") {
      //  msg.push("Please select employee submission deadline.");
      //}
      if (msg.length > 0) {
        this.dialog.open(DialogDataExampleDialog, {
          data: msg
        });
      }
    }
    else {
      if (this.EmpViewDate != "" || this.EmpViewDate != null || this.EmpViewDate != undefined) {
        if (this.EmpSubStartDate != "" || this.EmpDeadline != "" || this.ManagerDeadline != "" || this.ReviewerDeadline != "") {
          Flag = 0;
          if (this.EmpSubStartDate == "") {
            msg.push("Please select employee Submission start date.");
          }
          if (this.EmpDeadline == "") {
            msg.push("Please select employee submission deadline.");
          }
          if (this.ManagerDeadline == "") {
            msg.push("Please select manager submission deadline.");
          }
          if (this.ReviewerDeadline == "") {
            msg.push("Please select reviewer submission deadline.");
          }
          if (new Date(this.EmpSubStartDate) < new Date(this.EmpViewDate)) {
            msg.push("Employee submission start date should be greater than employee view date.");
          }
          if (new Date(this.EmpDeadline) < new Date(this.EmpSubStartDate)) {
            msg.push("Employee submission deadline should be greater than employee Submission start date.");
          }
          if (new Date(this.ManagerDeadline) < new Date(this.EmpDeadline)) {
            msg.push("Manager deadline should be greater than employer submission deadline.");
          }
          if (new Date(this.ReviewerDeadline) < new Date(this.ManagerDeadline)) {
            msg.push("Reviewer deadline should be greater than manager deadline.");
          }
          if (dtEmpReleaseDatesFiltered1.length == 0) {
            msg.push("Please select an employee for submission");
          }
        }
        if (msg.length > 0) {
          Flag = 0;
        }
        else {
          if (dtEmpReleaseDatesFiltered1.length == 0) {
            msg.push("Please select an employee for submission");
          }
          else {
            Flag = 1;
          }
        }
        if (msg.length > 0) {
          this.dialog.open(DialogDataExampleDialog, {
            data: msg
          });
        }
      }

    }

    if (Flag == 1) {
      // else {
      var data = {
        "PeriodId": this.selectedPeriod,
        "dtEmployeeReleaseDatesPSNo": dtEmpReleaseDatesFiltered1,
        "EmpViewDate": this.EmpViewDate,
        "EmpSubStartDate": this.EmpSubStartDate,
        "EmpDeadline": this.EmpDeadline,
        "ManagerDeadline": this.ManagerDeadline,
        "ReviewerDeadline": this.ReviewerDeadline,
        "AppType": this.userInfo.AppType
      }
      this.appService.SetLoadingShow(true);
      var url = this.configs.SaveEmployeeReleaseDatesURL();
      this.appService.GetDataFromAPI(url, data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
             this.SendMailforSubmissionDates();
            this.onReset();
            this.GetEmployeeReleaseDatesData();
          }
        }

          ,
          error => {
            this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
          });
      // }
    }
  }
  SearchFor(value): any {
    if (this.EmpReleaseDatesFiltered.length == 0) {
      this.EmpReleaseDatesFiltered = this.EmpReleaseDates
    }
    var data = JSON.parse(JSON.stringify(this.EmpReleaseDatesFiltered));
    if (value != '' && value != undefined) {
      if (value.length > 2) {
        const filterValue = value.toLowerCase();
        this.SearchText = value;
        for (var o in data) {
          if (data[o].Name == null)
            data[o].Name = "";
        }
        data = data.filter(option => option.Name.toLowerCase().includes(filterValue));
      }
      this.EmpReleaseDatesFiltered = data;
    }
    if (value.length == 0) {
      this.EmpReleaseDatesFiltered = this.EmpReleaseDates
    }

  }


  SendMailforSubmissionDates() {
    var dtEmpReleaseDatesFiltered1 = [];
    for (var o in this.EmpReleaseDatesFiltered) {
      if (this.EmpReleaseDatesFiltered[o].select) {
        dtEmpReleaseDatesFiltered1.push({
          'PSNO': this.EmpReleaseDatesFiltered[o].PSNO
        });
      }
    };

    var msg = [];

    //if (dtEmpReleaseDatesFiltered1.length == 0) {
    //    msg.push("Please select an employee for submission");
    //}
    //if (msg.length > 0) {
    //    this.dialog.open(DialogDataExampleDialog, {
    //        data: msg
    //    });
    //}

    //else {

    var data = {
      "PeriodId": this.selectedPeriod,
      "dtEmployeeReleaseDatesPSNo": dtEmpReleaseDatesFiltered1,
      "EmpViewDate": this.EmpViewDate,
      "EmpSubStartDate": this.EmpSubStartDate,
      "EmpDeadline": this.EmpDeadline,
      "ManagerDeadline": this.ManagerDeadline,
      "ReviewerDeadline": this.ReviewerDeadline,
      "AppType": this.userInfo.AppType
    }
    //this.appService.SetLoadingShow(true);
    var url = this.configs.SendMailForSubmissionDatesURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          //this.dialog.open(DialogDataExampleDialog, {
          //    data: response.ResponseData
          //});
        }
        else {
          //this.dialog.open(DialogDataExampleDialog, {
          //    data: response.ResponseData
          //});
          //this.GetEmployeeReleaseDatesData();
        }
      }

        ,
        error => {
          this.errorMessage = <string>error; //this.appService.SetLoadingShow(false);
        });
    //}
  }

  CheckIsAlphaNumericTarget(i) {

    if (this.MetricsMaster[i].IsAlphaNumericTargetValue == 1) {
      this.MetricsMaster[i].IsAlphaNumericTargetValue = 0;
      this.MetricsMaster[i].percentageFlag = 0;
      this.MetricsMaster[i].proratedFlag = 0;
    }
    else if (this.MetricsMaster[i].IsAlphaNumericTargetValue == 0) {
      this.MetricsMaster[i].IsAlphaNumericTargetValue = 1;
      this.MetricsMaster[i].percentageFlag = 1;
      this.MetricsMaster[i].proratedFlag = 1;

    }
  }
  CheckIsPercentage(i) {

    if (this.MetricsMaster[i].IsPercentageValue == 1) {
      this.MetricsMaster[i].IsPercentageValue = 0;
      if (this.MetricsMaster[i].isMetricNegative == 0) {
        this.MetricsMaster[i].alphanumericFlag = 0;
      }
      this.MetricsMaster[i].proratedFlag = 0;
    }
    else if (this.MetricsMaster[i].IsPercentageValue == 0) {
      this.MetricsMaster[i].IsPercentageValue = 1;
      this.MetricsMaster[i].alphanumericFlag = 1;
      this.MetricsMaster[i].proratedFlag = 1;
    }
  }
  SetFlags(i) {
    if (this.MetricsMaster[i].IsProratedValue == 1 || this.MetricsMaster[i].IsProratedValue == 2) {
      this.MetricsMaster[i].alphanumericFlag = 1;
      this.MetricsMaster[i].percentageFlag = 1;
    }
    else {
      if (this.MetricsMaster[i].isMetricNegative == 0) {
        this.MetricsMaster[i].alphanumericFlag = 0;
      }
      this.MetricsMaster[i].percentageFlag = 0;
    }
  }
  CheckisMetricNegative(i) {

    if (this.MetricsMaster[i].isMetricNegative == 1) {
      this.MetricsMaster[i].isMetricNegative = 0;
      if (this.MetricsMaster[i].IsPercentageValue == 0 && this.MetricsMaster[i].IsProratedValue == 0) {
        this.MetricsMaster[i].alphanumericFlag = 0;
      }
    }
    else if (this.MetricsMaster[i].isMetricNegative == 0) {
      this.MetricsMaster[i].isMetricNegative = 1;
      if (this.MetricsMaster[i].IsPercentageValue == 0 && this.MetricsMaster[i].IsProratedValue == 0) {
        this.MetricsMaster[i].alphanumericFlag = 1;
      }
    }
  }
  GetMetricConfig() {
    var data = {
      'PeriodId': this.selectedPeriod,
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetMetricConfigDetailsURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.MetricConfig = response.ResponseData;
          this.MetricConfigFiltered = this.MetricConfig;
          //for (var o in this.MetricConfig) {
          //  if (this.MetricConfig[o].ThresholdCap.toString() == null) {
          //    this.MetricConfig[o].ThresholdCap = "";
          //  }

          //  this.MetricConfigFiltered.push(this.MetricConfig[o]);
          //}
          this.dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);
          this.appService.SetLoadingShow(false);
          if (this.MetricConfig.length > 0) {
            this.showTable = '9';
          }
        }
      }
        , error => {
          this.errorMessage = <string>error;
        });
  }

  RemoveMetricMetricConfig(i) {
    this.MetricConfig.splice(i, 1);
    //this.MetricConfigFilteredCopy = [];
    ////for (let matrics in this.MetricConfig) {
    ////  if (matrics == i) {
    //    this.MetricConfig[i].IsActive = 0;
    ////  }
    ////}

    //for (let matrics in this.MetricConfig) {
    //  if (this.MetricConfig[matrics].IsActive == 1) {
    //    this.MetricConfigFilteredCopy.push(this.MetricConfig[matrics]);
    //  }
    //}

    this.dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);
  }
  CancelMetricConfig() {
    this.ShowNewRowForMetricConfigFiltered = false;
  }
  AddNewRowForMetricConfig() {

    if (this.MetricConfig.length < 0 || this.MetricConfig[this.MetricConfig.length - 1].SalesMetricsID == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Select Metric Details before Adding"
      });
    }
    else {

      //if (this.ShowNewRowForMetricConfigFiltered && this.MetricConfigFiltered.length > 0) {
      //  this.MetricConfigFiltered.push(JSON.parse(JSON.stringify(this.MetricConfigFiltered[this.MetricConfigFiltered.length - 1])));
      //}


      this.ShowNewRowForMetricConfigFiltered = true;
      var newRow = {
        'SalesMetricsID': 0,
        'ThresHold': 0,
        'CappedAtValue': 0,
        'IgnoreInTargetLetter': 0,
        'ThresholdCap': "",
        'IsActive': 1,
        'MetricName': "",
      };
      this.MetricConfig.push(newRow);
      this.dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);

    }

  }

  CheckIgnoreInTargetLetter(i) {

    if (this.MetricConfig[i].IgnoreInTargetLetter == 1) {
      this.MetricConfig[i].IgnoreInTargetLetter = 0;
    }
    else if (this.MetricConfig[i].IgnoreInTargetLetter == 0) {
      this.MetricConfig[i].IgnoreInTargetLetter = 1;
    }
  }
  CheckExistingMetricsConfig(name) {
    var MetricName = name.option.value;
    for (let o: number = 0; o < this.MetricConfig.length - 1; o++) {
      if (this.MetricConfig[o].MetricName == MetricName) {
        this.dialog.open(DialogDataExampleDialog, {
          data: "Selected Metric already exists"
        });
        this.MetricConfig.splice(this.MetricConfig.length - 1, 1);
        this.dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);
        var newRow = {
          'SalesMetricsID': 0,
          'ThresHold': 0,
          'CappedAtValue': 0,
          'IgnoreInTargetLetter': 0,
          'ThresholdCap': "",
          'IsActive': 1,
          'MetricName': "",
        };
        this.MetricConfig.push(newRow);
        this.dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);
        break;
      }
    }
    if (this.MetricConfig[this.MetricConfig.length - 1].MetricName != "") {
      for (var o in this.MetricSearchArray) {
        if (this.MetricSearchArray[o].MetricName == MetricName) {
          this.MetricConfig[this.MetricConfig.length - 1].SalesMetricsID = this.MetricSearchArray[o].SalesMetricsID;
          break;
        }
      }
    }
    this.dataSourceMetricConfig = new MatTableDataSource(this.MetricConfig);
  }
  SubmitChangesForMetricConfig() {
    var dtMetricConfig = [];
    for (var o in this.MetricConfig) {
      dtMetricConfig.push({
        'SalesMetricsID': this.MetricConfig[o].SalesMetricsID,
        'ThresHold': this.MetricConfig[o].ThresHold,
        'CappedAtValue': this.MetricConfig[o].CappedAtValue,
        'IgnoreInTargetLetter': this.MetricConfig[o].IgnoreInTargetLetter,
        'ThresholdCap': this.MetricConfig[o].ThresholdCap,
        'IsActive': this.MetricConfig[o].IsActive,
        'MetricName': this.MetricConfig[o].MetricName,
      });

    };
    if (this.MetricConfig.length < 0 || this.MetricConfig[this.MetricConfig.length - 1].SalesMetricsID == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Select Metric Details before Adding"
      });
    }

    else {
      this.CancelMetricConfig();
      var data = {
        'PeriodId': this.selectedPeriod,
        'metricsConfigdata': dtMetricConfig,
        'AppType': this.userInfo.AppType
      };
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.SaveMetricConfigDetailsURL(), data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.GetMasterData(true);
          }
        });
    }
  }
  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.MetricSort.findIndex((d) => d === event.item.data);
    //event.currentIndex =1

    var OldSortIndex = this.MetricSort[prevIndex].SortIndex;

    ///// Array 
    var NewIndex = event.currentIndex;
    var NewSortIndex = this.MetricSort[NewIndex].SortIndex;

    //for (var o in this.MetricSort) {
    //  if (this.MetricSort[o].SortIndex > OldSortIndex && this.MetricSort[o].SortIndex < SortIndex_Current) {
    //    this.MetricSort[o].SortIndex = this.MetricSort[o].SortIndex + 1;
    //  }
    //}
    if (OldSortIndex > NewSortIndex) {
      for (var o in this.MetricSort) {
        if (this.MetricSort[o].SortIndex <= OldSortIndex && this.MetricSort[o].SortIndex >= NewSortIndex) {
          this.MetricSort[o].SortIndex = this.MetricSort[o].SortIndex + 1;
        }
      }
    }
    else {
      for (var o in this.MetricSort) {
        if (this.MetricSort[o].SortIndex <= NewSortIndex && this.MetricSort[o].SortIndex > OldSortIndex) {
          this.MetricSort[o].SortIndex = this.MetricSort[o].SortIndex - 1;
        }
      }
    }
    this.MetricSort[prevIndex].SortIndex = NewSortIndex;
    moveItemInArray(this.MetricSort, prevIndex, event.currentIndex);
    // this.MetricSort = this.MetricSort.sort(function (a, b) { return a.SortIndex = b.SortIndex});  
    this.dataSourceMetricSort = new MatTableDataSource(this.MetricSort);

    //this.table.renderRows();
  }



  GetMetricSort() {
    var data = {
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.GetMetricSortDetailsURL(), data)
      .then(response => {
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.appService.SetLoadingShow(false);
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.MetricSort = response.ResponseData;
          this.dataSourceMetricSort = new MatTableDataSource(this.MetricSort);
          this.appService.SetLoadingShow(false);
          if (this.MetricSort.length > 0) {
            this.showTable = '10';
          }
        }
      }
        , error => {
          this.errorMessage = <string>error;
        });
  }

  SubmitChangesForMetricSort() {
    var dtMetricSort = [];
    for (var o in this.MetricSort) {
      dtMetricSort.push({
        'SalesMetricsID': this.MetricSort[o].SalesMetricsID,
        'SortIndex': this.MetricSort[o].SortIndex
      });

    };

    var data = {
      'metricsSortdata': dtMetricSort,
      'AppType': this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    this.appService.GetDataFromAPI(this.configs.SaveMetricSortDetailsURL(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetMasterData(true);
        }
      });

  }
  ChangeType() {
    if (this.typeSelected == '1') {
      this.MetricSearchArray = JSON.parse(JSON.stringify(this.MetricsMaster));
    }
    if (this.typeSelected == '2') {
      this.MetricSearchArray = JSON.parse(JSON.stringify(this.QualitativeMetricsMaster));
    }

    if (this.actiontype == '1') {
      this.ChangeRole();
    }
    if (this.actiontype == '3') {
      this.showTable = '3';
      //this.GetMasterData(false);
    }

  }
  RemoveQualitativeMetricsMaster(i) {
    this.QualitativeMetricsMasterCopy = [];
    for (let matrics in this.QualitativeMetricsMaster) {
      if (this.QualitativeMetricsMaster[matrics].SalesMetricsID == i) {
        this.QualitativeMetricsMaster[matrics].isActive = 0;
      }
    }

    for (let matrics in this.QualitativeMetricsMaster) {
      if (this.QualitativeMetricsMaster[matrics].isActive == 1) {
        this.QualitativeMetricsMasterCopy.push(this.QualitativeMetricsMaster[matrics]);
      }
    }
    this.dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMasterCopy);
  }

  AddNewRowMetricMasterQualitative() {
    let MaxKey = 0;
    if (this.masterData.dtQualitativeMetricsMaster.length > 0) {
      MaxKey = Math.max.apply(Math, this.masterData.dtQualitativeMetricsMaster.map(function (o) { return o.SalesMetricsID; }));
      let MaxKeynew = Math.max.apply(Math, this.QualitativeMetricsMaster.map(function (o) { return o.SalesMetricsID; }));
      if (MaxKeynew > MaxKey) {
        MaxKey = MaxKeynew;
      }
    }
    if (this.QualitativeMetricsMaster.length == 0) {
      this.ShowNewRowMetricId = true;
      var newRow = {
        'SalesMetricsID': 0,
        'MetricName': '',
        'isActive': 1,
        'Measurement': ''
      };
      this.QualitativeMetricsMaster.push(newRow);
      this.QualitativeMetricsMasterCopy = [];
      for (let matrics in this.QualitativeMetricsMaster) {
        if (this.QualitativeMetricsMaster[matrics].isActive == 1) {
          this.QualitativeMetricsMasterCopy.push(this.QualitativeMetricsMaster[matrics]);
        }
      }
      this.dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMasterCopy);
    }
    else {
      if (this.ValidateNewMatric()) {
        this.ShowNewRowMetricId = true;
        var newRow = {
          'SalesMetricsID': 0,
          'MetricName': '',
          'isActive': 1,
          'Measurement': ''
        };
        this.QualitativeMetricsMaster.push(newRow);
        this.QualitativeMetricsMasterCopy = [];
        for (let matrics in this.QualitativeMetricsMaster) {
          if (this.QualitativeMetricsMaster[matrics].isActive == 1) {
            this.QualitativeMetricsMasterCopy.push(this.QualitativeMetricsMaster[matrics]);
          }
        }
        this.dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMasterCopy);
      }
    }
  }

  SubmitMetricMasterChangesQualitative() {
    if (this.ValidateNewMatricQualitative()) {

      for (let MatricsCount = 0; MatricsCount < this.masterData.dtQualitativeMetricsMaster.length; MatricsCount++) {
        let metricUpdateFilter = this.QualitativeMetricsMaster.filter((matrics: any) => matrics.MetricName.trim().toUpperCase() == this.masterData.dtQualitativeMetricsMaster[MatricsCount].MetricName.trim().toUpperCase())
        if (metricUpdateFilter != undefined && metricUpdateFilter.length > 0) {
          this.masterData.dtQualitativeMetricsMaster[MatricsCount].isActive = 1;
        }
        else {
          this.masterData.dtQualitativeMetricsMaster[MatricsCount].isActive = 0;
        }
      }

      for (let newMatricsCount = 0; newMatricsCount < this.QualitativeMetricsMaster.length; newMatricsCount++) {
        let metricUpdateFilter = this.masterData.dtQualitativeMetricsMaster.filter((matrics: any) => matrics.MetricName.trim().toUpperCase() == this.QualitativeMetricsMaster[newMatricsCount].MetricName.trim().toUpperCase())
        if (!(metricUpdateFilter != undefined && metricUpdateFilter.length > 0)) {
          this.masterData.dtQualitativeMetricsMaster.push(JSON.parse(JSON.stringify(this.QualitativeMetricsMaster[newMatricsCount])));
        }
      }
      //  this.masterData.dtQualitativeMetricsMaster.push(JSON.parse(JSON.stringify(this.QualitativeMetricsMaster[this.QualitativeMetricsMaster.length - 1])));
      //}

      var data = {
        'PS_No': this.userInfo.PS_No,
        'QualitativemetricsMasterData': this.QualitativeMetricsMaster,
        'AppType': this.userInfo.AppType
      };
      this.appService.SetLoadingShow(true);
      this.appService.GetDataFromAPI(this.configs.UpdateQualitativeMetricsMasterURL(), data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.GetMasterData(true);
          }
        });
    }
  }
  ValidateNewMatricQualitative() {
    let isvalid = true;
    if (this.QualitativeMetricsMaster[this.QualitativeMetricsMaster.length - 1].MetricName == '') {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please Select Metric Details before saving"
      });
      isvalid = false;
    }
    else {
      for (let o: number = 0; o < (this.QualitativeMetricsMaster.length - 1); o++) {
        if (this.QualitativeMetricsMaster[o].MetricName.trim().toUpperCase() == this.QualitativeMetricsMaster[this.QualitativeMetricsMaster.length - 1].MetricName.trim().toUpperCase()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "Selected Metric already exists"
          });
          this.QualitativeMetricsMaster[this.QualitativeMetricsMaster.length - 1].MetricName = '';
          this.QualitativeMetricsMasterCopy = [];
          for (let matrics in this.QualitativeMetricsMaster) {
            if (this.QualitativeMetricsMaster[matrics].isActive == 1) {
              this.QualitativeMetricsMasterCopy.push(this.QualitativeMetricsMaster[matrics]);
            }
          }
          this.dataSourceNewRowMetricQualitative = new MatTableDataSource(this.QualitativeMetricsMasterCopy);
          isvalid = false;
        }
      }
    }
    return isvalid;
  }

  DownLoadExcelMetric(): void {
    //call api  >
    var data = {
      'PeriodId': this.selectedPeriod,
      'PS_No': this.userInfo.PS_No,
      "AppType": this.userInfo.AppType
    };
    this.appService.GetDataFromAPI(this.configs.MasterInfoDetailsForMetricParameters(), data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          window.open(this.configs.UploadTargetsExcelFileURl1());
        }
      }
        , error => {
          this.errorMessage = <string>error;
        }
      );

  }
  SearchForMetric1(value): any {
    if (this.MetricSearchArray.length == 0) {
      this.MetricSearchArray = this.MetricSearchArray
    }
    var data = JSON.parse(JSON.stringify(this.MetricSearchArray));
    if (value != '' && value != undefined) {
      if (value.length > 2) {
        const filterValue = value.toLowerCase();
        this.SearchText1 = value;
        for (var o in data) {
          if (data[o].MetricName == null)
            data[o].MetricName = "";
        }
        data = data.filter(option => option.MetricName.toLowerCase().includes(filterValue));
      }
      this.dataSourceNewRowMetric = data;
    }
    //if (value.length == 0) {
    //  this.MetricSearchArray = this.MetricSearchArray
    //}

  }

  AddValues(): void {
    this.sum = 0;
    for (var o in this.AdminTemplatesFiltered) {
      if (this.AdminTemplatesFiltered[o].Value != null) {
        this.sum = this.sum + parseFloat(this.AdminTemplatesFiltered[o].Value);
      }
      this.TotalValues = this.sum;

    }
  }

  onItemSelectForStatus(selectedrole, selectedItemsForStatus) {
    if (this.selectedFunction.length == this.dropdownListFunction.length) {
      this.EmpReleaseDates = this.EmpReleaseDatesNew
    }
    this.showTable = '0';
    var data = [];
    if (selectedrole.length < this.dropdownListRole.length) {
      for (var o in selectedrole) {
        if (selectedrole[o].itemName != '' || selectedrole[o].itemName != undefined || selectedrole[o].itemName != "Select") {
          for (var i in this.EmpReleaseDates) {
            if (this.EmpReleaseDates[i].Role == selectedrole[o].itemName) {
              data.push(this.EmpReleaseDates[i]);
            }
          }
        }
      }
    }
    else {
      data = this.EmpReleaseDates;
    }

    var dataSBU = [];

    this.showTable = '0';

    if (selectedItemsForStatus.length < this.dropdownListSBU.length) {
      for (var o in selectedItemsForStatus) {
        if (selectedItemsForStatus[o].itemName != '' || selectedItemsForStatus[o].itemName != undefined || selectedItemsForStatus[o] != "Select") {
          for (var i in data) {
            if (data[i].Status == selectedItemsForStatus[o].itemName) {
              dataSBU.push(data[i]);
            }
          }
        }
      }
    }
    else {
      dataSBU = data;
    }
    this.EmpReleaseDatesFiltered = dataSBU;

    if (this.EmpReleaseDatesFiltered.length > 0) {
      this.showTable = '8';
      this.showsubmit = true;
    }
    else {
      this.showTable = '8';
      this.showsubmit = false;
      //this.dialog.open(DialogDataExampleDialog, {
      //    data: "No record found."
      //});
      return;
    }
  }
}
