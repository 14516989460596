import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
  selector: 'app-spm-calculations',
  templateUrl: './spm-calculations.component.html',
  styleUrls: ['./spm-calculations.component.less']
})
export class SPMCALCULATIONComponent implements OnInit {
  PeriodId: any;
  ShowIOptionTypeSection: number;
  filters: any;
  selectedperiod: any;
  selectedAppType: any;
  selectedCalcFor: any;
  selectedPSNOForCalc: any;
  dtHunterData_Content: any;
  dtHunterData_Header: any;
  Reportnumber: any;


  UserPsno: any;
  ShowSection: number;
  userInfo: any;
  masterData: any;
  UploadOptionTypes: any = [];
  SelectCalcForOption: any = [];
  DownloadFilePathBaseForDetailedReport: any;
  DownloadFilePathForDetailedReport: any;
  LoggedInUserRoleText: any;

  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.ShowIOptionTypeSection = 1;
    this.filters = {
      'dtAppTypeMaster': [],
      'dtPeriodMaster': [],
      'dtPlanMaster': []
    };
    this.selectedperiod = 0;
    this.selectedCalcFor = 0;
    this.selectedAppType = 0;
    this.selectedPSNOForCalc = '';
    this.dtHunterData_Content = [];
    this.dtHunterData_Header = [];
    this.Reportnumber = '';



    this.masterData = {};
    this.UserPsno = '';
    this.ShowSection = 1;

    this.UploadOptionTypes = [{ 'OptionTypeId': 1, 'OptionType': 'HunterUSA' }, { 'OptionTypeId': 2, 'OptionType': 'Incentive Calculation' }]
    this.SelectCalcForOption = [{ 'CalcForOptionId': 1, 'CalcForOptionType': 'All' }, { 'CalcForOptionId': 2, 'CalcForOptionType': 'PSno(s)' }]
    this.DownloadFilePathBaseForDetailedReport = '../../../UploadTemplates/DetailedReport_';
    this.DownloadFilePathForDetailedReport = '';
    this.LoggedInUserRoleText = '';
  }
  ngOnInit() {
    this.appService.SetLoadingShow(false);
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.UserPsno = this.userInfo.PS_No;
        this.masterData = this.appService.GetMasterDetails();
        this.getMasterTable();
        this.ShowSection = 0;
        this.ShowIOptionTypeSection = 0;
      }
      else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }

  getMasterTable(): void {
    var data = {
      "AppType": this.userInfo.AppType
    };
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetCalculaionMasterTableURl();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.filters.dtPeriodMaster = response.ResponseData.dtPeriodMaster;
          this.filters.dtAppTypeMaster = response.ResponseData.dtAppTypeMaster;
          this.filters.dtPlanMaster = response.ResponseData.dtPlanMaster;
        }
      }
        ,
        error => {

        });
  }

  ShowOptiontype(value: number) {
    this.ShowIOptionTypeSection = value;
    this.GetDataForCalucation();
  }

  GetDataForCalucation() {
    if (this.ShowIOptionTypeSection == 1) {
      this.GetMasterFor_HunterUSARoleInSMA(this.selectedperiod);
    }
    else {

    }

  }
  onChangeOFPeriod(id) {
    if (id != '') {
      this.selectedperiod = id;
      if (id != '' && this.ShowIOptionTypeSection == 1) {
        this.GetMasterFor_HunterUSARoleInSMA(id);
      }
      //else if (id != '' && this.ShowIOptionTypeSection == 2) {
      //  this.
      //}

    }
    else {
      this.selectedperiod = '0';
    }

  }



  GetMasterFor_HunterUSARoleInSMA(id): void {
    var Input = {
      "PeriodId": id,
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetMasterFor_HunterUSARoleInSMAURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dtHunterData_Content = response.ResponseData.dtHunterData_Content;
          this.dtHunterData_Header = response.ResponseData.dtHunterData_Header;
        }
      }
        ,
        error => {

        });
  }
  ClickToAddHunterUSARoleInSMA(PeriodId, PSNO): void {
    var Input = {
      "PeriodId": PeriodId,
      "PSNO": PSNO
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.AddHunterUSARoleInSMAURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
          this.GetMasterFor_HunterUSARoleInSMA(PeriodId);

        }
      }
        ,
        error => {

        });
  }
  CalculateIncentiveActuals() {

    if (this.selectedCalcFor == 2) {
      this.EmployeeIncentiveCalculations(this.selectedPSNOForCalc, this.selectedPSNOForCalc);
    }
    else {
      this.EmployeeIncentiveCalculations("ALL", "0");
    }
  }

  EmployeeIncentiveCalculations(CalcFor, PSNO) {
    var Input = {
      "CalcFor": CalcFor,
      "PSNo": PSNO,
      "PeriodId": this.selectedperiod,
      "AppType": this.selectedAppType
    }
    this.appService.SetLoadingShow(true);
    var url = this.configs.EmployeeIncentiveCalculationsURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });


        }
      }
        ,
        error => {

        });
  }
  DownLoadExcelReport(): void {
    //call api  >
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == this.selectedperiod)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }

    var TargetScreenRoles = this.appService.GetUserRoleForTargetApproval(this.userInfo.SPMRoles);
    if (TargetScreenRoles.CNBRoleId > 0) {
      this.LoggedInUserRoleText = 'CNB';
    }
    else {
      this.LoggedInUserRoleText = 'NONCNB';
    }

    var data = {
      "Periodvalues": this.selectedperiod,
      "AppType": this.selectedAppType,
      'DataFor': this.LoggedInUserRoleText,
      'PeriodName': PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDetailedReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.Reportnumber = response.ResponseData.ReportNumber;
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + PeriodName +'_version_' +this.Reportnumber+ ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);
          // window.open(this.configs.DetailedReportExcelFileURl());
        }
      });
  }
}

 

   


