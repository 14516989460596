import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';
import { yearsPerPage } from '@angular/material/datepicker/typings/multi-year-view';
//import * as CryptoJS from 'crypto-js' 
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/toPromise';

@Injectable()
export class AppService {

  loadingShow: boolean = true;
  loadingShowChangeObserver: any;
  loadingShowChange: Observable<any>;

  UserDetailsChangeObserver: any;
  UserDetailsChange: Observable<any>;

  constructor(private http: Http) {
    this.UserDetailsChange = new Observable(observer => {
      this.UserDetailsChangeObserver = observer;
    });
    this.loadingShowChange = new Observable(observer => {
      this.loadingShowChangeObserver = observer;
    });
  }
  FileUpLoad(url: string, file, UserPsno: string, UserRole: string, rolename: string, allowedperiodupload: any, PeriodYear: string, AppType: string): Promise<any> {
    //  let file: File;
    // if (fileList.length > 0) {
    // file = fileList[0];
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('userPsno', UserPsno);
    formData.append('userRole', UserRole);
    formData.append('rolename', rolename);
    formData.append('allowedperiods', JSON.stringify(allowedperiodupload));
    formData.append('periodYear', PeriodYear);
    formData.append('AppType', AppType);
    let headers = new Headers()
    headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
    //headers.append('Content-Type', 'json');  
    //headers.append('Accept', 'application/json');  
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, formData, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
    // }
  }

  FileUpLoadActuals(url: string, file: any, PeriodId: string, AppType: string, MappingType: string, UserPsno: string, YearType: string, TypeofUpload: string): Promise<any> {
    //  let file: File;
    // if (fileList.length > 0) {
    // file = fileList[0];
    let formData: FormData = new FormData();
    formData.append('userPsno', UserPsno);
    formData.append('uploadFile', file, file.name);
    formData.append('periodId', PeriodId);
    formData.append('AppType', AppType);
    formData.append('MappingType', MappingType);
    formData.append('YearType', YearType);
    formData.append('TypeofUpload', TypeofUpload);
    let headers = new Headers()
    headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
    //headers.append('Content-Type', 'json');  
    //headers.append('Accept', 'application/json');  
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, formData, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
    // }
  }

  FileUpLoadAccountMapping(url: string, file: any, AppType: string, MappingType: string, UserPsno: string): Promise<any> {
    //  let file: File;
    // if (fileList.length > 0) {
    // file = fileList[0];
    let formData: FormData = new FormData();
    formData.append('userPsno', UserPsno);
    formData.append('uploadFile', file, file.name);
    formData.append('AppType', AppType);
    formData.append('MappingType', MappingType);

    let headers = new Headers()
    headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
    //headers.append('Content-Type', 'json');  
    //headers.append('Accept', 'application/json');  
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, formData, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
    // }
  }

  GetADLogin(url: string): Promise<any> {
    return this.http.get(url).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
  }
  GetDataFromAPI(url: string, data: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, data, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
  }
  GetDataFromAPILogin(url: string, data: any, token: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', "Bearer " + localStorage.getItem("user.token") );
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, data, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error);
    //   return Promise.reject(error || error);
  }
  //CloseSession(url: string, data: any): void {
  //    let headers = new Headers({ 'Content-Type': 'application/json' });
  //    let options = new RequestOptions({ headers: headers });
  //    this.http.post(url, data, options);
  //}
  ShareMasterDetails(data: any) {
    localStorage.setItem('masterdetails', JSON.stringify(data));
  }
  GetMasterDetails() {
    return JSON.parse(localStorage.getItem('masterdetails'));
  }

  LoginUserDetails: any;

  SetLoginUserInfo(loginUserInfo: any) {
    if (loginUserInfo != null && loginUserInfo != undefined) {
      this.LoginUserDetails = JSON.parse(JSON.stringify(loginUserInfo));
      this.UserDetailsChangeObserver.next(JSON.parse(JSON.stringify(this.LoginUserDetails)));
    }
    else {
      this.LoginUserDetails = null;
      this.UserDetailsChangeObserver.next(null)

    }
  }
  SetLoadingShow(showLoad: boolean) {
    this.loadingShow = showLoad;

    this.loadingShowChangeObserver.next(this.loadingShow);
  }

  GetLoginUserInfo() {
    if (this.LoginUserDetails != null && this.LoginUserDetails != undefined) {
      return JSON.parse(JSON.stringify(this.LoginUserDetails));
    }
    else {
      return null;
    }
  }

  BulkUploadList: any;

  SetBulkUploadList(values: any) {
    this.BulkUploadList = values;

  }

  GetBulkUploadList() {
    return this.BulkUploadList;
  }
  BulkUploadListSelectedForInitiate: any;

  SetBulkUploadListSelectedForInitiate(values: any) {
    this.BulkUploadListSelectedForInitiate = values;

  }

  GetBulkUploadListSelectedForInitiate() {
    return this.BulkUploadListSelectedForInitiate;
  }

  periodId: any;
  SetperiodId(periodId: any) {
    this.periodId = periodId;

  }

  GetperiodId() {
    return this.periodId;
  }
  GetTodaysDateInYYYYMMDD() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var DD; var MM; var YYYY;
    if (dd < 10)
      DD = '0' + dd;
    else
      DD = dd

    if (mm < 10)
      MM = '0' + mm;
    else
      MM = mm;

    YYYY = yyyy;
    var TodayDate = YYYY + '-' + MM + '-' + DD;
    return TodayDate;
  }
  RoleID: any;
  SetRoleID(roleid: any) {
    this.RoleID = roleid;

  }

  GetRoleId() {
    return this.RoleID;
  }

  CheckIfAssessmentNoAreFilled(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].Actual != '' && tblMetrics[o].Actual != undefined && tblMetrics[o].Actual != null) {
        if (tblMetrics[o].IsAlphaNumericTarget == 0) {
          if (tblMetrics[o].SelfAssessment === undefined || tblMetrics[o].SelfAssessment === null) {
            isValid = false;
            break;
          }
          if (tblMetrics[o].SelfAssessment.toString().trim().length == 0 || tblMetrics[o].SelfAssessment === '') {
            isValid = false;
            break;
          }

        }
        else {
          if (tblMetrics[o].SelfAssessmentText === undefined || tblMetrics[o].SelfAssessmentText === null) {
            isValid = false;
            break;
          }
          if (tblMetrics[o].SelfAssessmentText.toString().trim().length == 0 || tblMetrics[o].SelfAssessmentText === '') {
            isValid = false;
            break;
          }
        }

      }
    }

    return isValid;
  }

  CheckOtherParameters(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0) {
        if (tblMetrics[o].SelfAssessment.toString().trim().length == 0 || tblMetrics[o].SelfAssessment == 0 || tblMetrics[o].SelfAssessment == "SELECT") {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
  CheckOtherParametersForSelfComments(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0) {
        if (tblMetrics[o].SelfComments === undefined || tblMetrics[o].SelfComments === null) {
          isValid = false;
          break;
        }
        if (tblMetrics[o].SelfComments.toString().trim().length == 0) {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }


  CheckOtherParametersForManagerComments(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0) {
        if (tblMetrics[o].ManagerComments === undefined || tblMetrics[o].ManagerComments === null) {
          isValid = false;
          break;
        }
        if (tblMetrics[o].ManagerComments.toString().trim().length == 0 || tblMetrics[o].ManagerComments === '') {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }

  CheckOtherParametersForReviewerComments(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0) {
        if (tblMetrics[o].ReviewerComments === undefined || tblMetrics[o].ReviewerComments === null) {
          isValid = false;
          break;
        }
        if (tblMetrics[o].ReviewerComments.toString().trim().length == 0 || tblMetrics[o].ReviewerComments === '') {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }


  CheckIfManagerAssessmentNoAreFilled(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].Actual != '' && tblMetrics[o].Actual != undefined && tblMetrics[o].Actual != null) {
        if (tblMetrics[o].IsAlphaNumericTarget == 0) {
          if (tblMetrics[o].ManagerAssessment === undefined || tblMetrics[o].ManagerAssessment === null) {
            isValid = false;
            break;
          }
          if (tblMetrics[o].ManagerAssessment.toString().trim().length == 0 || tblMetrics[o].ManagerAssessment === '') {
            isValid = false;
            break;
          }
        }
        else {
          if (tblMetrics[o].ManagerAssessmentText === undefined || tblMetrics[o].ManagerAssessmentText === null) {
            isValid = false;
            break;
          }
          if (tblMetrics[o].ManagerAssessmentText.toString().trim().length == 0 || tblMetrics[o].ManagerAssessmentText === '') {
            isValid = false;
            break;
          }

        }
      }
    }
    return isValid;
  }
  CheckOtherParametersManager(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0 && tblMetrics[o].isAppraisal == 1) {
        if (tblMetrics[o].ManagerAssessment.toString().trim().length == 0 || tblMetrics[o].ManagerAssessment == 0 || tblMetrics[o].ManagerAssessment == "SELECT") {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }

  CheckIfReviewerAssessmentNoAreFilled(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].Actual != '' && tblMetrics[o].Actual != undefined && tblMetrics[o].Actual != null) {
        if (tblMetrics[o].IsAlphaNumericTarget == 0) {
          if (tblMetrics[o].ReviewerAssessment === undefined || tblMetrics[o].ReviewerAssessment === null) {
            isValid = false;
            break;
          }
          if (tblMetrics[o].ReviewerAssessment.toString().trim().length == 0 || tblMetrics[o].ReviewerAssessment === '') {
            isValid = false;
            break;
          }
        }
        else {
          if (tblMetrics[o].ReviewerAssessmentText === undefined || tblMetrics[o].ReviewerAssessmentText === null) {
            isValid = false;
            break;
          }
          if (tblMetrics[o].ReviewerAssessmentText.toString().trim().length == 0 || tblMetrics[o].ReviewerAssessmentText === '') {
            isValid = false;
            break;
          }
        }
      }
    }
    return isValid;
  }
  CheckOtherParametersReviewer(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0 && tblMetrics[o].isAppraisal == 1) {
        if (tblMetrics[o].ReviewerAssessment.toString().trim().length == 0 || tblMetrics[o].ReviewerAssessment == "SELECT" || tblMetrics[o].ReviewerAssessment == 0) {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
  selectedPsno: string;  
  SetPsno(psno: string): void {
    this.selectedPsno = psno;
  }

  GetPsno(): string {
    return this.selectedPsno;
  }

  selectedisAccessValue: string
  SetisAccessValue(isAccessValue: string): void {
    this.selectedisAccessValue = isAccessValue;
  }
  GetisAccessValue(): string {
    return this.selectedisAccessValue;
  }

  selectedUploadId: string
  SetSelectedUploadId(uploadId: string): void {
    this.selectedUploadId = uploadId;
  }
  GetSelectedUploadId(): string {
    return this.selectedUploadId;
  }

  selectedType: string
  SeTypeSelected(TypeSelect: string): void {
    this.selectedType = TypeSelect;
  }
  GetTypeSelected(): string {
    return this.selectedType;
  }

  selectedOption: string
  SetOptionSelected(OptionSelect: string): void {
    this.selectedOption = OptionSelect;
  }
  GetOptionSelected(): string {
    return this.selectedOption;
  }

  selectedAppType: string
  SetSelectedAppType(appTypeSelect: string): void {
    this.selectedAppType = appTypeSelect;
  }
  GetAppTypeSelected(): string {
    return this.selectedAppType;
  }

  selectedPlan: string
  SetSelectedPlan(PlanSelect: string): void {
    this.selectedPlan = PlanSelect;
  }
  GetPlanSelected(): string {
    return this.selectedPlan;
  }

  selectedSBU: string
  SetSelectedSBU(SBUSelect: string): void {
    this.selectedSBU = SBUSelect;
  }
  GetSBUSelected(): string {
    return this.selectedSBU;
  }

  isManagerOrReviewer: boolean;
  SetIsManagerOrReviewer(ManagerOrReviewer: boolean): void {
    this.isManagerOrReviewer = ManagerOrReviewer;
  }

  GetIsManagerOrReviewer(): boolean {
    return this.isManagerOrReviewer;
  }

  selectedStatus: string;
  SetStatus(status: string): void {
    this.selectedStatus = status;
  }

  GetStatus(): string {
    return this.selectedStatus;
  }

  dropdownListStatusData: any;
  SetdropdownListStatus(statusData: any = []): void {
    this.dropdownListStatusData = statusData;
  }

  GetdropdownListStatus(): string {
    return this.dropdownListStatusData;
  }

  reporteeData: any;
  StoreReporteedata(data: any): void {
    this.reporteeData = data;
  }

  GetReporteeData(): any {
    return this.reporteeData;
  }

  reporteeStatusData: any;
  StoreReporteeStatusData(data: any): void {
    this.reporteeStatusData = data;
  }
  GetReporteeStatusData(): any {
    return this.reporteeStatusData;
  }



  ClearVariables(): void {
    //this.SetCookieTokenId("1a2l3z", '');
    //this.SetSessionId('1m2n3b', '');
    this.periodId = undefined;
    this.reporteeStatusData = [];
    this.reporteeData = [];
    this.selectedStatus = '';
    this.selectedPsno = '';
    this.RoleID = null;
    this.BulkUploadList = [];
    this.selectedApprovalID = 0;
    this.FromHistory = 'N';
    this.DashBoardDataObject = undefined;
    this.ActualDataObject = undefined;
    //this.AdUserMailId = undefined;
    this.ActualAccountWiseObject = undefined;
    this.IncentiveDetailsList = [];
    this.selectedisAccessValue = '';
  }

  selectedApprovalID: number;
  SetApprovalID(ApprovalID: number): void {
    this.selectedApprovalID = ApprovalID;
  }

  GetApprovalID(): number {
    return this.selectedApprovalID;
  }

  selectedApprovalType: number;
  SetApprovalType(Type: number): void {
    this.selectedApprovalType = Type;
  }

  GetApprovalType(): number {
    return this.selectedApprovalType;
  }
  CorporateUserType: string;
  SetCorporateUserType(type: string): void {
    this.CorporateUserType = type;
  }

  GetCorporateUserType(): string {
    return this.CorporateUserType;
  }
  FromHistory: string;
  SetFromHistory(FromHistory: string): void {
    this.FromHistory = FromHistory;
  }

  GetFromHistory(): string {
    return this.FromHistory;
  }
  DashBoardDataObject: any;
  SetDashBoardData(data: any): void {
    this.DashBoardDataObject = data;
  }

  GetDashBoardData(): any {
    return this.DashBoardDataObject;
  }

  ActualDataObject: any;
  SetActualData(data: any): void {
    this.ActualDataObject = data;
  }
  ResetSetActualData(): void {
    this.ActualDataObject = undefined;
  }

  GetActualData(): any {
    return this.ActualDataObject;
  }

  ActualAccountWiseObject: any;
  SetAccountWiseReport(data: any): void {
    this.ActualDataObject = data;
  }
  GetAccountWiseReport(): any {
    return this.ActualAccountWiseObject;
  }

  AdUserMailId: any;
  SetAdUserMailId(data: any): void {
    this.AdUserMailId = data;
  }

  GetAdUserMailId(): any {
    return this.AdUserMailId;
  }


  SetCookieTokenId(name: any, value: any): void {
    document.cookie = name + "=" + (value || "");
  }


  SetSessionId(name: any, value: any): void {
    document.cookie = name + "=" + (value || "")
  }

  IncentiveDetailsList: any;
  SetIncentiveDetailsList(data: any): void {
    this.IncentiveDetailsList = data;
  }
  GetIncentiveDetailsList(): any {
    return this.IncentiveDetailsList;
  }
  AppraisalDetailsList: any;
  SetAppraisalDetailsList(data: any): void {
    this.AppraisalDetailsList = data;
  }
  GetAppraisalDetailsList(): any {
    return this.AppraisalDetailsList;
  }

  GetWeightedAverage(RevenueAchievement: number, WeightedPercentage: number) {
    var FinalPercentage = 0;
    if (RevenueAchievement > 50 && RevenueAchievement > WeightedPercentage) {
      FinalPercentage = RevenueAchievement;
    }
    else if (50 > RevenueAchievement && 50 > WeightedPercentage) {
      FinalPercentage = 50;
    }
    else {
      FinalPercentage = WeightedPercentage;
    }

    if (isNaN(FinalPercentage)) {
      return '';
    }
    else {
      return this.AddPercentageSign(FinalPercentage.toString());
    }
  }
  GetRevenueAchievement(tblMetrics, CompanyPerFormance: number) {
    var RevenueAchievement = 0;
    for (var o in tblMetrics) {
      if (tblMetrics[o].SalesMetricID == 1) {
        RevenueAchievement = (parseFloat(tblMetrics[o].AchievementValue));
        if (RevenueAchievement > 100) {
          RevenueAchievement = 100;
          break;
        }
      }
    }
    if (isNaN(RevenueAchievement)) {
      return '';
    }
    else {
      return this.AddPercentageSign(RevenueAchievement.toString());
    }
  }

  AddPercentageSign(value) {
    if (value != null && value != undefined) {
      if (value.toString().length > 0) {
        return value + '%';
      }
      else {
        return value;
      }
    }
  }
  userTypeForIncetiveScreen: string = '';
  SetRoleForIncentivePage(data: string) {
    this.userTypeForIncetiveScreen = data;
  }
  GetRoleForIncentivePage() {
    return this.userTypeForIncetiveScreen;
  }

  GetUserRoleForTargetApproval(SPMRoles): any {
    var targetScreenRoles = {
      'CSRoleId': 0,
      'HRRoleId': 0,
      'CBORoleId': 0,
      'CNBRoleId': 0,
      'MGRRoleId': 0,
      'SELARoleId': 0,
      'FunctionHeadId': 0
    }
    for (var o in SPMRoles) {
      if (SPMRoles[o].RoleID == 11) // CS
      {
        targetScreenRoles.CSRoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 17) // HR
      {
        targetScreenRoles.SELARoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 8) // HR
      {
        targetScreenRoles.HRRoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 13) // CS
      {
        targetScreenRoles.CNBRoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 14) // CS
      {
        targetScreenRoles.CBORoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 9) // MGR
      {
        targetScreenRoles.MGRRoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 20) // FunctionHead
      {
        targetScreenRoles.FunctionHeadId = SPMRoles[o].RoleID;
      }
    }
    return targetScreenRoles;
  }
  GetUserRoleForTargetUpload(SPMRoles): any {
    var targetScreenRoles = {
      'CSRoleId': 0,
      'HRRoleId': 0,
      'CBORoleId': 0,
      'SELARoleId': 0,
      'FunctionHeadId': 0,
    }
    for (var o in SPMRoles) {
      if (SPMRoles[o].RoleID == 11) // CS
      {
        targetScreenRoles.CSRoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 8) // CS
      {
        targetScreenRoles.HRRoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 14) // CS
      {
        targetScreenRoles.CBORoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 17) // SELA
      {
        targetScreenRoles.SELARoleId = SPMRoles[o].RoleID;
      }
      else if (SPMRoles[o].RoleID == 20) // FunctionHead
      {
        targetScreenRoles.FunctionHeadId = SPMRoles[o].RoleID;
      }
    }
    return targetScreenRoles;
  }
  NavbackPathForAssessmentDetails: string = '';
  GetNavbackPathForAssessmentDetails(): string {
    return this.NavbackPathForAssessmentDetails;
  }
  SetNavbackPathForAssessmentDetails(value): void {
    this.NavbackPathForAssessmentDetails = value;
  }
  IncentiveEligibleAmountUpload(url: string, file: any, PeriodId: string, AppType: string): Promise<any> {
    //  let file: File;
    // if (fileList.length > 0) {
    // file = fileList[0];
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
      formData.append('periodId', PeriodId);
      formData.append('AppType', AppType);
      let headers = new Headers()
      headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
    //headers.append('Content-Type', 'json');  
    //headers.append('Accept', 'application/json');  
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, formData, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
    // }
    }
    UserMasterUpload(url: string, file: any, PeriodYear: string, cetpsno: string, AppType: string): Promise<any> {
        //  let file: File;
        // if (fileList.length > 0) {
        // file = fileList[0];
        let formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        formData.append('PeriodYear', PeriodYear);
        formData.append('cetpsno', cetpsno);
        formData.append('AppType', AppType);
      let headers = new Headers()
      headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
        //headers.append('Content-Type', 'json');  
        //headers.append('Accept', 'application/json');  
        let options = new RequestOptions({ headers: headers });
        return this.http.post(url, formData, options).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorPromise);
        // }
  }
  TargetMappingAndWeightagesUpload(url: string, file: any, PeriodId: string, AppType: string, PSNo: string, LoggedInUserRole: string): Promise<any> {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('periodId', PeriodId);
    formData.append('AppType', AppType);
    formData.append('userPsno', PSNo);
    formData.append('userRole', LoggedInUserRole);
    let headers = new Headers()
    headers.append('Authorization', "Bearer " + localStorage.getItem("user.token"));
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, formData, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorPromise);
  }


  DownloadTargetletter(path) {
    window.open("TargetLetterDownload.ashx?fileName=" + path);
  }
  GetPidmappingMonths(year): any {
    var Months = [{ 'Id': '01', 'Name': 'Jan' }, { 'Id': '02', 'Name': 'Feb' }, { 'Id': '03', 'Name': 'Mar' }, { 'Id': '04', 'Name': 'Apr' }, { 'Id': '05', 'Name': 'May' }, { 'Id': '06', 'Name': 'Jun' }, { 'Id': '07', 'Name': 'Jul' }, { 'Id': '08', 'Name': 'Aug' }, { 'Id': '09', 'Name': 'Sep' }, { 'Id': '10', 'Name': 'Oct' }, { 'Id': '11', 'Name': 'Nov' }, { 'Id': '12', 'Name': 'Dec' }]
    for (var o in Months) {
      if (parseInt(Months[o].Id) > 0 && parseInt(Months[o].Id) < 4) {
        Months[o]["Id"] = (parseInt(year) + 1).toString() + "-" + Months[o].Id;
        Months[o]["Name"] = Months[o].Name + "-" + (parseInt(year) + 1).toString().substr(2, 2);
      }
      else {
        Months[o]["Id"] = year.toString() + "-" + Months[o].Id;
        Months[o]["Name"] = Months[o].Name + "-" + year.toString().substr(2, 2);
      }
    }

    return Months;
  }

  ////encrypt(msg, pass) {
  ////    // random salt for derivation
  ////    var keySize = 256;
  ////    var salt = CryptoJS.lib.WordArray.random(16);
  ////    // well known algorithm to generate key
  ////    var key = CryptoJS.PBKDF2(pass, salt, {
  ////        keySize: keySize / 32,
  ////        iterations: 100
  ////    });
  ////    // random IV
  ////    var iv = CryptoJS.lib.WordArray.random(128 / 8);
  ////    // specify everything explicitly
  ////    var encrypted = CryptoJS.AES.encrypt(msg, key, {
  ////        iv: iv,
  ////        padding: CryptoJS.pad.Pkcs7,
  ////        mode: CryptoJS.mode.CBC
  ////    });
  ////    // combine everything together in base64 string
  ////    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
  ////    return result;
  ////}

  //for Theme selection 
  ThemeValue: any;
  SetThemeValue(data: any): void {
    this.ThemeValue = data;
  }
  GetThemeValue(): any {
    //this.ThemeValue = "Dark";
    return this.ThemeValue;
  }
  DataForPreTargets: any;
  SetPreTargetData(data) {
    this.DataForPreTargets = data;
  }
  GetDataForPreTargets() {
    return this.DataForPreTargets;
  }
  TeamName(AppType): string {
    var TeamName = '';
    switch (AppType.toString()) {
      case "1": TeamName = 'Sales Excellence';
        break;
      case "2": TeamName = 'Business Operations';
        break;

      default: TeamName = 'Sales Excellence';
        break;
    }
    return TeamName;
  }
  CheckOtherParametersForManagerWeightage(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0 && tblMetrics[o].isDB == 1) {
        if (tblMetrics[o].ManagerWeightage === undefined || tblMetrics[o].ManagerWeightage === null) {
          isValid = false;
          break;
        }
        if (tblMetrics[o].ManagerWeightage.toString().trim().length == 0 || tblMetrics[o].ManagerWeightage.toString() === '') {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
  CheckOtherParametersForReviewerWeightage(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0 && tblMetrics[o].isDB == 1) {
        if (tblMetrics[o].ReviewerWeightage === undefined || tblMetrics[o].ReviewerWeightage === null) {
          isValid = false;
          break;
        }
        if (tblMetrics[o].ReviewerWeightage.toString().trim().length == 0 || tblMetrics[o].ReviewerWeightage.toString() === '') {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
  CheckOtherParametersForExecutiveWeightage(tblMetrics: any): boolean {
    var isValid = true;
    for (var o in tblMetrics) {
      if (tblMetrics[o].isTotal == 0 && tblMetrics[o].isDB == 1) {
        if (tblMetrics[o].ExecutiveWeightage === undefined || tblMetrics[o].ExecutiveWeightage === null) {
          isValid = false;
          break;
        }
        if (tblMetrics[o].ExecutiveWeightage.toString().trim().length == 0 || tblMetrics[o].ExecutiveWeightage.toString() === '') {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
  CheckFinalReviewerAssessment(FinalReviewerAssessment: number): boolean {
    var isValid = true;
    if (FinalReviewerAssessment.toString().trim().length == 0 || FinalReviewerAssessment.toString() == "SELECT" || FinalReviewerAssessment == 0 || FinalReviewerAssessment == null) {
      isValid = false;
    }
    return isValid;
  };
  CheckNegativeNumbersForDiscretionaryComponent(value, Max) {

    var InValid = false;

    if (isNaN(value)) {
      InValid = true;
    }

    if (value < 0 || value > Max) {
      InValid = true;
    }
    return InValid;
  }
  CheckAccessForRoute(url, table) {
    var AllowUser = false;
    for (var o in table) {
      if (table[o].OptionRoute == url || (url.indexOf(table[o].OptionRoute) != -1) || (table[o].OptionRoute.indexOf(url) != -1)) {
        AllowUser = true;
        break;
      }
    }
    return AllowUser;
  }

  IsNavBwtweenTargetDashBoardAndMyTargets: boolean;
  SetIsNavBwtweenTargetDashBoardAndMyTargets(NavBwtweenTargetDashBoardAndMyTarget: boolean): void {
    this.IsNavBwtweenTargetDashBoardAndMyTargets = NavBwtweenTargetDashBoardAndMyTarget;
  };


  GetIsNavBwtweenTargetDashBoardAndMyTargets(): boolean {
    return this.IsNavBwtweenTargetDashBoardAndMyTargets;
  }

  AutoPeriodId() {

    let periodid = 14;
    return periodid;

  }

  AutoPeriodYear() {

    let PeriodYear = 'FY24';
    return PeriodYear;
    
  }
}
