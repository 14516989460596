import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { UserIdleModule } from 'angular-user-idle'; //For Autologout functionality
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MzNavbarModule,
  MzInputModule,
  MzButtonModule,
  MzSelectModule,
  MzRadioButtonModule,
  MzCheckboxModule,
  MzTextareaModule,
  MzIconModule,
  MzIconMdiModule,
  MzDatepickerModule,
  MzModalModule,
  MzCollapsibleModule,
  MzTooltipModule
} from 'ngx-materialize'
import { AppComponent } from './app.component';
import {
  MatCardModule,
  MatTableModule,
  MatDatepickerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatIconModule,
  MatTabsModule,
  MatSelectModule


} from '@angular/material';
import 'hammerjs';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { SelfAssessmentComponent } from './self-assessment/self-assessment.component';
import { ReporteeViewComponent } from './reportee-view/reportee-view.component';
import { ManagerViewComponent } from './manager-view/manager-view.component';
import { ReviewerViewComponent } from './reviewer-view/reviewer-view.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { AppService } from './app.service';
import { Configs } from './app.config';
import { HttpModule } from '@angular/http';
import { DialogDataExampleDialog } from './popup.component';
import { RejectAssessmentComponent } from './reject-assessment/reject-assessment.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './history/history.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewTargetsComponent } from './view-Targets/view-targets.component';
import { AssessmentDetailsComponent } from './assessment-details/assessment-details.component';
import { CalculationsDetailsComponent } from './calculations-details/calculations-details.component';
import { ActualsComponent } from './actuals/actuals.component';
import { CNBDashboardComponent } from './cnb-dashboard/cnb-dashboard.component';
import { IncentiveDetailsComponent } from './incentive-details/incentive-details.component';
import { MyTargetsComponent } from './my-targets/my-targets.component';
import { NoAccessComponent } from './noaccess/noaccess.component';
import { ActualUploadComponent } from './actual-upload/actual-upload.component';
import { AppraisalDashboardComponent } from './appraisal-dashboard/appraisal-dashboard.component';
import { FinalstrataUpdateComponent } from './finalstrata-update/finalstrata-update.component';
import { HRDashboardComponent } from './hr-dashboard/hr-dashboard.component';
import { PIDMAPPINGComponent } from './pid-mapping/pid-mapping.component';
import { PIDAPPROVALComponent } from './pid-approval/pid-approval.component';
import { UserMasterManagementComponent } from './usermaster-management/usermaster-management.component';
import { SPMCALCULATIONComponent } from './spm-calculations/spm-calculations.component';
import { SPMREPORTComponent } from './spm-report/spm-report.component';
import { PreTargetComponent } from './pre-target/pre-target.component';
import { TeamDashboardComponent } from './team-dashboard/team-dashboard.component';
import { ExecutiveViewComponent } from './executive-view/executive-view.component';
import { AchievementDashboardComponent } from './achievement-dashboard/achievement-dashboard.component';
import { CALCULATIONSComponent } from './calculations/calculations.component';
import { LoginLoadingComponent } from './loginloading/loginloading.component';
import { MsAdalAngular6Module, AuthenticationGuard } from 'microsoft-adal-angular6';
import { OAuthSettings } from './oAuth';
// Routing
const appRoutes: Routes = [
  //{ path: 'login', component: LoginComponent },
  { path: '', component: LoginLoadingComponent, canActivate: [AuthenticationGuard] },
  { path: '1a0y3h', component: LoginComponent, canActivate: [AuthenticationGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'bulk-upload', component: BulkUploadComponent, canActivate: [AuthenticationGuard] },
  { path: 'self-assessment', component: SelfAssessmentComponent, canActivate: [AuthenticationGuard] },
  { path: 'reportee-view/:RoleID', component: ReporteeViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'manager-view', component: ManagerViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'reviewer-view', component: ReviewerViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'super-admin', component: SuperAdminComponent, canActivate: [AuthenticationGuard] },
  { path: 'reject-assessment/:Flag', component: RejectAssessmentComponent, canActivate: [AuthenticationGuard] },
  { path: 'reject-assessment', component: RejectAssessmentComponent, canActivate: [AuthenticationGuard] },
  { path: 'history', component: HistoryComponent, canActivate: [AuthenticationGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
  { path: 'view-targets', component: ViewTargetsComponent, canActivate: [AuthenticationGuard] },
  { path: 'assessment-details', component: AssessmentDetailsComponent, canActivate: [AuthenticationGuard] },
  { path: 'calculations-details', component: CalculationsDetailsComponent, canActivate: [AuthenticationGuard] },
  { path: 'actual-details', component: ActualsComponent, canActivate: [AuthenticationGuard] },
  { path: 'cnb-dashboard', component: CNBDashboardComponent, canActivate: [AuthenticationGuard] },
  { path: 'incentive-details', component: IncentiveDetailsComponent, canActivate: [AuthenticationGuard] },
  { path: 'my-targets', component: MyTargetsComponent, canActivate: [AuthenticationGuard] },
  { path: 'actual-upload', component: ActualUploadComponent, canActivate: [AuthenticationGuard] },
  { path: 'appraisal-dashboard', component: AppraisalDashboardComponent, canActivate: [AuthenticationGuard] },
  { path: 'finalstrata-update', component: FinalstrataUpdateComponent, canActivate: [AuthenticationGuard] },
  { path: 'hr-dashboard', component: HRDashboardComponent, canActivate: [AuthenticationGuard] },
  { path: 'pid-mapping', component: PIDMAPPINGComponent, canActivate: [AuthenticationGuard] },
  { path: 'pid-approval', component: PIDAPPROVALComponent },
  { path: 'usermaster-management', component: UserMasterManagementComponent, canActivate: [AuthenticationGuard] },
  { path: 'logout', component: NoAccessComponent },
  { path: 'session', component: NoAccessComponent },
  { path: 'spm-calculations', component: SPMCALCULATIONComponent, canActivate: [AuthenticationGuard] },
  { path: 'spm-report', component: SPMREPORTComponent, canActivate: [AuthenticationGuard] },
  { path: 'pre-target', component: PreTargetComponent },
  { path: 'actual-upload/:ReportId', component: ActualUploadComponent, canActivate: [AuthenticationGuard] },
  { path: 'team-dashboard', component: TeamDashboardComponent, canActivate: [AuthenticationGuard] },
  { path: 'executive-view', component: ExecutiveViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'calculations', component: CALCULATIONSComponent, canActivate: [AuthenticationGuard] },
  { path: 'achievement-dashboard', component: AchievementDashboardComponent },
  { path: '**', component: NoAccessComponent }
]


@NgModule({
  declarations: [
    AppComponent,
    BulkUploadComponent,
    LoginComponent,
    HeaderComponent,
    SelfAssessmentComponent,
    ReporteeViewComponent,
    ManagerViewComponent,
    ReviewerViewComponent,
    SuperAdminComponent,
    RejectAssessmentComponent,
    DialogDataExampleDialog,
    HomeComponent,
    HistoryComponent,
    DashboardComponent,
    ViewTargetsComponent,
    AssessmentDetailsComponent,
    CalculationsDetailsComponent,
    ActualsComponent,
    CNBDashboardComponent,
    IncentiveDetailsComponent,
    MyTargetsComponent,
    ActualUploadComponent,
    AppraisalDashboardComponent,
    FinalstrataUpdateComponent,
    HRDashboardComponent,
    PIDMAPPINGComponent,
    PIDAPPROVALComponent,
    UserMasterManagementComponent,
    SPMCALCULATIONComponent,
    SPMREPORTComponent,
    PreTargetComponent,
    TeamDashboardComponent,
    NoAccessComponent,
    ExecutiveViewComponent,
    AchievementDashboardComponent,
    CALCULATIONSComponent,
    LoginLoadingComponent
  ],
  entryComponents: [DialogDataExampleDialog],
  imports: [
    RouterModule.forRoot(
      appRoutes, { onSameUrlNavigation: 'reload', enableTracing: false, useHash: true } // <-- debugging purposes only
    ),
    BrowserModule, FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatCardModule,
    MatTableModule,
    MzNavbarModule,
    MzInputModule,
    MzButtonModule,
    MzSelectModule,
    MzRadioButtonModule,
    MzCheckboxModule,
    MzTextareaModule,
    MzDatepickerModule,
    MzIconModule,
    MzIconMdiModule,
    MzModalModule,
    HttpModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MzCollapsibleModule,
    MatMenuModule,
    MatIconModule,
    MzTooltipModule,
    MatTabsModule,
    MatSelectModule,
    AngularMultiSelectModule,
    MsAdalAngular6Module.forRoot({
      instance: OAuthSettings.instance,
      tenant: OAuthSettings.tenant,
      clientId: OAuthSettings.clientId,
      redirectUri: OAuthSettings.redirectUri,
      navigateToLoginRequestUrl: OAuthSettings.navigateToLoginRequestUrl,
      cacheLocation: OAuthSettings.cacheLocation,
      endpoints: OAuthSettings.endpoints
    }),
    //FOR AUTOLOGOUT
    //UserIdleModule.forRoot({ idle: 600, timeout: 300, ping: 240 })
    UserIdleModule.forRoot({ idle: 1200, timeout: 900, ping: 240 }) //updated for better user experience
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    ,DragDropModule

  ],
  providers: [AuthenticationGuard,AppService, Configs],
  bootstrap: [AppComponent]

})
export class AppModule { }

