import { Component, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  userInfo: any;
  LtiUrl: string = this.configs.myLtiURL();
    isprod: boolean = false;
    errorMessage: string;
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.appService.UserDetailsChange.subscribe((data) => {
      this.userInfo = data;
      this.isprod = this.configs.isPROD_Web;
      if (this.isprod) {
        this.LtiUrl = this.configs.myLtiURLProd();
      }
      //this.roleSelected = this.userInfo.SPMRoles[0];
    });
    }

    Logout() {
        //this.userInfo = this.appService.GetLoginUserInfo();
        let data = {
            "SessionId": this.userInfo.SessionId
        }
        this.appService.GetDataFromAPI(this.configs.UpdateUserSessionInfoURL(), data)
            .then(response => {
                if (response.ReturnCode == this.configs.RetCodeFailure()) {
                }
                else {
                    this._router.navigate(['logout']);
                }
                },
                error => {
                    this.errorMessage = <string>error;
                });
    }

}
