import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { forEach } from '@angular/router/src/utils/collection';
import { MzModalComponent } from 'ngx-materialize';
@Component({
    selector: 'app-usermaster-management',
    templateUrl: './usermaster-management.component.html',
    styleUrls: ['./usermaster-management.component.less']
})
export class UserMasterManagementComponent implements OnInit {
  @ViewChild('confirmModel') confirmModel: MzModalComponent
  uploadedFile: File;
  periodid: any;
  UserPsno: any;
  periodValus: any;
  ShowSection: number;
  userInfo: any;
  Message: any;
  DownloadFilePath: any;
  DownloadUserMasterFilePath: any;
  selectedPeriod: any;
    UserTempFiltered: any = [];
    errorMessage: string; parse
    cetpsno: any;
    periodValues: any;
  //dataSource = ELEMENT_DATA;
    isH1Applicable: boolean = false;
    AppType: string = 'SPM';
  constructor(private appService: AppService, private configs: Configs, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.periodid = '0';
    this.UserPsno = '';
    this.uploadedFile = null;
    this.periodValus = [];
    this.ShowSection = 1;
    this.Message = '';
      this.DownloadUserMasterFilePath = '../../../UploadTemplates/UserMaster.xlsx';
    this.DownloadFilePath = '';
      this.selectedPeriod = '0';
      this.UserTempFiltered = [];
      this.periodValues = [];
  }
  ngOnInit() {
    this.appService.SetLoadingShow(true);
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this.router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.cetpsno = this.userInfo.PS_No;
        //this.GetMasterData(false);
        this.GetTargetYearTextForPeriod();
      }
      else {
        this.router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
    fileChange(event) {

        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.uploadedFile = fileList[0];
        }
        else {
            this.uploadedFile = null;
        }
    }
    GetUserDataTemp() {

        var data = {
            'PeriodYear': this.selectedPeriod,
            'AppType': this.userInfo.AppType
        };
        this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPI(this.configs.GetUserDataTempURL(), data)
            .then(response => {
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.appService.SetLoadingShow(false);
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.UserTempFiltered = response.ResponseData.dtUserMasterTemp;
                    this.appService.SetLoadingShow(false);
                    //if (this.UserTempFiltered.length == 0) {
                    //    this.dialog.open(DialogDataExampleDialog, {
                    //        data: "No record found."
                    //    });
                    //    // return;
                    //}
                }
            }
                , error => {
                    this.errorMessage = <string>error;
                });
    }

    UploadUserMasterFile() {

        if (this.uploadedFile != null) {
            this.appService.SetLoadingShow(true);
            this.appService.UserMasterUpload(this.configs.UploadUserMasterDetailsURL(), this.uploadedFile, this.selectedPeriod, this.cetpsno, this.userInfo.AppType)
                .then(response => {
                    if (response.ResponseCode == this.configs.RetCodeFailure()) {
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }
                    else {
                        this.uploadedFile = null;
                        this.GetUserDataTemp();
                        this.appService.SetLoadingShow(false);
                        this.dialog.open(DialogDataExampleDialog, {
                            data: response.ResponseData
                        });
                    }

                });
        }
    }

    SubmitUserMasterFinal() {
        var data = {
            'PeriodYear': this.selectedPeriod,
            'AppType': this.userInfo.AppType,
            'dtUserMasterTemp': this.UserTempFiltered
        };
        this.appService.SetLoadingShow(true);
        this.appService.GetDataFromAPI('', data)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                    //this.GetMasterData(true);
                }
            });
    }
    GetTargetYearTextForPeriod() {
        var UserData = {
            "PS_No": this.userInfo.PS_No,
            "AppType": this.userInfo.AppType
        }
        this.appService.GetDataFromAPI(this.configs.GetYearsForTargetUpload(), UserData)
            .then(response => {
                this.appService.SetLoadingShow(false);
                if (response.ResponseCode == this.configs.RetCodeFailure()) {
                    this.dialog.open(DialogDataExampleDialog, {
                        data: response.ResponseData
                    });
                }
                else {
                    this.periodValues = response.ResponseData.dtYearsForTrgts;
                    this.selectedPeriod = '0';
                }
            });
    }

}

