import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataExampleDialog } from '../popup.component';
import { MzModalComponent } from 'ngx-materialize';
import { MatTabChangeEvent } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';
import { parse } from 'url';

@Component({
  selector: 'app-calculations-details',
  templateUrl: './calculations-details.component.html',
  styleUrls: ['./calculations-details.component.less']
})
export class CalculationsDetailsComponent implements OnInit {
  @ViewChild('ConfirmPopup') confirmPopup: MzModalComponent
  editCache: { [key: string]: any } = {};
  listOfData: any[] = [];
  ShowNewRowAccMappingConfig: boolean;
  empPrRevData: any = [];
  dataSourceAdminTemplates = new MatTableDataSource(this.empPrRevData);
  AdminTemplates: any;
  userInfo: any; 
  startDate: any;
  endDate: any;
  isEdit: boolean = false;
  Reportnumber: any;
  DownloadFilePathForDetailedReport: any;
  DownloadFilePathBaseForDetailedReport: any;
  masterData: any;
  errorMessage: string;  
  PeriodName: string = '';  
  NavbackPath: string = 'dashboard';  
  PsNo: string;
  CGCode: string;
  EffectiveFromDate: string;
  EffectiveToDate: string;
  EmpPeriodrevAccountMappingId: any;
  RevPIDMappingForPeriodID: any;
  AddType: any;
  RowId: any;
  Type: any;
  IsAccessValue: any;
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.empPrRevData = [];
    this.Reportnumber = '';
    this.isEdit = false;
    this.DownloadFilePathForDetailedReport = '';
    this.DownloadFilePathBaseForDetailedReport = '../../../UploadTemplates/DetailedMappingReport_';
    this.masterData = {};
    this.listOfData = [];
    this.startDate = '';
    this.endDate = '';
    this.ShowNewRowAccMappingConfig = false;
    this.PsNo= '';
    this.CGCode = '';
    this.EffectiveFromDate = '';
    this.EffectiveToDate = '';
    this.EmpPeriodrevAccountMappingId = '';
    this.RevPIDMappingForPeriodID = '';
    this.AddType = '';
    this.RowId = '';
    this.Type = '';
    this.IsAccessValue = this.appService.GetisAccessValue();
  }

  ngOnInit() {
    this.IsAccessValue = this.appService.GetisAccessValue();
    this.userInfo = this.appService.GetLoginUserInfo();

    if (this.userInfo != null) {

      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
        this.masterData = this.appService.GetMasterDetails();
        this.getCalculationDetails();

      }
      else {
        this._router.navigate(['no-access']);
      }
    }
  }

  startEdit(selectedData: string, data): void {
    this.editCache[selectedData].edit = true;
    this.isEdit = true;
    this.AddType = '';
  }

  cancelEdit(id: string, type: string): void {
    if (type == "EmpAccMap") {
      const index = this.listOfData.findIndex((item) => item.id === id);
      this.editCache[id] = {
        data: { ...this.listOfData[index] },
        edit: false,
      };
    }
    else {
      const index = this.empPrRevData.findIndex((item) => item.id === id);
      this.editCache[id] = {
        data: { ...this.empPrRevData[index] },
        edit: false,
      };
    }
  }

  saveEdit(data, type): void {
    var msg = [];
    var Flag = 1;
    var d = this.startDate;
    d = d.split('T')[0];
    var e = this.endDate;
    e = e.split('T')[0];
    if (data.CGCode == '') {
      Flag = 0;
      msg.push("CGCode should not be blank");
    }
    else if (data.EffectiveFrom == '' || data.EffectiveTo == '') {
      Flag = 0;
      msg.push("EffectiveFrom Date or EffectiveTo Date should not be blank");
    }     
    else if (new Date(data.EffectiveFrom) >= new Date(data.EffectiveTo)) {
      Flag = 0;
      msg.push("EffectiveFrom Date should smaller then EffectiveTo");
    }
    else if (new Date(data.EffectiveFrom) < new Date(this.startDate) || new Date(data.EffectiveFrom) > new Date(this.endDate)) {
      Flag = 0;
      msg.push("EffectiveFrom Date should be between " + d + " & " + e);
    }
    else if (new Date(data.EffectiveTo) < new Date(this.startDate) || new Date(data.EffectiveTo) > new Date(this.endDate)) {
      Flag = 0;
      msg.push("EffectiveTo Date should be between " + d  + " & " + e);
    }
    
    if (msg.length > 0) {
      this.editCache[data.EmpPeriodrevAccountMappingId].edit = true;
      this.dialog.open(DialogDataExampleDialog, {
        data: msg
      });      
    }

    if (Flag == 1) {
      data.Type = type;
      this.appService.SetLoadingShow(true);
      var url = this.configs.RevUpdateDataURL();
      this.appService.GetDataFromAPI(url, data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.editCache[data.EmpPeriodrevAccountMappingId].edit = true;
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.editCache[data.EmpPeriodrevAccountMappingId].edit = false;
          }
          this.getCalculationDetails();
        },
          error => {
            this.editCache[data.EmpPeriodrevAccountMappingId].edit = true;
          });     
    }   
  }

  savePIDEdit(data, type): void {   
    var msg = [];
    var Flag = 1;
   
    var y: number = data.Percentage;

    if (data.Project_ID == '') {
      Flag = 0;
      msg.push("Project ID should not be blank");
    }
    else if (data.CustomerCode == '') {
      Flag = 0;
      msg.push("Customer Code should not be blank");
    }
    else if (data.CustomerGroupCode == '') {
      Flag = 0;
      msg.push("Customer Group Code should not be blank");
    }
    else if (data.OwnDelivery == '') {
      Flag = 0;
      msg.push("Own Delivery should not be blank");
    }
    else if (data.Percentage == '' || data.Percentage == null) {
      Flag = 0;
      msg.push("Percentage should not be blank");
    }
    else if (y > 100) {
      Flag = 0;
      msg.push("Percentage should not be greater than 100");
    }
    if (msg.length > 0) {
      this.editCache[data.RevPIDMappingForPeriodID].edit = true;
      this.dialog.open(DialogDataExampleDialog, {
        data: msg
      });
    }

    if (Flag == 1) {
      data.Type = type;
      this.appService.SetLoadingShow(true);
      var url = this.configs.RevUpdateDataURL();
      this.appService.GetDataFromAPI(url, data)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
            this.editCache[data.RevPIDMappingForPeriodID].edit = true;
          }
          else {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });           
            this.editCache[data.RevPIDMappingForPeriodID].edit = false;
          }
          this.getCalculationDetails();
        },
          error => {
            this.editCache[data.RevPIDMappingForPeriodID].edit = true;
          });      
    }    
  }

  onBackClick() {
    this.NavbackPath = this.appService.GetNavbackPathForAssessmentDetails();
    if (this.NavbackPath.length == 0) {
      this.NavbackPath = 'calculations';
    }
    this._router.navigate([this.NavbackPath]);
  }
  ToggleGrid(event: MatTabChangeEvent) {
    var time = 1000;
    console.log(event);
  }
  getCalculationDetails() {
    let j = 0;
    var data = {
      "PSNo": this.appService.GetPsno(),
      "PeriodId": this.appService.GetperiodId(),
      "AppType": this.appService.GetApprovalType()
    }
    this.listOfData = [];
    this.empPrRevData = [];
    this.appService.SetLoadingShow(true);
    var url = this.configs.GetCalculationDetailsURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          for (let i = 0; i < response.ResponseData.dtcalDetails.Table1.length; i++) {
            this.listOfData.push({
              CustomerCode: response.ResponseData.dtcalDetails.Table1[i].CustomerCode,
              CustomerGroupCode: response.ResponseData.dtcalDetails.Table1[i].CustomerGroupCode,
              OwnDelivery: response.ResponseData.dtcalDetails.Table1[i].OwnDelivery,
              Percentage: response.ResponseData.dtcalDetails.Table1[i].Percentage,
              Project_ID: response.ResponseData.dtcalDetails.Table1[i].Project_ID,
              RevPIDMappingForPeriodID: response.ResponseData.dtcalDetails.Table1[i].RevPIDMappingForPeriodID,
              PeriodId: this.appService.GetperiodId(),
              AppType: this.appService.GetApprovalType(),
              PSNo: this.appService.GetPsno(),
              isEdit: true
            });
          }
          for (let i = 0; i < response.ResponseData.dtcalDetails.Table.length; i++) {
            j = i;
            this.empPrRevData.push({
              PSNo: response.ResponseData.dtcalDetails.Table[i].PSNo,
              CGCode: response.ResponseData.dtcalDetails.Table[i].CGCode,
              EffectiveFrom: new Date(response.ResponseData.dtcalDetails.Table[i].EffectiveFrom),
              EffectiveTo: new Date(response.ResponseData.dtcalDetails.Table[i].EffectiveTo),
              EmpPeriodrevAccountMappingId: response.ResponseData.dtcalDetails.Table[i].EmpPeriodrevAccountMappingId,
              PeriodId: this.appService.GetperiodId(),
              AppType: this.appService.GetApprovalType(),
              isEdit: true
            });
          }
        }

        this.startDate = response.ResponseData.dtcalDetails.Table2[0].StartDate;
        this.endDate = response.ResponseData.dtcalDetails.Table2[0].EndDate;
        this.ShowNewRowAccMappingConfig = false;
        this.EmpPeriodrevAccountMappingId = response.ResponseData.dtcalDetails.Table3[0].Column1;
        this.RevPIDMappingForPeriodID = response.ResponseData.dtcalDetails.Table4[0].Column1;
        this.updateEditCache();
        this.updateEditEmpAccMappCache();
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
  }

  updateEditCache(): void {
    this.listOfData.forEach((item) => {
      this.editCache[item.RevPIDMappingForPeriodID] = {
        edit: false,
        data: { ...item },
      };
    });
  }

  updateEditEmpAccMappCache(): void {
    this.empPrRevData.forEach((item) => {
      this.editCache[item.EmpPeriodrevAccountMappingId] = {
        edit: false,
        data: { ...item },
      };
    });
  }

  DownLoadExcelReport(value): void {
    //call api  >
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == this.appService.GetperiodId())
        PeriodName = this.masterData.dtPeriodDet[o].Name;

    }

    var data = {
      "PSNo": this.appService.GetPsno(),
      "PeriodId": this.appService.GetperiodId(),
      "AppType": this.appService.GetApprovalType(),
      'PeriodName': PeriodName,
      'Type': value
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetRevAccountMappingDetailedURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
          this.Reportnumber = response.ResponseData.ReportNumber;
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + PeriodName + '_version_' + this.Reportnumber + ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);
          // window.open(this.configs.DetailedReportExcelFileURl());
        }
      });
  }

  AddNewRowAccMapping(value) {
    this.AddType = value;
    if (this.empPrRevData.length > 0 && (this.empPrRevData[this.empPrRevData.length - 1].EffectiveFrom == ""
      || this.empPrRevData[this.empPrRevData.length - 1].EffectiveTo == "" || this.empPrRevData[this.empPrRevData.length - 1].CGCode == "")) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please add existing details before adding new row"
      });
    }
    else {

      if (this.ShowNewRowAccMappingConfig && this.empPrRevData.length > 0) {
        this.AdminTemplates.push(JSON.parse(JSON.stringify(this.empPrRevData[this.empPrRevData.length - 1])));
      }

      
      var newRow = {
        'EmpPeriodrevAccountMappingId': this.EmpPeriodrevAccountMappingId + 1,
        'PSNo': this.appService.GetPsno(),
        'CGCode': this.CGCode,
        'EffectiveFrom': this.EffectiveFromDate,
        'EffectiveTo': this.EffectiveToDate,
        'isEdit': false,
        'PeriodId': this.appService.GetperiodId(),
        'AppType': this.appService.GetApprovalType()

      };
      this.empPrRevData.push(newRow);
      this.dataSourceAdminTemplates = new MatTableDataSource(this.empPrRevData);
      this.updateEditCache();
      this.updateEditEmpAccMappCache();
      this.editCache[this.EmpPeriodrevAccountMappingId + 1].edit = true;
    }
  }

  AddNewRowPIDMapping(value) {
    this.AddType = value;
    if (this.listOfData.length > 0 && (this.listOfData[this.listOfData.length - 1].Project_ID == ""
      || this.listOfData[this.listOfData.length - 1].Percentage == "" || this.listOfData[this.listOfData.length - 1].CustomerCode == ""
      || this.listOfData[this.listOfData.length - 1].CustomerGroupCode == "" || this.listOfData[this.listOfData.length - 1].OwnDelivery == "")) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please add existing details before adding new row"
      });
    }
    else {

      if (this.ShowNewRowAccMappingConfig && this.listOfData.length > 0) {
        this.AdminTemplates.push(JSON.parse(JSON.stringify(this.listOfData[this.listOfData.length - 1])));
      }


      var newRow = {
        'RevPIDMappingForPeriodID': this.RevPIDMappingForPeriodID + 1,
        'PSNo': this.appService.GetPsno(),
        'Project_ID': '',
        'CustomerCode': '',
        'CustomerGroupCode': '',
        'OwnDelivery': '',
        'Percentage':'',
        'isEdit': false,
        'PeriodId': this.appService.GetperiodId(),
        'AppType': this.appService.GetApprovalType()

      };
      this.listOfData.push(newRow);
      this.dataSourceAdminTemplates = new MatTableDataSource(this.listOfData);
      this.updateEditCache();
      this.updateEditEmpAccMappCache();
      this.editCache[this.RevPIDMappingForPeriodID + 1].edit = true;
    }
  }

  openConfirmPopupModal(RowId, Type) {
    this.RowId = RowId;
    this.Type = Type;
    this.confirmPopup.modalElement.addClass("ConfirmPopup");  
    this.confirmPopup.openModal();
  }

  closeConfirmPopupModal() {
    this.confirmPopup.closeModal();
    this.Type = '';
    this.RowId = '';
  }

  DeleteMapingRow() {
    this.confirmPopup.closeModal();
    this.appService.SetLoadingShow(true);
    var data = null;
    if (this.Type == "EmpAccMap") {
        data = {
          "EmpPeriodrevAccountMappingId": this.RowId,
          "Type": this.Type,
        };
      }
      else {
        data = {
          "RevPIDMappingForPeriodID": this.RowId,
          "Type": this.Type,
        };
    }
      
    var url = this.configs.DeleteMappingRowURL();
    this.appService.GetDataFromAPI(url, data)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        this.getCalculationDetails();
        this.Type = '';
        this.RowId = '';
      },
        error => {

        });
  } 

  CancelRow(type: string): void {
    this.AddType = '';
    if (type == 'PID') {
      const index = this.listOfData.indexOf(this.listOfData.length - 1);
      this.listOfData.splice(index, 1);
    }
    else {
      const index = this.empPrRevData.indexOf(this.empPrRevData.length - 1);
      this.empPrRevData.splice(index, 1);
    }
  }
}
