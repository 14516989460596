import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Configs } from '../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogDataExampleDialog } from '../popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';
@Component({
  selector: 'achievement-dashboard',
  templateUrl: './achievement-dashboard.component.html',
  styleUrls: ['./achievement-dashboard.component.less']
})
export class AchievementDashboardComponent implements OnInit {
  // autocomplete
  autocomplete: { data: { [key: string]: string } };

  displayedColumns: string[] = ['select', 'Name', 'SBU', 'ActualRevenue', 'ActualTCV', 'ActualACD', 'ActualPAT', 'VCPRevenue', 'VCPTCV', 'VCPACD', 'VCPPAT', 'TotalVCP', 'Currency', 'SPI', 'ProratedSPI', 'PayoutAmount'];
  displayedRole2Columns: string[] = ['select', 'Name', 'SBU', 'ActualRevenue', 'ActualTCV', 'ActualPAT', 'VCPRevenue', 'VCPTCV', 'VCPPAT', 'TotalVCP', 'Currency', 'SPI', 'ProratedSPI', 'PayoutAmount'];
  displayedRole3Columns: string[] = ['select', 'Name', 'SBU', 'ActualRevenue', 'ActualTCV', 'ActualACD', 'ActualPAT', 'UnBillRev', 'VCPRevenue', 'VCPTCV', 'VCPACD', 'VCPPAT', 'VCPUnbillRev', 'TotalVCP', 'Currency', 'SPI', 'ProratedSPI', 'PayoutAmount'];
  displayedRole6Columns: string[] = ['select', 'Name', 'SBU', 'ActualRevenue', 'ActualTCV', 'VCPRevenue', 'VCPTCV', 'TotalVCP', 'Currency', 'SPI', 'ProratedSPI', 'PayoutAmount'];
  //dataSource = ELEMENT_DATA;
  masterData: any;
  userInfo: any;
  RoleID: any;
  tblStatus: any;
  tblStatusOrginal: any;
  selectedStatus: any;
  selectedPeriod: any;
  filters: any;
  errorMessage: string;
  ShowFilters: boolean = false;
  selectedSBU: any;
  selectedFunction: any;
  DownloadFilePath: any;
  DashBoardDataObject: any;
  SearchText: any;
  RoleDetails: any;
  FetchDashboardDetails: any;
  roleSelected: any;
  FilterData: any;
  List: any;
  ResetFilter: any;
  ResetFilterNew: any;
  dropdownListRole: any = [];
  dropdownListSBU: any = [];
  dropdownListFunction: any = [];
  dropdownSettings: any = {};
  DownloadFilePathBaseForDetailedReport: any;
  DownloadFilePathForDetailedReport: any;
  isManagerOrReviewer: boolean = false;
  ShowTable: boolean = false;
  SBUDistinct: any = [];
  SBUDistinctModel: any = [];
  constructor(private appService: AppService, private configs: Configs, private _router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.appService.SetLoadingShow(false);
    this.tblStatus = [];
    this.tblStatusOrginal = [];
    this.filters = {};
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.selectedPeriod = '';
    this.selectedStatus = '';
    this.RoleID = '';
    this.SearchText = '';
    this.filters = {
      'dtStatusType': [],
      'dtSBU': [],
      'dtRole': [],
      'dtStatusTiles': {},
      'dtFunction': []
    };
    this.DownloadFilePath = this.configs.DashBoardExcelFileURl();
    this.DashBoardDataObject = {};
    this.RoleDetails = [];
    this.FetchDashboardDetails = [];
    this.roleSelected = [];
    this.FilterData = [];
    this.List = [];
    this.ResetFilter = [];
    this.ResetFilterNew = [];
    this.DownloadFilePathBaseForDetailedReport = '../../../UploadTemplates/DetailedReport_';
    this.DownloadFilePathForDetailedReport = '';
    this.SBUDistinct = [];
    this.SBUDistinctModel = [];
  }

  ngOnInit() {
    this.userInfo = this.appService.GetLoginUserInfo();
    if (this.userInfo != null) {
      var checkAccess = this.appService.CheckAccessForRoute(this._router.url, this.userInfo.UserAccessOptions);
      if (checkAccess) {
    this.masterData = this.appService.GetMasterDetails();
    this.DashBoardDataObject = this.appService.GetDashBoardData();

    this.roleSelected = this.appService.GetRoleId();
    this.selectedPeriod = this.appService.GetperiodId();
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      badgeShowLimit: 1,
      classes: ""
    };
    if (this.selectedPeriod == null || this.selectedPeriod == undefined) {
      this.selectedPeriod = '';
      this.roleSelected = '';
    }
    if (this.selectedPeriod != '') {

      var Input = {
          "Period": this.selectedPeriod,
          "PSNO": this.userInfo.PS_No,
          "AppType": this.userInfo.AppType
      }

      this.appService.SetLoadingShow(true);
      var url = this.configs.GetAchievementDashBoardDetailsURL();
      this.appService.GetDataFromAPI(url, Input)
        .then(response => {
          this.appService.SetLoadingShow(false);
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            this.ResetFilter = response.ResponseData.dataList;
            this.ResetFilterNew = response.ResponseData.dataList;
            this.FetchDashboardDetails = response.ResponseData.UserData;
            if (this.FetchDashboardDetails.length > 0) {
              this.ShowTable = true;
            }
            else {
              this.ShowTable = false;
            }
            this.ShowFilters = true;
            this.FilterData = response.ResponseData.dataList;
            this.onChangeOFPeriod(this.selectedPeriod);

            if (this.FetchDashboardDetails.length == 0) {
              this.ShowFilters = false;
              this.dialog.open(DialogDataExampleDialog, {
                data: "No record found."
              });
              return;
            }
          }
        }
          ,
          error => {
            this.errorMessage = <string>error;
          });

    }
      } else {
        this._router.navigate(['no-access']);
      }
    }
    else {
      window.location.href = "../Index.html";
    }
  }
  onChangeOFPeriod(value: any): void {
    this.ShowTable = false;
    this.List = [];
    this.roleSelected = [];
    this.selectedSBU = [];
    this.selectedFunction = [];
    this.SearchText = '';
      this.appService.SetLoadingShow(true);
      var Input = {
          "AppType": this.userInfo.AppType,
          "PSNO": this.userInfo.PS_No
      }

    var url = this.configs.GetCNBDashboardFilterDataURL();
      this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.filters.dtRole = response.ResponseData.dtRole;
          this.dropdownListRole = [];
          for (var o in this.filters.dtRole) {
            this.dropdownListRole.push({ "id": this.filters.dtRole[o].RoleID, "itemName": this.filters.dtRole[o].RoleName });
          }
          this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
          this.filters.dtSBU = response.ResponseData.dtSBU;
          this.dropdownListSBU = [];
          for (var o in this.filters.dtSBU) {
            this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
          }
          this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
          var SBU = [];
          for (var j in this.selectedSBU) {
            if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
              SBU.push(this.selectedSBU[j].itemName)
            }
          }
          for (var o in SBU) {
            this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
          }

          this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

          this.filters.dtFunction = response.ResponseData.dtFunction;
          this, this.dropdownListFunction = [];
          for (var o in this.filters.dtFunction) {
            this.dropdownListFunction.push({ "id": this.filters.dtFunction[o].FunctionId, "itemName": this.filters.dtFunction[o].FunctionName });
          }
          this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
          //this.roleSelected = [];
          //this.selectedSBU = [];
          this.onChangeOFRole('0');
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });
  }

  onChangeOFRole(value: string): void {
    this.ShowTable = false;
    var Input = {
      "Period": this.selectedPeriod.toString(),
        "Role": value,
        "PSNO": this.userInfo.PS_No,
        "AppType": this.userInfo.AppType
    }

    this.roleSelected = value;

    this.appService.SetLoadingShow(true);
    var url = this.configs.GetAchievementDashBoardDetailsURL();
    this.appService.GetDataFromAPI(url, Input)
      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: response.ResponseData
          });
        }
        else {
          this.ResetFilter = response.ResponseData.UserData;
          this.ResetFilterNew = response.ResponseData.UserData;
          this.FetchDashboardDetails = response.ResponseData.UserData;
          if (this.FetchDashboardDetails.length > 0) {
            this.ShowTable = true;
          }
          else {
            this.ShowTable = false;
          }
          this.ShowFilters = true;
          this.FilterData = response.ResponseData.UserData;
          this.onReset();

          if (this.FetchDashboardDetails.length == 0) {
            this.ShowFilters = false;
            this.dialog.open(DialogDataExampleDialog, {
              data: "No record found."
            });
            return;
          }
        }
      }
        ,
        error => {
          this.errorMessage = <string>error;
        });

  }

  onReset(): void {
    this.selectedSBU = [];
    this.setDropdownListSBU();
    var SBU = [];
    this.SBUDistinctModel = [];
    this.SBUDistinct = [];
    for (var j in this.selectedSBU) {
      if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
        SBU.push(this.selectedSBU[j].itemName)
      }
    }
    for (var o in SBU) {
      this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
    }
    this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

    //this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));
    this.selectedFunction = [];
    this.selectedFunction = JSON.parse(JSON.stringify(this.dropdownListFunction));
    this.selectedStatus = '';
    this.roleSelected = [];
    this.roleSelected = JSON.parse(JSON.stringify(this.dropdownListRole));
    this.SearchText = '';
    this.FetchDashboardDetails = this.ResetFilterNew;
  }

  OnViewDetails(value, Role) {

    this.appService.SetRoleID(Role);
    this.appService.SetperiodId(this.selectedPeriod);
    this.appService.SetPsno(value);
    this.appService.SetIsManagerOrReviewer(this.isManagerOrReviewer)
    this.SelectRecords(value);

    var Data = [];
    for (var o in this.FilterData) {
      if (value.toString() == this.FilterData[o].PSNo) {
        Data.push(this.FilterData[o]);
        break;
      }
    }
    this.appService.SetIncentiveDetailsList(Data);
    this.appService.SetAppraisalDetailsList(Data);
    this.appService.SetRoleForIncentivePage('CNB');
    this.appService.SetNavbackPathForAssessmentDetails('cnb-dashboard');
    this._router.navigate(['incentive-details']);
  }

  setDropdownListSBU() {
    this.dropdownListSBU = [];
    for (var o in this.filters.dtSBU) {
      this.dropdownListSBU.push({ "id": parseInt(o) + 1, "itemName": this.filters.dtSBU[o].DeputedBU, "Functionid": this.filters.dtSBU[o].FunctionId });
    }
    this.selectedSBU = JSON.parse(JSON.stringify(this.dropdownListSBU));

  }

  onFunctionChange(selectedFunction) {
    this.setDropdownListSBU();
    this.ResetFilter = this.ResetFilterNew;
    var data = [];
    var dataselectedSBU = [];

    if (selectedFunction.length <= this.dropdownListFunction.length) {
      for (var o in selectedFunction) {
        if (selectedFunction[o].itemName != '' || selectedFunction[o].itemName != undefined || selectedFunction[o].itemName != "SELECT") {
          for (var i in this.ResetFilter) {
            if (this.ResetFilter[i].FunctionName == selectedFunction[o].itemName) {
              data.push(this.ResetFilter[i]);
            }
          }

          for (var j in this.selectedSBU) {
            if (this.selectedSBU[j].Functionid == selectedFunction[o].id) {
              dataselectedSBU.push(this.selectedSBU[j])
            }
          }

        }

      }
      this.selectedSBU = JSON.parse(JSON.stringify(dataselectedSBU))
      this.dropdownListSBU = JSON.parse(JSON.stringify(this.selectedSBU))
      var SBU = [];
      this.SBUDistinctModel = [];
      this.SBUDistinct = [];
      for (var j in this.selectedSBU) {
        if (SBU.indexOf(this.selectedSBU[j].itemName) == -1) {
          SBU.push(this.selectedSBU[j].itemName)
        }
      }
      for (var o in SBU) {
        this.SBUDistinct.push({ "id": parseInt(o) + 1, "itemName": SBU[o] })
      }
      this.SBUDistinctModel = JSON.parse(JSON.stringify(this.SBUDistinct));

      this.ResetFilter = data

    }
    this.onFilterChange(this.SBUDistinctModel, this.roleSelected, this.SearchText)
  }

  onFilterChange(selectedSBU, selectedRole, SearchText) {

    //var data = JSON.parse(JSON.stringify(this.FilterData));

    var data = [];
    if (this.selectedFunction.length == this.dropdownListFunction.length) {
      this.ResetFilter = this.ResetFilterNew
    }

    if (selectedRole.length < this.dropdownListRole.length) {
      for (var o in selectedRole) {
        if (selectedRole[o].itemName != '' || selectedRole[o].itemName != undefined || selectedRole[o].itemName != "SELECT") {
          for (var i in this.ResetFilter) {
            if (this.ResetFilter[i].RoleName == selectedRole[o].itemName) {
              data.push(this.ResetFilter[i]);
            }
          }
        }
      }
    }
    else {
      data = this.ResetFilter;
      //data = [];
    }


    var dataSBU = [];

    if (selectedSBU.length < this.dropdownListSBU.length) {
      for (var o in selectedSBU) {
        if (selectedSBU[o].itemName != '' || selectedSBU[o].itemName != undefined || selectedSBU[o].itemName != "SELECT") {
          for (var i in data) {
            if (data[i].DeputedBU == selectedSBU[o].itemName) {
              dataSBU.push(data[i]);
            }
          }
        }
      }
    }
    else {
      dataSBU = data;
        //  dataSBU= [];
    }
    if (SearchText != '' && SearchText != undefined) {
      if (SearchText.length > 2) {
        const filterValue = SearchText.toLowerCase();
        this.SearchText = filterValue;
        for (var o in dataSBU) {
          if (dataSBU[o].Name == null)
            dataSBU[o].Name = "";
          if (dataSBU[o].ManagerName == null)
            dataSBU[o].ManagerName = "";
          if (dataSBU[o].ReviewerName == null)
            dataSBU[o].ReviewerName = "";
        }
        dataSBU = dataSBU.filter(option => option.Name.toLowerCase().includes(filterValue) || option.ManagerName.toLowerCase().includes(filterValue) || option.ReviewerName.toLowerCase().includes(filterValue));
        //data = data.filter(option => option.Name.toLowerCase().includes(filterValue));
      }
    }


    this.FetchDashboardDetails = dataSBU;
    //if (this.FetchDashboardDetails.length == 0) {
    //  this.dialog.open(DialogDataExampleDialog, {
    //    data: "No record found for the selected filter(s)."
    //  });
    //  return;
    //}
  }
  SelectRecords(value: string): void {
    for (var o in this.FilterData) {
      if (value.toString() == this.FilterData[o].PSNo && this.FilterData[o].StatusId == '1') {
        this.FilterData[o].select = !this.FilterData[o].select;
        this.List.push(this.FilterData[o]);
        break;
      }

    }

  }

  Publish(): void {
    if (this.List.length == 0) {
      this.dialog.open(DialogDataExampleDialog, {
        data: "Please select atleast one record"
      });
    }
    else {
      this.appService.SetLoadingShow(true);
        var data = {
            "Period": this.selectedPeriod.toString(),
            "UserData": this.List,
            "AppType": this.userInfo.AppType
        };
      this.appService.GetDataFromAPI(this.configs.ApproveAchievementDetailsURL(), data)
        .then(response => {
          if (response.ResponseCode == this.configs.RetCodeFailure()) {
            this.appService.SetLoadingShow(false);
            this.dialog.open(DialogDataExampleDialog, {
              data: response.ResponseData
            });
          }
          else {
            if (response.ResponseData) {
              this.dialog.open(DialogDataExampleDialog, {
                data: "The achievement percentage value is approved for the employee."
              });
              this.onChangeOFPeriod("0");
            }
            else {
              this.dialog.open(DialogDataExampleDialog, {
                data: "An Error Occured while updating the data. Please try again."
              });
            }
          }

        });
    }
  }
 
  DownLoadExcelReport(): void {
    //call api  >
    var PeriodName = '';
    for (var o in this.masterData.dtPeriodDet) {
      if (this.masterData.dtPeriodDet[o].PeriodID == this.selectedPeriod)
        PeriodName = this.masterData.dtPeriodDet[o].Name;
    }
    var data = {
      "Periodvalues": this.selectedPeriod,
      "AppType": this.userInfo.AppType,
      'DataFor': 'CNB',
      'PeriodName': PeriodName
    };
    this.appService.SetLoadingShow(true);

    this.appService.GetDataFromAPI(this.configs.GetDetailedReportURL(), data)

      .then(response => {
        this.appService.SetLoadingShow(false);
        if (response.ResponseCode == this.configs.RetCodeFailure()) {
          this.dialog.open(DialogDataExampleDialog, {
            data: "No Excel found. "
          });
        }
        else {
         var ReportNumber= response.ResponseData.ReportNumber;
          this.DownloadFilePathForDetailedReport = this.DownloadFilePathBaseForDetailedReport + PeriodName + '_version_' + ReportNumber + ".xlsx";
          window.open(this.DownloadFilePathForDetailedReport);
          // window.open(this.configs.DetailedReportExcelFileURl());
        }
      });
  }
}




