import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { Configs } from '../app.config'
import { MzModalComponent } from 'ngx-materialize';
import { DialogDataExampleDialog } from '../popup.component';

import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
    @ViewChild('ChooseAppType') ChooseAppType: MzModalComponent
  errorMessage: string;
    userInfo: any;
    AppName: any = [];
    AppTypeIDS: any = [];
    button: boolean=false;
    chosenAppType: number = 0;
    showpage: boolean = false;

    constructor(private appService: AppService, private router: Router, private configs: Configs) {
        this.appService.SetLoadingShow(false);
        this.AppName = [];
        this.AppTypeIDS = [];
  }

    ngOnInit() {
   
        this.userInfo = this.appService.GetLoginUserInfo();
        if (!isNullOrUndefined(this.userInfo)) {
            this.showpage = true;
            this.chosenAppType = this.userInfo.AppType;
            if (this.userInfo.UserAccessOptions.length == 1) {
                this.LoadPage(this.userInfo.UserAccessOptions[0].OptionRoute);
            }
            if (this.userInfo.AccessType.length > 1) {
                this.button = true;

            }
            else {
                this.userInfo.AppType = this.userInfo.AccessType[0].AppType;


            }
        } else {
          this.router.navigate(['no-access']);
        }
    }
 
  LoadPage(url): void {
    if (url != '') {
      this.appService.SetLoadingShow(true);
      //if (url == '/approvals') {
      this.router.navigate([url]);
      //}
    }

  }
    openAppTypeModal() {
      
        this.ChooseAppType.openModal();
             
    }
    closeAppTypeModal() {
        this.userInfo.AppType = this.chosenAppType;
        this.appService.SetLoginUserInfo(this.userInfo);
        this.ChooseAppType.closeModal();
    }
}
