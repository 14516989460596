


export class Configs {
    TCVReportURL(): any {
        throw new Error("Method not implemented.");
    }
  isDev: boolean = false;
  isQA_Auth_Web: boolean = false;
  isQA_Web: boolean = false;
  isQA_Mob: boolean = false;
  isPre_PROD_Web: boolean = false;
  isPre_PROD_Mob: boolean = false;
  isPROD_Web: boolean = false;
  isPROD_Mob: boolean = false;
  isAppEnvironmentSet: boolean = false;
  isMobile: boolean = false;

  BaseURL(): string {
    if (this.isDev) { return "" }
    else if (this.isQA_Auth_Web) { return "https://ispurqa.azurewebsites.net/" }
    else if (this.isQA_Web) { return "https://spmqa.azurewebsites.net/" }
    else if (this.isQA_Mob) { return "https://spmqa.azurewebsites.net/" }
    else if (this.isPre_PROD_Web) { return "https://spmpreprod.azurewebsites.net/" }
    else if (this.isPre_PROD_Mob) { return "https://spmpreprod.azurewebsites.net/" }
    else if (this.isPROD_Web) { return "" }
    else if (this.isPROD_Mob) { return "" }
    else { return "" }
  }
  setENV(value): void {
    this.isAppEnvironmentSet = true;
    switch (value) {
      case "DEV": this.isDev = true;
        break;
      case "QA_Web": this.isQA_Web = true;
        break;
      case "QA_Auth_Web": this.isQA_Auth_Web = true;
        break;
      case "QA_Mob": this.isQA_Mob = true;
        this.isMobile = true;
        break;
      case "PRE_PROD_Web": this.isPre_PROD_Web = true;
        break;
      case "PRE_PROD_Mob": this.isPre_PROD_Mob = true;
        this.isMobile = true;
        break;
      case "PROD_Web": this.isPROD_Web = true;
        break;
      case "PROD_Mob": this.isPROD_Mob = true;
        this.isMobile = true;
        break;
      default: this.isDev = true;
        break;
    }
  };
  EnvBaseURL(): string {
    if (localStorage.getItem("MobileAppName") != null && localStorage.getItem("MobileAppName") != undefined) {
      return localStorage.getItem("MobileAppName").toString();
    }
    else { return "" }
  }
  myLtiURL(): string { return "https://empportalad.azurewebsites.net" };
  myLtiURLProd(): string { return "https://myLti.lntinfotech.com" };
  rootURL(): string { return this.BaseURL() + "api/" };
  ADAuthURL(): string { return this.BaseURL() + ".auth/me" };
  GraphUrl(): string { return "https://graph.microsoft.com" };
  AutoLogoutURL(): string { return this.BaseURL() + ".auth/logout?post_logout_redirect_uri=/Index.html" };
  UploadFile(): string { return this.rootURL() + "Home/ExcelUpload" };
  GetUserDetailsURL(): string { return this.rootURL() + "User/GetUserDetails" };
  GetPeriodValues(): string { return this.rootURL() + "Home/GetPeriodInfo" };

  GetSalesMetricsActualValuesURL(): string { return this.rootURL() + "Home/GetSalesMetricsActualValues" };
  GetSubmissionAllowedPeriodURL(): string { return this.rootURL() + "Home/GetSubmissionAllowedPeriod" };
  SaveSalesMetricsActualValuesURL(): string { return this.rootURL() + "Home/SaveSalesMetricsActualValues" };

  GetPreTargetDataForYearByRoleURL(): string { return this.rootURL() + "Home/GetPreTargetDataForYearByRole" };

  MESSAGETYPEINFO(): string { return "INFORMATION" };
  MESSAGETYPEALERT(): string { return "ALERT" };
  MESSAGETYPECONFIRM(): string { return "CONFIRM" };
  MESSAGETYPEERROR(): string { return "ERROR" };
  MESSAGETYPESUCCESS(): string { return "SUCCESS" };
  RetCodeSuccess(): string { return "SUCCESS" };
  RetCodeFailure(): string { return "FAILURE" };

  MESSAGESHOW(): string { return "block" };
  MESSAGEHIDE(): string { return "none" };

  MasterInfoDetailsURL(): string { return this.rootURL() + "Home/GetBasicInfoOfMaster" };
  MetricsDetailsURL(): string { return this.rootURL() + "Home/GetMetricsDetails" };
  GetH1MetricsDetailsURL(): string { return this.rootURL() + "Home/GetH1MetricsDetails" };
  UpdateMetricsDetailsURL(): string { return this.rootURL() + "Home/UpdateMetricsDetails" };
  SaveMetricsForRoleURL(): string { return this.rootURL() + "User/SaveMetricsForRole" };
  UpdateMetricsMasterURL(): string { return this.rootURL() + "User/UpdateMetricsMaster" };
  GetDeadLinesForSubmissionURL(): string { return this.rootURL() + "Home/GetDeadLinesForSubmissionForPeriod" };
  GetQuanQualPercentageURL(): string { return this.rootURL() + "Home/GetQuanQualPercentage" };
  SaveDeadLinesForSubmissionURL(): string { return this.rootURL() + "Home/SaveDeadLinesForSubmission" };
  GetRoleMetricMappingURL(): string { return this.rootURL() + "User/GetRoleMetricMapping" };
  GetAllMetricMappingURL(): string { return this.rootURL() + "User/GetAllMetricMapping" };
  GetRejectionReasonsURL(): string { return this.rootURL() + "Home/GetRejectionReasons" };
  SubmitDisagreementDataURL(): string { return this.rootURL() + "Home/SubmitDisagreementData" };
  GetActualDisAgreeDataURL(): string { return this.rootURL() + "Home/GetActualDisAgreeData" };

  GetReporteeStatusMasterURL(): string { return this.rootURL() + "Home/GetReporteeStatus" };
  GetReporteeGridDataURL(): string { return this.rootURL() + "Home/GetReporteeGridData" };
  GetDisagreementDataURL(): string { return this.rootURL() + "Home/GetDisagreementData" };
  ApproveDisagreementURL(): string { return this.rootURL() + "Home/ApproveDisagreement" };
  ChangeWorkFlowforDisagreementURL(): string { return this.rootURL() + "Home/ChangeWorkFlowforDisagreement" };
  GetDashBoardGridDataURL(): string { return this.rootURL() + "Home/GetDashboardData" };
  GetUploadedTargetsURL(): string { return this.rootURL() + "Home/GetUploadedTargets" };

  DashBoardExcelFileURl(): string { return this.rootURL() + "../../../UploadTemplates/DashBoardData_1.xlsx" }

  GetActualDataURL(): string { return this.rootURL() + "Home/GetActualData" };
  GetEnvironmentDetailsURL(): string { return "api/User/GetEnvironmentDetails" };
  CheckAdminAccessURL(): string { return this.rootURL() + "User/CheckAdminAccess" };
  GetIncentiveDetailsURL(): string { return this.rootURL() + "Home/GetIncentiveDetails" };

  GetCNBDashBoardDetailsURL(): string { return this.rootURL() + "Home/FetchCNBDetails" };
  PublishIncentiveURL(): string { return this.rootURL() + "Home/PublishIncentiveData" };
  GetCNBDashboardFilterDataURL(): string { return this.rootURL() + "Home/GetCNBDashboardFilterData" };
  GetEmployeeDetailsForDashBoardURL(): string { return this.rootURL() + "Home/GetEmployeeDetailsForDashBoard" };
  SendMailForSBUURL(): string { return this.rootURL() + "Home/SendMailForSBU" };
  ApproveRejectTargetsURL(): string { return this.rootURL() + "Home/ApproveRejectTargets" };
  GetYearsForTargetUpload(): string { return this.rootURL() + "Home/GetYearsForTargetUpload" };
  GetTargetHistoryURL(): string { return this.rootURL() + "Home/GetTargetHistory" };
  UpdateTargetsAfterConfirmationURL(): string { return this.rootURL() + "Home/UpdateTargetsAfterConfirmation" };
  PIDMappingURL(): string { return this.rootURL() + "Upload/PIDMappingUpload" };
  AccountMappingURL(): string { return this.rootURL() + "Upload/AccountMappingUpload" };
  UpdateSalesMappingURL(): string { return this.rootURL() + "Upload/UpdateSalesMappingUpload" };
  GetOtherParametersDataURL(): string { return this.rootURL() + "User/GetOtherParametersData" };
  GetQualitativeParametersDataURL(): string { return this.rootURL() + "User/GetQualitativeParametersData" };
  SaveQualitativePercentageURL(): string { return this.rootURL() + "User/SaveQualitativePercentage" };
  GetAppraisalDataURL(): string { return this.rootURL() + "User/GetAppraisalData" };
  PublishAppraisalURL(): string { return this.rootURL() + "Home/PublishAppraisal" };
  GetFYPeriodIdsURL(): string { return this.rootURL() + "Home/GetFYPeriodIds" };
  UploadIncentiveEligibleAmountURL(): string { return this.rootURL() + "Upload/UploadIncentiveEligibleAmount" };
  UploadEmployeeWiseTCVDetailsURL(): string { return this.rootURL() + "Upload/UploadEmployeeWiseTCVDetails" };

  UploadAccountMappingDetailsURL(): string { return this.rootURL() + "Upload/UploadAccountMappingDetails" };

  UploadCSATDetailsURL(): string { return this.rootURL() + "Upload/UploadCSATDetails" };
  GetHRDashBoardDataURL(): string { return this.rootURL() + "User/GetHRDashBoardData" };
  UploadCMMIDetailsURL(): string { return this.rootURL() + "Upload/UploadCMMIDetails" };

  UploadEmployeeWeightagesDetailsURL(): string { return this.rootURL() + "Home/UploadEmployeeWeightagesDetails" };

  GetKickerDataURL(): string { return this.rootURL() + "Home/GetKickerData" };
  InsertKickerDataURL(): string { return this.rootURL() + "Home/InsertKickerData" };
  InsertGrowthKickerDataURL(): string { return this.rootURL() + "Home/InsertGrowthKickerData" };
  GetKickerApprovalDataURL(): string { return this.rootURL() + "Home/GetKickerApprovalData" };
  InsertKickerApprovalDataURL(): string { return this.rootURL() + "Home/InsertKickerApprovalData" };
  InsertGrowthKickerApprovalDataURL(): string { return this.rootURL() + "Home/InsertGrowthKickerApprovalData" };

  GetFeedbackDetailsURL(): string { return this.rootURL() + "Home/GetFeedbackDetails" };
  GetFeedbackReportDetailsURL(): string { return this.rootURL() + "Reports/GetFeedbackReportDetails" };
  GetDBReportDetailsURL(): string { return this.rootURL() + "Reports/GetDBReportDetails" };
  GetStrataReportDetailsURL(): string { return this.rootURL() + "Reports/GetStrataReportDetails" };
  GetPerformanceReportDetailsURL(): string { return this.rootURL() + "Reports/GetPerformanceReportDetails" };
  GetMultiYearPerformanceReportDetailsURL(): string { return this.rootURL() + "Reports/GetMultiYearPerformanceReportDetails" };
  GetAccountMappingsFromTargetsReportDetailsURL(): string { return this.rootURL() + "Reports/GetAccountMappingsFromTargetsReportDetails" };
  GetCompReportDetailedURL(): string { return this.rootURL() + "Reports/GetCompReportDetailed" };
  GetPayoutH1ReportURL(): string { return this.rootURL() + "Reports/GetPayoutH1Report" };
  GetPayoutFYReportURL(): string { return this.rootURL() + "Reports/GetPayoutFYReport" };
  GetTargetVSPayoutReportURL(): string { return this.rootURL() + "Reports/GetTargetVSPayoutReport" };
  GetStatusReport(): string { return this.rootURL() + "Reports/GetStatusReport" };
  GetTargetStatusReport(): string { return this.rootURL() + "Reports/GetTargetStatusReport" };
  GetHyperscalerRevenueReportURL(): string { return this.rootURL() + "Reports/GetHyperscalerRevenueReport" };

  GetH1PeriodIdsURL(): string { return this.rootURL() + "Home/GetH1PeriodIds" };

  GetEligibleSPIDataURL(): string { return this.rootURL() + "User/GetEligibleSPIAmounts" };
  GetUserDataURL(): string { return this.rootURL() + "User/GetUserData" };
  GetUserDataTempURL(): string { return this.rootURL() + "User/GetUserDataTemp" };
  GetDataforPlanURL(): string { return this.rootURL() + "User/GetDataforPlan" };
  SubmitDataforPlanURL(): string { return this.rootURL() + "User/SubmitDataforPlan" };
  SubmitDataforUserMasterURL(): string { return this.rootURL() + "User/SubmitDataforUserMaster" };

  GetEmployeeReleaseDatesDataURL(): string { return this.rootURL() + "User/GetEmployeeReleaseDatesData" };
  SaveEmployeeReleaseDatesURL(): string { return this.rootURL() + "User/SaveEmployeeReleaseDates" };
  SendMailForSubmissionDatesURL(): string { return this.rootURL() + "Home/SendMailForSubmissionDates" };

  GetSBUAccessToPSNosURL(): string { return this.rootURL() + "User/GetSBUAccessToPSNos" };
  InsertSBUAccessToURL(): string { return this.rootURL() + "User/InsertIntoSBUAccessToPSNos" };
  GetTargetHistoryForYearURL(): string { return this.rootURL() + "Home/GetTargetHistoryForYear" };

  GetMyTargetsForYearURL(): string { return this.rootURL() + "Home/GetMyTargetsForYear" };
  GetPlanDataURL(): string { return this.rootURL() + "Home/GetPlanData" };
  GetDetailedReportURL(): string { return this.rootURL() + "Reports/GetDetailedReport" };
  GetDetailedReportprodURL(): string { return this.rootURL() + "Reports/GetDetailedReportprod" };
  GetParameterDownloadDataForEmployeeByPeriodURL(): string { return this.rootURL() + "Reports/GetParameterDownloadDataForEmployeeByPeriod" };

  DetailedReportExcelFileURl(): string { return this.rootURL() + "../../../UploadTemplates/DetailedReport.xlsx" }
  DetailedFeedBackReportExcelFileURl(): string { return this.rootURL() + "../../../UploadTemplates/FeedbackReport.xlsx" }

  GetDataforFinalRatingURL(): string { return this.rootURL() + "User/GetDataforFinalRating" };
  SubmitDataforFinalRatingURL(): string { return this.rootURL() + "User/SubmitDataforFinalRating" };

  UpdateUserSessionInfoURL(): string { return this.rootURL() + "User/UpdateUserSessionInfo" };

  GetFilterMasterForPIDMappingURL(): string { return this.rootURL() + "User/GetFilterMasterForPIDMapping" };
  GetAccountwiseDataForPIDMappingURL(): string { return this.rootURL() + "User/GetAccountwiseDataForPIDMapping" };
  SaveAccountwiseDataForPIDMappingURL(): string { return this.rootURL() + "User/SaveAccountwiseDataForPIDMapping" };
  DeleteAccountwiseDataForPIDMappingURL(): string { return this.rootURL() + "User/DeleteAccountwiseDataForPIDMapping" };

  GetProjectwiseDataForPIDMappingURL(): string { return this.rootURL() + "User/GetProjectwiseDataForPIDMapping" };
  SaveProjectwiseDataForPIDMappingURL(): string { return this.rootURL() + "User/SaveProjectwiseDataForPIDMapping" };

  UploadIncentiveEligibleAmountDetailedURL(): string { return this.rootURL() + "Upload/UploadIncentiveEligibleAmountDetailed" };

  UploadUserMasterDetailsURL(): string { return this.rootURL() + "Upload/UploadUserMasterDetails" };

  GetAccountwiseDataForPIDApprovalURL(): string { return this.rootURL() + "User/GetAccountwiseDataForPIDApproval" };
  GetProjectwiseDataForPIDApprovalURL(): string { return this.rootURL() + "User/GetProjectwiseDataForPIDApproval" };
  PIDApprovalURL(): string { return this.rootURL() + "User/PIDApproval" };
  GetUploadTargetsPendingForApproval(): string { return this.rootURL() + "User/GetUploadTargetsPendingForApproval" };
  UploadTargetsExcelFileURl(): string { return this.rootURL() + "../../../UploadTemplates/TargetExcel.xlsx" }
  GetUploadTargetsPendingForSalesExcellenceURL(): string { return this.rootURL() + "User/GetUploadTargetsPendingForSalesExcellence" };
  UploadTargetsExcelFORSEFileURl(): string { return this.rootURL() + "../../../UploadTemplates/TargetExcelForSE.xlsx" }
  GetUserDetailsViaAdminURL(): string { return this.rootURL() + "User/GetUserDetailsViaAdmin" };
  GetCalculaionMasterTableURl(): string { return this.rootURL() + "User/GetCalculaionMasterTable" };
  GetMasterFor_HunterUSARoleInSMAURL(): string { return this.rootURL() + "User/GetMasterFor_HunterUSARoleInSMA" };
  AddHunterUSARoleInSMAURL(): string { return this.rootURL() + "User/AddHunterUSARoleInSMA" };
  GetUploadsByUserURL(): string { return this.rootURL() + "User/GetUploadsByUser" };
  UploadFinalIncentivesURL(): string { return this.rootURL() + "Upload/UploadFinalIncentivesPaid" };
  UploadFinalRatingURL(): string { return this.rootURL() + "Upload/UploadFinalRating" };
  GetScoreCardDataURL(): string { return this.rootURL() + "Reports/GetScoreCardData" };
  UploadACDExcelURL(): string { return this.rootURL() + "Upload/UploadACD" }
  GetReportsByUserURL(): string { return this.rootURL() + "Reports/GetReportsByUser" };
  GetMyLTIThemeURL(): string { return this.rootURL() + "User/GetMyLTITheme" };
  GetFiltersForScoreCardURL(): string { return this.rootURL() + "Reports/GetFiltersForScoreCard" };
  UploadPreTargetDetailsURL(): string { return this.rootURL() + "Upload/UploadPreTargetDetails" };
  MoveDatafromPretargetsURL(): string { return this.rootURL() + "Home/MoveDatafromPretargets" };
  UploadPreTargetAccountsDetailsURL(): string { return this.rootURL() + "Upload/UploadPreTargetAccountDetails" };
  UploadPreTargetAccountsDetailswithDUURL(): string { return this.rootURL() + "Upload/UploadPreTargetAccountDetailswithDU" };
  UploadPreTargetACDDetailsURL(): string { return this.rootURL() + "Upload/UploadPreTargetACDDetails" };
  UploadPreTargetBudgetUploadwithPUDetailsURL(): string { return this.rootURL() + "Upload/UploadPreTargetBudgetUploadwithPUDetails" };
  CalculateMetricsAndIncentivesForEmployeeURL(): string { return this.rootURL() + "Home/CalculateMetricsAndIncentivesForEmployee" };
  CalculateMetricsAndIncentivesURL(): string { return this.rootURL() + "Home/CalculateMetricsAndIncentives" };
  PastYear_PIDMappingURL(): string { return this.rootURL() + "Upload/PastYear_PIDMappingUpload" };
  Past_AccountMappingURL(): string { return this.rootURL() + "Upload/PastYear_AccountMappingUpload" };
  UploadTargetMappingURL(): string { return this.rootURL() + "Upload/UploadTargetMapping" };
  UploadWeightageMappingURL(): string { return this.rootURL() + "Upload/UploadWeightageMapping" };
  UploadPreTargetCSATURL(): string { return this.rootURL() + "Upload/PreTargetCSATUpload" };
  UpdateEmployeeTargetAcceptanceURL(): string { return this.rootURL() + "Home/UpdateEmployeeTargetAcceptance" };
  EmployeeIncentiveCalculationsURL(): string { return this.rootURL() + "Calculations/EmployeeIncentiveCalculations" };
  MoveDataToProdURL(): string { return this.rootURL() + "Calculations/MoveDataToProd" };
  CalculateInProdURL(): string { return this.rootURL() + "Calculations/CalculateInProd" };
  GetMetricConfigDetailsURL(): string { return this.rootURL() + "User/GetMetricConfigDetails" };
  SaveMetricConfigDetailsURL(): string { return this.rootURL() + "User/SaveMetricConfigDetails" };
  GetMetricSortDetailsURL(): string { return this.rootURL() + "User/GetMetricSortDetails" };
  SaveMetricSortDetailsURL(): string { return this.rootURL() + "User/SaveMetricSortDetails" };
  DUPortfoliomappingForActualURL(): string { return this.rootURL() + "Upload/DUPortfoliomappingForActual" };
  HunterUSAMappingURL(): string { return this.rootURL() + "Upload/HunterUSAMapping" };

  GetPidmappingforEmployeeURL(): string { return this.rootURL() + "User/GetPidmappingforEmployee" };
  GetAccountmappingforEmployeeURL(): string { return this.rootURL() + "User/GetAccountmappingforEmployee" };

  GetTeamDashBoardGridDataURL(): string { return this.rootURL() + "Home/GetTeamDashboardData" };
  TeamDashBoardExcelFileURl(): string { return this.rootURL() + "../../../UploadTemplates/TeamDashBoardData.xlsx" }
  UpdateAchievementPercentageURL(): string { return this.rootURL() + "Upload/UpdateAchievementPercentage" };
  GetAchievementDashBoardDetailsURL(): string { return this.rootURL() + "Home/GetAchievementDashBoardDetails" };
  ApproveAchievementDetailsURL(): string { return this.rootURL() + "Home/ApproveAchievementDetails" };
  UpdateQualitativeMetricsMasterURL(): string { return this.rootURL() + "User/UpdateQualitativeMetricsMaster" };
  UploadTargetMappingQualitativeURL(): string { return this.rootURL() + "Upload/UploadTargetMappingQualitative" };
  UpdateTargetsAfterConfirmationQualitativeURL(): string { return this.rootURL() + "Home/UpdateTargetsAfterConfirmationQualitative" };
  UploadTargetMappingQualitativeCustomURL(): string { return this.rootURL() + "Upload/UploadTargetMappingQualitativeCustom" };
  UploadPreTargetDetailsForACMURL(): string { return this.rootURL() + "Upload/UploadPreTargetDetailsForACM" };
  UploadPreTargetDetailsForACMWithPUURL(): string { return this.rootURL() + "Upload/UploadPreTargetDetailsForACMWithPU" };
  DashBoardExcelFile2URl(): string { return this.rootURL() + "../../../UploadTemplates/DashBoardData_2.xlsx" }
  GetAppraisalReportDetailsURL(): string { return this.rootURL() + "Reports/GetAppraisalReportDetails" };
  GetDiscretionaryBonusReportURL(): string { return this.rootURL() + "Reports/GetDiscretionaryReportDetails" };
  PublishDBURL(): string { return this.rootURL() + "Home/PublishDBData" };
  GetUploadsForCalculationURL(): string { return this.rootURL() + "User/GetUploadsForCalculation" };
  GetPSNOsCalculationsURL(): string { return this.rootURL() + "User/GetPSNOsCalculations" };
  GetProdPSNOsCalculationsURL(): string { return this.rootURL() + "User/GetProdPSNOsCalculations" };
  CalculationsApprovalURL(): string { return this.rootURL() + "Calculations/CalculationsApproval" };
  MasterInfoDetailsForMetricParameters(): string { return this.rootURL() + "Home/GetBasicInfoOfMasterForMetricParameters" };
  UploadTargetsExcelFileURl1(): string { return this.rootURL() + "../../../UploadTemplates/TargetExcel1.xlsx" }
  GetTargetLetterURL(): string { return this.rootURL() + "Downloads/GetTargetLetter" };
  GetAccountWiseReportURL(): string { return this.rootURL() + "Reports/GetAccountWiseReport" }
  GetCalculationDetailsURL(): string { return this.rootURL() + "Home/GetCalculationDetails" };
  GetRevAccountMappingDetailedURL(): string { return this.rootURL() + "Reports/GetRevAccountMappingDetailedReport" };
  RevUpdateDataURL(): string { return this.rootURL() + "Home/RevUpdateData" }
  DeleteMappingRowURL(): string { return this.rootURL() + "Home/DeleteMappingRow" }
  GetParameterwiseURL(): string { return this.rootURL() + "Home/GetParameterwiseData" };
  GetParameterwiseThresoldURL(): string { return this.rootURL() + "Home/GetParameterwiseThresold" };
  GetMyTargetDataURL(): string { return this.rootURL() + "Home/GetMyTargetData" };
};


